import Socket from '../services/SocketWebrtc';
import PeerConnection from '../services/PeerConnection';

interface StartMessage {
  id: string;
  sdp: string;
  token: string;
  cameraId: number;
}

export default async function start(
  video: HTMLVideoElement,
  selectedCameraId: number,
  jwt: string,
) {
  const pc = new PeerConnection();
  const offer = await pc.createOffer();

  const message: StartMessage = {
    id: 'start',
    sdp: offer,
    token: jwt,
    cameraId: selectedCameraId,
  };
  Socket.send(message);

  pc.initOnIceCandidate(jwt);
  pc.initOnTrack(video);

  return pc.instance;
}
