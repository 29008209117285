import {
  EVENT_APP_LOAD,
  EVENT_AGREEMENTS_LOAD,
  EVENT_AVATAR_UPLOAD,
  EVENT_FIO_UPDATE,
} from 'Actions/app';
import UserService from 'Services/User';

const initialState = {
  user: {
    name: '',
    role: '',
    fname: '',
    sname: '',
    birthDate: {},
    apartments: [{}],
    apartmentIds: [],
    active: null,
    agreements: null,
    avatar: '',
  },
};

export function app(state = initialState, action) {
  let data = {};

  switch (action.type) {
    case EVENT_APP_LOAD:
      data = {
        ...state,
        user: action.event,
      };

      data.user.avatar = UserService.getAvatar(data.user.avatars) || null;

      return data;
    case EVENT_AGREEMENTS_LOAD:
      data = {
        ...state,
      };

      data.user.agreements = action.event.agreementList;

      return data;
    case EVENT_AVATAR_UPLOAD:
      data = {
        ...state,
      };

      data.user.avatar = action.url;

      return data;
    case EVENT_FIO_UPDATE: {
      return {
        ...state,
        user: {
          ...state.user,
          fname: action.payload.fname,
          mname: action.payload.mname,
          sname: action.payload.sname,
        },
      };
    }

    default:
      return state;
  }
}
