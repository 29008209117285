import { STORAGE_KEY, CACHE_ACTIVE } from 'Config';
import Logger from 'Components/Logger';

const storage = window.localStorage;

const storageAvailable = (type) => {
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0)
    );
  }
};

export const getStorageKey = (type) => {
  if (!CACHE_ACTIVE) return storage.removeItem(`${STORAGE_KEY}_${type}`);

  const data = storage.getItem(`${STORAGE_KEY}_${type}`) || '{}';

  try {
    return JSON.parse(data);
  } catch (err) {
    Logger.log(err, 'error', 'Storage');

    return storage.removeItem(`${STORAGE_KEY}_${type}`);
  }
};

export const updateStorage = (type = '', data = {}, merge = false) => {
  if (!CACHE_ACTIVE) return false;

  let _data = data;

  if (merge) {
    _data = {
      ...getStorageKey(type),
      ...data,
    };
  }

  if (storageAvailable('localStorage')) {
    return storage.setItem(`${STORAGE_KEY}_${type}`, JSON.stringify(_data));
  }
};
