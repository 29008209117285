import React from 'react';
import BaseWidget from 'Components/BaseWidget';
import { IconRosette, IconSettings } from 'Components/Icons';
import { DEVICE_STATUS_OFF, DEVICE_STATUS_ON } from 'Config/devices';

class DeviceRosetteWidget extends BaseWidget {
  getData = () => {
    const { status } = this.props;
    let className;
    switch (status) {
      case DEVICE_STATUS_ON:
        className = ' active';
        break;
      case DEVICE_STATUS_OFF:
        className = ' off';
        break;
      default:
        className = '';
    }
    return {
      className,
    };
  };

  openModal = () => {
    this.props.panelOpen('EditDevice', {
      device: this.props,
      type: 'device',
    });
  };

  render() {
    const room = this.props.room ? this.props.room : {};
    const id = this.props.deviceId;
    const sensorData = this.getData();

    return (
      <div
        className={
          'tile-items__item tile-switch tile-switch_led switcher' +
          sensorData.className
        }
      >
        <div className='tile-items__fact-value'>
          <div className='tile-items__icon-sensor'>{IconRosette}</div>
          <div className='tile-items__value'>
            {sensorData.value ? <span>{sensorData.value}</span> : <>&nbsp;</>}
          </div>
        </div>
        <div className='tile-items__fact-titles'>
          <div className='tile-items__group'>
            {this.cropText(this.props.title)}
          </div>
          <div className='tile-items__room'>
            {room.title ? room.title : 'Квартира'}
          </div>
        </div>
        <input
          id={id}
          type='checkbox'
          checked={Number(this.props.status) === DEVICE_STATUS_ON}
          className='tile-items__toggle-status'
          onChange={this.props.onStatusChange.bind(this, id)}
        />
        <button
          type={'button'}
          aria-label={'settings_button'}
          name={'settings_button'}
          className='tile-items__settings-btn'
          onClick={this.openModal}
        >
          {IconSettings}
        </button>
      </div>
    );
  }
}

export default DeviceRosetteWidget;
