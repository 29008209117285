import React from 'react';
import { connect } from 'react-redux';
import BaseWidget from 'Components/BaseWidget';
import {
  IconDeviceTermostat,
  IconSettings,
} from 'Components/Icons';
import { panelOpen } from 'Actions/panel';
import {
  DEVICE_STATUS_OFF,
  DEVICE_STATUS_ON,
  DEVICE_STATUS_WARNING,
} from 'Config/devices';

class DeviceRadiatorWidget extends BaseWidget {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
    };
  }

  openModal = () => {
    this.props.panelOpen('EditDevice', {
      device: this.props,
      type: 'device',
    });
  };

  getData = () => {
    const { status } = this.props;
    let className;

    switch (status) {
      case DEVICE_STATUS_ON:
        className = ' active';
        break;
      case DEVICE_STATUS_OFF:
        className = ' off';
        break;
      case DEVICE_STATUS_WARNING:
        className = ' warning';
        break;
      default:
        className = '';
    }

    return className;
  };

  render() {
    const room = this.props.room ? this.props.room : {};
    const className = this.getData();

    return (
      <div
        className={'tile-items__item tile-switch tile-switch_led' + className}
      >
        <div className='tile-items__fact-value'>
          {/*<div className='tile-items__icon-sensor'>{IconRadiator}</div>*/}
          <div className='tile-items__icon-sensor'>{IconDeviceTermostat}</div>
          <span className='tile-items__value'>&nbsp;</span>
        </div>
        <div className='tile-items__fact-titles'>
          <div className='tile-items__group'>
            {this.cropText(this.props.title)}
          </div>
          <div className='tile-items__room'>
            {room.title ? room.title : 'Квартира'}
          </div>
        </div>
        {/*<div className="tile-items__drag">{IconDrag}</div>*/}
        <button
          type={'button'}
          aria-label={'settings_button'}
          name={'settings_button'}
          className='tile-items__settings-btn'
          onClick={this.openModal}
        >
          {IconSettings}
        </button>
      </div>
    );
  }
}

export default connect(null, {
  panelOpen: panelOpen,
})(DeviceRadiatorWidget);
