const component = 'Counter';

export const EVENT_COUNTERS_LOAD = `${component}/LOAD`;
export const EVENT_COUNTER_CREATE = `${component}/CREATE`;
export const EVENT_COUNTER_UPDATE = `${component}/UPDATE`;

export function countersLoad(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_COUNTERS_LOAD,
      data: data
    });
  };
}

export function counterCreate(data) {
  return (dispatch, getStore) => {
    dispatch({
      type: EVENT_COUNTER_CREATE,
      data: data
    });
    dispatch(countersLoad(getStore().counters))
  };
}

export function counterUpdate(telemetryId, data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_COUNTER_UPDATE,
      telemetryId: telemetryId,
      data: data
    });
  };
}
