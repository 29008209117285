import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import Form from 'Components/Form';
import ControllerService from 'Services/Controller';
import { controllerAdd } from 'Actions/controllers';
import Loader from 'Components/Loader';

const controllerOptions = [
  // {
  //   value: 'tone',
  //   label: 'Универсальный контроллер T.one'
  // },
  {
    value: 'zway',
    label: 'z-wave.me',
  },
  {
    value: 'xiaomi',
    label: 'xiaomi',
  },
  {
    value: 'vera',
    label: 'vera',
  },
  {
    value: 'zipato',
    label: 'zipato',
  },
];

class AddControllerPanel extends BaseComponent {
  state = {
    step: 1,
    content: '',
    controllerType: undefined,
    controllerData: {},
    controllerCred: {},
    controllerRegCred: {},
    controllerId: undefined,
  };

  constructor(props) {
    super(props);

    if (props.email) this.state.controllerRegCred.email = props.email;
  }

  goStep(step) {
    this.setState({
      step: step,
    });
  }

  controllerTypeFormProps() {
    return {
      className: 'form_components-edit',
      fields: {
        type: {
          id: 'form_components-edit__controller-type',
          type: 'select',
          label: (
            <p>
              Давайте определим контроллер, через который вы будете управлять
              устройствами Умного дома. <br />
              Выберите из списка ваш Умный дом:
            </p>
          ),
          className: 'input_full-width input_center',
          validationRules: ['required'],
          props: {
            defaultValue: this.state.type,
            onChange: () => {},
            options: controllerOptions,
            placeholder: 'Выбрать...',
            className: 'align-center',
          },
        },
      },
      btnSubmit: 'Подключить контроллер',
      btnClass: 'btn_primary',
      prependBtn: (formCtx) => {
        if (this.props.onCancel)
          return (
            <span
              className='btn btn_secondary'
              disabled={formCtx.state.formLoad}
              onClick={this.props.onCancel}
            >
              Пропустить
            </span>
          );
      },
      callbackSubmit: (data) => {
        this.setState({
          controllerType: data.type,
        });
        this.goStep(2);
      },
    };
  }

  controllerFormProps() {
    // TODO: get apartmentId from cookies
    const { apartmentId } = this.props;

    return {
      className: 'form_components-edit',
      annotation: (
        <p>Чтобы начать работу с контроллером, введите его название:</p>
      ),
      fields: {
        title: {
          id: 'form_components-edit__controller-title',
          type: 'text',
          label: 'Название Умного дома',
          value: this.state.controllerData.title,
          className: 'input_full-width',
          validationRules: ['required'],
        },
        // ip: {
        //   id: 'form_components-edit__controller-ip',
        //   type: 'text',
        //   mask: '999.999.999.999',
        //   placeholder: '___.___.___.___',
        //   label: 'IP-адрес',
        //   value: this.state.controllerData.ip,
        //   className: 'input_full-width',
        //   validationRules: ['required']
        // },
        // port: {
        //   id: 'form_components-edit__controller-port',
        //   type: 'text',
        //   label: 'Порт',
        //   value: this.state.controllerData.port,
        //   className: 'input_full-width',
        //   validationRules: ['required', 'integer']
        // }
      },
      btnSubmit: 'Далее',
      btnClass: 'btn_primary',
      extendData: {},
      prependBtn: (formCtx) => {
        return (
          <span
            className='btn btn_secondary'
            disabled={formCtx.state.formLoad}
            onClick={this.goStep.bind(this, 2)}
          >
            Назад
          </span>
        );
      },
      callbackSubmit: (data) => {
        ControllerService.addZWave({
          title: data.title,
          login: 'admin',
          password: this.state.controllerRegCred.password,
          id: this.state.controllerId,
          apartmentId: apartmentId,
        }).then((res) => {
          this.props.onControllerAdd(res);
        });

        this.setState({
          controllerData: data,
        });

        this.goStep(5);
      },
    };
  }

  controllerCredentialsFormProps() {
    // TODO: get apartmentId from cookies
    const { apartmentId } = this.props;

    return {
      action: ControllerService.addZWave,
      className: 'form_components-edit',
      annotation: (
        <p>
          При первом подключении необходимо задать логин и пароль
          <br />
          <span className='text-description text-blue add-icon-attention'>
            Пароль должен содержать не менее 6 символов
          </span>
        </p>
      ),
      fields: {
        login: {
          id: 'form_components-edit__controller-login',
          type: 'text',
          label: 'Логин',
          value: this.state.controllerCred.login,
          className: 'input_full-width',
          validationRules: ['required'],
        },
        password: {
          id: 'form_components-edit__controller-password',
          type: 'password',
          label: 'Пароль',
          value: this.state.controllerCred.password,
          className: 'input_full-width',
          validationRules: ['required'],
        },
      },
      extendData: {
        ...this.state.controllerData,
        apartmentId: apartmentId,
      },
      btnSubmit: 'Далее',
      btnClass: 'btn_primary',
      prependBtn: (formCtx) => {
        return (
          <span
            className='btn btn_secondary'
            disabled={formCtx.state.formLoad}
            onClick={this.goStep.bind(this, 3)}
          >
            Назад
          </span>
        );
      },
      callback: (res) => {
        this.setState({
          controller: res,
        });
        this.props.onControllerAdd(res);
        this.goStep(5);
      },
    };
  }

  instructionsContent = (
    <div className='customization-info'>
      <ol className='customization-info__list'>
        <li>
          Подключите к устройству провод питания.
          <br />
          <span>
            Используйте USB зарядное устройство и Micro-USB кабель питания,
            которые входят в комплект.
          </span>
        </li>
        <li>
          Подключите контроллер к локальной сети.
          <br />
          <span>
            Используйте патч-корд, чтобы подключиться к роутеру. Он идет в
            комплекте к устройству.
          </span>
        </li>
      </ol>

      <div className='customization-info__buttons'>
        <span className='btn btn_secondary' onClick={this.goStep.bind(this, 1)}>
          Назад
        </span>
        <span className='btn btn_primary' onClick={this.goStep.bind(this, 0)}>
          Далее
        </span>
      </div>
    </div>
  );

  controllerNotFound = () => {
    return (
      <div className='form form_addDevice'>
        <p className='form_addDevice__info'>
          Не удалось обнаружить контроллер, попробуем еще раз?
        </p>
        <div className='form_addDevice__buttons'>
          <a href='/' className='btn btn_secondary'>
            Пропустить
          </a>
          <button
            type='button'
            className='btn btn_primary'
            onClick={() => {
              this.setState({
                controllerId: undefined,
              });
              this.goStep(1);
            }}
          >
            Подключить
          </button>
        </div>
      </div>
    );
  };

  getCotrollerId() {
    const controllerUrl = 'http://192.168.115.1:8083/';
    const idPath = 'ZAutomation/api/v1/system/remote-id';
    const regPath = 'ZAutomation/api/v1/auth/update/1';
    const accessPath = 'ZAutomation/api/v1/system/first-access';

    const fromProps = {
      annotation: (
        <>
          Мы успешно подключились к вашему контроллеру,{' '}
          <b>ID: {this.state.controllerId}</b>
          <br />
          <span className='text-description add-icon-attention'>
            При первом подключении необходимо задать пароль контроллера и почту
            для восстановления пароля.
          </span>
          <br />
          <br />
        </>
      ),
      className: 'form_components-edit',
      fields: {
        email: {
          id: 'form_components-edit__controller-email',
          type: 'text',
          label: 'Почта',
          value: this.state.controllerRegCred.email,
          className: 'input_full-width',
          validationRules: ['required', 'email'],
        },
        password: {
          id: 'form_components-edit__controller-password',
          type: 'password',
          label: 'Пароль',
          value: this.state.controllerRegCred.password,
          className: 'input_full-width',
          validationRules: ['required'],
        },
      },
      btnSubmit: 'Подтвердить',
      btnClass: 'btn_primary',
      prependBtn: (formCtx) => {
        return (
          <span
            className='btn btn_secondary'
            disabled={formCtx.state.formLoad}
            onClick={this.goStep.bind(this, 2)}
          >
            Назад
          </span>
        );
      },
      callbackSubmit: (data) => {
        this.setState({
          controllerRegCred: data,
        });

        ControllerService.getControllerId(controllerUrl + accessPath, (res) => {
          if (!res.data.defaultProfile) return this.goStep(3);

          const sid = res.data.defaultProfile.sid;
          const regProps = {
            method: 'PUT',
            headers: {
              ZWAYSession: sid,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: 1,
              email: this.state.controllerRegCred.email,
              password: this.state.controllerRegCred.password,
              passwordConfirm: this.state.controllerRegCred.password,
              trust_my_network: 'true',
            }),
          };

          ControllerService.setControllerUser(
            controllerUrl + regPath,
            regProps,
            () => {},
          );
        });

        this.goStep(3);
      },
    };

    if (this.state.controllerId === null) {
      return this.controllerNotFound();
    }

    if (!this.state.controllerId && !this.state.isConnecting) {
      this.setState({
        isConnecting: true,
      });
      ControllerService.getControllerId(controllerUrl + idPath, (res, err) => {
        if (res.data && res.data.remote_id) {
          this.setStateSafe({
            controllerId: res.data.remote_id,
            isConnecting: false,
          });
        } else if (err) {
          this.setStateSafe({
            controllerId: null,
            isConnecting: false,
          });
        }
      });
    }

    if (this.state.isConnecting) {
      return (
        <div className='form form_addDevice'>
          <Loader
            text={
              <>
                Подключение
                <br />
                Контроллера...
              </>
            }
          />
          <div className='form_addDevice__buttons'>
            <button
              type='button'
              className='btn btn_secondary'
              onClick={this.goStep.bind(this, 1)}
            >
              Отмена
            </button>
          </div>
        </div>
      );
    }

    if (this.state.controllerId) {
      return <Form {...fromProps} />;
    }

    return null;
  }

  render() {
    const _step = this.state.step;

    if (_step === 1) {
      return <Form {...this.controllerTypeFormProps()} />;
    } else if (_step === 2) {
      return this.instructionsContent;
    } else if (_step === 0) {
      return this.getCotrollerId();
    } else if (_step === 3) {
      return (
        <div className='controller-credentials'>
          <Form {...this.controllerFormProps()} />
        </div>
      );
    } else if (_step === 4) {
      return (
        <div className='controller-credentials'>
          <Form {...this.controllerCredentialsFormProps()} />
        </div>
      );
    } else if (_step === 5) {
      return (
        <div className='form'>
          <div className='push-notification push-notification_success align-center'>
            Контроллер успешно подключен!
          </div>
          <br />
          <h4 className='text-center'>Давайте подключим устройства</h4>
          <p>
            Осталось еще чуть-чуть: распознать ваши устройства и задать их
            расположение в квартире.
          </p>
          <div className='customization-info'>
            <div className='customization-info__buttons'>
              <span
                className='btn btn_secondary'
                onClick={this.props.callback.bind(this, 'close')}
              >
                Пропустить
              </span>
              <span
                className='btn btn_primary'
                onClick={this.props.callback.bind(this, 'addDevice')}
              >
                Продолжить
              </span>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default connect(
  (state) => ({
    rooms: state.rooms,
    apartmentId: state.apartments.active.apartmentId,
  }),
  {
    onControllerAdd: controllerAdd,
  },
)(AddControllerPanel);
