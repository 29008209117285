import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import Cameras from 'Components/Cameras';
import { connect } from 'react-redux';
import ActivateToken from 'Components/forms/ActivateToken';
import VideoService from 'Services/Video';
import { cameraLoad, cameraUpdate } from 'Actions/video';
import ControlService from 'Services/Control';
import { intercomLoad } from 'Actions/intercom';
import Preloader from 'Components/Preloader';

class Video extends BaseComponent {
  className = 'video';

  state = {
    filteredCameras: [],
  };

  componentDidMount() {
    super.componentDidMount();

    VideoService.getCameras(this.props.activeApartment.apartmentId, (res) => {
      this.props.onCameraLoad(res);

      const apartmentId = this.props.activeApartment.apartmentId;

      ControlService.getIntercom({ apartmentId }, (res) => {
        this.props.onIntercomLoad(res.intercomList);
        this.filterCameras();
      });
    });
  }

  filterCameras = () => {
    const { intercom = [], cameras = [] } = this.props;
    const intercomCameraIds =
      intercom
        .filter(({ cameraId }) => !!cameraId)
        .map(({ cameraId }) => `${cameraId}`) || [];

    this.setState({
      filteredCameras: cameras.filter(
        ({ cameraId }) => !intercomCameraIds.includes(`${cameraId}`),
      ),
    });
  };

  render() {
    const { filteredCameras = [] } = this.state;

    if (filteredCameras.length === 0) return <Preloader />;

    const _apartment = this.props.activeApartment || { apartment: {} };

    if (_apartment.tokenActivated === false) return <ActivateToken />;

    return (
      <div className={this.getPageClasses()}>
        <Cameras
          cameras={filteredCameras}
          activeApartmentId={_apartment.apartmentId}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cameras: state.video.cameras,
    intercom: (state.intercom || {}).list || [],
    activeApartment: state.apartments.active,
  }),
  {
    onCameraLoad: cameraLoad,
    onCameraUpdate: cameraUpdate,
    onIntercomLoad: intercomLoad,
  },
)(Video);
