import React from 'react';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';

class MessageComplainPanel extends BaseSettingPanel {
  title = this.props.t('messenger.message_complaint');
  className = 'message-complain-panel';
  complainOptions = [];
  complainType = null;

  constructor(props) {
    super(props);

    this.complainOptions = this.props
      .t('messenger.options_complaints', {
        returnObjects: true,
      })
      .map((type, index) => {
        return {
          label: type,
          value: index,
          id: this.generateId(`complain_${index}`),
        };
      });

    this.complainForm = {
      className: `form_${this.className}-edit inline-fields submit-right`,
      fields: {
        complain: {
          id: this.generateId('complain'),
          type: 'radio',
          options: this.complainOptions,
          label: this.props.t('messenger.reason_complaint'),
          className: 'row',
          validationRules: ['required'],
          value: this.complainType,
        },
      },
      btnSubmit: this.props.t('commons.buttons.send'),
      btnClass: 'btn_primary',
      callbackSubmit: () => {
        this.props.onClose();
      },
    };
  }

  // TODO: move to Form
  generateId(fieldName) {
    let id = `form_${this.className}-edit`;

    if (fieldName) {
      id += `__${fieldName}`;
    }

    return id;
  }

  content() {
    return <Form {...this.complainForm} />;
  }
}

export default MessageComplainPanel;
