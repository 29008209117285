import React from 'react';
import { connect } from 'react-redux';
import Form from 'Components/Form';
import ControlService from 'Services/Control';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import { roomDelete, roomUpdate } from 'Actions/rooms/rooms';
import { deleteRoomDevices, renameRoomDevices } from 'Actions/devices';
import { panelClose } from 'Actions/panel';
import UserService from 'Services/User';

class EditDevicePanel extends BaseSettingPanel {
  state = {
    room: null,
    title: '',
  };
  title = this.props.t('panels.edit_rooms.title');

  getRooms() {
    return Object.keys(this.props.rooms).map((i) => {
      const room = this.props.rooms[i];

      return {
        value: room.roomId,
        label: room.title,
      };
    });
  }

  // продолжение кода для коллбэка удаления комнаты
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (Object.values(prevProps.rooms).length > Object.values(this.props.rooms).length) {
  //     // удаляем комнаты из редакса девайсов\датчиков
  //     this.props.deleteRoomDevices(this.props.panelData.room.roomId);
  //     this.props.onClose();
  //   }
  // }

  renderForm() {
    const { t } = this.props;
    let action = ControlService.updateRoom;

    const formParams = {
      action: action,
      className: 'form_components-edit',
      fields: {
        title: {
          id: 'form_components-edit__device-title',
          type: 'text',
          label: `${t('panels.edit_rooms.enter_new_name')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
          focus: true,
        },
      },
      btnSubmit: t('commons.buttons.confirm'),
      btnClass: 'btn_primary btn_sm',
      prependBtn: () => (
        <button
          type='button'
          className='btn btn_danger btn_sm'
          onClick={() =>
            this.props.roomDelete({
              devices: this.props.devices,
              roomId: this.props.panelData.room.roomId,
              updateSensor: ControlService.updateSensor, //  удаляем комнату из датчиков
              updateDevice: ControlService.updateDevice, // удаляем комнату из девайсов
              deleteRoom: UserService.deleteRoom, // удаляет room из списка комнат
              deleteRoomDevices: () =>
                this.props.deleteRoomDevices(this.props.panelData.room.roomId),
              onClose: this.props.onClose,
            })
          }
        >
          {t('commons.buttons.delete')}
        </button>
      ),
      formatBeforeSend: (data) => {
        this.setState({ title: data.title });
        return {
          title: data.title,
          room_id: this.props.panelData.room.roomId,
        };
      },
      callback: (res) => {
        if (res && res.ok) {
          this.props.roomUpdate(
            this.state.title,
            this.props.panelData.room.roomId,
          );
          this.props.renameRoomDevices(
            this.state.title,
            this.props.panelData.room.roomId,
          );
          this.props.onClose();
        }
      },
    };

    return <Form {...formParams} />;
  }

  content() {
    return this.renderForm.call(this);
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current === 'EditRooms',
    panelData: state.panel.props,
    rooms: state.rooms,
    devices: state.devices,
  }),
  {
    onClose: panelClose,
    roomUpdate,
    renameRoomDevices,
    roomDelete,
    deleteRoomDevices,
  },
)(EditDevicePanel);
