import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BaseComponent from 'Components/BaseComponent';
import {
  ControlCounters,
  ControlSensors,
  ControlSockets,
  ControlLightning,
  ControlVideo,
  ControlScripts,
  ControlEntry,
  ControlDevices,
  ControlRoom,
  IconSettings,
  ControlVideoDisable,
  ControlCountersDisable,
  ControlEntryDisable,
  ControlSocketsDisable,
  ControlScriptsDisable,
  ControlRoomDisable,
  ControlLightningDisable,
  ControlSensorsDisable,
  ControlDevicesDisable,
} from 'Components/Icons';
import {
  ROUTE_CONTROL_DEVICE,
  ROUTE_CONTROL_ENTRY,
  ROUTE_CONTROL_LIGHTING,
  ROUTE_CONTROL_METER,
  ROUTE_CONTROL_ROSETTE,
  ROUTE_CONTROL_SCENARIO,
  ROUTE_CONTROL_SENSOR,
  ROUTE_CONTROL_VIDEO,
  ROUTE_CONTROL_ROOMS,
} from 'Config/routes';
import { childRole } from './ProfileScreen';
import { withTranslation } from 'react-i18next';

export const createTileItems = (
  items,
  isCustomize = false,
  openModal = null,
  openModalEditDevices = null,
  t = null,
) => {
  return (
    <>
      {items.map((item) => {
        const roomKey = (item.props && item.props.roomId) || null;
        return (
          <li
            key={roomKey ? roomKey : item.title}
            className={`tile-items__wrap two-col`}
          >
            <Link
              to={item.link}
              className={`tile-items__item tile-items__icon-type ${
                item.inRoomDevices > 0 ? 'tile-items__in-room-devices' : ''
              }${item.isDisable ? ' tile-items_disable' : ''}`}
              onClick={() => {
                if (item.inRoomDevices > 0)
                  openModalEditDevices({
                    roomId: item.props.roomId,
                    title: item.title,
                  });
              }}
            >
              <div className='tile-items__fact'>
                <span className='tile-items__image'>{item.image}</span>
              </div>
              <div className='tile-items__fact'>
                <span className='tile-items__title'>{item.title}</span>
                {item.inRoomDevices > 1 && (
                  <span className='tile-items__text'>
                    {item.inRoomDevices}{' '}
                    {t('control_menu.qty_devices.more_one')}
                  </span>
                )}
                {item.inRoomDevices === 1 && (
                  <span className='tile-items__text'>
                    {item.inRoomDevices} {t('control_menu.qty_devices.one')}
                  </span>
                )}
              </div>
            </Link>
            {isCustomize ? (
              <button
                type={'button'}
                aria-label={'settings_button'}
                name={'settings_button'}
                className='tile-items__settings-btn'
                onClick={() => openModal(item.props)}
              >
                {IconSettings}
              </button>
            ) : null}
          </li>
        );
      })}
    </>
  );
};

class Control extends BaseComponent {
  state = {
    tileItems: [],
  };
  className = 'control';
  tileItems = (availableDevices) => [
    {
      link: availableDevices.cameras === 0 ? '#' : ROUTE_CONTROL_VIDEO,
      image:
        availableDevices.cameras === 0 ? ControlVideoDisable : ControlVideo,
      title: this.props.t('control_menu.cameras'),
      text: 'Просмотр видеокамер',
      isDisable: availableDevices.cameras === 0,
    },
    {
      link: availableDevices.cameras === 0 ? '#' : ROUTE_CONTROL_ENTRY,
      image:
        availableDevices.cameras === 0 ? ControlEntryDisable : ControlEntry,
      title: this.props.t('control_menu.intercom'),
      text: 'Взаимодействие с домофоном',
      isDisable: availableDevices.cameras === 0,
    },
    {
      link:
        this.props.user.apartments[0].roleId === childRole
          ? '#'
          : ROUTE_CONTROL_METER,
      image:
        this.props.user.apartments[0].roleId === childRole
          ? ControlCountersDisable
          : ControlCounters,
      title: this.props.t('control_menu.counters'),
      text: 'Графики расхода ГВС и ХВС',
      isDisable:
        availableDevices.counters === 0 ||
        this.props.user.apartments[0].roleId === childRole,
    },
    {
      link: availableDevices.sensors === 0 ? '#' : ROUTE_CONTROL_SENSOR,
      image:
        availableDevices.sensors === 0 ? ControlSensorsDisable : ControlSensors,
      title: this.props.t('control_menu.sensors'),
      text: 'Контроль и управление',
      isDisable: availableDevices.sensors === 0,
    },
    {
      link: availableDevices.lights === 0 ? '#' : ROUTE_CONTROL_LIGHTING,
      image:
        availableDevices.lights === 0
          ? ControlLightningDisable
          : ControlLightning,
      title: this.props.t('control_menu.lighting'),
      text: `Управление выключателями и <br /> яркостью освещения`,
      isDisable: availableDevices.lights === 0,
    },
    {
      link: availableDevices.sockets === 0 ? '#' : ROUTE_CONTROL_ROSETTE,
      image:
        availableDevices.sockets === 0 ? ControlSocketsDisable : ControlSockets,
      title: this.props.t('control_menu.sockets'),
      text: 'Управление розетками',
      isDisable: availableDevices.sockets === 0,
    },
    {
      link: availableDevices.devices === 0 ? '#' : ROUTE_CONTROL_DEVICE,
      image:
        availableDevices.devices === 0 ? ControlDevicesDisable : ControlDevices,
      title: this.props.t('control_menu.devices'),
      text: 'Контроль и управление',
      isDisable: availableDevices.devices === 0,
    },
    {
      link: availableDevices.scenarios === 0 ? '#' : ROUTE_CONTROL_SCENARIO,
      image:
        availableDevices.scenarios === 0
          ? ControlScriptsDisable
          : ControlScripts,
      title: this.props.t('control_menu.scripts'),
      text: 'Управление сценариями',
      isDisable: availableDevices.scenarios === 0,
    },
    {
      link: ROUTE_CONTROL_ROOMS,
      image: ControlRoom,
      title: this.props.t('control_menu.rooms'),
      text: 'Комнаты',
      isDisable: availableDevices.rooms === 0,
    },
  ];

  constructor(props) {
    super(props);
    this.createTileItems = createTileItems;
  }

  componentDidUpdate(prevProps) {
    const { availableDevices } = this.props;

    if (prevProps.availableDevices !== availableDevices) {
      this.setState({
        tileItems: this.tileItems(availableDevices),
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();
    const { availableDevices } = this.props;

    if (availableDevices) {
      this.setState({
        tileItems: this.tileItems(availableDevices),
      });
    }
  }

  render() {
    if (this.state.tileItems !== null && this.state.tileItems.length === 0)
      return this.getLoader();

    return (
      <div className={this.getPageClasses()}>
        <ul className='tile-items tile-items_main-menu'>
          {this.createTileItems(this.state.tileItems)}
        </ul>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.app.user,
    availableDevices: state.availableDevices,
  }),
  null,
)(withTranslation()(Control));
