import React from 'react';
import { connect } from 'react-redux';
import Form from 'Components/Form';
import ControlService from 'Services/Control';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import { roomAdd } from 'Actions/rooms/rooms';
import { deviceEdit, sensorEdit } from 'Actions/devices';
import { panelClose } from 'Actions/panel';

class EditDevicePanel extends BaseSettingPanel {
  state = {
    deviceTitle: null,
    room: null,
    submitted: false,
    status: null,
    type: {},
  };
  title = this.props.t('panels.edit_device.title');

  getRooms() {
    return Object.keys(this.props.rooms).map((i) => {
      const room = this.props.rooms[i];

      return {
        value: room.roomId,
        label: room.title,
      };
    });
  }

  getTypes() {
    let collection = this.props.devicesTypes;
    const { type } = this.props.panelData;

    if (type === 'sensor') {
      collection = this.props.sensorsTypes;
    }

    return Object.keys(collection).map((i) => {
      const deviceType = collection[i];

      return {
        value: deviceType[`${type}TypeId`],
        label: deviceType.title,
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.devices !== this.props.devices &&
      this.props.panelData &&
      this.props.panelData.device
    ) {
      this.props.devices.forEach((d) => {
        if (d.deviceId === this.props.panelData.device.deviceId) {
          this.setState({ status: d.status });
        }
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();

    const { device, type } = this.props.panelData;

    if (device) {
      this.setState({ deviceTitle: device.title, status: device.status });
    }
    if (device.room) {
      this.setState({
        room: {
          label: device.room.title,
          value: device.room.roomId,
        },
      });
    } else {
      this.setState({ room: null });
    }
    if (device.type) {
      this.setState({
        type: {
          label: device.type.title,
          value: device.type[`${type}TypeId`],
        },
      });
    } else {
      this.setState({
        type: null,
      });
    }
  }

  renderForm() {
    let action = ControlService.updateDevice;
    const { device, type } = this.props.panelData;
    const { t } = this.props;

    if (type === 'sensor') action = ControlService.updateSensor;

    const formParams = {
      action: action,
      className: 'form_components-edit',
      fields: {
        title: {
          id: 'form_components-edit__device-title',
          type: 'text',
          label: `${t('commons.inputs.select_title')}:`,
          className: 'input_full-width',
          value: this.state.deviceTitle,
          validationRules: ['required'],
          focus: true,
        },
        room_id: {
          id: 'form_components-edit__device-room',
          type: 'select',
          label: `${t('commons.inputs.room')}:`,
          className: 'input_full-width',
          validationRules: [],
          props: {
            placeholder: `${t('commons.inputs.select_placeholder')}...`,
            isCreatable: true,
            isClearable: true,
            defaultValue: this.state.room,
            options: this.getRooms(),
            // onChange: (data) => {
            //   // этот код вообще не работает?
            //   this.state.room = data;
            //   if (data !== null && !data.value)
            //     UserService.addRoom({
            //       apartment_id: _apartmentId,
            //       title: data.label,
            //     }).then((res) => {
            //       if (res && res.roomId && !Number(data.value)) {
            //         this.state.room.value = res.roomId;
            //
            //         this.setState({
            //           addedRoom: res,
            //         });
            //
            //         this.props.onRoomAdd(res);
            //       }
            //     });
            // },
          },
        },
        [`${type}_type_id`]: {
          id: 'form_components-edit__device-type',
          type: 'select',
          label: `${t('commons.inputs.device_type')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
          props: {
            placeholder: `${t('commons.inputs.select_placeholder')}...`,
            defaultValue: this.state.type,
            options: this.getTypes(),
            className: 'basic-multi-select',
            status: this.state.status,
            readonly: true,
            curValue: device.value || null,
            // onChange: (data) => {
            //   this.state.type = data;
            // },
          },
        },
      },
      extendData: {
        [`${type}_id`]: device[`${type}Id`],
      },
      btnSubmit: t('commons.buttons.confirm'),
      btnClass: 'btn_primary btn_full-width',
      formatBeforeSend: (data) => {
        if (data.room_id) {
          data.room_id = data.room_id.value;
        }
        if (this.state.addedRoom && this.state.addedRoom.roomId) {
          data.room_id = this.state.addedRoom.roomId;
          this.setState({
            addedRoom: {},
          });
        }
        if (data.room_id === null) {
          delete data.room_id;
        }
        data[`${type}_type_id`] = data[`${type}_type_id`].value;

        return data;
      },
      callback: (res) => {
        if (res && res.ok) {
          if (type === 'sensor') this.props.onSensorEdit(res);
          else this.props.onDeviceEdit(res);

          this.props.onClose();
        }
      },
    };

    return <Form {...formParams} />;
  }

  content() {
    if (this.state.type.value && this.state.type.label)
      return this.renderForm.call(this);
    return null;
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current === 'EditDevice',
    panelData: state.panel.props,
    apartmentId: state.apartments.active.apartmentId,
    rooms: state.rooms,
    devices: state.devices.devices,
    devicesTypes: state.devices.devicesTypes,
    sensorsTypes: state.devices.sensorsTypes,
  }),
  {
    onDeviceEdit: deviceEdit,
    onSensorEdit: sensorEdit,
    onClose: panelClose,
    onRoomAdd: roomAdd,
  },
)(EditDevicePanel);
