import {
  ROUTE_CONTROL_METER,
  ROUTE_HOME,
  ROUTE_LOGOUT,
  ROUTE_MESSENGER,
  ROUTE_NEWS,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_PROFILE,
} from './routes';

export const getTitles = (key, t) => {
  const titles = {
    chat: t('navigation.chat'),
    home: t('screen.home.title'),
    document: t('screen.documents.title'),
    settings: t('navigation.settings'),
    profile: t('navigation.profile'),
    applicationToUk: t('navigation.applicationToUk'),
    logout: t('navigation.logout'),
    news: t('screen.news.title'),
  };

  return titles[key];
};

export const NAV_ITEMS = (t) => [
  {
    title: getTitles('home', t),
    link: ROUTE_HOME,
    icon: 'Home02',
    key: 'Home',
  },
  {
    title: getTitles('chat', t),
    link: ROUTE_MESSENGER,
    icon: 'Chat',
    key: 'Chat',
  },
  {
    title: getTitles('profile', t),
    link: ROUTE_SETTINGS_PROFILE,
    icon: 'Profile',
    key: 'Profile',
  },
  {
    title: getTitles('applicationToUk', t),
    link: '#',
    icon: 'Support02',
    key: 'UK',
  },
  {
    title: getTitles('news', t),
    link: ROUTE_NEWS,
    icon: 'News',
    key: 'News',
  },
  {
    title: getTitles('settings', t),
    link: ROUTE_SETTINGS,
    icon: 'Settings',
    key: 'Settings',
  },
];
