import {
  EVENT_COUNTERS_LOAD,
  EVENT_COUNTER_CREATE,
  EVENT_COUNTER_UPDATE,
} from 'Actions/counters';
import {
  IconHotWater,
  IconColdWater,
  IconEnergyMeter,
  IconWarmMeter,
} from 'Components/Icons';
import {
  COUNTER_TYPE_WATER_HOT,
  COUNTER_TYPE_WATER_COLD,
  COUNTER_TYPE_ELECTRICITY,
  COUNTER_TYPE_HEATING,
} from 'Config/devices';
import i18next from 'i18next';

const initialState = {};

export function counters(state = initialState, action) {
  let data = {};

  switch (action.type) {
    case EVENT_COUNTERS_LOAD:
      Object.keys(action.data).map((groupId) => {
        const group = action.data[groupId];

        return Object.keys(group).map((telemetryId) => {
          let counter = group[telemetryId];

          switch (groupId) {
            case COUNTER_TYPE_WATER_HOT:
              counter.icon = IconHotWater;
              counter.unit = i18next.t('meters.history.m3');
              break;
            case COUNTER_TYPE_WATER_COLD:
              counter.icon = IconColdWater;
              counter.unit = i18next.t('meters.history.m3');
              break;
            case COUNTER_TYPE_ELECTRICITY:
              counter.icon = IconEnergyMeter;
              counter.unit = i18next.t('meters.history.kwt');
              break;
            case COUNTER_TYPE_HEATING:
              counter.icon = IconWarmMeter;
              counter.unit = i18next.t('meters.history.gcal');
              break;
            default:
              counter.icon = null;
              counter.unit = '';
          }

          counter.group = groupId;
          counter.price = i18next.t('meters.history.price');

          if (!counter.status)
            counter.status = {
              value: 1,
              label: i18next.t('meters.history.status'),
            };
          if (!counter.valueList) counter.valueList = [];

          return null;
        });
      });

      return {
        ...state,
        ...action.data,
      };
    case EVENT_COUNTER_CREATE:
      if (!action.data.counterType || !action.data.telemetryId) return state;
      data = {
        ...state,
      };

      if (!data[action.data.counterType.unitType])
        data[action.data.counterType.unitType] = {};

      let _group = data[action.data.counterType.unitType] || {};

      if (_group) _group[action.data.telemetryId] = action.data;

      return data;
    case EVENT_COUNTER_UPDATE:
      if (!action.data.group || !action.telemetryId) return state;
      data = {
        ...state,
      };
      let _counter = data[action.data.group][action.telemetryId];

      if (action.data && _counter)
        _counter = {
          ..._counter,
          ...action.data,
        };

      return data;
    default:
      return state;
  }
}
