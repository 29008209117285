const component = 'Messenger';

export const EVENT_MESSENGER_LOAD = `${component}/LOAD`;
export const EVENT_CHAT_LOAD = `${component}/CHAT_LOAD`;
export const EVENT_CHAT_INVITE = `${component}/CHAT_INVITE`;
export const EVENT_CHAT_UPDATED = `${component}/CHAT_UPDATED`;
// Socket events
export const EVENT_CHAT_CREATE = `${component}/CHAT_CREATE`;
export const EVENT_CHAT_UPDATE = `${component}/CHAT_UPDATE`;
export const EVENT_CHAT_CREATE_UPDATE = `${component}/CHAT_CREATE_UPDATE`;
export const EVENT_CHAT_GET_CREATE = `${component}/CHAT_GET_CREATE`;
export const EVENT_CHAT_DISCONNECT = `${component}/CHAT_DISCONNECT`;
export const EVENT_CHAT_CONNECT = `${component}/CHAT_CONNECT`;
export const EVENT_CHAT_RECONNECT = `${component}/CHAT_RECONNECT`;
export const EVENT_CHAT_DELETE = `${component}/CHAT_DELETE`;
export const EVENT_CHAT_CLEAR = `${component}/CHAT_CLEAR`;
// Socket outgoing message events
export const EVENT_CHAT_MESSAGE_SEND = `${component}/CHAT_MESSAGE_SEND`;
export const EVENT_CHAT_MESSAGE_DELETE = `${component}/CHAT_MESSAGE_DELETE`;
export const EVENT_CHAT_MESSAGE_UPDATE = `${component}/CHAT_MESSAGE_UPDATE`;
export const EVENT_CHAT_MESSAGE_REPLY = `${component}/CHAT_MESSAGE_REPLY`;
// Socket incoming message events
export const EVENT_CHAT_MESSAGE = `chatMessage`;
export const EVENT_CHAT_MESSAGE_DELETED = `${component}/CHAT_MESSAGE_DELETED`;
export const EVENT_CHAT_MESSAGE_UPDATED = `${component}/CHAT_MESSAGE_UPDATED`;
export const EVENT_CHAT_MESSAGES = `${component}/GET_MESSAGES`;
export const EVENT_CHAT_MESSAGE_READ = `${component}/CHAT_MESSAGE_READ`;

export function messengerInit(messenger) {
  return (dispatch) => {
    dispatch({
      type: EVENT_MESSENGER_LOAD,
      messenger: messenger,
    });
  };
}
export function chatInvitation(props) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_INVITE,
      data: props,
    });
  };
}
export function chatConnect(chatId) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_CONNECT,
      chatId: chatId,
    });
  };
}
export function chatDisconnect() {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_DISCONNECT,
    });
  };
}
export function chatDelete(chatId) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_DELETE,
      chatId: chatId,
    });
  };
}
export function chatClear(chatId, chatName) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_CLEAR,
      chatName: chatName,
      chatId: chatId,
    });
  };
}
export function chatCreateOrUpdate(props) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_CREATE_UPDATE,
      data: props,
    });
  };
}
export function sendMessage(message, reply, currentChat = {}) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_MESSAGE_SEND,
      message: message,
      reply: reply,
      chat: currentChat,
    });
  };
}
export function incomingMessage(message) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_MESSAGE,
      message: message,
    });
  };
}
export function deleteMessage(messageId, currentChat = {}) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_MESSAGE_DELETE,
      messageId: messageId,
      chat: currentChat,
    });
  };
}
export function editMessage(messageId, currentChat = {}, content) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_MESSAGE_UPDATE,
      messageId: messageId,
      chat: currentChat,
      content: content,
    });
  };
}
export function replyMessage(replyId, message, currentChat = {}) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHAT_MESSAGE_REPLY,
      replyId: replyId,
      message: message,
      chat: currentChat,
    });
  };
}
