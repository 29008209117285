import {
  EVENT_FILTERS_SET,
  EVENT_FILTERS_UPDATE,
} from 'Actions/filters/filters';

import {
  ROUTE_CONTROL_DEVICE,
  ROUTE_CONTROL_LIGHTING,
  ROUTE_CONTROL_ROSETTE,
  ROUTE_CONTROL_SCENARIO,
  ROUTE_CONTROL_SENSOR,
} from 'Config/routes';
import { updateFilter } from 'Panels/FilterDevice';

export interface IFilters {
  pages: {
    location: string;
    filter: {
      room: string | null;
      label: string | null;
      status: string | null;
    };
  }[];
}

const initialState: IFilters = {
  pages: [
    {
      location: ROUTE_CONTROL_SENSOR,
      filter: { room: null, label: null, status: null },
    },
    {
      location: ROUTE_CONTROL_LIGHTING,
      filter: { room: null, label: null, status: null },
    },
    {
      location: ROUTE_CONTROL_ROSETTE,
      filter: { room: null, label: null, status: null },
    },
    {
      location: ROUTE_CONTROL_DEVICE,
      filter: { room: null, label: null, status: null },
    },
    {
      location: ROUTE_CONTROL_SCENARIO,
      filter: { room: null, label: null, status: null },
    },
  ],
};

export const filters = (state = initialState, action) => {
  if (action.type === EVENT_FILTERS_UPDATE) {
    const newState = updateFilter(state, {
      location: action.location,
      room: action.room,
      status: action.status,
      label: action.label,
    });

    return { ...newState };
  }

  if (action.type === EVENT_FILTERS_SET) {
    return { ...state, pages: action.data.pages };
  }
  return { ...state };
};
