import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import ScrollBarConfig from 'Config/scrollBar';
import InputMask from 'react-input-mask';
import AuthService from 'Services/Auth';
import moment from 'moment';
// import UserService from 'Services/User';
// import ControlService from 'Services/Control';
import { appLoad, uploadAvatar, agreementsLoad } from 'Actions/app';
import { controllersLoad } from 'Actions/controllers';
// import {
//   socketConnect,
//   socketMessage,
//   socketError,
//   socketClose,
// } from 'Actions/socket';
import {
  categoriesTypesLoad,
  devicesTypesLoad,
  sensorsTypesLoad,
} from 'Actions/devices';
import Header from 'Containers/user/Header/Header';
import Scrollbar from 'react-scrollbars-custom';
import Oferta from 'Components/Agreements/Oferta';
import Persodata from 'Components/Agreements/Persodata';
import Conf from 'Components/Agreements/Conf';
import { withTranslation } from 'react-i18next';
import Input from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { IconEye, IconEyeOff } from 'Components/Icons';
import Form from 'Components/Form';
import UserService from 'Services/User';

// let socket = {};

const steps = {
  STEP_FORM_REGISTRATION: 1,
  STEP_CONFIRM_EMAIL: 2,
  STEP_OFFERTA: 3,
  STEP_PERSO_DATA: 4,
  STEP_POLITICA_CONFID: 5,
};

class UserRegistration extends BaseComponent {
  state = {
    step: 1,
    identification: false,
    userRegistrationName: '',
    errorUserName: false,
    userRegistrationSecondName: '',
    errorUserSecondName: false,
    userRegistrationPatronymic: '',
    errorUserPatronymic: false,
    userRegistrationPhone: '',
    errorUserPhone: false,
    userRegistrationEmail: '',
    errorUserEmail: false,
    userRegistrationPassword: '',
    errorUserPassword: false,
    userRegistrationPasswordAgain: '',
    errorUserPasswordAgain: false,
    userRegistrationBirthday: '',
    errorUserBirthday: false,
    preloader: false,
    regResponse: {},
    loginResponse: {},
    checkedConditions: false,
    isShowPassword: false,
    isShowPasswordRepeat: false,
    isShowLoader: false,
  };

  constructor(props) {
    super(props);

    this.ScrollBarCfg = new ScrollBarConfig();
  }

  sendForm = (token) => {
    const callback = (regResponse) => {
      if (!regResponse.ok) {
        AuthService.addLog(
          {
            resp: regResponse,
            fname: this.state.userRegistrationName,
            mname: this.state.userRegistrationPatronymic,
            sname: this.state.userRegistrationSecondName,
            parts: this.state.parts,
            vEmail: this.state.vEmail,
            phone: this.state.userRegistrationPhone.replace(/[-)( ]/g, ''),
            password: this.state.userRegistrationPassword,
            email: this.state.userRegistrationEmail,
            birth_date: moment(
              this.state.userRegistrationBirthday,
              'DD.MM.YYYY',
            ).format('DD.MM.YYYY'),
            token: token ? token : this.props.qrToken,
          },
          () => {
            this.setState({ isShowLoader: false }, () => alert('Ошибка!'));
          },
        );
      }

      let err = null;
      if (regResponse.errorList && regResponse.errorList[0])
        err = regResponse.errorList[0];

      if (err) {
        this.setState({ isShowLoader: false });
        switch (err) {
          case 'Phone already exist': {
            this.setState({
              step: steps.STEP_FORM_REGISTRATION,
              errorUserPhoneExists: true,
            });
            break;
          }

          case 'Email already exist': {
            this.setState({
              step: steps.STEP_FORM_REGISTRATION,
              errorUserEmailExists: true,
            });
            break;
          }
          case 'Token is invalid': {
            AuthService.addLog(
              {
                token: this.props.qrToken,
                inviteToken: this.props.inviteToken,
                text: 'token is invalid',
                regResponse,
              },
              () => alert('Неправильный токен!'),
            );
            break;
          }
          default: {
            AuthService.addLog(
              {
                regResponse,
              },
              () => alert('Ошибка!'),
            );
          }
        }
      }

      this.setState({ isShowLoader: false });

      if (!regResponse.active && regResponse.ok && !err) {
        this.setState({ step: steps.STEP_CONFIRM_EMAIL });
      }
    };

    if (token) {
      AuthService.inviteRegistration(
        {
          fname: this.state.userRegistrationName,
          mname: this.state.userRegistrationPatronymic,
          sname: this.state.userRegistrationSecondName,
          phone: this.state.userRegistrationPhone,
          password: this.state.userRegistrationPassword,
          email: this.state.userRegistrationEmail,
          birth_date: moment(
            this.state.userRegistrationBirthday,
            'DD.MM.YYYY',
          ).format('DD.MM.YYYY'),
          token,
        },
        (res) => {
          callback(res);
        },
      );
    } else {
      AuthService.qrCodeActivate(
        {
          fname: this.state.userRegistrationName,
          mname: this.state.userRegistrationPatronymic,
          sname: this.state.userRegistrationSecondName,
          phone: this.state.userRegistrationPhone,
          password: this.state.userRegistrationPassword,
          email: this.state.userRegistrationEmail,
          birth_date: moment(
            this.state.userRegistrationBirthday,
            'DD.MM.YYYY',
          ).format('DD.MM.YYYY'),
          token: this.props.qrToken,
        },
        (regResponse) => {
          callback(regResponse);
        },
      );
    }
  };

  goToStep2 = () => {
    console.log(isValidPhoneNumber(this.state.userRegistrationPhone));
    let vEmail = this.validateEmail(this.state.userRegistrationEmail);

    this.setState({ vEmail });

    if (!this.state.userRegistrationName) {
      this.setState({
        errorUserName: true,
      });
    }

    if (!this.state.userRegistrationSecondName) {
      this.setState({
        errorUserSecondName: true,
      });
    }

    if (!this.state.userRegistrationPatronymic) {
      this.setState({
        errorUserPatronymic: true,
      });
    }
    if (!isValidPhoneNumber(this.state.userRegistrationPhone)) {
      this.setState({
        errorUserPhone: true,
      });
    }
    if (!vEmail) {
      this.setState({
        errorUserEmail: true,
      });
    }
    if (
      !this.state.userRegistrationBirthday ||
      !moment(this.state.userRegistrationBirthday, 'DD.MM.YYYY', true).isValid()
    ) {
      this.setState({
        errorUserBirthday: true,
      });
    }
    if (
      !this.state.userRegistrationPassword ||
      this.state.userRegistrationPassword.length < 8
    ) {
      this.setState({
        errorUserPassword: true,
      });
    }

    if (
      this.state.userRegistrationPassword !==
        this.state.userRegistrationPasswordAgain ||
      this.state.userRegistrationPasswordAgain.length < 8
    ) {
      this.setState({
        errorUserPasswordAgain: true,
      });
    }

    if (
      this.state.userRegistrationName &&
      this.state.userRegistrationSecondName &&
      this.state.userRegistrationPatronymic &&
      isValidPhoneNumber(this.state.userRegistrationPhone) &&
      vEmail &&
      this.state.userRegistrationPassword &&
      this.state.userRegistrationPasswordAgain &&
      this.state.userRegistrationBirthday
    ) {
      if (this.props.inviteToken) {
        this.setState({ isShowLoader: true }, () =>
          this.sendForm(this.props.inviteToken),
        );
      } else if (this.props.qrToken) {
        this.setState({ isShowLoader: true }, () => this.sendForm());
      } else {
        AuthService.addLog(
          {
            text: 'Проблема с токеном',
            token: this.props.qrToken,
            inviteToken: this.props.inviteToken,
          },
          () => {
            alert('Ошибка!');
          },
        );
      }
    } else {
      AuthService.addLog(
        {
          text: 'Неправильно заполнена форма',
          fname: this.state.userRegistrationName,
          mname: this.state.userRegistrationPatronymic,
          sname: this.state.userRegistrationSecondName,
          isValidPhoneNumber: isValidPhoneNumber(
            this.state.userRegistrationPhone,
          ),
          vEmail,
          phone: this.state.userRegistrationPhone.replace(/[-)( ]/g, ''),
          password: this.state.userRegistrationPassword,
          email: this.state.userRegistrationEmail,
          birth_date: moment(
            this.state.userRegistrationBirthday,
            'DD.MM.YYYY',
          ).format('DD.MM.YYYY'),
          token: this.props.qrToken,
          inviteToken: this.props.inviteToken,
        },
        () => {
          alert('Ошибка!');
        },
      );
    }
  };

  confirmEmailForm = {
    action: AuthService.activatedCode,
    annotation: (
      <p className='text-description text-blue add-icon-attention'>
        Код доступа отправлен на почту, указанную при регистрации, введите его в
        поле ниже.
      </p>
    ),
    className: 'form_login',
    fields: {
      code: {
        id: 'form_login__code',
        type: 'text',
        label: ' ',
        className: 'input_full-width',
        validationRules: ['required', { length: 7 }],
      },
    },
    btnSubmit: 'Подтвердить',
    btnClass: 'btn_primary',
    formatBeforeSend: (data) => {
      if (this.props.password && this.props.email) {
        data.email = this.props.email;
      } else {
        data.email = this.state.userRegistrationEmail;
      }

      return data;
    },
    callback: (loginResponse) => {
      if (loginResponse.ok && loginResponse.active) {
        AuthService.login({
          email: this.props.email || this.state.userRegistrationEmail,
          password: this.props.password || this.state.userRegistrationPassword,
        }).then((res) => {
          if (res.ok) {
            UserService.sendToken().then(() => {
              window.location.reload();
            });
          }
        });
      } else {
        AuthService.addLog(
          {
            loginResponse,
          },
          () => {
            alert('Ошибка!');
          },
        );
      }
    },
  };

  handleChangeUserName = (event) => {
    this.setState({
      userRegistrationName: event.target.value,
      errorUserName: false,
    });
  };

  handleChangeUserSecondName = (event) => {
    this.setState({
      userRegistrationSecondName: event.target.value,
      errorUserSecondName: false,
    });
  };

  handleChangeUserPatronymic = (event) => {
    this.setState({
      userRegistrationPatronymic: event.target.value,
      errorUserPatronymic: false,
    });
  };

  handleChangeUserPhone = (event) => {
    console.log(event, 'test', this.state.userRegistrationPhone);
    if (event) {
      return this.setState({
        userRegistrationPhone: event,
        errorUserPhoneExists: false,
        errorUserPhone: false,
      });
    }

    this.setState({
      errorUserPhone: true,
    });
  };

  handleChangeUserEmail = (event) => {
    this.setState({
      userRegistrationEmail: event.target.value,
      errorUserEmailExists: false,
    });
    if (this.validateEmail(event.target.value)) {
      this.setState({
        errorUserEmail: false,
      });
    }
  };

  handleChangeUserPassword = (event) => {
    this.setState({
      userRegistrationPassword: event.target.value,
    });
    if (this.validatePassword(event.target.value)) {
      this.setState({
        errorUserPassword: false,
      });
    }
  };

  handleChangeUserPasswordAgain = (event) => {
    this.setState({ userRegistrationPasswordAgain: event.target.value });

    if (this.state.userRegistrationPassword !== event.target.value) {
      return this.setState({ errorUserPasswordAgain: true });
    }

    return this.setState({ errorUserPasswordAgain: false });
  };

  handleChangeUserBirthday = (e) => {
    const inputVal = e.target.value;

    this.setState({
      userRegistrationBirthday: inputVal,
      errorUserBirthday: false,
    });
  };

  validateEmail = (email) => {
    let reg =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return reg.test(email);
  };

  validatePassword = (pass) => {
    return pass.length > 1;
  };

  openAgreements = (e, step) => {
    e.preventDefault();
    this.setState({ step });
  };

  getHeader = (step) => {
    const { t } = this.props;
    switch (step) {
      case steps.STEP_CONFIRM_EMAIL:
        return (
          <Header
            title={'Проверочный код'}
            goBack={() => {
              // если форма подтверждения открылась, после регистрации,
              // то возвращение назад обратно к форме регистрации
              if (!this.state.checkedConditions) {
                this.props.onCancel();
              } else {
                this.setState({ step: steps.STEP_FORM_REGISTRATION });
              }
            }}
          />
        );
      case steps.STEP_FORM_REGISTRATION:
        return (
          <Header
            title={t('user_registration.title')}
            goBack={this.props.onCancel}
          />
        );
      case steps.STEP_OFFERTA:
        return (
          <Header
            title={t('agreements.public_offer')}
            goBack={() => this.setState({ step: steps.STEP_FORM_REGISTRATION })}
          />
        );
      case steps.STEP_PERSO_DATA:
        return (
          <Header
            title={t('agreements.terms_of_use')}
            goBack={() => this.setState({ step: steps.STEP_FORM_REGISTRATION })}
          />
        );
      case steps.STEP_POLITICA_CONFID:
        return (
          <Header
            title={t('agreements.privacy_policy')}
            goBack={() => this.setState({ step: steps.STEP_FORM_REGISTRATION })}
          />
        );
    }
  };

  componentDidMount() {
    if (this.props.password && this.props.email)
      this.setState({ step: steps.STEP_CONFIRM_EMAIL });
  }

  render() {
    const { t } = this.props;

    if (this.state.isShowLoader) return this.getLoader();

    return (
      <div className='user-registration-page'>
        <Scrollbar cfg={this.ScrollBarCfg}>
          <div className='user-registration-page__wrapper'>
            <div className='user-registration-page__fact'>
              {this.getHeader(this.state.step)}
              {this.state.step === 1 && (
                <div className='user-registration-page__notice'>
                  <p>
                    <span>{t('user_registration.notice_top')}</span>
                    <span>{t('user_registration.notice_bottom')}</span>
                  </p>
                </div>
              )}
            </div>
            <div className='user-registration-page__fact'>
              <div className='container container_md'>
                {this.state.step === steps.STEP_FORM_REGISTRATION && (
                  <form className='form'>
                    <div className='user-registration__step2'>
                      <div className='container container_sm'>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserSecondName ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='userRegistrationSecondName'>
                            {t('commons.inputs.second_name')}:
                          </label>
                          <input
                            id='userRegistrationSecondName'
                            className='form-control'
                            type='text'
                            value={this.state.userRegistrationSecondName}
                            onChange={this.handleChangeUserSecondName}
                            placeholder={t('commons.inputs.second_name')}
                          />
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserSecondName}
                          >
                            {t('user_registration.input_req')}
                          </p>
                        </div>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserName ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='userRegistrationName'>
                            {t('commons.inputs.first_name')}:
                          </label>
                          <input
                            id='userRegistrationName'
                            className='form-control'
                            type='text'
                            value={this.state.userRegistrationName}
                            onChange={this.handleChangeUserName}
                            placeholder={t('commons.inputs.first_name')}
                          />
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserName}
                          >
                            {t('user_registration.input_req')}
                          </p>
                        </div>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserPatronymic ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='userRegistrationPatronymic'>
                            {t('commons.inputs.middle_name')}:
                          </label>
                          <input
                            id='userRegistrationPatronymic'
                            className='form-control'
                            type='text'
                            value={this.state.userRegistrationPatronymic}
                            onChange={this.handleChangeUserPatronymic}
                            placeholder={t('commons.inputs.middle_name')}
                          />
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserPatronymic}
                          >
                            {t('user_registration.input_req')}
                          </p>
                        </div>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserPhone ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='userRegistrationPhone'>
                            {t('commons.inputs.phone')}:
                          </label>
                          <Input
                            placeholder='+12133734253'
                            value={this.state.userRegistrationPhone}
                            onChange={this.handleChangeUserPhone}
                            className={'form-control'}
                            id={'userRegistrationPhone'}
                          />
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserPhone}
                          >
                            {t('user_registration.incorrect_phone')}
                          </p>
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserPhoneExists}
                          >
                            {t('user_registration.phone_exist')}
                          </p>
                        </div>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserEmail ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='userRegistrationEmail'>
                            {t('commons.inputs.email')}:
                          </label>
                          <input
                            id='userRegistrationEmail'
                            className='form-control'
                            type='email'
                            value={this.state.userRegistrationEmail}
                            onChange={this.handleChangeUserEmail}
                            placeholder={t('commons.inputs.email')}
                          />
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserEmail}
                          >
                            {t('user_registration.incorrect_email')}
                          </p>
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserEmailExists}
                          >
                            {t('user_registration.email_exist')}
                          </p>
                        </div>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserPassword ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='errorUserPassword'>
                            {t('commons.inputs.password')}:
                          </label>
                          <div
                            className={`show-password ${
                              this.state.isShowPassword
                                ? 'show-password--show'
                                : 'show-password--hidden'
                            }`}
                          >
                            <input
                              id='errorUserPassword'
                              className='form-control'
                              type={
                                this.state.isShowPassword ? 'text' : 'password'
                              }
                              value={this.state.userRegistrationPassword}
                              onChange={this.handleChangeUserPassword}
                            />
                            <label className={'show-password__label'}>
                              {!this.state.isShowPassword
                                ? IconEye
                                : IconEyeOff}
                              <input
                                type='checkbox'
                                className='show-password__checkbox'
                                onChange={() =>
                                  this.setState({
                                    isShowPassword: !this.state.isShowPassword,
                                  })
                                }
                                checked={this.state.isShowPassword}
                              />
                            </label>
                          </div>
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserPassword}
                          >
                            {t('user_registration.enter_password')}
                          </p>
                          <p
                            className='input__error'
                            hidden={
                              (!this.state.errorUserPassword &&
                                this.state.userRegistrationPassword.length >=
                                  8) ||
                              this.state.userRegistrationPassword.length === 0
                            }
                          >
                            {t('user_registration.short_pass')}
                          </p>
                        </div>

                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserPasswordAgain
                              ? ' is-error'
                              : '')
                          }
                        >
                          <label htmlFor='errorUserPassword'>
                            {t('user_registration.confirm_pass')}:
                          </label>
                          <div
                            className={`show-password ${
                              this.state.isShowPasswordRepeat
                                ? 'show-password--show'
                                : 'show-password--hidden'
                            }`}
                          >
                            <input
                              id='errorUserPasswordAgain'
                              className='form-control'
                              type={
                                this.state.isShowPasswordRepeat
                                  ? 'text'
                                  : 'password'
                              }
                              value={this.state.userRegistrationPasswordAgain}
                              onChange={this.handleChangeUserPasswordAgain}
                            />
                            <label className={'show-password__label'}>
                              {!this.state.isShowPasswordRepeat
                                ? IconEye
                                : IconEyeOff}
                              <input
                                type='checkbox'
                                className='show-password__checkbox'
                                onChange={() =>
                                  this.setState({
                                    isShowPasswordRepeat:
                                      !this.state.isShowPasswordRepeat,
                                  })
                                }
                                checked={this.state.isShowPasswordRepeat}
                              />
                            </label>
                          </div>
                          <p
                            className='input__error'
                            hidden={
                              this.state.userRegistrationPassword ===
                              this.state.userRegistrationPasswordAgain
                            }
                          >
                            {t('user_registration.password_mismatch')}
                          </p>
                        </div>
                        <div
                          className={
                            'form-group' +
                            (this.state.errorUserBirthday ? ' is-error' : '')
                          }
                        >
                          <label htmlFor='userRegistrationBirthday'>
                            {t('commons.inputs.birthday')}:
                          </label>
                          <InputMask
                            id='serRegistrationBirthday'
                            className='form-control'
                            type='ext'
                            value={this.state.userRegistrationBirthday}
                            onChange={this.handleChangeUserBirthday}
                            placeholder={t(
                              'commons.inputs.birthday_placeholder',
                            )}
                            mask='ed.nm.ziyy'
                            alwaysShowMask={false}
                            formatChars={{
                              i: '[0,9]',
                              n: '[0-1]',
                              m: '[0-9]',
                              e: '[0-3]',
                              d: '[0-9]',
                              z: '[1-2]',
                              y: '[0-9]',
                            }}
                          />
                          <p
                            className='input__error'
                            hidden={!this.state.errorUserBirthday}
                          >
                            {!this.state.userRegistrationBirthday
                              ? t('user_registration.input_req')
                              : t('user_registration.invalid_date')}
                          </p>
                        </div>
                        <div className='form-group'>
                          <div className='custom-control custom-control_checkbox'>
                            <div>
                              <input
                                type='checkbox'
                                id={'checkbox_conditions'}
                                className='custom-control__input'
                                checked={this.state.checkedConditions}
                                onChange={() =>
                                  this.setState({
                                    checkedConditions:
                                      !this.state.checkedConditions,
                                  })
                                }
                              />
                              <label
                                className='custom-control__label'
                                htmlFor={'checkbox_conditions'}
                              >
                                <p className='user-registration-page__conditions'>
                                  {t('user_registration.agreement_checkbox')}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div className='user-registration-page__contract-wrapper'>
                            <a
                              href='https://boxt.one/publichnaya-ofreta'
                              className='link'
                              onClick={(e) =>
                                this.openAgreements(e, steps.STEP_OFFERTA)
                              }
                            >
                              {t('agreements.public_offer')}
                            </a>
                            <a
                              href='https://boxt.one/polzovatelskoe-soglashenie-oasu-tone'
                              className='link'
                              onClick={(e) =>
                                this.openAgreements(e, steps.STEP_PERSO_DATA)
                              }
                            >
                              {t('agreements.terms_of_use')}
                            </a>
                            <a
                              href='https://boxt.one/politika-konfidencialnosti-oasu-tone'
                              className='link'
                              onClick={(e) =>
                                this.openAgreements(
                                  e,
                                  steps.STEP_POLITICA_CONFID,
                                )
                              }
                            >
                              {t('agreements.privacy_policy')}
                            </a>
                          </div>
                        </div>
                        <div className='user-registration__buttons text-center'>
                          <button
                            type='button'
                            className='btn btn_full-width btn_primary user-registration__submit'
                            disabled={!this.state.checkedConditions}
                            onClick={this.goToStep2}
                          >
                            {t('commons.buttons.continue')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
                {this.state.step === steps.STEP_CONFIRM_EMAIL && (
                  <div className='container container_sm'>
                    <Form {...this.confirmEmailForm} />
                  </div>
                )}
                {this.state.step === steps.STEP_OFFERTA && <Oferta />}
                {this.state.step === steps.STEP_PERSO_DATA && <Persodata />}
                {this.state.step === steps.STEP_POLITICA_CONFID && <Conf />}
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    app: state.app,
  }),
  {
    onLoad: appLoad,
    updateAvatar: uploadAvatar,
    onAgreementsLoad: agreementsLoad,
    onControllersLoad: controllersLoad,
    // socket actions
    // onOpen: socketConnect,
    // onClose: socketClose,
    // onMessage: socketMessage,
    // onError: socketError,
    onCategoriesTypesLoad: categoriesTypesLoad,
    onDevicesTypesLoad: devicesTypesLoad,
    onSensorsTypesLoad: sensorsTypesLoad,
  },
)(withTranslation()(UserRegistration));
