import React from 'react';
import { connect } from 'react-redux';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import AddDevicesPanel from 'Panels/AddDevicesPanel';
import AddControllerPanel from 'Panels/AddControllerPanel';
import AddRoomPanel from 'Panels/AddRoomPanel';
import { panelClose } from 'Actions/panel';

class ConfigControllerPanel extends BaseSettingPanel {
  title = '';
  className = 'controller-panel';
  state = {
    type: '',
  };

  devicesPanelCallback(res) {
    if (res === 'close') {
      this.props.onClose();
    } else if (res === 'addController') {
      // this.title = this.props.t('panels.config_controller.add_controller');
      this.setState({
        type: res,
      });
    }
  }

  controllerPanelCallback(res) {
    if (res === 'addDevice') {
      // this.title = this.props.t('panels.config_controller.add_device');
      this.setState({
        type: res,
      });
    } else if (res === 'close') {
      this.props.onClose();
    }
  }

  content() {
    const _type = this.state.type || this.props.panelProps.type;

    if (_type === 'addDevice') {
      return (
        <AddDevicesPanel
          t={this.props.t}
          callback={this.devicesPanelCallback.bind(this)}
        />
      );
    } else if (_type === 'addController') {
      return (
        <AddControllerPanel
          t={this.props.t}
          user={this.props.user}
          callback={this.controllerPanelCallback.bind(this)}
        />
      );
    } else if (_type === 'addRoom') {
      return (
        <AddRoomPanel
          t={this.props.t}
          callback={this.controllerPanelCallback.bind(this)}
        />
      );
    }

    return null;
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current === 'ConfigController',
    user: state.app.user,
    rooms: state.rooms,
    panelProps: state.panel.props,
  }),
  {
    onClose: panelClose,
  },
)(ConfigControllerPanel);
