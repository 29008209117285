import React from 'react';
import { PageErrorImage } from 'Components/Icons';

import styles from './styles.module.scss';

export default ({ history }) => {
  return (
    <div className={styles.container}>
      <div>
        {PageErrorImage}
        <span className={styles.content}>Ой, непредвиденная ошибка!</span>
      </div>
      <div>
        <button
          type='button'
          className='btn btn_secondary btn_full-width'
          onClick={() => {
            history.push('/');
          }}
        >
          На главную
        </button>
      </div>
    </div>
  );
};
