import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import Form from 'Components/Form';
// import UserService from 'Services/User'

class ActivateToken extends BaseComponent {
  formProps = {
    className: 'form-activate-token',
    annotation: (
      <p>
        Этот сервис пока недоступен. Для активации данного раздела введите
        токен-ключ.
        <br />
        <span className='text-description text-blue add-icon-attention'>
          Если вы не получили токен-ключ при покупке квартиры или от вашей
          управляющей компании, обратитесь в чат технической поддержки
        </span>
      </p>
    ),
    fields: {
      token: {
        id: 'form-activate-token__key',
        type: 'text',
        placeholder: '_________',
        className: 'input_full-width color-primary-border',
        validationRules: ['required', { length: 9 }],
        focus: true,
        maxLength: 9,
      },
    },
    btnSubmit: 'Далее',
    btnClass: 'btn_primary',
    callbackSubmit: (data) => null,
  };

  render() {
    return <Form {...this.formProps} />;
  }
}

export default ActivateToken;
