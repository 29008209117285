import { EVENT_LOAD_USER_DATA } from 'Actions/userData';

export function userData(state = {}, action) {
  switch (action.type) {
    case EVENT_LOAD_USER_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
