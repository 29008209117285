import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import BaseComponent from 'Components/BaseComponent';
import ConnectedTileItem from 'Components/TileItem';
import ControlSensorWidget from 'Widgets/ControlSensor';
import { devicesLoad, sensorNotifyToggle } from 'Actions/devices';
import FilterDevice from 'Components/FilterDevice';
import { IFilters } from 'Reducers/filters/filters';
import { IItem, IRoomList } from '../Interfaces/interfaces';
import ControlService from 'Services/Control';
import UserService from 'Services/User';
import { roomsLoad } from 'Actions/rooms/rooms';
import { IApartment } from 'Actions/apartments';

class Sensor extends BaseComponent {
  className = 'sensor';
  state = {
    items: [],
    isLoading: true,
  };

  prepareDataRooms = (items: any[]) => {
    const { t } = this.props;
    const filters = this.getFiltersData(
      this.props.filters,
      this.props.location.pathname,
    );
    const { statusFilter, roomFilter } = filters;

    if (statusFilter !== null) {
      let filteredItems: IItem[];

      if (roomFilter !== null) {
        filteredItems = items.filter(
          (item: { room: { title: string }; status: number }) => {
            if (
              item.room &&
              item.room.title === roomFilter &&
              item.status === statusFilter
            )
              return item;
            return false;
          },
        );
      } else {
        filteredItems = items.filter((item: IItem) => {
          if (statusFilter === item.status) return item;
          return false;
        });
      }
      if (filteredItems.length > 0) {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={t('sensors.title')} />
            <ul className='tile-items'>
              {filteredItems.map((i) => {
                return (
                  <ConnectedTileItem key={i.sensorId} className='led'>
                    <ControlSensorWidget {...i} />
                  </ConnectedTileItem>
                );
              })}
            </ul>
          </div>
        );
      } else {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={t('sensors.title')} />
          </div>
        );
      }
    } else {
      const rooms: any = []; // todo сделать интерфейсы

      rooms.push({
        roomId: 0,
        title: 'Квартира',
        items: [],
      });
      Object.keys(this.props.rooms).map((i) => {
        const room = this.props.rooms[i];
        rooms[room.roomId] = {
          ...room,
          items: [],
        };
        return null;
      });

      if (rooms.length === 0) return rooms;

      items.forEach((item: { room: any }) => {
        const room = item.room ? item.room : { roomId: 0 };

        if (rooms[room.roomId]) rooms[room.roomId].items.push(item);
      });
      return (
        <div className={this.getPageClasses()}>
          <FilterDevice title={t('sensors.title')} />
          <ul className='tile-items'>
            {Object.keys(rooms).map((i) => {
              const room = rooms[i];

              if (room.items && room.items.length > 0) {
                return Object.keys(room.items).map((itemId) => {
                  const item = room.items[itemId];
                  return (
                    <ConnectedTileItem key={itemId} className='led'>
                      <ControlSensorWidget {...item} />
                    </ConnectedTileItem>
                  );
                });
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      );
    }
  };

  componentDidMount() {
    super.componentDidMount();
    UserService.getRooms((res: IRoomList) => {
      this.props.onRoomsLoad(res.roomList);
    });

    ControlService.getSensors(
      { apartmentId: this.props.apartmentId },
      (res) => {
        this.props.onDevicesLoad(res.sensorList, 'sensors');
        this.setState({ isLoading: false });
      },
    );
  }

  render() {
    if (this.state.isLoading) return this.getLoader();

    if (this.props.items && this.props.items.length > 0) {
      return this.prepareDataRooms(this.props.items);
    }
    return (
      <div className={this.getPageClasses()}>
        <FilterDevice title={this.props.t('sensors.title')} />
      </div>
    );
  }
}

const mapStateToProps = (state: {
  devices: {
    sensors: IItem[];
  };
  rooms: any;
  filters: IFilters;
  apartments: {
    active: IApartment;
  };
}) => {
  return {
    items: state.devices.sensors,
    rooms: state.rooms,
    filters: state.filters.pages,
    apartmentId: state.apartments.active.apartmentId,
  };
};

export default connect(mapStateToProps, {
  onNotifyToggle: sensorNotifyToggle,
  onDevicesLoad: devicesLoad,
  onRoomsLoad: roomsLoad,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
})(withTranslation()(withRouter(Sensor)));
