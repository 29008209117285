import React from 'react';
import { IconArrow, IconLogout } from 'Components/Icons';
import { useTranslation } from 'react-i18next';
import { ROUTE_LOGOUT } from 'Config/routes';
import { NavLink } from 'react-router-dom';

export default () => {
  const { t } = useTranslation();
  const links = [
    {
      key: 'biometricAgreement',
      value: 'https://boxt.one/biometric-agreement.pdf',
    },
    {
      key: 'licenseAgreement',
      value: 'https://boxt.one/license-agreement-offer.pdf',
    },
    { key: 'privacyPolicy', value: 'https://boxt.one/privacy-policy.pdf' },
    {
      key: 'protectionPersonalData',
      value: 'https://boxt.one/protection-personal-data.pdf',
    },
    { key: 'publicOffer', value: 'https://boxt.one/public-offer.pdf' },
    {
      key: 'revocationPersonalData',
      value: 'https://boxt.one/revocation-personal-data.pdf',
    },
    { key: 'userAgreement', value: 'https://boxt.one/user-agreement.pdf' },
  ];

  return (
    <div
      style={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
      }}
    >
      <h1
        style={{
          fontSize: '2rem',
        }}
      >
        {t(`screen.documents.title`)}
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '15px',
          backgroundColor: '#ffffff',
        }}
      >
        {links.map(({ key, value }, index) => (
          <a
            href={value}
            target='_blank'
            key={key}
            style={{
              padding: '10px',
              cursor: 'pointer',
              borderBottom: index !== links.length - 1 && '1px solid #ccc',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              transition: 'all 0.3s ease',
              ':hover': {
                background: '#f5f5f5',
              },
              gap: '6px',
            }}
          >
            <span
              style={{
                flex: 1,
              }}
            >
              {t(`label.${key}`)}
            </span>
            <span
              style={{
                width: '16px',
              }}
            >
              {IconArrow}
            </span>
          </a>
        ))}
      </div>
      <NavLink
        to={ROUTE_LOGOUT}
        className='btn btn_danger btn_full'
        style={{
          marginTop: '10px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {t('label.logout')}
      </NavLink>
    </div>
  );
};
