import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import Form from 'Components/Form';
import UserService from 'Services/User';
import { roomAdd } from 'Actions/rooms/rooms';

class AddRoomPanel extends BaseComponent {
  state = {
    roomTitle: '',
    submitted: false,
  };
  roomFormProps = {
    action: UserService.addRoom,
    className: 'form_components-edit',
    fields: {
      title: {
        id: 'form_components-edit__controller-title',
        type: 'text',
        label: `${this.props.t('commons.inputs.enter_title')}:`,
        className: 'input_full-width',
        value: this.state.roomTitle,
        validationRules: ['required'],
        focus: true,
      },
    },
    extendData: {
      apartmentId: this.props.apartmentId,
    },
    btnSubmit: this.props.t('commons.buttons.confirm'),
    btnClass: 'btn_primary btn_full-width',
    callback: (data) => {
      if (data) this.props.onRoomAdd(data);

      this.setState({
        submitted: true,
        roomTitle: data.title,
      });
    },
  };

  render() {
    const { t } = this.props;

    if (this.state.submitted) {
      return (
        <div className='form'>
          <form>
            <div className='form__fact'>
              <div className='text-center'>
                {t('panels.config_controller.add_room.success_text.first')}{' '}
                <b>{this.state.roomTitle}</b>{' '}
                {t('panels.config_controller.add_room.success_text.second')}!
              </div>
            </div>
            <div className='form__fact'>
              <div className='text-center'>
                <button
                  type='button'
                  className='btn btn_primary btn_full-width'
                  onClick={() => {
                    this.setState({
                      submitted: false,
                      roomTitle: '',
                    });
                  }}
                >
                  {t('commons.buttons.add_more')}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }

    return <Form {...this.roomFormProps} />;
  }
}

export default connect(
  (state) => ({
    rooms: state.rooms,
    apartmentId: state.apartments.active.apartmentId,
  }),
  {
    onRoomAdd: roomAdd,
  },
)(AddRoomPanel);
