import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BigGrid, SmallGrid } from 'Components/Icons';
import UserService from 'Services/User';
import { loadUserData } from 'Actions/userData';
import { switchGrid } from 'Actions/navigation';

class SwitchGrid extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  updateStatus = (status) => {
    const jsonData = { ...this.props.userData, gridStatus: status };

    this.props.onLoadUserData(jsonData);
    UserService.updateSettings({ json_data: JSON.stringify(jsonData) }).then(
      (res) => {
        if (res) {
          this.props.toggleGrid(status);
        }
      },
    );
  };

  render() {
    return (
      <div className='select-grid'>
        <button
          type='button'
          className={`select-grid__btn btn btn_navigation${
            this.props.currentStatus === 1 ? ' active' : ''
          }`}
          aria-label='two-column'
          onClick={() => this.updateStatus(1)}
        >
          <span>{BigGrid}</span>
        </button>
        <button
          type='button'
          className={`select-grid__btn btn ${
            this.props.currentStatus === 0 ? 'active' : ''
          }`}
          aria-label='one-column'
          onClick={() => this.updateStatus(0)}
        >
          <span>{SmallGrid}</span>
        </button>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentStatus: state.navigation.gridStatus,
    userData: state.userData,
  }),
  { toggleGrid: switchGrid, onLoadUserData: loadUserData },
)(SwitchGrid);
