import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  dateFilter,
} from 'react-bootstrap-table2-filter';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';

import styles from './styles.module.scss';

const selectOptions = {
  0: 'ГВС',
  1: 'ХВС',
};

class ValuesHistoryContainer extends BaseComponent {
  state = {
    rowsDetail: [],
    selectedCounter: null,
  };

  getColumnsDetails = () => {
    const { t } = this.props;
    const counter =
      this.props.counters[Object.keys(this.props.counters)[0]] || {};

    return [
      {
        dataField: 'counter',
        text: t('meters.history.title_counter'),
        sort: true,
        attrs: { title: t('meters.history.title_counter') },
        filter: textFilter(),
      },
      {
        dataField: 'type',
        text: t('meters.history.title_type'),
        attrs: { title: t('meters.history.title_type') },
        filter: selectFilter({
          options: selectOptions,
        }),
      },
      {
        dataField: 'price',
        text: t('meters.history.title_rate') + counter.unit,
        attrs: { title: t('meters.history.title_rate') },
        filter: numberFilter(),
      },
      {
        dataField: 'date',
        text: t('meters.history.title_date'),
        attrs: { title: t('meters.history.title_date') },
        filter: dateFilter(),
      },
      {
        dataField: 'value',
        text: `${t('meters.history.title_values')}, ` + counter.unit,
        attrs: { title: t('meters.history.title_values') },
      },
      {
        dataField: 'cost',
        text: `${t('meters.history.title_consumption')}, ` + counter.unit,
        attrs: { title: t('meters.history.title_consumption') },
      },
      {
        dataField: 'count',
        text: t('meters.history.title_cost'),
        attrs: { title: t('meters.history.title_cost') },
      },
    ];
  };

  getData = (counter) => {
    const rowsDetail = [];
    let count = 0; // Расход в рублях
    let value = 0; // показания в единицах измерения (м3\гкал и.т.д) за всё время
    let cost = 0; // Расход в единицах измерения (м3\гкал и.т.д) за сегодня

    (counter.valueList || []).forEach((_val) => {
      value = Number(_val.value) >= 0 ? Number(_val.value) : 0;
      cost = _val.price.toFixed(2);
      count =
        _val.tarif && _val.tarif.tarif ? _val.price * _val.tarif.tarif : 0;

      rowsDetail.push({
        id: _val.telemetryValueId,
        counter: counter.serialNumber,
        type: counter.label,
        price: (_val.tarif && _val.tarif.tarif) || 0,
        date: moment(_val.createdTs).format('DD.MM.YY'),
        value: value.toFixed(2),
        cost,
        count: count.toFixed(2),
      });
    });

    this.setState({
      rowsDetail,
    });
  };

  customTotal = () => (
    <span className='react-bootstrap-table-pagination-total'>
      {this.props.t('meters.history.rows_in_tables')}:
    </span>
  );

  onClickFilter = (telemetryId) => {
    const counter = this.props.counters.filter(
      (item) => item.telemetryId === telemetryId,
    )[0];
    this.setState({ selectedCounter: telemetryId }, () =>
      this.getData(counter),
    );
  };

  render() {
    const { t } = this.props;

    const options = {
      paginationSize: 4,
      custom: true,
      totalSize: this.state.rowsDetail.length,
      paginationTotalRenderer: this.customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: t('meters.history.all'),
          value: this.state.rowsDetail.length,
        },
      ],
    };

    const _rowsDetail = this.state.rowsDetail.sort((a, b) => {
      return a.id < b.id ? 1 : -1;
    });

    return (
      <div className='data-grid-wrapper'>
        <div className={styles.FiltersContainer}>
          {this.props.counters &&
            this.props.counters.length > 0 &&
            this.props.counters.map((item) => {
              return (
                <button
                  key={item.serialNumber}
                  className={`btn btn_filter ${
                    item.telemetryId === this.state.selectedCounter
                      ? 'btn_filter-active'
                      : ''
                  }`}
                  type={'button'}
                  onClick={() => this.onClickFilter(item.telemetryId)}
                >
                  {item.title}
                </button>
              );
            })}
        </div>
        {!this.state.selectedCounter ? (
          <p>
            Выберите счётчик, по которому хотите получить историю показаний.
          </p>
        ) : _rowsDetail.length > 0 ? (
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  keyField='id'
                  classes='table-filter table-nowrap'
                  data={_rowsDetail}
                  columns={this.getColumnsDetails()}
                  filter={filterFactory()}
                  {...paginationTableProps}
                />
                <div className='react-bootstrap-table-pagination'>
                  <div className='react-bootstrap-table-pagination-list'>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                  <div className='react-bootstrap-table-pagination-select'>
                    <PaginationTotalStandalone {...paginationProps} />
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>
        ) : (
          'Нет данных'
        )}
      </div>
    );
  }
}

export default ValuesHistoryContainer;
