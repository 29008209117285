import { EVENT_PANEL_OPEN, EVENT_PANEL_CLOSE } from 'Actions/panel';

const initialState = {
  current: false,
  props: {},
  isOverflowHidden: false,
};

export function panel(state = initialState, action) {
  switch (action.type) {
    case EVENT_PANEL_OPEN:
      return {
        ...state,
        current: action.panelType,
        props: action.props,
      };
    case EVENT_PANEL_CLOSE:
      return {
        ...state,
        current: false,
        props: {},
      };
    default:
      return state;
  }
}
