import React from 'react';
import { connect } from 'react-redux';
import BaseModal from 'Components/BaseModal';
import Form from 'Components/Form';
import UserService from 'Services/User';
import moment from 'moment';
import { accountCreate } from 'Actions/accounts';
import { getRoles } from 'Services/Utils';

class CreateUserAccountModal extends BaseModal {
  id = 'userCreateModal';
  title = this.props.t('modals.add_user.title');
  className = 'user';
  state = {
    title: '',
    createUserForm: null,
    currentRoleId: null,
  };

  getFilteredApartmentList = () =>
    this.props.apartmentList.filter(
      (item) => item.apartmentId !== this.props.apartmentId,
    );

  generateId(fieldName) {
    let id = `form_${this.className}-create`;

    if (fieldName) {
      id += `__${fieldName}`;
    }

    return id;
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.state.createUserForm) return;

    const roles = [
      { value: 0, label: this.props.t('roles.transfer') },
      ...getRoles(this.props.t),
    ];

    this.setState({
      createUserForm: {
        action: UserService.createUser,
        className:
          'form_user-create inline-fields form-inline-custom form-padding',
        fields: {
          email: {
            id: this.generateId('email'),
            type: 'email',
            label: `${this.props.t('commons.inputs.email')}:`,
            className: 'row',
            validationRules: ['required', 'email'],
          },
          password: {
            id: this.generateId('password'),
            type: 'password',
            label: `${this.props.t('commons.inputs.password')}:`,
            className: 'row',
            validationRules: ['required', { length: { min: 8 } }],
          },
          roleId: {
            id: this.generateId('role'),
            type: 'select',
            label: `${this.props.t('commons.inputs.select_role')}:`,
            className: 'row',
            validationRules: ['required'],
            props: {
              options: roles,
              className: 'basic-select',
              placeholder: `${this.props.t('commons.inputs.find')}...`,
              onChange: (data) => {
                this.setState({ currentRoleId: data.value });
                if (data && data.value === 0) {
                  const newApartmentList = this.getFilteredApartmentList();
                  alert(
                    `Вы уверены? ${
                      newApartmentList.length > 0
                        ? `Ваша активная квартира станет ${newApartmentList[0].stringAddress}`
                        : 'К вашему аккаунту прикреплена только одна квартира, вы выйдете из аккаунта автоматически.'
                    }`,
                  );
                }
              },
            },
          },
        },
        formatBeforeSend: (data) => {
          data.birthDate = moment(data.birthDate).format('YYYY-MM-DD');
          data.apartmentId = this.props.apartmentId;
          data.roleId = data.roleId.value;

          return data;
        },
        btnSubmit: this.props.t('commons.buttons.confirm'),
        btnClass: 'btn_primary',
        callback: (res) => {
          if (res && res.ok) {
            const newApartmentList = this.getFilteredApartmentList();

            // Передача квартиры
            if (this.state.currentRoleId === 0) {
              if (!newApartmentList.length) {
                UserService.logout();
              } else {
                UserService.updateSettings({
                  json_data: JSON.stringify({
                    ...this.props.userData,
                    apartmentId: newApartmentList[0].apartmentId,
                  }),
                }).then(() => {
                  this.props.onSetActiveApartment(newApartmentList[0]);
                  this.props.onLoadUserData({
                    apartmentId: newApartmentList[0].apartmentId,
                  });
                });
              }
            }
            this.props.onAccountCreate(res);
            this.props.onCloseModal();
          }
        },
      },
    });
  }

  content() {
    if (!this.state.createUserForm) return null;

    return <Form {...this.state.createUserForm} />;
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    apartmentList: state.apartments.list,
    userData: state.userData,
  }),
  {
    onAccountCreate: accountCreate,
  },
)(CreateUserAccountModal);
