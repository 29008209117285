interface Message {
  candidate: RTCIceCandidateInit;
}

export default async function ICECandidateFound(
  pc: RTCPeerConnection,
  message: Message,
) {
  await pc.addIceCandidate(new RTCIceCandidate(message.candidate));
}
