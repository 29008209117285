import React, { createRef } from 'react';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';
import { connect } from 'react-redux';
import * as Icons from 'Components/Icons';
import BaseComponent from 'Components/BaseComponent';
import { getNoun } from 'Services/Utils';
import ChatSettingsPanel from 'Components/messenger/SettingsPanel';
import ChatVotingPanel from 'Components/messenger/VotingPanel';
// import VotingResult from 'Components/VotingResult'
import ContextMenu from 'Components/ContextMenu';
import ContextMenuProvider from 'Components/ContextMenuProvider';
import Config, { isMobile } from 'Config';
import { deleteMessage, editMessage } from 'Actions/messenger';
import moment from 'moment';
import MessageComplainPanel from 'Components/messenger/ComplainPanel';

const MESSAGE_EDIT_INTERVAL = 30; // minutes

class Chat extends BaseComponent {
  refBody = createRef();
  state = {
    message: '',
    complainOpened: false,
  };

  constructor(props) {
    super(props);

    this.state.currentId = props.currentId;

    this.ScrollBarCfg = new ScrollBarConfig({
      onLoad: () => {
        this.ScrollBarCfg.toBottom();
      },
    });
  }

  getActions(currentChat) {
    const { t } = this.props;
    const actions = [
      { value: 'clear', label: t('messenger.clear_history') },
      // { value: 'notification', label: 'Включить уведомления' },
    ];

    if (!currentChat.name) {
      actions.push({
        value: 'settings',
        label: t('messenger.settings'),
      });
      actions.push({
        value: 'remove',
        label: <span className='text-red'>{t('messenger.delete_chat')}</span>,
      });
    }

    return actions;
  }

  componentWillMount() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this.props.chatDisconnect();
  }

  getLogoTxt = (title) => {
    if (!title) return null;

    const parts = title.split(' ');
    let label = '';

    parts.forEach((str) => {
      if (label.length < 2) {
        label += str[0].toUpperCase();
      }
    });

    return label;
  };

  scrollToBottom() {
    const ref = this.refBody.current;

    if (!isMobile) this.ScrollBarCfg.toBottom();
    else if (ref) {
      ref.scroll(0, ref.scrollHeight);
    }
  }

  sendMessage = (event) => {
    event.preventDefault();

    const currentChat = this.getCurrentChatInstance();

    if (this.state.message.length === 0) return null;

    this.scrollToBottom();

    const msg = {
      from: this.props.user.userId,
      to: currentChat.to_users_id.toString(),
      chatId: currentChat.chat_id,
      userId: this.props.user.userId,
      date: new Date(),
      text: this.state.message,
      isGroup: true,
    };

    this.clearMessage();

    this.textInput.focus();

    if (this.state.msgReply) {
      this.setState({
        msgReply: undefined,
      });
    }

    if (this.state.msgEdit) {
      this.setState({
        msgEdit: undefined,
      });

      return this.props.onMsgEdit(msg.message_id, currentChat, msg.text);
    }

    this.props.onSubmit(msg, this.state.msgReply);
  };

  setMessage = (event) => {
    const msg = event.target.value;

    this.setState({
      message: msg,
    });
  };

  clearMessage = () => {
    this.setState({
      message: '',
    });
  };

  componentDidUpdate() {
    this.scrollToBottom();

    if (this.state.redirect !== false) this.setState({ redirect: false });
  }

  getCurrentChatInstance = () => {
    const messenger = this.props.messenger;
    const currentChat =
      messenger.chats[this.props.currentId] ||
      messenger.groups[this.props.currentId];

    return currentChat;
  };

  onKeyPressed = (event) => {
    if (event.keyCode === 13) {
      this.sendMessage(event);
    }
  };

  getTime = (date_str) => {
    const date = new Date(date_str);
    const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();

    return hours + ':' + minutes + ` ${moment(date_str).format('DD.MM.YYYY')}`;
  };

  onAction = (item) => {
    const currentChat = this.getCurrentChatInstance();

    if (item.value === 'remove') {
      if (window.confirm('Вы уверены?')) {
        this.setState({
          redirect: true,
        });

        return this.props.onDelete(currentChat.chat_id);
      }
    } else if (item.value === 'clear') {
      currentChat.messages = [];

      this.setState({
        reload: true,
      });

      return this.props.onClear(currentChat.chat_id, currentChat.name);
    } else if (item.value === 'settings') {
      this.props.onAddChatSettingsToggle(true);
    }
  };

  getChatUsers = () => {
    const currentChat = this.getCurrentChatInstance();

    const usersIds = currentChat.to_users_id.split(',');
    const list = [];

    usersIds.map((userId) => {
      if (this.props.messenger.peoples[userId]) {
        list.push(this.props.messenger.peoples[userId]);
      }
      return null;
    });

    return list;
  };

  getUsersCount() {
    const { t } = this.props;
    const currentChat = this.getCurrentChatInstance();

    if (!currentChat) return 0;

    const usersIds = currentChat.to_users_id.split(',');
    const list = [];

    usersIds.map((userId) => {
      if (this.props.messenger.peoples[userId]) {
        list.push(this.props.messenger.peoples[userId]);
      }
      return null;
    });

    return (
      <span
        className='users-count'
        onClick={this.props.onSettingsToggle.bind(this)}
      >
        {list.length +
          ' ' +
          getNoun(
            list.length,
            t('messenger.user_noun.one'),
            t('messenger.user_noun.two'),
            t('messenger.user_noun.more_two'),
          )}
      </span>
    );
  }

  onMsgAction(type, msg) {
    const currentChat = this.getCurrentChatInstance();

    switch (type) {
      case 'delete':
        this.props.onMsgDelete(msg.message_id, currentChat);
        break;
      case 'reply':
        this.setState({
          msgReply: msg,
          msgEdit: undefined,
          complainOpened: false,
        });
        break;
      case 'edit':
        this.setState({
          msgEdit: msg,
          msgReply: undefined,
          complainOpened: false,
          message: msg.content,
        });
        break;
      case 'complain':
        this.setState({
          complainOpened: true,
          msgEdit: undefined,
          msgReply: undefined,
        });
        break;
      default:
        return null;
    }
  }

  cancelReply() {
    this.setState({
      msgReply: undefined,
    });
  }

  cancelEdit() {
    this.setState({
      msgEdit: undefined,
    });
  }

  cancelComplain() {
    this.setState({
      complainOpened: false,
    });
  }

  renderReply(msg) {
    const reply = {};

    if (msg.reply_user_id) {
      reply.userId = msg.reply_user_id;
      reply.content = msg.reply_content;
    } else if (msg.reply) {
      reply.userId = msg.reply.user_id;
      reply.content = msg.reply.content;
    }

    if (!reply.userId || !this.props.messenger.peoples[reply.userId])
      return null;

    return (
      <div className='chat-message-reply'>
        <div className='chat-message-reply__title'>
          <span className='text-blue'>
            {this.props.messenger.peoples[reply.userId].fname +
              ' ' +
              this.props.messenger.peoples[reply.userId].sname}
            {this.props.messenger.peoples[reply.userId].number
              ? `, квартира №
      ${this.props.messenger.peoples[reply.userId].number}`
              : null}
          </span>
        </div>
        <div className='chat-message-reply__message'>{reply.content}</div>
      </div>
    );
  }

  getMsgActions(msg) {
    const { t } = this.props;
    const defaultActions = [
      {
        text: t('messenger.reply'),
        cb: this.onMsgAction.bind(this, 'reply', msg),
      },
      {
        text: t('messenger.complain'),
        cb: this.onMsgAction.bind(this, 'complain', msg),
      },
      {
        text: t('messenger.delete'),
        cb: this.onMsgAction.bind(this, 'delete', msg),
      },
    ];

    if (this.msgIsEdit(msg)) {
      defaultActions.unshift({
        text: t('messenger.edit'),
        cb: this.onMsgAction.bind(this, 'edit', msg),
      });
    }

    return defaultActions;
  }

  msgIsEdit(msg) {
    const createdTs = moment(msg.created_ts);
    const currentTs = moment(Date.now());

    return (
      currentTs.diff(createdTs, 'minutes') < MESSAGE_EDIT_INTERVAL &&
      Number(msg.user_id) === Number(this.props.user.userId)
    );
  }

  onComplainToggle() {
    this.setState({
      complainOpened: false,
    });
  }

  render() {
    const { t } = this.props;
    const currentChat = this.getCurrentChatInstance();
    const chatUsersCount = this.getUsersCount();

    if (currentChat && !currentChat.messages) {
      currentChat.messages = [];
    }

    if (this.state.redirect) {
      window.history.back();
      return null;
    }

    if (currentChat && currentChat.chat_id) {
      return (
        <div className='chat-content' onKeyDown={this.onKeyPressed.bind(this)}>
          <div className='chat-content-head'>
            {/* <Link
              to="/messenger"
              className="chat-content-head__back btn btn_secondary">
              {Icons.IconArrow}
            </Link> */}
            <div className='chat-content-head__img chat-img'>
              {currentChat.logo_img ? (
                <span className='img'>
                  {/*<img src={currentChat.logo_img} alt="" />*/}
                  {Icons[`Icon${currentChat.logo_img}`]}
                </span>
              ) : (
                <span className='txt'>
                  {this.getLogoTxt(currentChat.title)}
                </span>
              )}
            </div>
            <div className='chat-content-head__text'>
              <div className='width-full position-relative'>
                <div className='chat-content-head__fact'>
                  <div className='chat-content-head__title'>
                    {currentChat.title}
                  </div>
                  <div>
                    <span className='chat-content-head__desc'>
                      {chatUsersCount}
                    </span>
                  </div>
                </div>
                <div className='chat-content-head__fact'>
                  <span className='chat-content-head__icon chat-content-head__context'>
                    {currentChat.name ? Icons.IconLock : Icons.IconUnlock}
                  </span>
                  {/*  <BaseSelect*/}
                  {/*    options={this.getActions(currentChat)}*/}
                  {/*    onChange={this.onAction.bind(this)}*/}
                  {/*    className='chat-content-head__context select-context'*/}
                  {/*    isSearchable={false}*/}
                  {/*  />*/}
                </div>
              </div>
            </div>
          </div>
          <div className='chat-content-body' ref={this.refBody}>
            <ChatSettingsPanel
              isOpened={this.props.settingsOpened}
              data={this.getChatUsers()}
              onClose={this.props.onSettingsToggle.bind(this)}
              key={currentChat.chat_id}
              t={t}
            />
            <MessageComplainPanel
              isOpened={this.state.complainOpened}
              onClose={this.onComplainToggle.bind(this)}
              t={t}
            />
            <ChatVotingPanel
              isOpened={this.props.votingOpened}
              onClose={this.props.onVotingToggle.bind(this)}
              t={t}
            />

            <Scrollbar cfg={this.ScrollBarCfg}>
              <div className='chat-content-body__messages'>
                <div
                  className='chat-content-messages'
                  ref={(ref) => {
                    this.messagesRef = ref;
                  }}
                >
                  {currentChat.messages.map((msg, key) => {
                    return (
                      <div
                        key={key}
                        className={`chat-message ${
                          Number(msg.user_id) === Number(this.props.user.userId)
                            ? 'chat-message_yours'
                            : ''
                        }`}
                      >
                        <div
                          className='chat-message__avatar'
                          style={{
                            backgroundImage: `url(${Config.urls.uploads}/user_${msg.user_id}.jpeg)`,
                          }}
                        />
                        <ContextMenuProvider
                          className='chat-message__txt'
                          id={`message-${msg.message_id}`}
                        >
                          <ContextMenu
                            id={`message-${msg.message_id}`}
                            actions={this.getMsgActions(msg)}
                          />
                          <div
                            className={`chat-message__text ${
                              !msg.is_readed &&
                              Number(msg.user_id) !==
                                Number(this.props.user.userId)
                                ? 'chat-message__unread'
                                : ''
                            }`}
                          >
                            {this.renderReply(msg)}
                            {Number(msg.user_id) !==
                              Number(this.props.user.userId) && (
                              <div className='chat-message__title'>
                                <span className='text-blue'>
                                  {this.props.messenger.peoples[msg.user_id] &&
                                  this.props.messenger.peoples[msg.user_id]
                                    .number
                                    ? this.props.messenger.peoples[msg.user_id]
                                        .fname +
                                      ' ' +
                                      this.props.messenger.peoples[msg.user_id]
                                        .sname
                                    : t('messenger.anonymous')}
                                  {this.props.messenger.peoples[msg.user_id] &&
                                  this.props.messenger.peoples[msg.user_id]
                                    .number
                                    ? `, ${t('messenger.apartment')}
                                  ${
                                    this.props.messenger.peoples[msg.user_id]
                                      .number
                                  }`
                                    : null}
                                </span>
                              </div>
                            )}
                            <div className='chat-message__message'>
                              {msg.content}
                            </div>
                          </div>
                          <div className='clear' />
                          <div className='chat-message__time'>
                            {this.getTime(msg.created_ts)}
                          </div>
                        </ContextMenuProvider>
                      </div>
                    );
                  })}
                  {/* {currentChat.name === 'house' ? <VotingResult /> : null} */}
                </div>
              </div>
            </Scrollbar>
          </div>
          <div className='chat-content-input'>
            {this.state.msgReply ? (
              <div className='chat-message-reply'>
                <div className='chat-message-reply__title'>
                  <span className='text-blue'>
                    {this.props.messenger.peoples[this.state.msgReply.user_id]
                      .fname +
                      ' ' +
                      this.props.messenger.peoples[this.state.msgReply.user_id]
                        .sname}
                    {this.props.messenger.peoples[this.state.msgReply.user_id]
                      .number
                      ? `, квартира №
                  ${
                    this.props.messenger.peoples[this.state.msgReply.user_id]
                      .number
                  }`
                      : null}
                  </span>
                </div>
                <div className='chat-message-reply__content'>
                  {this.state.msgReply.content}
                </div>
                <span
                  className='chat-message-reply__cancel'
                  onClick={this.cancelReply.bind(this)}
                >
                  ×
                </span>
              </div>
            ) : null}
            {this.state.msgEdit ? (
              <div className='chat-message-edit'>
                <div className='chat-message-edit__title'>
                  <span className='text-blue'>
                    {this.props.messenger.peoples[this.state.msgEdit.user_id]
                      .fname +
                      ' ' +
                      this.props.messenger.peoples[this.state.msgEdit.user_id]
                        .sname}
                    {this.props.messenger.peoples[this.state.msgEdit.user_id]
                      .number
                      ? `, квартира №
                  ${
                    this.props.messenger.peoples[this.state.msgEdit.user_id]
                      .number
                  }`
                      : null}
                  </span>
                </div>
                <div className='chat-message-edit__content'>
                  {this.state.msgEdit.content}
                </div>
                <span
                  className='chat-message-edit__cancel'
                  onClick={this.cancelEdit.bind(this)}
                >
                  ×
                </span>
              </div>
            ) : null}
            <form onSubmit={this.sendMessage.bind(this)}>
              <textarea
                placeholder={t('messenger.enter_message')}
                onChange={this.setMessage.bind(this)}
                value={this.state.message}
                ref={(ref) => {
                  this.textInput = ref;
                }}
              />
              <button type='submit'>{Icons.IconSend}</button>
              {/* <div className="chat-content-input__actions">
                <button
                  type="button"
                  onClick={this.props.onVotingToggle.bind(this)}>
                  {Icons.IconVoting}
                </button>
              </div> */}
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className='chat-empty'>
          <div>
            <h2>Выберите чат</h2>
          </div>
        </div>
      );
    }
  }
}

export default connect(null, {
  onMsgDelete: deleteMessage,
  onMsgEdit: editMessage,
})(Chat);
