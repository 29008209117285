import { COOKIE_PREFIX } from 'Config';

const getCookie = (name) => {
  let c_name = `${COOKIE_PREFIX}${name}`;

  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        c_name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
const setCookie = (name, value, options) => {
  options = options || {};

  options['path'] = '/';

  let expires = options.expires;

  if (typeof expires == 'number' && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let c_name = `${COOKIE_PREFIX}${name}`;

  let updatedCookie = c_name + '=' + value;

  for (let propName in options) {
    updatedCookie += '; ' + propName;
    let propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }

  document.cookie = updatedCookie;
};

const deleteCookie = (name) => {
  setCookie(name, null, {
    expires: -1,
  });
};

const CookieService = {
  get: getCookie,

  set: setCookie,

  del: deleteCookie,
};

export default CookieService;
