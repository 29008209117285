import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import {IconClose} from 'Components/Icons';
import Scrollbar from 'Components/BaseScrollbar';

/**
 * title
 * className
 * props[isOpened]
 * content()
 */

class BaseSettingPanel extends BaseComponent {
  title;
  className = '';

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  getPanelTitle() {
    let panelTitle = this.title || this.props.title;

    if (this.props.panelProps && this.props.panelProps.title)
      panelTitle = this.props.panelProps.title;

    return panelTitle;
  }

  render() {
    if (!this.props.isOpened) return null;

    return (
      <div
        className={`settings-panel ${this.className ? this.className : ''}${
          this.props.isOpened ? ' open' : ' close'
        }`}
      >
        <button
          type='button'
          className='modal-close settings-panel-close'
          onClick={this.onClose.bind(this)}
        >
          {IconClose}
        </button>
        <h3 className='settings-panel__title'>{this.getPanelTitle()}</h3>
        <div className='settings-panel__content-wrapper'>
          <div className={`settings-panel__content ${this.className}`}>
            <Scrollbar noDefaultStyles>{this.content()}</Scrollbar>
          </div>
        </div>
      </div>
    );
  }
}

export default BaseSettingPanel;
