import React from 'react';
import {
  // DEVICE_CONDITIONER_ID,
  DEVICE_HEAT_FLOOR_ID,
  DEVICE_LIGHT_CONTROL_ID,
  DEVICE_LIGHT_RGB_ID,
  // DEVICE_LIGHT_RGB_ID,
  DEVICE_TERMOSTAT_ID,
} from 'Config/devices';
import LightSettings from 'Components/advancedSettings/Light';
// import Conditioner from 'Components/advancedSettings/Conditioner';
import DeviceTemperature from 'Components/advancedSettings/common/DeviceTemperature';
import { IconThermostat, IconWarmFloor } from 'Components/Icons';

const AdvancedSettings = ({
  id,
  deviceId,
  onRangeChange,
  rangeSliderValue,
  isTurnOn,
}: {
  id: string;
  deviceId: number;
  onRangeChange: (value: number) => React.SetStateAction<number>;
  rangeSliderValue: number;
  isTurnOn: boolean;
}) => {
  switch (id) {
    case DEVICE_LIGHT_CONTROL_ID:
    case DEVICE_LIGHT_RGB_ID:
      return (
        <LightSettings
          type={id}
          rangeSliderValue={rangeSliderValue || 10}
          onRangeChange={onRangeChange}
          isDisabled={isTurnOn}
        />
      );
    // case DEVICE_CONDITIONER_ID:
    //   return (
    //     <Conditioner
    //       rangeSliderValue={rangeSliderValue || 10}
    //       onRangeChange={onRangeChange}
    //       deviceId={deviceId}
    //     />
    //   );
    case DEVICE_HEAT_FLOOR_ID:
      return (
        <DeviceTemperature
          icon={IconWarmFloor}
          rangeSliderValue={rangeSliderValue || 10}
          deviceId={deviceId}
          onRangeChange={onRangeChange}
          isDisabled={isTurnOn}
        />
      );
    case DEVICE_TERMOSTAT_ID:
      return (
        <DeviceTemperature
          icon={IconThermostat}
          rangeSliderValue={rangeSliderValue || 10}
          deviceId={deviceId}
          onRangeChange={onRangeChange}
          isDisabled={isTurnOn}
        />
      );
    default:
      return null;
  }
};

export default AdvancedSettings;
