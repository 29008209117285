import React, { useState, useEffect } from 'react';
import UserService from 'Services/User';
import Webrtc from 'Components/Webrtc';
import WebrtcTwo from 'Components/WebtrcTwo';
import BaseSelect from 'Components/BaseSelect';

interface Button {
  intercomId: string;
}

interface Camera {
  buttons: Button[];
  cameraId: number;
  hasPlateRecognition: boolean;
  hasRecognition: boolean;
  name: string;
  rtspUrl: string;
  sort: number;
  url: string;
}

interface Props {
  cameras: Camera[];
  activeApartmentId: number;
}

type WebrtcVersion = 0 | 1 | 2;

const Cameras: React.FC<Props> = ({ cameras, activeApartmentId }) => {
  const options = [];
  const option = null;
  const [dnsName, setDnsName] = useState<string | null>(null);
  const [webrtcVersion, setWebrtcVersion] = useState<WebrtcVersion>(0);
  const [cameraOptions, setCameraOptions] = useState<
    { label: string; value: number }[]
  >([]);

  useEffect(() => {
    UserService.getUser(async (user) => {
      const apartment = user.apartments.find(
        (x) => x.apartmentId === activeApartmentId,
      );
      if (apartment) {
        setDnsName(apartment.building.dnsName);

        try {
          const response = await fetch(
            `https://${apartment.building.dnsName}/streams`,
            {
              headers: {
                Authorization: 'Basic ' + btoa('demo:demo'),
              },
            },
          );

          if (response.ok) {
            setWebrtcVersion(2);
          } else if (response.status === 404) {
            setWebrtcVersion(1);
          } else {
            setWebrtcVersion(0);
            console.error('Error fetching streams. Status:', response.status);
          }
        } catch (error) {
          setWebrtcVersion(0);
          console.error('Error fetching streams:', error);
        }
      }
    });

    const options = cameras.map((camera) => ({
      label: camera.name,
      value: camera.cameraId,
    }));
    setCameraOptions(options);
  }, [activeApartmentId, cameras]);

  let componentToRender;
  if (webrtcVersion === 0) {
    componentToRender = (
      <div>
        <video
          playsInline
          controls
          muted
          style={{
            width: '100%',
            backgroundColor: 'black',
          }}
        ></video>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '10px',
          }}
        >
          <BaseSelect
            isCreatable={false}
            isClearable={false}
            isSearchable={false}
            options={options}
            value={option}
            placeholder='Выберите камеру'
          />
        </div>
      </div>
    );
  } else if (webrtcVersion === 1) {
    componentToRender = (
      <Webrtc dnsName={dnsName} options={cameraOptions} cameras={cameras} />
    );
  } else if (webrtcVersion === 2) {
    componentToRender = (
      <WebrtcTwo dnsName={dnsName} options={cameraOptions} cameras={cameras} />
    );
  }

  return <div>{componentToRender}</div>;
};

export default Cameras;
