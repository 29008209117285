import React from 'react'
import BaseComponent from 'Components/BaseComponent'
import { Menu, Item } from 'react-contexify'

class ContextMenu extends BaseComponent {
  render() {
    return (
      <Menu id={this.props.id}>
        {this.props.actions.map((action, id) => {
          return (
            <Item key={id} onClick={action.cb}>
              {action.text}
            </Item>
          )
        })}
      </Menu>
    )
  }
}

export default ContextMenu
