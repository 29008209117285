const component = 'Device';

export const EVENT_DEVICES_LOAD = `${component}/LOAD`;
export const EVENT_DEVICE_OFF = `${component}/DEVICE_OFF`;
export const EVENT_STATUS_TOGGLE_DEVICE = `${component}/STATUS_TOGGLE`;
export const EVENT_NOTIFY_TOGGLE_SENSOR = `${component}/NOTIFY_TOGGLE`;

export const EVENT_SENSORS_TYPES_LOAD = `${component}/SENSORS_TYPES_LOAD`;
export const EVENT_DEVICES_TYPES_LOAD = `${component}/DEVICES_TYPES_LOAD`;
export const EVENT_CATEGORIES_TYPES_LOAD = `${component}/CATEGORIES_TYPES_LOAD`;
export const EVENT_DEVICE_EDIT = `${component}/EVENT_DEVICE_EDIT`;
export const EVENT_SENSOR_EDIT = `${component}/EVENT_SENSOR_EDIT`;
export const EVENT_DEVICE_EDIT_VALUE = `${component}/EVENT_DEVICE_EDIT_VALUE`;

export const RENAME_ROOM_DEVICE = `${component}/RENAME_ROOM_DEVICE`;
export const DELETE_ROOM_DEVICE = `${component}/DELETE_ROOM_DEVICE`;

export function devicesLoad(event = [], dataType) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DEVICES_LOAD,
      event: event,
      dataType: dataType,
    });
  };
}

export function sensorNotifyToggle(sensorId) {
  return (dispatch) => {
    dispatch({
      type: EVENT_NOTIFY_TOGGLE_SENSOR,
      sensorId: sensorId,
    });
  };
}

export function deviceStatusToggle(deviceId) {
  return (dispatch) => {
    dispatch({
      type: EVENT_STATUS_TOGGLE_DEVICE,
      deviceId: deviceId,
    });
  };
}

export function categoriesTypesLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CATEGORIES_TYPES_LOAD,
      event: event,
    });
  };
}

export function devicesTypesLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DEVICES_TYPES_LOAD,
      event: event,
    });
  };
}

export function sensorsTypesLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_SENSORS_TYPES_LOAD,
      event: event,
    });
  };
}

export function deviceEdit(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DEVICE_EDIT,
      device: event,
    });
  };
}

export function sensorEdit(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_SENSOR_EDIT,
      sensor: event,
    });
  };
}

export const renameRoomDevices = (title, id) => ({
  type: RENAME_ROOM_DEVICE,
  id,
  title,
});

export const deleteRoomDevices = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_ROOM_DEVICE,
      id,
    });
  };
};

export const editDeviceValue = (payload) => ({
  type: EVENT_DEVICE_EDIT_VALUE,
  payload,
});
