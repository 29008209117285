import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthService from 'Services/Auth';
import ErrorPage from 'Components/ErrorPage';
import { withRouter } from 'react-router-dom';
import { toggleNotification } from 'Actions/notify';

class ErrorBoundary extends Component<
  {
    notificationsOpen: boolean;
    onNotificationsToggle: (bol: boolean) => void;
  },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { history } = this.props;

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }

      if (this.props.notificationsOpen) {
        this.props.onNotificationsToggle(false);
      }
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    AuthService.addLog({ error: error.stack.toString(), errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <ErrorPage history={this.props.history} />
      );
    }

    return this.props.children;
  }
}

export default connect(
  (state: { notify: { notificationsOpen: boolean } }) => ({
    notificationsOpen: state.notify.notificationsOpen,
  }),
  { onNotificationsToggle: toggleNotification },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(withRouter(ErrorBoundary));
