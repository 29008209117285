import React, { useState, useRef, useEffect } from 'react';
import AuthService from 'Services/Auth';

interface Props {
  dnsName: string;
  cameraId: number;
}

const WebtrcVideo: React.FC<Props> = ({ dnsName, cameraId }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [webrtc, setWebrtc] = useState<RTCPeerConnection | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [time, setTime] = useState<number | null>(null); // хранение предыдущего значения времени

  function startPlay(cameraId: number): void {
    if (webrtc) {
      webrtc.close();
    }

    const newWebrtc = new RTCPeerConnection({
      iceServers: [
        {
          urls: ['stun:stun.l.google.com:19302'],
        },
      ],
    });

    newWebrtc.ontrack = function (event: RTCTrackEvent) {
      if (videoRef.current) {
        videoRef.current.srcObject = event.streams[0];
        videoRef.current.play();
      }
    };

    newWebrtc.addTransceiver('video', { direction: 'sendrecv' });

    newWebrtc.onnegotiationneeded = async function handleNegotiationNeeded() {
      const offer = await newWebrtc.createOffer();
      await newWebrtc.setLocalDescription(offer);

      const headers = new Headers();
      headers.append('Authorization', 'Basic ' + btoa('demo:demo'));

      fetch(
        `https://${dnsName}/stream/camera${cameraId}/channel/0/webrtc?token=${
          AuthService.getToken() as string
        }`,
        {
          method: 'POST',
          body: new URLSearchParams({
            data: btoa(newWebrtc.localDescription!.sdp),
          }),
          headers: headers,
        },
      )
        .then((response) => response.text())
        .then((data) => {
          try {
            newWebrtc.setRemoteDescription(
              new RTCSessionDescription({
                type: 'answer',
                sdp: atob(data),
              }),
            );
          } catch (e) {
            setIsError(true);
          }
        });
    };

    const webrtcSendChannel = newWebrtc.createDataChannel(
      'rtsptowebSendChannel',
    );
    webrtcSendChannel.onopen = (_event) => {
      console.log(`${webrtcSendChannel.label} has opened`);
      webrtcSendChannel.send('ping');
    };
    webrtcSendChannel.onclose = (_event) => {
      console.log(`${webrtcSendChannel.label} has closed`);
    };
    webrtcSendChannel.onmessage = () => {};

    setWebrtc(newWebrtc);
  }

  const restartConnection = () => {
    console.log('restarting connection');

    startPlay(cameraId);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current && videoRef.current.currentTime !== null) {
        if (!videoRef.current.currentTime) {
          return;
        }
        if (time !== null && time === videoRef.current.currentTime) {
          restartConnection();
          return;
        }
        setTime(videoRef.current.currentTime);
      }
    }, 2500);

    return () => clearInterval(interval);
  }, [videoRef, time]);

  useEffect(() => {
    startPlay(cameraId);
  }, []);

  return (
    <>
      {isError ? (
        <div className={`video-player__content no-signal`}>
          <div className='video-player__text'>Нет сигнала</div>
          <video></video>
        </div>
      ) : (
        <video
          ref={videoRef}
          playsInline
          controls
          muted
          autoPlay
          style={{ width: '100%', height: 'auto' }}
        ></video>
      )}
    </>
  );
};

export default WebtrcVideo;
