import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';
import { panelClose } from 'Actions/panel';

class EditDevicesInRoom extends BaseSettingPanel {
  title = this.props.panelData.props.title;

  renderForm() {
    const formParams = {
      action: null,
      className: 'form_in-room-devices',
      fields: {
        items: {
          type: 'in-room-devices',
          devices: this.props.panelData.props.devices,
          light: this.props.panelData.props.light,
          rosette: this.props.panelData.props.rosette,
          sensors: this.props.panelData.props.sensors,
        },
      },
      formatBeforeSend: () => null,
      callback: () => null,
    };

    return <Form {...formParams} />;
  }

  content() {
    return this.renderForm.call(this);
  }
}

const mapStateToProps = (state) => {
  return {
    isOpened: state.panel.current === 'EditDevicesInRoom',
    panelData: state.panel,
    sensors: state.devices.sensors,
    devices: state.devices.devices,
  };
};

export default connect(mapStateToProps, {
  onClose: panelClose,
})(withRouter(EditDevicesInRoom));
