import Config from 'Config';

let socket = null;
const url = `${Config.urls.chat}/`;

const SocketService = {
  connect() {
    socket = new WebSocket(url);

    socket.onopen = SocketService.onOpen;
    socket.onclose = SocketService.onClose;
    socket.onmessage = SocketService.onMessage;
    socket.onerror = SocketService.onError;

    return socket;
  },

  execute(event) {
    if (socket && event) {
      socket.send(JSON.stringify(event));
    }
  },

  onOpen(event) {
    console.log('WebSocket onOpen:', event);
  },

  onClose(event) {
    console.log('WebSocket onClose: ', event);
  },

  onMessage(event) {
    console.log('WebSocket onMessage: ', event);
  },

  onError(event) {
    console.log('WebSocket onError: ', event);
  },

  reconnect() {
    this.close();
    return this.connect();
  },

  close() {
    if (!socket) return;

    return socket.close();
  },
};

export default SocketService;
