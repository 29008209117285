import ApiService from 'Services/Api';
import Logger from 'Components/Logger';
import { updateStorage, getStorageKey } from 'Services/Storage';
import { isEmptyObj } from 'Services/Utils';

const _module = 'meter';
const _endpoint = 'counters';
let _errCount = 0;

const request = (method, data, cb) => {
  if (_errCount >= 10) {
    return Logger.log('API error', 'error');
  }

  const storageCounters = getStorageKey('counters') || {};
  let skip = true;
  let _counters = {};

  switch (method) {
    case 'getElectricity':
      if (!storageCounters.electricity) {
        _counters.electricity = storageCounters.electricity;
        skip = false;
      }
      break;
    case 'getWaterSupply':
      console.log(_counters, storageCounters);
      if (!storageCounters.cool_water || !storageCounters.hot_water) {
        _counters.cool_water = storageCounters.cool_water;
        _counters.hot_water = storageCounters.hot_water;
        skip = false;
        console.log(_counters, storageCounters);
      }
      break;
    case 'getHeating':
      if (!storageCounters.heating) {
        _counters.heating = storageCounters.heating;
        skip = false;
      }
      break;
    default:
      skip = true;
  }

  if (!isEmptyObj(_counters) && skip === true) {
    return cb({
      counters: _counters,
    });
  }

  return ApiService[_module][method](_endpoint, data)
    .then((res) => {
      if (!res || !res.counters) return null;

      cb(res);

      updateStorage('counters', res.counters, true);
    })
    .catch((err) => {
      Logger.log(err, 'error');

      _errCount++;
    });
};

const MeterService = {
  addCounter: (data) => {
    return ApiService.addCounter(data);
  },
  addCounterValue: (data) => {
    return ApiService.addCounterValue(data);
  },
  getCounterReceipt: (type, data, cb = null) => {
    return ApiService.getCounterReceipt(type, data)
      .then((res) => {
        if (cb) cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  getElectricity: (data, cb) => {
    return request('getElectricity', data, cb);
  },
  getWaterSupply: (data, cb) => {
    return request('getWaterSupply', data, cb);
  },
  getHeating: (data, cb) => {
    return request('getHeating', data, cb);
  },
  getHistory: (data, cb) => {
    return request('getHistory', data, cb);
  },
};

export default MeterService;
