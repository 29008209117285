import {
  EVENT_MESSENGER_LOAD,
  EVENT_CHAT_LOAD,
  EVENT_CHAT_CONNECT,
  EVENT_CHAT_DISCONNECT,
  EVENT_CHAT_RECONNECT,
  EVENT_CHAT_MESSAGE_SEND,
  EVENT_CHAT_MESSAGE,
  EVENT_CHAT_INVITE,
  EVENT_CHAT_CREATE,
  EVENT_CHAT_UPDATE,
  EVENT_CHAT_UPDATED,
  EVENT_CHAT_CREATE_UPDATE,
  EVENT_CHAT_CLEAR,
  EVENT_CHAT_DELETE,
  EVENT_CHAT_MESSAGE_DELETE,
  EVENT_CHAT_MESSAGE_DELETED,
  EVENT_CHAT_MESSAGE_UPDATE,
  EVENT_CHAT_MESSAGE_UPDATED,
  EVENT_CHAT_MESSAGES
} from 'Actions/messenger';
import { EVENT_CONNECT } from 'Actions/socket';
import SocketService from 'Services/Socket';

const initialState = {
  chats: {},
  groups: {},
  peoples: {},
  currentId: null,
  loading: true,
};

export function messenger(state = initialState, action) {
  let data = {};
  let eventData = {};
  let chat = {};

  switch (action.type) {
    case EVENT_CONNECT:
      return state;
    case EVENT_MESSENGER_LOAD:
      eventData = JSON.parse(action.event.data).data;

      data = {
        ...state,
        chats: eventData.messenger.chats,
        groups: eventData.messenger.groups,
        peoples: eventData.messenger.peoples,
        loading: false,
      };

      return data;
    case EVENT_CHAT_CONNECT:
    case EVENT_CHAT_RECONNECT:
      SocketService.execute({
        type: EVENT_CHAT_MESSAGES,
        data: {
          chat_id: action.chatId,
          limit: 200,
        },
      });

      return state;
    case EVENT_CHAT_DISCONNECT:
      SocketService.execute({
        type: EVENT_CHAT_DISCONNECT,
      });

      return state;
    case EVENT_CHAT_CREATE_UPDATE:
      if (action.data.chatId) {
        SocketService.execute({
          type: EVENT_CHAT_UPDATE,
          data: action.data,
        });

        return state;
      }

      SocketService.execute({
        type: EVENT_CHAT_CREATE,
        data: action.data,
      });

      return state;
    case EVENT_CHAT_DELETE:
      SocketService.execute({
        type: EVENT_CHAT_DELETE,
        data: {
          chatId: action.chatId,
        },
      });

      data = {
        ...state,
      };

      delete data.groups[action.chatId];

      return data;
    case EVENT_CHAT_CLEAR:
      SocketService.execute({
        type: EVENT_CHAT_CLEAR,
        data: {
          chatId: action.chatId,
        },
      });

      data = {
        ...state,
      };

      chat = data.chats[action.chatName] || data.groups[action.chatId];

      chat.messages = [];

      return data;
    case EVENT_CHAT_UPDATED:
      eventData = JSON.parse(action.event.data).data;
      delete eventData.id;

      data = {
        ...state,
      };

      // TODO: remove after chat server fix
      let toUsersId = [...new Set(eventData.to_users_id.split(','))];
      toUsersId = toUsersId.filter(function(uid) {
        return uid !== '';
      });
      eventData.to_users_id = toUsersId.toString();
      // TODO: ^^^ very bad code

      data.groups[eventData.chat_id] = eventData;

      return data;
    case EVENT_CHAT_INVITE:
      eventData = JSON.parse(action.event.data).data;
      delete eventData.type;

      data = {
        ...state,
      };

      // TODO: remove after chat server fix
      let toUsersIdFiltered = [...new Set(eventData.to_users_id)];
      toUsersIdFiltered = toUsersIdFiltered.map((i) => {
        return Number(i);
      });
      toUsersIdFiltered = toUsersIdFiltered.filter(function(uid) {
        return uid !== 0;
      });
      eventData.to_users_id = [...new Set(toUsersIdFiltered)].toString();
      // TODO: ^^^ very bad code

      data.groups[eventData.chat_id] = eventData;

      if (
        eventData.openAfterCreate &&
        Number(eventData.userCreate) === Number(eventData.created_user_id)
      ) {
        return {
          ...data,
          currentId: eventData.chat_id,
        };
      }

      return data;
    case EVENT_CHAT_MESSAGE_SEND:
      SocketService.execute({
        type: 'create',
        data: {
          ...action.message,
          reply: action.reply,
        },
      });

      return state;
    case EVENT_CHAT_MESSAGE:
      eventData = JSON.parse(action.event.data).data;

      data = {
        ...state,
      };

      chat = data.groups[data.currentId] || data.chats[data.currentId];

      if (!chat.messages) chat.messages = [];

      if (data.currentId && Number(data.currentId) === Number(chat.chat_id)) {
        chat.messages.push(eventData);
        chat.latest_msg = eventData;
      }

      return data;
    case EVENT_CHAT_LOAD:
      eventData = JSON.parse(action.event.data).data;

      data = {
        ...state,
      };

      chat = data.chats[data.currentId] || data.groups[data.currentId];

      if (!eventData || !chat) return data;

      chat.messages = eventData.messages || [];

      return data;
    case EVENT_CHAT_MESSAGE_DELETE:
      data = {
        ...state,
      };

      if (action.chat.name) {
        chat = data.chats[action.chat.name];
      } else {
        chat = data.groups[action.chat.chat_id];
      }

      SocketService.execute({
        type: EVENT_CHAT_MESSAGE_DELETE,
        data: {
          messageId: action.messageId,
          chat: chat,
        },
      });

      chat.messages.some((msg, index) => {
        if (msg.message_id === action.messageId) {
          delete chat.messages[index];
        }

        return null;
      });

      return data;
    case EVENT_CHAT_MESSAGE_DELETED:
      eventData = JSON.parse(action.event.data).data;

      data = {
        ...state,
      };

      if (eventData.chat.name) {
        chat = data.chats[eventData.chat.name];
      } else {
        chat = data.groups[eventData.chat.chat_id];
      }

      chat.messages.some((msg, index) => {
        if (msg.message_id === eventData.messageId) {
          delete chat.messages[index];
        }

        return null;
      });

      return data;
    case EVENT_CHAT_MESSAGE_UPDATE:
      data = {
        ...state,
      };

      if (action.chat.name) {
        chat = data.chats[action.chat.name];
      } else {
        chat = data.groups[action.chat.chat_id];
      }

      SocketService.execute({
        type: EVENT_CHAT_MESSAGE_UPDATE,
        data: {
          messageId: action.messageId,
          content: action.content,
          chat: chat,
        },
      });

      chat.messages.some((msg, index) => {
        if (msg.message_id === action.messageId) {
          chat.messages[index].content = action.content;
        }

        return null;
      });

      return data;
    case EVENT_CHAT_MESSAGE_UPDATED:
      eventData = JSON.parse(action.event.data).data;

      data = {
        ...state,
      };

      if (eventData.chat.name) {
        chat = data.chats[eventData.chat.name];
      } else {
        chat = data.groups[eventData.chat.chat_id];
      }

      chat.messages.some((msg, index) => {
        if (msg.message_id === eventData.messageId) {
          chat.messages[index].content = eventData.content;
        }

        return null;
      });

      return data;
    case '@@router/LOCATION_CHANGE':
      const parts = action.payload.location.pathname.split('/');

      if (parts[1] === 'messenger') {
        return {
          ...state,
          currentId: parts[2] || null,
        };
      }
      return state;
    default:
      return state;
  }
}
