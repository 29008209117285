const component = 'modal';

export const EVENT_MODAL_OPEN = `${component}/MODAL_OPEN`;

export function openModal(id, data = {}) {
  return (dispatch) => {
    dispatch({
      type: EVENT_MODAL_OPEN,
      isModalOpen: id,
      data: data
    });
  };
}