import React from 'react';
import { connect } from 'react-redux';
import BaseWidget from 'Components/BaseWidget';
import { setActiveApartment } from 'Actions/apartments';
import UserService from 'Services/User';
import { loadUserData } from 'Actions/userData';
import { IconApartment } from 'Components/Icons';

class ActiveApartmentWidget extends BaseWidget {
  onChangeApartment = (id) => {
    const newApartment = this.props.apartments.list.filter(
      (item) => item.apartmentId === id,
    )[0];
    UserService.updateSettings({
      json_data: JSON.stringify({
        ...this.props.userData,
        apartmentId: newApartment.apartmentId,
      }),
    }).then(() => {
      this.props.onSetActiveApartment(newApartment);
      this.props.onLoadUserData({
        apartmentId: newApartment.apartmentId,
      });
    });
  };

  render() {
    const { stringAddress, apartmentId } = this.props;
    const activeApartmentId = this.props.apartments.active.apartmentId;

    return (
      <div
        className={`tile-items__item tile-switch tile-switch_led switcher ${
          apartmentId === activeApartmentId ? 'active' : 'off'
        }`}
      >
        <div className='tile-items__fact-value'>
          <div className='tile-items__icon-sensor'>{IconApartment}</div>
          <div className='tile-items__value'>&nbsp;</div>
        </div>
        <div className='tile-items__fact-titles'>
          <div className='tile-items__group'>
            {stringAddress.length > 0 && this.cropText(stringAddress)}
          </div>
        </div>
        <input
          id={apartmentId}
          type='checkbox'
          checked={apartmentId === activeApartmentId}
          className='tile-items__toggle-status'
          onChange={() => this.onChangeApartment(apartmentId)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    apartments: state.apartments,
    userData: state.userData,
  }),
  {
    onSetActiveApartment: setActiveApartment,
    onLoadUserData: loadUserData,
  },
)(ActiveApartmentWidget);
