import CookieService from 'Services/Cookie'
import {
  COOKIE_MENU_MIN
} from 'Config'
import {
  EVENT_SWITCH_GRID,
  EVENT_TOGGLE,
  EVENT_TOGGLE_REQUESTED
} from 'Actions/navigation'
import {
  isMobile
} from 'Config'

const initialState = {
  isMinimized: isMobile ? true : CookieService.get(COOKIE_MENU_MIN) === 'true',
  isProcess: false
}

export function navigation(state = initialState, action) {
  switch (action.type) {
    case EVENT_TOGGLE:
      return {
        ...state,
        isProcess: false
      }
    case EVENT_TOGGLE_REQUESTED:
      CookieService.set(COOKIE_MENU_MIN, action.isMinimized)

      return {
        ...state,
        isMinimized: action.isMinimized,
        isProcess: true
      }
    case EVENT_SWITCH_GRID:
      return { ...state, gridStatus: action.data }
    default:
      return state
  }
}
