import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from 'Components/BaseComponent';
import UserService from 'Services/User';
import DataTransform from 'Services/DataTransform';
import { IconAccounts, IconClose } from 'Components/Icons';
import { openModal } from 'Actions/modal';
import { accountDelete, accountsLoad } from 'Actions/accounts';
import { isEmptyObj } from 'Services/Utils';
import { withTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { panelOpen } from 'Actions/panel';

const automobiles = (t) => t('profile.cars');

class AccountsList extends BaseComponent {
  state = {
    groups: {
      [this.props.t('roles.owner')]: [],
      [this.props.t('roles.citizen')]: [],
      [automobiles(this.props.t)]: [],
    },
    isLoading: true,
  };

  updateCars = () => {
    UserService.getCars(
      {
        apartment_id: this.props.apartment_id,
      },
      (res) => {
        const cars = {};
        if (res && res.carNumberList && res.carNumberList.length > 0) {
          cars[automobiles(this.props.t)] = res.carNumberList;
        } else {
          cars[automobiles(this.props.t)] = {};
        }

        this.setState({ groups: { ...this.state.groups, ...cars } });
      },
    );
  };

  getAccountGroups = () => {
    const users = {};
    this.props.accounts.forEach((acc) => {
      const _roleName = this.getRole(acc.roleId);

      if (_roleName) {
        users[_roleName] = users[_roleName] || [];

        users[_roleName].push(acc);
      }
    });

    const cars =
      this.state.groups[automobiles(this.props.t)].length > 0
        ? this.state.groups[automobiles(this.props.t)]
        : [];

    if (!users[this.props.t('roles.citizen')])
      users[this.props.t('roles.citizen')] = [];

    this.setState({
      groups: { ...users, [automobiles(this.props.t)]: cars },
    });
  };

  getRole(roleId) {
    const { t } = this.props;

    return {
      0: t('roles.admin'),
      1: t('roles.owner'),
      2: t('roles.citizen'),
      // [childRole]: t('roles.child')',
      // 3: 'Гость',
      //4: 'Арендатор'
    }[roleId];
  }

  deleteAccount = (userId) => {
    const { t } = this.props;
    if (!window.confirm(t('accounts.confirm_delete_user'))) return null;

    UserService.deleteAccount(userId, () => {
      this.props.onAccountDelete(userId);
    });
  };

  deleteCar = (carNumberId) => {
    const { t } = this.props;

    if (!window.confirm(t('accounts.confirm_delete_car'))) return null;
    UserService.deleteCar({ car_number_id: carNumberId }, (res) => {
      if (res.ok) {
        const cars = this.state.groups[automobiles(t)].filter(
          (car) => car.carNumberId !== carNumberId,
        );
        this.setState({
          groups: { ...this.state.groups, [automobiles(this.props.t)]: cars },
        });
      }
    });
  };

  onOpenModal = (groupTitle) => {
    if (groupTitle === automobiles(this.props.t)) {
      this.props.onModalOpen('carCreateModal', {
        updateGroups: (data) => {
          if (this.state.groups[automobiles(this.props.t)].length > 0) {
            this.setState({
              groups: {
                ...this.state.groups,
                [automobiles(this.props.t)]: [
                  ...this.state.groups[automobiles(this.props.t)],
                  data,
                ],
              },
            });
          } else {
            this.setState({
              groups: {
                ...this.state.groups,
                [automobiles(this.props.t)]: [data],
              },
            });
          }
        },
      });
    } else {
      this.props.onModalOpen('userCreateModal');
    }
  };

  onOpenPanel = () => {
    this.props.onPanelOpen('InviteUsers', {});
  };

  componentDidUpdate(prevProps) {
    if (prevProps.accounts.length !== this.props.accounts.length) {
      this.getAccountGroups();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.accounts.length !== nextProps.accounts.length) return true;
    return this.state.groups !== nextState.groups;
  }

  componentDidMount() {
    super.componentDidMount();
    UserService.getAccounts((accounts) => {
      this.props.onAccountsLoad(accounts);
      this.getAccountGroups();
      this.updateCars();
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { t } = this.props;
    if (this.state.isLoading) return this.getLoader();
    return (
      <div className={styles.accountsList}>
        <div className={`row ${styles.faction}`}>
          {!isEmptyObj(this.state.groups) &&
            Object.keys(this.state.groups).map((groupTitle) => {
              const group = this.state.groups[groupTitle];

              return (
                <div key={groupTitle} className='col-xxl-4 col-lg-6'>
                  <div className='pane-border-block'>
                    <div
                      className='pane-border-block__title'
                      onClick={() => this.onOpenModal(groupTitle)}
                    >
                      <span>{groupTitle}</span>
                      <button className='btn btn_primary btn_add'>+</button>
                    </div>
                    {group.length > 0 && (
                      <div className='pane-border-block__body'>
                        {groupTitle === automobiles(t) ? (
                          <ul>
                            {Object.keys(group).map((i) => {
                              return (
                                <li key={i}>
                                  <span className='list-title'>
                                    {DataTransform.getCarName(group[i])}
                                  </span>
                                  <span className='list-actions'>
                                    <button
                                      title='Удалить автомобиль'
                                      onClick={() =>
                                        this.deleteCar(group[i].carNumberId)
                                      }
                                    >
                                      {IconClose}
                                    </button>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          <ul>
                            {Object.keys(group).map((i) => {
                              return (
                                <li key={i}>
                                  <span className='list-title'>
                                    {DataTransform.getName(group[i])}
                                  </span>
                                  <span className='list-actions'>
                                    <button
                                      title='Удалить пользователя'
                                      onClick={this.deleteAccount.bind(
                                        this,
                                        group[i].userId,
                                        i,
                                      )}
                                    >
                                      {IconClose}
                                    </button>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {/* <div className={`${styles.faction} col-xxl-4 col-lg-6`}>
          <button
            type='button'
            className='btn btn_primary btn_full-width'
            onClick={this.onOpenPanel}
          >
            <span className={styles.buttonIcon}>{IconAccounts}</span>{' '}
            {t('accounts.invite_users')}
          </button>
        </div> */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    accounts: state.accounts,
    apartment_id: state.apartments.active.apartmentId,
  }),
  {
    onPanelOpen: panelOpen,
    onModalOpen: openModal,
    onAccountDelete: accountDelete,
    onAccountsLoad: accountsLoad,
  },
)(withTranslation()(AccountsList));
