import {
  EVENT_INTERCOM_LOAD
} from 'Actions/intercom'

const initialState = {
  list: []
}

export function intercom(state = initialState, action) {
  let data = {};

  switch (action.type) {
    case EVENT_INTERCOM_LOAD:
      data = {
        ...state,
        list: action.data
      }

      return data
    default:
      return state
  }
}