import {
  EVENT_UK_LOAD,
  EVENT_UK_ADD,
  EVENT_UK_EDIT,
  EVENT_UK_ISSUE,
  EVENT_UK_ISSUES_LOAD
} from 'Actions/uk'

const initialState = {
  instance: {},
  issues: []
}

export function uk(state = initialState, action) {
  let data = {}

  switch (action.type) {
    case EVENT_UK_LOAD:
    case EVENT_UK_ADD:
    case EVENT_UK_EDIT:
      data = {
        ...state,
        instance: action.data
      }

      return data
    case EVENT_UK_ISSUES_LOAD:
      data = {
        ...state,
        issues: action.data
      }

      return data
    case EVENT_UK_ISSUE:
      data = {
        ...state
      }

      data.issues.unshift(action.data)

      return data
    default:
      return state
  }
}
