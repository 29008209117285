import React from 'react';
import { connect } from 'react-redux';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import { panelClose } from 'Actions/panel';

class ShowAgreements extends BaseSettingPanel {
  title = this.props.panelData.title;

  content() {
    return this.props.panelData.agreement;
  }
}

export default connect(
  (state) => ({
    panelData: state.panel.props,
    isOpened: state.panel.current === 'ShowAgreements',
  }),
  {
    onClose: panelClose,
  },
)(ShowAgreements);
