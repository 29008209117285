const component = 'push';

export const EVENT_ADD_PUSH = `${component}/ADD_PUSH`;
export const EVENT_DELETE_PUSH = `${component}/DELETE_PUSH`;

export function addNotice(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ADD_PUSH,
      notice: obj
    });
  };
}

export function deleteNotice(notice, id, save) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DELETE_PUSH,
      notice: notice,
      noticeId: id,
      save: save
    });
  };
}
