import {
  EVENT_CAMERA_LOAD,
  EVENT_CAMERA_UPDATE,
  EVENT_CAMERA_PLAY,
  EVENT_CAMERA_STOP
} from 'Actions/video'

const initialState = {
  cameras: [],
  player: {
    open: false,
    cam: null
  }
}

export function video(state = initialState, action) {
  let data = {}

  switch (action.type) {
    case EVENT_CAMERA_LOAD:
      data = {
        ...state,
        cameras: action.data
      }

      return data
    case EVENT_CAMERA_UPDATE:
      data = {
        ...state
      }
      data.cameras[action.data.id] = { ...action.data, isLoading: false }

      return data
    case EVENT_CAMERA_PLAY:
      data = {
        ...state
      }
      data.player.open = true
      data.player.cam = action.data

      return data
    case EVENT_CAMERA_STOP:
      data = {
        ...state
      }
      data.player.open = false
      data.player.cam = null

      return data
    default:
      return state
  }
}
