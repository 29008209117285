import React from 'react'
import BaseComponent from 'Components/BaseComponent'
import ControlService from 'Services/Control'
import {
  IconVideoSip,
  IconUnlockSip,
  IconAnswerSip,
  IconDeclineSip,
  IconPauseSip
} from 'Components/Icons'
import ringingTone from 'Assets/sounds/ringing.mp3'

class SipClient extends BaseComponent {
  timerClose = false

  onDoorOpen = () => {
    let intercom = this.props.intercom[0] || {}
    const intercomId = intercom.intercomId || 0

    ControlService.executeIntercom({ intercomId: intercomId })
  }

  componentDidUpdate() {
    if (
      this.props.client.ringing &&
      this.props.client.answer === false &&
      !this.timerClose
    ) {
      this.timerClose = setTimeout(() => {
        if (this.props.client.ringing && this.props.client.answer === false) {
          this.props.actions.onDecline.bind(this)
        }
      }, 1000)
    } else if (this.timerClose) {
      clearTimeout(this.timerClose)
    }
  }

  render() {
    const client = this.props.client
    const actions = { ...this.props.actions, onOpen: this.onDoorOpen }

    return (
      <div
        className="sip-client-container"
        hidden={client.ringing === false && client.answer === false}>
        <div className="sip-client">
          {client.answer && client.answer === 'video' ? (
            <video className="sip-client__video" autoPlay />
          ) : null}
          <audio className="sip-client__ring">
            <source src={ringingTone} type="audio/mp3" />
          </audio>
          <audio className="sip-client__audio" autoPlay />
          {!client.answer ? (
            <div className="sip-client__controls">
              <button
                onClick={actions.onVideo.bind(this)}
                className="btn btn_success btn-icon ">
                {IconVideoSip}
              </button>
              <button
                onClick={actions.onAnswer.bind(this)}
                className="btn btn_success btn-icon ">
                {IconAnswerSip}
              </button>
              <button
                onClick={actions.onDecline.bind(this)}
                className="btn btn_danger btn-icon ">
                {IconDeclineSip}
              </button>
            </div>
          ) : (
            <div className="sip-client__controls">
              <button
                onClick={actions.onHold.bind(this)}
                className="btn btn_success btn-icon ">
                {!client.hold ? IconPauseSip : IconAnswerSip}
              </button>
              <button
                onClick={actions.onOpen.bind(this)}
                className="btn btn_success btn-icon ">
                {IconUnlockSip}
              </button>
              <button
                onClick={actions.onDecline.bind(this)}
                className="btn btn_danger btn-icon ">
                {IconDeclineSip}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default SipClient
