const component = 'UK'

export const EVENT_UK_LOAD = `${component}/LOAD`
export const EVENT_UK_ADD = `${component}/ADD`
export const EVENT_UK_EDIT = `${component}/EDIT`
export const EVENT_UK_ISSUE = `${component}/ISSUE/ADD`
export const EVENT_UK_ISSUES_LOAD = `${component}/ISSUE/LOAD`

export function ukLoad(data) {
  return dispatch => {
    dispatch({
      type: EVENT_UK_LOAD,
      data: data
    })
  }
}

export function ukAdd(data) {
  return dispatch => {
    dispatch({
      type: EVENT_UK_ADD,
      data: data
    })
  }
}

export function ukEdit(data) {
  return dispatch => {
    dispatch({
      type: EVENT_UK_EDIT,
      data: data
    })
  }
}

export function ukIssuesLoad(data) {
  return dispatch => {
    dispatch({
      type: EVENT_UK_ISSUES_LOAD,
      data: data
    })
  }
}

export function ukIssueAdd(data) {
  return dispatch => {
    dispatch({
      type: EVENT_UK_ISSUE,
      data: data
    })
  }
}
