import React from 'react';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import BaseSelect from 'Components/BaseSelect';
//import CreatableSelect from 'react-select/lib/Creatable';
import Tooltip from 'Components/Tooltip';
import { IconCalendar, IconClose } from 'Components/Icons';
import { isMobile } from 'Config';
// eslint-disable-next-line import/default
import DatePicker from 'react-date-picker';

const hours = [
  { value: '00:00', label: '00:00' },
  { value: '00:30', label: '00:30' },
  { value: '01:00', label: '01:00' },
  { value: '01:30', label: '01:30' },
  { value: '02:00', label: '02:00' },
  { value: '02:30', label: '02:30' },
  { value: '03:00', label: '03:00' },
  { value: '03:30', label: '03:30' },
  { value: '04:00', label: '04:00' },
  { value: '04:30', label: '04:30' },
  { value: '05:00', label: '05:00' },
  { value: '05:30', label: '05:30' },
  { value: '06:00', label: '06:00' },
  { value: '06:30', label: '06:30' },
];
const days = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
];

class ScenarioPanel extends BaseSettingPanel {
  title = 'Создание сценария';
  className = 'scenario-panel';

  state = {
    dateStart: null,
    dateFinish: null,
    selectedDay: days[0],
    scenarioTitle: '',
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.currentScenario.title &&
      prevProps.currentScenario.title !== this.props.currentScenario.title &&
      this.state.scenarioTitle !== this.props.currentScenario.title
    ) {
      this.setState({ scenarioTitle: this.props.currentScenario.title });
    } else if (
      !this.props.currentScenario.title &&
      this.state.scenarioTitle !== ''
    ) {
      this.setState({ scenarioTitle: '' });
    }
  }

  onChange = (date) => this.setState({ date });

  handleChangeTitle = (event) => {
    this.setState({ scenarioTitle: event.target.value });
  };

  handleChange = (selectedDay) => {
    this.setState({ selectedDay });
  };

  content() {
    return (
      <div className='form form_scenario'>
        <form>
          <div className='form-group'>
            <label htmlFor='scenarioName'>Название</label>
            <input
              id='scenarioName'
              className='form-control'
              type='text'
              value={this.state.scenarioTitle}
              onChange={this.handleChangeTitle}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='scenarioRoom'>Комнаты</label>
            <BaseSelect
              id='scenarioRoom'
              isMulti
              options={this.props.rooms}
              placeholder={null}
              isSearchable={false}
            />
          </div>
          <div className='scenario-group'>
            <h4>Устройства</h4>
            <div className='row scenario-group__item'>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='scenarioDevice'>Устройство</label>
                  <BaseSelect
                    id='scenarioDevice'
                    options={this.props.rooms}
                    placeholder={null}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='scenarioMethod'>Метод</label>
                  <BaseSelect
                    id='scenarioMethod'
                    options={this.props.rooms}
                    placeholder={null}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
            <div className='row scenario-group__item'>
              <button type='button' className='group-item-close'>
                {IconClose}
              </button>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='scenarioDevice2'>Устройство</label>
                  <BaseSelect
                    id='scenarioDevice2'
                    options={this.props.rooms}
                    placeholder={null}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='scenarioMethod2'>Метод</label>
                  <BaseSelect
                    id='scenarioMethod2'
                    options={this.props.rooms}
                    placeholder={null}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
            <button
              type='button'
              className='btn btn_secondary btn_circle add-item'
              data-tip='Добавить устройство'
            >
              +
            </button>
            <Tooltip place='right' type='dark' effect='solid' />
          </div>
          <div className='scenario-group'>
            <h4>Планировщик времени</h4>
            <div className='form-group'>
              <label htmlFor='scenarioMethod2'>Временной интервал</label>
              <div className='row align-items-center'>
                {isMobile ? (
                  <>
                    <div className='col'>
                      <input type='time' className='form-control' />
                    </div>
                    <div className='col'>
                      <input type='time' className='form-control' />
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col'>
                      <BaseSelect options={hours} placeholder={null} />
                    </div>
                    <div className='col'>
                      <BaseSelect options={hours} placeholder={null} />
                    </div>
                  </>
                )}
                <div className='col'>
                  <div className='custom-control custom-control_checkbox m-0'>
                    <input
                      type='checkbox'
                      className='custom-control__input'
                      id='customCheck1'
                    />
                    <label
                      className='custom-control__label text-nowrap'
                      htmlFor='customCheck1'
                    >
                      Весь день
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor='scenarioMethod2'>Начало действия</label>
              <DatePicker
                onChange={this.onChange}
                value={this.state.dateStart}
                className='form-control'
                calendarIcon={IconCalendar}
                clearIcon={null}
                showLeadingZeros={true}
              />
            </div>
            <div className='form-group align-items-center'>
              <div className='custom-control custom-control_checkbox m-0'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheck2'
                />
                <label className='custom-control__label' htmlFor='customCheck2'>
                  Каждые
                </label>
              </div>
              <BaseSelect
                options={days}
                placeholder={null}
                className='ml-15'
                value={this.state.selectedDay}
                onChange={this.handleChange}
              />
              <div className='ml-15'>дн.</div>
            </div>
            <div className='form-group'>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay1'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay1'
                >
                  Пн
                </label>
              </div>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay2'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay2'
                >
                  Вт
                </label>
              </div>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay3'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay3'
                >
                  Ср
                </label>
              </div>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay4'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay4'
                >
                  Чт
                </label>
              </div>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay5'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay5'
                >
                  Пт
                </label>
              </div>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay6'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay6'
                >
                  Сб
                </label>
              </div>
              <div className='custom-control custom-control_checkbox custom-control_circle'>
                <input
                  type='checkbox'
                  className='custom-control__input'
                  id='customCheckDay7'
                />
                <label
                  className='custom-control__label'
                  htmlFor='customCheckDay7'
                >
                  Вс
                </label>
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor='scenarioMethod2'>Окончание действия</label>
              <DatePicker
                onChange={this.onChange}
                value={this.state.dateFinish}
                className='form-control'
                calendarIcon={IconCalendar}
                clearIcon={null}
                showLeadingZeros={true}
              />
            </div>
          </div>
          <div className='btn-form-wrap'>
            <button type='submit' className='btn btn_primary'>
              Сохранить
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ScenarioPanel;
