import AuthService from 'Services/Auth';
import {
  EVENT_MESSENGER_LOAD,
  EVENT_CHAT_LOAD
} from 'Actions/messenger'

class MessengerService {
  socket = null;

  constructor(connection) {
    this.socket = connection
  }

  getChats() {
    let event = {
      type: EVENT_MESSENGER_LOAD,
      data: {
        token: AuthService.getToken()
      }
    };

    this.socket.send(JSON.stringify(event));

    return this.socket;
  }

  getMessages() {
    let event = {
      type: EVENT_CHAT_LOAD,
      data: {
        token: AuthService.getToken()
      }
    };

    this.socket.send(JSON.stringify(event));

    return this.socket;
  }
}

export default MessengerService;