import React from 'react';
import { connect } from 'react-redux';
import BaseWidget from 'Components/BaseWidget';
import {
  SENSORS_STATUS_OFF,
  SENSORS_STATUS_ON,
  SENSORS_STATUS_WARNING,
  SENSORS_STATUS_WITH_VAL_OFF,
  SENSORS_STATUS_WITH_VAL_ON,
    SENSOR_DOOR_OPEN_ID,
  SENSOR_HUMIDITY_ID,
  SENSOR_LEAKS_ID,
  SENSOR_LIGHT,
  SENSOR_MOVE_ID,
  SENSOR_SMOKE_ID,
  SENSOR_TEMP_ID,
  SENSOR_CO2_ID,
  SENSOR_UNDEFINED_ID,
  SENSOR_COOLING_ID,
  SENSOR_ATMOSPHERE_PRESSURE_ID,
  SENSOR_VOLTAGE_ID,
} from 'Config/devices';
import { panelOpen } from 'Actions/panel';
import {
  IconAtmosphericPress,
  IconCooling,
  IconDevices,
  IconLightSensor,
  IconMoveSensor,
  IconSensorCO2,
  IconSensorHumidity,
  IconSensorLeak,
  IconSensorOpenDoor,
  IconSensorSmoke,
  IconSensorTemp,
  IconSettings,
  IconToggler,
  IconVoltage,
} from 'Components/Icons';

class ControlSensorWidget extends BaseWidget {
  getData = () => {
    const { status } = this.props;
    const { sensorTypeId } = this.props.type;
    let className, icon, value;

    if (!this.props.value) {
      switch (status) {
        case SENSORS_STATUS_ON:
          className = ' active';
          break;
        case SENSORS_STATUS_OFF:
          className = ' off';
          break;
        case SENSORS_STATUS_WARNING:
          className = ' warning';
          break;
        default:
          className = '';
      }
    } else {
      switch (status) {
        case SENSORS_STATUS_WITH_VAL_ON:
          className = ' active';
          break;
        case SENSORS_STATUS_WITH_VAL_OFF:
          className = ' off';
          break;
        default:
          className = '';
      }
    }

    // icon
    switch (sensorTypeId) {
      case SENSOR_TEMP_ID:
        icon = IconSensorTemp;
        break;
      case SENSOR_DOOR_OPEN_ID:
        icon = IconSensorOpenDoor;
        break;
      case SENSOR_LEAKS_ID:
        icon = IconSensorLeak;
        break;
      case SENSOR_SMOKE_ID:
        icon = IconSensorSmoke;
        break;
      case SENSOR_HUMIDITY_ID:
        icon = IconSensorHumidity;
        break;
      case SENSOR_CO2_ID:
        icon = IconSensorCO2;
        break;
      case SENSOR_MOVE_ID:
        icon = IconMoveSensor;
        break;
      case SENSOR_LIGHT:
        icon = IconLightSensor;
        break;
      case SENSOR_UNDEFINED_ID:
        icon = IconToggler;
        break;
      case SENSOR_VOLTAGE_ID:
        icon = IconVoltage;
        break;
      case SENSOR_ATMOSPHERE_PRESSURE_ID:
        icon = IconAtmosphericPress;
        break;
      case SENSOR_COOLING_ID:
        icon = IconCooling;
        break;
      default:
        icon = IconDevices;
    }

    if (!this.props.value && sensorTypeId === SENSOR_TEMP_ID) {
      value = '-- C';
    } else if (this.props.value && sensorTypeId === SENSOR_TEMP_ID) {
      value = '+' + Math.round(this.props.value) + ' C';
    } else if (this.props.value && sensorTypeId === SENSOR_VOLTAGE_ID) {
      value = Math.round(this.props.value) + ' V';
    }

    return {
      className,
      icon,
      value,
    };
  };

  openModal = () => {
    this.props.panelOpen('EditDevice', {
      device: this.props,
      type: 'sensor',
    });
  };

  render() {
    const room = this.props.room ? this.props.room : {};
    const sensorData = this.getData();
    return (
      <div
        className={
          'tile-items__item tile-switch tile-switch_led' + sensorData.className
        }
      >
        <div className='tile-items__fact-value'>
          <div className='tile-items__icon-sensor'>{sensorData.icon}</div>
          <div className='tile-items__value'>
            {sensorData.value ? <span>{sensorData.value}</span> : <>&nbsp;</>}
          </div>
        </div>
        <div className='tile-items__fact-titles'>
          <div className='tile-items__group'>
            {this.cropText(this.props.title)}
          </div>
          <div className='tile-items__room'>
            {room.title ? room.title : 'Квартира'}
          </div>
        </div>
        <button
          type={'button'}
          aria-label={'settings_button'}
          name={'settings_button'}
          className='tile-items__settings-btn'
          onClick={this.openModal}
        >
          {IconSettings}
        </button>
      </div>
    );
  }
}

export default connect(
  null,
  {
    panelOpen,
  },
)(ControlSensorWidget);
