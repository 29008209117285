/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class SendUserPhotoForm extends Component {
  state = {
    file: null,
    isDisabled: false,
  };

  _onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    const data = { ...this.props.extendData, file: this.state.file };

    for (const key in data) {
      formData.append(key, data[key]);
    }

    this.props
      .action(formData)
      .then((res) => {
        if (res.ok) {
          this.props.callback(res);
        } else {
          this.props.handleError();
        }
      })
      .catch((e) => this.props.handleError());
  };

  _onFileChange = (ref) => {
    const _file = ref.current.files[0];

    this.setState({
      file: new File([_file], _file.name.toLowerCase(), {
        type: _file.type,
      }),
    });
  };

  render() {
    const field = this.props.fields['file'];

    return (
      <div className={'form ' + this.props.className}>
        <form onSubmit={this._onSubmit}>
          <div className='row'>
            <div className='col-sm-6'>
              <input
                id={field.id}
                type={field.type}
                className={field.className}
                name={field.name}
                disabled={this.state.isDisabled}
                onClick={() => {
                  const { t } = this.props;

                  if (window.cameraPermission !== 'true') {
                    this.setState({ isDisabled: true });
                    this.props.onDialogWindow({
                      title: t('dialog_window.cameraPermission.title'),
                      content: t('dialog_window.cameraPermission.content'),
                      cancelText: t('dialog_window.cameraPermission.cancel'),
                      agreeText: t('dialog_window.cameraPermission.agree'),
                      cancelCallback: async () => {
                        return this.setState({ isDisabled: false });
                      },
                      agreeCallback: async () => {
                        return this.setState({ isDisabled: false }, () => {
                          window.sendMessageToWebview({
                            action: 'openSettings',
                          });
                        });
                      },
                    });
                  }
                }}
                onChange={(e) => {
                  this.props.handleChange(e);
                  this._onFileChange(field.props.ref);
                }}
                accept={field.props.accept}
                ref={field.props.ref}
              />
              <label
                htmlFor={field.id}
                className='btn btn_big width-full btn_primary'
              >
                {field.label}
              </label>
            </div>
            <div className='col-sm-6'>
              <button
                type='submit'
                className='btn btn_big width-full btn_primary'
                disabled={!this.state.file}
              >
                <span>{this.props.btnSubmit}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SendUserPhotoForm;
