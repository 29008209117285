require('dotenv').config();

import { isMobile as _isMobile } from 'react-device-detect';
import { isTablet as _isTablet } from 'react-device-detect';

const API_HOST = process.env.REACT_APP_API_HOST || 'api.boxt.one';
const CHAT_HOST = process.env.REACT_APP_CHAT_HOST || 'chat.boxt.one';
const VIDEO_HOST = process.env.REACT_APP_VIDEO_HOST || 'video.boxt.one';
const INTERCOM_HOST = process.env.REACT_APP_INTERCOM_HOST || 'video.boxt.one';
const INTERCOM_PORT = process.env.REACT_APP_INTERCOM_PORT || 9076;

const URL_API = `https://${API_HOST}/api`;
const URL_AUTH = `https://${API_HOST}/auth`;
const URL_PAYMENT = `https://${API_HOST}/payment`;
const URL_CHAT = `wss://${CHAT_HOST}`;
const URL_VIDEO = `wss://${VIDEO_HOST}`;
const URL_UPLOADS = `https://${API_HOST}/uploads`;
const URL_API_HOME = `https://${API_HOST}`;

// Common
export const isMobile = _isMobile;
export const isTablet = _isTablet;
export const minWidth = document.documentElement.clientWidth < 840;
export const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

// Default SIP config
export const SIP_CONFIG = {
  uri: INTERCOM_HOST,
  host: `wss://${INTERCOM_HOST}:${INTERCOM_PORT}/ws`,
  connection_recovery_max_interval: 120,
  connection_recovery_min_interval: 60,
  no_answer_timeout: 30,
  contact_uri: 'sip:1010@' + INTERCOM_HOST, // flag for Asterisk
};

// API Version
const API_VERSION = 1;

// Storage
export const STORAGE_KEY = 'toneStorageData';
export const CACHE_ACTIVE = false;

// Cookies
export const COOKIE_PREFIX = 'tone_';
export const COOKIE_APPLICATION_TOKEN = 'app_token';
export const COOKIE_LOGIN_CODE_TIMER = 'user_login_timer';
export const COOKIE_MENU_MIN = 'main_menu_minimized';
export const COOKIE_USER_REDIRECT = 'user_redirect';
export const COOKIE_DASHBOARD = 'dashboard_config';
export const COOKIE_APPLICATION_TOKEN_EXP = 63072000000; // 2 years

// Storage Cache
export const CACHE_TIME_NEWS = 86400; // 1 day
export const CACHE_TIME_USER = 86400; // 1 day
export const CACHE_TIME_COUNTERS = 86400; // 1 day
export const CACHE_TIME_INTERCOM = 2592000; // 30 days
export const CACHE_TIME_AMENITIES = 604800; // 7 days
export const CACHE_TIME_WALLET = 86400; // 1 day
export const CACHE_TIME_TRANSACTIONS = 86400; // 1 day
export const CACHE_TIME_CATEGORIES = 2592000; // 30 days
export const CACHE_TIME_ROOMS = 2592000; // 30 days
export const CACHE_TIME_DEVICES = null; // no cache
export const CACHE_TIME_SCENARIOS = null; // no cache

export const isDev = () => {
  const { hostname } = window.location;
  return hostname === 'localhost' || hostname === 'app.dev.boxt.one';
};

export const Config = {
  api_version: `v${API_VERSION}`,
  NO_VIDEO: false,
  NO_SIP: false,
};

Config.urls = {
  api: URL_API,
  auth: URL_AUTH,
  payment: URL_PAYMENT,
  chat: URL_CHAT,
  video: URL_VIDEO,
  uploads: URL_UPLOADS,
  api_home: URL_API_HOME,
};

export default Config;
