import React from 'react';

export default () => (
  <div className='col-md-4 col-lg-3 mb-7 mb-md-0'>
    <ol>
      <li>
        <a href='#p1'>Что регулирует настоящая политика конфиденциальности</a>
      </li>
      <li>
        <a href='#p2'>Кто обрабатывает информацию</a>
      </li>
      <li>
        <a href='#p3'>Какова цель данной Политики</a>
      </li>
      <li>
        <a href='#p4'>Какую Персональную информацию о Вас собирает Т.один</a>
      </li>
      <li>
        <a href='#p5'>
          Какова правовая основа обработки Персональной информации
        </a>
      </li>
      <li>
        <a href='#p6'>Цели обработки Персональной информации</a>
      </li>
      <li>
        <a href='#p7'>Передача Персональной информации</a>
      </li>
      <li>
        <a href='#p8'>Как Т.один защищает Вашу Персональн</a>
      </li>
      <li>
        <a href='#p9'>Где хранится и обрабатывается Ваша Персональная и</a>
      </li>
      <li>
        <a href='#p10'>Как долго мы храним Вашу Персональную информацию</a>
      </li>
      <li>
        <a href='#p11'>Ваши права</a>
      </li>
      <li>
        <a href='#p12'>
          Как мы используем файлы cookie и другие подобные технологии на Сайтах
          или при использовании Вами Сервисов
        </a>
      </li>
      <li>
        <a href='#p13'>Обновление настоящей Политики</a>
      </li>
    </ol>

    <div>
      <p>
        <small>Дата публикации: 1 ноября 2023 (ред. 01.11.2023)</small>
      </p>

      <section>
        <p>
          <strong>
            <a name='p1'></a>
            1. Что регулирует настоящая политика конфиденциальности
          </strong>
        </p>
        <p>
          Настоящая политика конфиденциальности (далее — Политика) действует в
          отношении всей информации, включая персональные данные в понимании
          применимого законодательства (далее — «Персональная информация»),
          которую ООО «Т.один» и/или его аффилированные лица, в том числе
          входящие в одну группу с ООО «Т.один» (далее — Т.один), могут получить
          о Вас в процессе использования Вами любых сайтов, программ, продуктов
          и/или сервисов ОАСУ T.one (далее вместе — T.one), информацию о которых
          Вы можете найти на сайтах www.boxt.one, и других принадлежащих ООО
          «Т.один» сайтах (далее вместе — Сайты), а также в ходе исполнения
          Т.один и его аффилированными лицами любых соглашений и договоров,
          заключенных с Вами в связи с использованием Вами Сервисов.
        </p>
        <p>
          Т.один может также получать Персональную информацию от своих партнеров
          (далее — Партнеры), сайты, программы, продукты или сервисы, которых Вы
          используете. В таких случаях передача Персональной информации возможна
          только в случаях, установленных применимым законодательством, и
          осуществляется на основании специальных договоров между Т.один и
          каждым из Партнеров. Пожалуйста, обратите внимание, что использование
          любого из Сайтов и/или Сервисов может регулироваться дополнительными
          условиями, которые могут вносить в настоящую Политику изменения и/или
          дополнения.
        </p>
        <p>В Политике используются следующие термины и определения:</p>
        <ol type='I'>
          <li>
            Сервисы – программные продукты T.one, предназначенные для
            использования Пользователем, в том числе мобильное приложение.
          </li>

          <li>
            Мобильное приложение – программный продукт для iOS и Android,
            разработанный для управления функционалом системы «T.one».
          </li>

          <li>
            Пользователь – физическое лицо, намеревающееся использовать или
            использующее Сервисы.
          </li>

          <li>
            Управляющая организация – управляющая компания, товарищество
            собственников жилья, жилищный или жилищно-строительный кооператив
            либо иной специализированный потребительский кооператив, управляющие
            многоквартирным домом, в котором располагается помещение
            Пользователя, обслуживаемое с использованием программных продуктов
            T.один.
          </li>
        </ol>
      </section>

      <section>
        <p>
          <strong>
            <a name='p2'></a>
            2. Кто обрабатывает информацию
          </strong>
        </p>
        <p>
          Ваша Персональная информация собирается и используется обществом с
          ограниченной ответственностью «Т.один» (ИНН 1831191571, ОГРН
          1181832019544), юридическим лицом, созданным по законодательству
          Российской Федерации и зарегистрированным по адресу: Россия, Ижевск,
          ул. Проезд Дерябина 3, офис 201, или его аффилированным лицом,
          предоставляющим соответствующий Сервис. С информацией о том, какое
          лицо предоставляет тот или иной Сервис, Вы можете ознакомиться в
          условиях использования соответствующего Сервиса.
        </p>
      </section>

      <section>
        <p>
          <strong>
            <a name='p3'></a>
            3. Какова цель данной Политики
          </strong>
        </p>
        <p>
          Защита Вашей Персональной информации и Вашей конфиденциальности
          чрезвычайно важны для Т.один. Поэтому при использовании Вами Сайтов и
          ОАСУ T.one Т.один защищает и обрабатывает Вашу Персональную информацию
          в строгом соответствии с применимым законодательством. Следуя нашим
          обязанностям защищать Вашу Персональную информацию, в этой Политике мы
          хотели бы наиболее прозрачно проинформировать Вас о следующих
          моментах:
        </p>
        <ol type='I'>
          <li>
            зачем и как Т.один использует («обрабатывает») Вашу Персональную
            информацию, когда Вы используете Сайты и/или Сервисы;
          </li>
          <li>
            какова роль и обязанности Т.один как юридического лица, принимающего
            решение о том, зачем и как обрабатывать Вашу Персональную
            информацию;
          </li>
          <li>
            какие инструменты Вы можете использовать для сокращения объема
            собираемой Т.один Персональной информации о Вас;
          </li>
          <li>
            каковы Ваши права в рамках проводимой обработки Персональной
            информации.
          </li>
        </ol>
      </section>

      <section>
        <p>
          <strong>
            <a name='p4'></a>
            4. Какую Персональную информацию о Вас собирает Т.один
          </strong>
        </p>
        <p>
          Персональная информация, собранная в процессе работы Сайтов и/или
          предоставления Сервисов, может различаться в зависимости от того,
          используете Вы для доступа к Сайтам и/или Сервисам Вашу учетную запись
          или нет.
        </p>
        <p>
          В тех случаях, когда Вы осуществляете вход в свою учетную запись,
          Персональная информация, собранная о Вас Т.один во время использования
          Вами Сайтов и Сервисов, может быть сопоставлена и связана с другой
          Персональной информацией, собранной Т.один в рамках использования Вами
          Вашей учетной записи (например, данные о Вашей личности, контактные
          данные, возраст и пол, если они были предоставлены Т.один). Т.один не
          проверяет предоставленную Вами Персональную информацию и не может
          судить о ее достоверности, а также о том, обладаете ли вы достаточной
          правоспособностью для предоставления Вашей Персональной информации.
          Тем не менее Т.один исходит из того, что Вы предоставляете достоверную
          и достаточную Персональную информацию, а также своевременно обновляете
          её.
        </p>
        <p>
          Т.один может обрабатывать персональную информацию с использованием
          средств автоматизации или без использования таких средств с
          персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных
          данных.
        </p>
        <p>
          Т.один может собирать следующие категории Персональной информации о
          Вас во время использования Вами Сайтов и Сервисов:
        </p>
        <ol type='I'>
          <li>
            Персональная информация, предоставленная Вами при регистрации
            (создании учетной записи), такая как Ваше имя, номер телефона,
            адрес, электронная почта и возраст, адрес проживания,
            фотоизображение (с целью размещения в персональном профиле
            Пользователя);
          </li>
          <li>
            электронные данные (HTTP-заголовки, IP-адрес, файлы cookie,
            веб-маяки/пиксельные теги, данные об идентификаторе браузера,
            информация об аппаратном и программном обеспечении);
          </li>
          <li>дата и время осуществления доступа к Сайтам и/или Сервисам;</li>
          <li>
            информация о Вашей активности во время использования Сайтов и/или
            Сервисов (например, история поисковых запросов; адреса электронной
            почты тех, с кем Вы ведете переписку; содержание электронной почты и
            вложения, а также файлы, хранящиеся в системах Т.один);
          </li>
          <li>информация о геолокации;</li>
          <li>
            иная информация о Вас, необходимая для обработки в соответствии с
            условиями, регулирующими использование конкретных Сайтов или
            Сервисов Т.один;
          </li>
          <li>
            информация о Вас, которую мы получаем от наших Партнеров в
            соответствии с условиями соглашений, заключенных между Вами и
            соответствующим Партнером, и соглашений, заключенных между Т.один и
            Партнером.
          </li>
        </ol>
        <p>
          информация о Вас, которую мы получаем от наших Партнеров в
          соответствии с условиями соглашений, заключенных между Вами и
          соответствующим Партнером, и соглашений, заключенных между Т.один и
          Партнером. Также Т.один использует файлы cookie и веб-маяки (включая
          пиксельные теги) для сбора Персональной информации и связывания такой
          личной информации с Вашим устройством и веб-браузером (см. раздел 11
          настоящей Политики). Т.один не собирает чувствительную Персональную
          информацию (такую как расовое происхождение, политические взгляды,
          информация о здоровье и биометрические данные).
        </p>
      </section>

      <section>
        <p>
          <strong>
            <a name='p5'></a>
            5. Какова правовая основа обработки Персональной информации
          </strong>
        </p>
        <p>
          Т.один не вправе обрабатывать Вашу Персональную информацию без
          достаточных на то правовых оснований, поэтому Т.один обрабатывает Вашу
          Персональную информацию только в том случае, если:
        </p>
        <ol type='I'>
          <li>
            обработка необходима для выполнения договорных обязательств Т.один
            перед Вами, включая обеспечение работы Сайтов и Сервисов;
          </li>
          <li>
            обработка необходима для соблюдения установленных законодательством
            обязательств;
          </li>
          <li>
            когда это предусмотрено применимым законодательством, обработка
            необходима для обеспечения законных интересов Т.один в случае, если
            такая обработка не оказывает существенного влияния на Ваши интересы,
            Ваши фундаментальные права и свободы. Обратите внимание, что при
            обработке Вашей персональной информации на указанном основании
            Т.один всегда будет стремиться поддерживать баланс между своими
            законными интересами и защитой Вашей конфиденциальности. Т.один
            обрабатывает Вашу Персональную информацию для обеспечения своих
            законных интересов, например, в следующих случаях: (a) чтобы лучше
            понимать, как Вы взаимодействуете с нашими Сайтами и/или
            Сервисами;(b) чтобы совершенствовать, менять, персонализировать или
            иным образом улучшать Сайты и Сервисы в интересах всех
            пользователей; (c) чтобы предлагать Вам другие продукты и сервисы
            Т.один или других компаний, которые, по нашему мнению, могут Вас
            заинтересовать (т. е. показывать Вам рекламу, учитывающую Ваши
            интересы);
          </li>
          <li>
            для конкретных целей мы можем запросить Ваше отдельное согласие на
            обработку Вашей Персональной информации. Т.один всегда обрабатывает
            Вашу Персональную информацию в определенных целях и только ту
            Персональную информацию, которая имеет отношение к достижению таких
            целей.
          </li>
        </ol>
      </section>

      <section>
        <p>
          <strong>
            <a name='p6'></a>
            6. Цели обработки Персональной информации
          </strong>
        </p>
        <p>
          В частности, мы обрабатываем Вашу Персональную информацию для
          следующих целей:
        </p>
        <ol type='I'>
          <li>предоставление Вам доступа к Сайтам и/или Сервисам;</li>
          <li>
            предоставление доступа к Вашей учетной записи, если Вы
            зарегистрированы в соответствующих Сервисах;
          </li>
          <li>
            осуществление связи с Вами для направления Вам уведомлений, запросов
            и информации, относящейся к работе Сайтов и Сервисов, выполнения
            соглашений с Вами и обработки Ваших запросов и заявок;
          </li>
          <li>
            предоставление рекламы и предложений с учетом Ваших предпочтений, и
            другой Персональной информации о Вас, доступной Т.один;
          </li>
          <li>
            повышение удобства использования Сайтов и Сервисов, в том числе для
            показа наиболее релевантных результатов поиска и предоставления
            более персонализированных Сайтов и Сервисов, а также для улучшения
            других продуктов, приложений и Сервисов Т.один;
          </li>
          <li>создание новых продуктов, утилит и предложений Т.один;</li>
          <li>защита Ваших прав и прав Т.один;</li>
          <li>
            сбор, обработка и представление статистических данных, больших
            данных и других исследований.
          </li>
        </ol>
      </section>

      <section>
        <p>
          <strong>
            <a name='p7'></a>
            7. Передача Персональной информации
          </strong>
        </p>
        <p>
          Для предоставления Сервисов Пользователь соглашается на передачу
          Персональной информации в следующих случаях:
        </p>
        <ol type='I'>
          <li>
            Управляющей организации в целях осуществления её деятельности по
            управлению многоквартирным домом, в котором располагается помещение
            Пользователя, в том числе идентификации и аутентификации;
          </li>
          <li>
            третьим лицам, участвующим в проведении общих собраний или иных
            голосований собственников помещений в многоквартирном доме или
            пользователей помещений объекта недвижимости, в которых
            располагается помещение Пользователя, в целях их проведения;
          </li>
          <li>
            аффилированным лицам, в том числе другим компаниям из группы лиц, к
            которой принадлежит Т.один
          </li>
          <li>
            третьим лицам, не входящим в Т.один, для достижения целей, указанных
            в разделе 5 настоящей Политики. К таким третьим лицам могут
            относиться:
            <ol>
              <li>
                Партнеры, такие как владельцы сайтов и приложений, рекламные
                сети и другие партнеры, предоставляющие Т.один услуги, связанные
                с размещением и отображением рекламы на сайтах, в программах,
                продуктах или сервисах, которые принадлежат таким партнерам или
                контролируются ими;
              </li>
              <li>
                рекламодатели или другие Партнеры, которые отображают для Вас
                рекламу на Сайтах и/или на Сервисах Т.один, а также такие
                Партнеры как поставщики информационных сервисов или
                консультанты. Т.один также может передавать Персональную
                информацию третьим лицам, не входящим в Т.один:
              </li>
              <li>
                третьим лицам, в отношении которых произведена уступка прав или
                обязанностей, или новация по соответствующему соглашению;
              </li>
              <li>
                третьим лицам, в случае если Вы выразили согласие на передачу
                Вашей Персональной информации либо передача Персональной
                информации требуется для предоставления Вам соответствующего
                Сервиса или выполнения определенного соглашения или договора,
                заключенного с Вами;
              </li>
            </ol>
          </li>
        </ol>
      </section>

      <section>
        <p>
          <strong>
            <a name='p8'></a>
            8. Как Т.один защищает Вашу Персональную информацию
          </strong>
        </p>
        <p>
          В большинстве случаев Персональная информация обрабатывается
          автоматически без доступа к ней кого-либо из сотрудников Т.один. В
          случае если такой доступ понадобится, то он может быть предоставлен
          только тем сотрудникам Т.один, которые нуждаются в этом для выполнения
          своих задач. Для защиты и обеспечения конфиденциальности данных все
          сотрудники должны соблюдать внутренние правила и процедуры в отношении
          обработки Персональной информации. Они также должны следовать всем
          техническим и организационным мерам безопасности, действующим для
          защиты Вашей Персональной информации. Т.один также внедрил достаточные
          технические и организационные меры для защиты Персональной информации
          от несанкционированного, случайного или незаконного уничтожения,
          потери, изменения, недобросовестного использования, раскрытия или
          доступа, а также иных незаконных форм обработки. Данные меры
          безопасности были реализованы с учетом современного уровня техники,
          стоимости их реализации, рисков, связанных с обработкой и характером
          Персональной информации.
        </p>
      </section>

      <section>
        <p>
          <strong>
            <a name='p9'></a>
            9. Где хранится и обрабатывается Ваша Персональная информация
          </strong>
        </p>
        <p>
          Ваша Персональная информация будет храниться в Российской Федерации.
          Для российских пользователей: Т.один осуществляет запись,
          систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение персональных данных граждан Российской
          Федерации с использованием баз данных, находящихся на территории
          Российской Федерации.
        </p>
      </section>

      <section>
        <p>
          <strong>
            <a name='p10'></a>
            10. Как долго мы храним Вашу Персональную информацию
          </strong>
        </p>
        <p>
          Т.один будет хранить Вашу Персональную информацию столько времени,
          сколько это необходимо для достижения цели, для которой она была
          собрана, или для соблюдения требований законодательства и нормативных
          актов. Если иное не требуется по закону или соглашению с Вами,
          электронный контент, показатели и документы, которые Вы храните в
          системе Т.один, будут храниться до тех пор, пока у Вас есть учетная
          запись, но они могут быть удалены. Если Вы хотите, чтобы какая-либо
          Ваша Персональная информация была удалена из баз данных, Вы можете
          составить запрос в системе ОАСУ T.one на удаление Персональной
          информации с использованием Вашей учетной записи через интерфейс
          сервиса Т.один.
        </p>
      </section>

      <section>
        <p>
          <strong>
            <a name='p11'></a>
            11. Ваши права
          </strong>
        </p>
        <p>
          В случае если это предусмотрено применимым законодательством, Вы
          имеете право на доступ к Вашей Персональной информации, обрабатываемой
          Т.один в соответствии с настоящей Политикой. Если Вы считаете, что
          какая-либо информация, которую Т.один хранит о Вас, некорректная или
          неполная, Вы можете войти в свою учетную запись и исправить Вашу
          Персональную информацию самостоятельно. Если это предусмотрено
          применяемым законодательством, Вы имеете право: требовать удаления
          Вашей Персональной информации; требовать ограничений на обработку
          Вашей Персональной информации; запросить копию Стандартных договорных
          условий, разрешающих передачу Вашей Персональной информации в Россию и
          указанных в разделе 8 настоящей Политики; возражать против обработки
          Вашей Персональной информации, если это предусмотрено применимым
          законодательством. Т.один будет выполнять указанные запросы в
          соответствии с применимым законодательством. В случаях,
          предусмотренных применимым законодательством, Вы можете также обладать
          другими правами, не указанными выше.
        </p>
        <p>
          Для осуществления вышеуказанных прав, пожалуйста, войдите в свою
          учетную запись, а в случае отсутствия специальной функции в интерфейсе
          свяжитесь с Т.один (см. раздел 13 настоящей Политики) или напишите нам
          по адресу: 426006, г. Ижевск, проезд Дерябина, д. 3, оф. 201. Если Вы
          не удовлетворены тем, как Т.один обрабатывает Вашу Персональную
          информацию, пожалуйста, сообщите нам, и мы рассмотрим Вашу претензию.
          Если Вы не удовлетворены ответом Т.один, Вы имеете право подать жалобу
          в компетентный орган.
        </p>
      </section>

      <section>
        <p>
          <strong>
            <a name='p12'></a>
            12. Как мы используем файлы cookie и другие подобные технологии на
            Сайтах или при использовании Вами Сервисов
          </strong>
        </p>
        <p>Что такое файлы cookie и для чего их использует Т.один?</p>
        <p>
          Файлы cookie — это небольшие текстовые файлы, размещенные на
          устройстве, которое Вы используете для доступа к Сайтам. Они содержат
          информацию, которая собирается с Вашего устройства и отправляется
          обратно на Сайты при каждом последующем их посещении для того, чтобы
          помнить Ваши действия и предпочтения по истечении времени. На Сайтах
          используются следующие типы файлов cookie:
        </p>
        <ol type='I'>
          <li>
            строго необходимые файлы cookie / технические файлы cookie: эти
            файлы cookie необходимы для работы Сайтов и предоставления Вам
            Сервисов; кроме всего прочего, они позволяют идентифицировать Ваше
            аппаратное и программное обеспечение, включая тип Вашего браузера;
          </li>
          <li>
            статистические / аналитические файлы cookie: эти файлы cookie
            позволяют распознавать пользователей, подсчитывать их количество и
            собирать информацию, такую как произведенные Вами операции на Сайтах
            и в Сервисах, включая информацию о посещенных Вами веб-страницах и
            контенте, который Вы получаете;
          </li>
          <li>
            технические файлы cookie: эти файлы cookie собирают информацию о
            том, как пользователи взаимодействуют с Сайтами и/или Сервисами, что
            позволяет выявлять ошибки и тестировать новые функции для повышения
            производительности Сайтов и Сервисов;
          </li>
          <li>
            функциональные файлы cookie: эти файлы cookie позволяют
            предоставлять определенные функции, чтобы облегчить использование
            Вами Сайтов, например, сохраняя Ваши предпочтения (такие как язык и
            местоположение);
          </li>
          <li>
            (сторонние) файлы отслеживания / рекламные файлы cookie: эти файлы
            cookie собирают информацию о пользователях, источниках трафика,
            посещенных страницах и рекламе, отображенной для Вас, а также той,
            по которой Вы перешли на рекламируемую страницу. Они позволяют
            отображать рекламу, которая может Вас заинтересовать, на основе
            анализа Персональной информации, собранной о Вас. Они также
            используются в статистических и исследовательских целях.
          </li>
        </ol>
        <p>
          Т.один использует информацию, содержащуюся в файлах cookie только в
          указанных выше целях, после чего собранные данные будут храниться на
          Вашем устройстве в течение периода, который может зависеть от
          соответствующего типа файлов cookie, но не превышая срока,
          необходимого для достижения их цели, после чего они будут
          автоматически удалены из Вашей системы.
        </p>
        <p>
          Персональная информация, собранная с помощью файлов cookie,
          размещенных на Вашем устройстве, может быть передана и доступна Т.один
          и/или третьим лицам, указанным в разделе 7 настоящей Политики.
          Использование Персональной информации вне Сайтов в рекламных целях,
          если таковые имеются, может быть предметом отдельных пользовательских
          соглашений, доступных на веб-сайтах третьих лиц. Т.один и/или третьи
          лица могут также предоставить Вам возможность отказаться от получения
          персонализированной рекламы, которая может быть предметом
          регулирования законодательства и правил, применимых к таким продуктам
          и предложениям. При первом посещении Сайтов может быть запрошено Ваше
          согласие на использование файлов cookie. Если после того, как Вы
          одобрили использование файлов cookie, Вы захотите изменить свое
          решение, Вы сможете сделать это, удалив файлы cookie, хранящиеся в
          Вашем браузере (обычно это можно сделать в настройках браузера —
          пожалуйста, обратитесь к руководству по работе с браузером или сайту
          его разработчика). После этого может быть сново отображено всплывающее
          окно, запрашивающее Ваше согласие, и Вы сможете сделать иной выбор.
          Если Вы отказываетесь от использования файлов cookie, это может
          привести к тому, что некоторые функции Сайтов будут Вам недоступны, и
          повлияет на возможность использования Вами Cайтов. Вы также можете
          изменить настройки Вашего браузера, чтобы принимать или отклонять по
          умолчанию все файлы cookie или файлы cookie с определенных сайтов,
          включая Сайты Т.один. Если Вы одобрили использование файлов cookie на
          одном из Сайтов Т.один, мы будем считать, что использование файлов
          cookie на всех Cайтах было одобрено Вами. Т.один также может
          использовать веб-маяки (пиксельные теги) для доступа к файлам cookie,
          ранее размещенным на Вашем устройстве, для следующих целей:
        </p>
        <ol type='I'>
          <li>
            определение Ваших действий на Сайтах и в процессе использования
            Сервисов путем доступа и использования файлов cookie, хранящихся на
            Вашем устройстве;
          </li>
          <li>
            сбор статистической информации, связанной с работой Сайтов, Сервисов
            или продуктов, утилит и предложений Т.один.
          </li>
        </ol>
      </section>

      <section>
        <p>
          <strong>
            <a name='p13'></a>
            13. Обновление настоящей Политики
          </strong>
        </p>
        <p>
          В настоящую Политику могут быть внесены изменения. Т.один имеет право
          вносить изменения по своему усмотрению, в том числе, но не
          ограничиваясь, в случаях, когда соответствующие изменения связаны с
          изменениями в применимом законодательстве, а также когда
          соответствующие изменения связаны с изменениями в работе Сайтов и
          Сервисов. Т.один обязуется не вносить существенных изменений, не
          налагать дополнительных обременений или ограничений Ваших прав,
          установленных настоящей Политикой без Вашего уведомления. Вы будете
          уведомлены о таких изменениях. Соответствующие уведомления могут быть
          отображены на Сайте (например, через всплывающее окно или баннер или в
          указании редакции в документах, размещаемых на сайте https://boxt.one)
          до того, как такие изменения вступят в силу, или могут быть отправлены
          Вам по другим каналам связи (например, по электронной почте, если Вы
          предоставили нам Ваши контактные данные).
        </p>
      </section>
    </div>
  </div>
);
