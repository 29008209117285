export const GET_AVAILABLE_DEVICES = 'GET_AVAILABLE_DEVICES';

export type IAvailableDevices = {
  cameras: number;
  counters: number;
  devices: number;
  lights: number;
  rooms: number;
  scenarios: number;
  sensors: number;
  sockets: number;
};

export const getAvailableDevices = (
  data: IAvailableDevices,
): { type: 'GET_AVAILABLE_DEVICES'; payload: IAvailableDevices } => ({
  type: GET_AVAILABLE_DEVICES,
  payload: data,
});
