import React, { Component } from 'react';
import Preloader from 'Components/Preloader';
import { getFiltersData } from 'Services/Utils';

class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.getFiltersData = getFiltersData;
  }
  _isMounted = true;

  setStateSafe(props, loaded = true) {
    if (loaded) {
      props.isLoading = false;
    }
    if (this._isMounted !== false) {
      this.setState(props);
    }
  }

  getPageClasses() {
    let cls = 'page';

    if (this.className !== undefined) {
      cls += ` ${cls}-${this.className}`;
    }

    return cls;
  }

  getLoader() {
    return <Preloader />;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (
      this.prototype &&
      typeof this.prototype.componentWillUnmount == 'function'
    ) {
      this.prototype.componentWillUnmount();
    }
  }

  componentDidMount() {
    if (
      this.prototype &&
      typeof this.prototype.componentDidMount == 'function'
    ) {
      this.prototype.componentDidMount();
    }
  }
}

export default BaseComponent;
