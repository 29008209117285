import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import MenuItem from 'Components/navigation/MenuItem';
import { mainNavigationToggle } from 'Actions/navigation';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';
import { IconLogoNavigation } from 'Components/Icons';
import { Link } from 'react-router-dom';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.ScrollBarCfg = new ScrollBarConfig();
    this.container = createRef();
  }

  state = {
    curSwipeStart: null,
    curSwipeEnd: null,
    containerWidth: null,
    isKeyboardOpen: false,
  };

  checkKeyboard = () => {
    setInterval(() => {
      if (window.isKeyboardOpen === true) {
        this.setState({ isKeyboardOpen: true });
      } else {
        this.setState({ isKeyboardOpen: false });
      }
    }, 30);
  };

  swipeStart = (e) => {
    this.setState({ curSwipeStart: e.changedTouches[0].clientX });
  };

  swipeEnd = (e) => {
    this.setState({ curSwipeEnd: e.changedTouches[0].clientX });
  };

  toggleMenu = () => {
    const minSwipe = 40; // минимальное расстояние свайпа
    if (
      this.state.curSwipeStart < this.container.current.clientWidth &&
      this.state.curSwipeEnd + minSwipe < this.state.curSwipeStart
    ) {
      return this.props.onMenuToggle(true);
    }

    if (
      this.state.curSwipeStart < this.state.containerWidth &&
      this.state.curSwipeEnd - minSwipe > this.state.containerWidth
    ) {
      this.props.onMenuToggle(false);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.containerWidth !== this.state.containerWidth ||
      prevState.curSwipeEnd !== this.state.curSwipeEnd ||
      prevState.curSwipeStart !== this.state.curSwipeStart
    ) {
      this.toggleMenu();
    }
  }

  componentDidMount() {
    this.setState({ containerWidth: this.container.current.clientWidth });
    this.checkKeyboard();
  }

  componentWillUnmount() {
    clearInterval(this.checkKeyboard);
  }

  render() {
    return (
      <div
        className={`app-navigation ${
          this.props.navigation.isMinimized ? 'close' : 'open'
        }`}
        onTouchStart={this.swipeStart}
        onTouchEnd={this.swipeEnd}
        ref={this.container}
      >
        <Link to='/' className='app-logo' title='Главная'>
          {IconLogoNavigation}
        </Link>
        <div className='app-navigation__wrapper'>
          <Scrollbar cfg={this.ScrollBarCfg}>
            <div className='app-navigation__inner'>
              <div className='an__list-items'>
                <ul>
                  {this.props.items.map((item) => {
                    return (
                      <MenuItem t={this.props.t} key={item.key} item={item} />
                    );
                  })}
                </ul>
              </div>
            </div>
          </Scrollbar>
        </div>
        {!this.state.isKeyboardOpen && (
          <div className='app-navigation__version version'>
            <div className='version__fact'>
              <span className='version__full'>
                App {this.props.t('navigation.version')}{' '}
                {this.props.userData.versionApp}
              </span>
              <span className='version__short'>
                App {this.props.userData.versionApp}
              </span>
            </div>

            <div className='version__fact'>
              <span className='version__full'>
                Web {this.props.t('navigation.version')}{' '}
                {this.props.userData.versionWebview}
              </span>
              <span className='version__short'>
                Web {this.props.userData.versionWebview}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    navigation: state.navigation,
    location: state.router.location,
    userData: state.userData,
  }),
  {
    onMenuToggle: mainNavigationToggle,
  },
)(Navigation);
