import React, { createRef } from 'react';
import BaseModal from 'Components/BaseModal';
import UserService from 'Services/User';
import SendUserPhotoForm from 'Components/user/SendPhotoForm';
import Config from 'Config';
import { IconUser } from 'Components/Icons';

const styles = {
  errorTitle: {
    color: 'rgba(219, 67, 96, .9)',
    textAlign: 'center',
    marginTop: 10,
  },
};

class UserPhotoModal extends BaseModal {
  id = 'modalUserPhoto';
  title = this.props.t('modals.user_photo.title');
  headerClassName = 'color';
  state = {
    avatarUpload: false,
    moreThanTen: false,
    error: false,
    avatarSrc: null,
    avatarLoaded: false,
    avatarRef: createRef(),
  };
  userPhotoFormOpts = {
    action: UserService.uploadAvatar,
    className: 'form_avatar',
    formType: 'formData',
    fields: {
      file: {
        id: 'form_avatar__avatar',
        type: 'file',
        label: this.props.t('modals.user_photo.upload_photo'),
        className: 'd-none',
        name: 'file',
        props: {
          ref: this.state.avatarRef,
          accept: '.jpg, .jpeg, .png',
        },
      },
    },
    extendData: {
      quality: 100,
      x: 0,
      y: 0,
      width: 'auto',
      height: 'auto',
    },
    handleError: () => this.setStateSafe({ error: true }),
    handleChange: (event) => {
      const { size } = event.target.files[0];
      const maxSize = 10485760; // 10 мбайт
      if (size > maxSize) {
        this.setStateSafe({ moreThanTen: true });
        return false;
      }
      window.URL = window.URL || window.webkitURL;
      const url = event.target.files[0]
        ? window.URL.createObjectURL(event.target.files[0])
        : '';
      this.setStateSafe({
        avatarSrc: url,
        avatarLoaded: !!event.target.files[0],
      });
    },
    btnSubmit: this.props.t('commons.buttons.save'),
    callback: (e) => {
      if (e.ok) {
        this.setStateSafe({
          avatarUpload: true,
        });
        let url = `${Config.urls.api_home}/${e.sitePath}?v=${Date.now()}`;
        this.props.updateAvatar(url);
        this.setStateSafe({ error: false });
      } else {
        this.setStateSafe({ error: true });
      }
    },
  };

  componentDidMount() {
    this.setState({
      avatarSrc: UserService.getAvatar(this.props.app.user.avatars),
    });
  }

  componentWillUnmount() {
    this.setState({
      avatarUpload: false,
    });
  }

  header() {
    return (
      <div
        className='modal-header__img'
        style={{
          backgroundImage: `url(' ${
            !this.state.avatarSrc ? IconUser : this.state.avatarSrc
          }')`,
        }}
      />
    );
  }

  content() {
    return (
      <>
        {!this.state.avatarUpload && (
          <div>
            <p>{this.props.t('modals.user_photo.subtitle')}</p>
            <ol className=' list'>
              <li>{this.props.t('modals.user_photo.condition_one')}</li>
              <li>{this.props.t('modals.user_photo.condition_two')}</li>
              <li>{this.props.t('modals.user_photo.condition_three')}</li>
              <li>{this.props.t('modals.user_photo.condition_four')}</li>
            </ol>
            <div className=' btn-block'>
              <SendUserPhotoForm
                {...this.userPhotoFormOpts}
                onDialogWindow={this.props.onDialogWindow}
                t={this.props.t}
              />
            </div>
          </div>
        )}
        {this.state.avatarUpload && (
          <div>
            <h4>{this.props.t('modals.user_photo.upload_suc')}</h4>
          </div>
        )}
        {this.state.moreThanTen && (
          <div>
            <h4 style={styles.errorTitle}>
              {this.props.t('modals.user_photo.error_weight')}
            </h4>
          </div>
        )}
        {this.state.error && (
          <div>
            <h4 style={styles.errorTitle}>
              {this.props.t('modals.user_photo.error_backend')}
            </h4>
          </div>
        )}
      </>
    );
  }
}

export default UserPhotoModal;
