import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import {
  addApartamentNotices,
  addMessagesNotices,
  addRfidNotices,
  clearStateNotices,
  deleteAllNotices,
  switchActiveCategory,
  toggleNotification,
  deleteNotice,
} from 'Actions/notify';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';
import NotifyService from 'Services/Notify';
import DataTransform from 'Services/DataTransform';
import Preloader from 'Components/Preloader';
import Loader from 'Components/Loader';
import {
  IconClose,
  // IconError,
  // IconArrowLong,
  IconUnlock,
  IconError,
  IconEye,
  // IconLock,
  // IconAnswer
} from 'Components/Icons';
import SwipeableTabs from 'react-swipeable-tabs';
import {
  NOTIFY_CATEGORY_DEVICE,
  NOTIFY_GROUPS,
  NOTIFY_PAGE_COUNT,
  NOTIFY_CATEGORY_SENSOR,
  NOTIFY_STATUS_ACTIVE,
  SENSOR_AND_DEVICE_KEY,
  NOTIFY_CATEGORY_MESSAGE,
  RFID_KEY,
  CHAT_KEY,
  NOTIFY_CATEGORY_ACCESS,
  NOTIFY_TEXT_ACCESS_TITLE,
  NOTIFY_TEXT_ACCESS_OPENED,
} from 'Config/notify';
import {
  SENSOR_MOVE_TEXT_ACTIVE,
  SENSOR_LEAKAGE_TEXT_ACTIVE,
  SENSOR_REED_SWITCH_TEXT_ACTIVE,
  DEVICE_STATUS_ON,
  DEVICE_STATUS_OFF,
  DEVICE_STATUS_WARNING,
  SENSOR_DOOR_OPEN_ID,
  SENSOR_LEAKS_ID,
  SENSOR_MOVE_ID,
  SENSOR_DEFAULT_TURN_ON,
  SENSOR_DEFAULT_TURN_OFF,
  SENSOR_ATMOSPHERE_PRESSURE_ID,
  SENSOR_VOLTAGE_ID,
  SENSOR_TEMP_ID,
  DEVICE_LIGHT_DIMM_ID,
  DEVICE_CONDITIONER_ID,
  DEVICE_HEAT_FLOOR_ID,
  DEVICE_TERMOSTAT_ID,
} from 'Config/devices';

import Tab from 'Components/NotifyTabs/Tab';
import ControlService from 'Services/Control';
import { devicesLoad } from 'Actions/devices';
import { intercomLoad } from 'Actions/intercom';

const TABS_CONFIG = {
  fitItems: false,
  alignCenter: false,
  borderWidthRatio: 1,
  itemClassName: 'react-tabs__tab',
  borderPosition: 'bottom',
  borderThickness: 4,
  borderColor: 'transparent',
  activeStyle: {
    color: '#3759CA',
  },
  noFirstLeftPadding: false,
  noLastRightPadding: false,
};

const getDeviceValue = ({
  sensorTypeId = null,
  deviceTypeId = null,
  value,
  t,
}) => {
  if (sensorTypeId) {
    switch (sensorTypeId) {
      case SENSOR_TEMP_ID:
        return `${t('notify.devices_value.temperature')} ${value}`;
      case SENSOR_VOLTAGE_ID:
        return `${t('notify.devices_value.voltage')} ${value}`;
      case SENSOR_ATMOSPHERE_PRESSURE_ID:
        return `${t('notify.devices_value.pressure')} ${value}`;
      default:
        return null;
    }
  }
  if (deviceTypeId) {
    switch (deviceTypeId) {
      case DEVICE_TERMOSTAT_ID:
      case DEVICE_HEAT_FLOOR_ID:
      case DEVICE_CONDITIONER_ID:
        return `${t('notify.devices_value.temperature')} ${value}`;
      case DEVICE_LIGHT_DIMM_ID:
        return `${t('notify.devices_value.brightness')}: ${value}`;
      default:
        return null;
    }
  }

  return null;
};

const NotificationCard = ({
  status,
  title,
  valueText,
  valueNumber = null,
  valueClasses,
  valueIcon,
  createdTs,
}) => {
  return (
    <div
      className={`${
        status === NOTIFY_STATUS_ACTIVE
          ? 'notifications-item unread'
          : 'notifications-item'
      }`}
    >
      <div className='notifications-item__top'>
        <div className='notifications-item__place'>{title}</div>
        <div className='notifications-item__time'>
          {DataTransform.getDate(createdTs, 'DD.MM.YYYY / HH:mm')}
        </div>
      </div>
      <div className='notifications-item__title'>
        <span>{valueText}</span>
        {valueNumber && <span>{valueNumber}</span>}
      </div>
      <div className={valueClasses}>{valueIcon}</div>
    </div>
  );
};

class Notifications extends BaseComponent {
  state = {
    activeItemIndex: 0,
    isLoading: false,
    groups: [],
    stopLoading: false,
  };

  constructor(props) {
    super(props);

    this.ScrollBarCfg = new ScrollBarConfig();
  }

  getTabs() {
    const newNotifyGroup = {};

    this.props.notices.forEach((notice) => {
      const { category } = JSON.parse(notice.text).data;

      if (
        category === NOTIFY_CATEGORY_DEVICE ||
        category === NOTIFY_CATEGORY_SENSOR
      ) {
        newNotifyGroup[SENSOR_AND_DEVICE_KEY] = true;
      }
      if (category === NOTIFY_CATEGORY_ACCESS) {
        newNotifyGroup[RFID_KEY] = true;
      }
      if (category === NOTIFY_CATEGORY_MESSAGE) {
        newNotifyGroup[CHAT_KEY] = true;
      }
    });

    return NOTIFY_GROUPS.map((group, index) => (
      <Tab
        key={index}
        groupKey={group.key}
        isNewNotify={!!newNotifyGroup[group.key]}
      />
    ));
  }

  getDeviceViaKey(type, key) {
    const collection = this.props[`${type}s`];
    let _device;

    if (!type || !key || !(collection instanceof Array)) return;

    console.log('key', key, collection);
    collection.some((d) => {
      if (d.key === key) {
        _device = d;
      }

      return null;
    });

    return _device;
  }

  closeNotifications() {
    this.props.onClearStateNotices();
    this.props.onNotificationsToggle(false);
  }

  _loadGroupItems() {
    const { activeCategory } = this.props;
    const notices = this.props[activeCategory.name];

    let page = 1;

    if (notices.length > 0) {
      page = parseInt(notices.length / NOTIFY_PAGE_COUNT) + 1;
    }

    // находим активную вкладку, проверяем загружали ли мы туда уведомления
    const type = activeCategory.types;

    const loadNotices = () => {
      const pushToRedux = this.props['add' + activeCategory.name].bind(this);
      const data = {
        page,
      };
      if (Array.isArray(type)) {
        data.categories = type;
      } else {
        data.category = type;
      }
      NotifyService.getNotifications(data, (res) => {
        if (res.notifyList) {
          if (
            _.find(
              notices,
              (elem) => elem.notifyId === res.notifyList[0].notifyId,
            )
          ) {
            this.setState({ stopLoading: true });
          } else {
            pushToRedux(res.notifyList);
          }
        }
        this.setState({ isLoading: false });
      });
    };

    this.setState({ isLoading: true }, () => loadNotices());
  }

  onScrollGroup(e) {
    const target = e.target;
    const scrollDiff = target.scrollHeight - target.clientHeight;

    if (
      target.scrollTop === scrollDiff &&
      !this.state.isLoading &&
      !this.state.stopLoading
    ) {
      this.setState({ isLoading: true }, () => {
        this._loadGroupItems();
      });
    }
  }

  renderNotifyInstance(item, key) {
    const { t } = this.props;
    const _data = JSON.parse(item.text);

    if (_data.data.category === NOTIFY_CATEGORY_ACCESS) {
      const itemTitle = NOTIFY_TEXT_ACCESS_OPENED(t);
      const itemValueIcon = IconUnlock;
      const itemValueClasses = 'notifications-item__value color-primary';
      let itemValueText = '';

      const { intercom } = this.props;

      for (let i = 0; i < intercom.length; i++) {
        const item = this.props.intercom[i];
        if (Number(item.intercomId) === Number(_data.data.intercom_id)) {
          itemValueText = item.name;
          break;
        }
      }

      return (
        <NotificationCard
          key={key}
          status={item.status}
          createdTs={item.createdTs}
          title={itemTitle}
          valueText={itemValueText}
          valueNumber={null}
          valueClasses={itemValueClasses}
          valueIcon={itemValueIcon}
        />
      );
    }

    const _device =
      this.getDeviceViaKey(_data.data.entity, String(_data.data.key)) || {};
    const _deviceTypeId = _device.key
      ? _device.type[`${_data.data.entity}TypeId`]
      : null;

    console.log(_device.type, _data);
    const itemValueNumber = getDeviceValue({
      sensorTypeId: _device.type.sensorTypeId,
      deviceTypeId: _device.type.deviceTypeId,
      value: _device.value,
      t,
    });
    let itemTitle = _device.title;
    let itemValueIcon = '';
    let itemValueText = '';
    let itemValueClasses = 'notifications-item__value';

    if (_deviceTypeId && _data.data.category === NOTIFY_CATEGORY_SENSOR) {
      switch (_deviceTypeId) {
        case SENSOR_MOVE_ID:
          itemValueIcon = IconError;
          itemValueText = SENSOR_MOVE_TEXT_ACTIVE(t);
          itemValueClasses += ' color-danger';
          break;
        case SENSOR_LEAKS_ID:
          itemValueIcon = IconError;
          itemValueText = SENSOR_LEAKAGE_TEXT_ACTIVE(t);
          itemValueClasses += ' color-danger';
          break;
        case SENSOR_DOOR_OPEN_ID:
          itemValueIcon = IconError;
          itemValueText = SENSOR_REED_SWITCH_TEXT_ACTIVE(t);
          itemValueClasses += ' color-danger';
          break;
        default:
          itemValueIcon = '';
          itemValueText =
            _data.data.status === DEVICE_STATUS_ON
              ? SENSOR_DEFAULT_TURN_ON(t)
              : SENSOR_DEFAULT_TURN_OFF(t);
      }
    } else if (_data.data.category === NOTIFY_CATEGORY_ACCESS) {
      itemTitle = NOTIFY_TEXT_ACCESS_OPENED(t);
      itemValueText = NOTIFY_TEXT_ACCESS_TITLE(t);
      itemValueIcon = IconUnlock;
      itemValueClasses += ' color-danger';
    } else if (_data.data.category === NOTIFY_CATEGORY_DEVICE) {
      switch (Number(_data.data.status)) {
        case DEVICE_STATUS_ON:
          itemValueText = t('commons.statuses.turn_on');
          itemValueIcon = IconError;
          itemValueClasses += ' color-success';
          break;
        case DEVICE_STATUS_OFF:
          itemValueText = t('commons.statuses.turn_off');
          itemValueIcon = IconError;
          itemValueClasses += ' color-disable';
          break;
        case DEVICE_STATUS_WARNING:
          itemValueText = t('commons.statuses.warning');
          itemValueIcon = IconError;
          itemValueClasses += ' color-danger';
          break;
        default: {
          return true;
        }
      }
    }
    // else if (item.category === NOTIFY_CATEGORY_MESSAGE) {
    //   itemTitle = item.senderId.fname + ' ' + item.senderId.mname;
    //   itemValueText = _data.data.text;
    // }
    else {
      return null;
    }

    return (
      <NotificationCard
        key={key}
        status={item.status}
        createdTs={item.createdTs}
        title={itemTitle}
        valueText={itemValueText}
        valueNumber={itemValueNumber || null}
        valueClasses={itemValueClasses}
        valueIcon={itemValueIcon}
      />
    );
  }

  _readVisibleMsgs = () => {
    const { activeCategory } = this.props;

    const startIndex =
      this.props[activeCategory.name].length - NOTIFY_PAGE_COUNT <= 0
        ? 0
        : this.props[activeCategory.name].length - NOTIFY_PAGE_COUNT;

    const lastTwentyItems = this.props[activeCategory.name].slice(
      startIndex,
      this.props[activeCategory.name].length,
    );

    setTimeout(
      () => this.props.deleteNotice(lastTwentyItems, activeCategory.name),
      3000,
    );
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.setState({ groups: this.getTabs() });

    (async () => {
      await ControlService.getDevices(
        { apartmentId: this.props.apartmentId },
        (res) => {
          this.props.onDevicesLoad(res.deviceList, 'devices');
        },
      );

      await ControlService.getSensors(
        { apartmentId: this.props.apartmentId },
        (res) => {
          this.props.onDevicesLoad(res.sensorList, 'sensors');
          this._loadGroupItems();
        },
      );
    })();
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeCategory !== prevProps.activeCategory) {
      this._loadGroupItems();
      this._readVisibleMsgs();
      this.setState({ stopLoading: false });
    }

    if (this.props.notices !== prevProps.notices) {
      this.setState({ groups: this.getTabs() });
      this.setState({ stopLoading: false });
    }

    if (
      this.props.MessagesNotices.length > prevProps.MessagesNotices.length ||
      this.props.RfidNotices.length > prevProps.RfidNotices.length ||
      this.props.ApartamentNotices.length > prevProps.ApartamentNotices.length
    ) {
      this._readVisibleMsgs();
    }
  }

  render() {
    if (!this.props.activeCategory.name) return null;
    const { t } = this.props;

    const activeCategoryItems = this.props[this.props.activeCategory.name];

    return (
      <div className={`notifications open`}>
        <button
          type='button'
          className='modal-close notifications-close'
          onClick={this.closeNotifications.bind(this)}
        >
          {IconClose}
        </button>
        <h3 className='notifications__title'>{t('notify.title')}</h3>
        <div className='react-tabs react-tabs_swipe'>
          <div className='react-tabs__nav'>
            <SwipeableTabs
              {...TABS_CONFIG}
              items={this.state.groups}
              activeItemIndex={this.state.activeItemIndex}
              onItemClick={(_item, index) => {
                const callback = () => {
                  this.setState({ activeItemIndex: index });
                  this.props.switchActiveCategory({
                    name: NOTIFY_GROUPS[index].key,
                    types: NOTIFY_GROUPS[index].category,
                  });
                };
                if (NOTIFY_GROUPS[index].category === NOTIFY_CATEGORY_ACCESS) {
                  ControlService.getIntercom(
                    { apartmentId: this.props.apartmentId },
                    (res) => {
                      this.props.onIntercomLoad(res.intercomList);
                      callback();
                    },
                  );
                } else {
                  callback();
                }
              }}
            />
          </div>
          <div
            className='react-tabs__tab-panel active'
            onScroll={(e) => this.onScrollGroup(e)}
          >
            <Scrollbar cfg={this.ScrollBarCfg}>
              <div className='notifications__items'>
                {activeCategoryItems.length > 0 &&
                  activeCategoryItems.map((item, key) => {
                    return this.renderNotifyInstance(item, key);
                  })}
                {this.state.isLoading && activeCategoryItems.length === 0 && (
                  <Preloader />
                )}
                {this.state.isLoading && activeCategoryItems.length > 0 && (
                  <Loader />
                )}
                {activeCategoryItems.length === 0 &&
                  !this.state.isLoading &&
                  t('notify.no_notifications')}
              </div>
            </Scrollbar>
          </div>
          <button
            type='button'
            className='notifications__read-all-wrapper'
            onClick={() => this.props.deleteAllNotices()}
          >
            {IconEye}
            {t('notify.read_all_notifications')}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    notices: state.notify.notices,
    ApartamentNotices: state.notify.ApartamentNotices,
    RfidNotices: state.notify.RfidNotices,
    MessagesNotices: state.notify.MessagesNotices,
    activeCategory: state.notify.activeCategory,
    notificationsOpen: state.notify.notificationsOpen,
    devices: state.devices.devices,
    sensors: state.devices.sensors,
    intercom: (state.intercom || {}).list || [],
  }),
  {
    onNotificationsToggle: toggleNotification,
    addApartamentNotices,
    addRfidNotices,
    addMessagesNotices,
    deleteNotice,
    switchActiveCategory,
    deleteAllNotices,
    onDevicesLoad: devicesLoad,
    onClearStateNotices: clearStateNotices,
    onIntercomLoad: intercomLoad,
  },
)(Notifications);
