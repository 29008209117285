import React from 'react';
import BaseModal from 'Components/BaseModal';
import Form from 'Components/Form';
import UserService from 'Services/User';
import { connect } from 'react-redux';
import { accountCreate } from 'Actions/accounts';

class CreateUserCar extends BaseModal {
  id = 'carCreateModal';
  title = this.props.t('modals.add_car.title');
  className = 'car';
  state = {
    title: '',
    createCarForm: null,
  };

  generateId(fieldName) {
    let id = `form_${this.className}-create`;

    if (fieldName) {
      id += `__${fieldName}`;
    }

    return id;
  }

  getRoles() {
    const { t } = this.props;

    return [
      { value: 1, label: t('roles.owner') },
      { value: 2, label: t('roles.citizen') },
      { value: 7, label: t('roles.child') },
      // { value: 3, label: 'Гость' }//,
      //{ value: 4, label: 'Арендатор' }
    ];
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.state.createCarForm) return;

    this.setState({
      createCarForm: {
        action: UserService.createCars,
        className:
          'form_user-create inline-fields form-inline-custom form-padding',
        fields: {
          name: {
            id: this.generateId('carModel'),
            type: 'text',
            label: `${this.props.t('commons.inputs.model')}:`,
            className: 'row',
            validationRules: ['required', { length: { max: 30 } }],
            focus: true,
            placeholder: 'Touareg',
          },
          carNumber: {
            id: this.generateId('carNumber'),
            type: 'text',
            label: `${this.props.t('commons.inputs.license_plate_number')}:`,
            className: 'row',
            validationRules: ['required', { length: { max: 12 } }],
          },
        },
        formatBeforeSend: (data) => {
          data.carNumber = data.carNumber.toUpperCase();
          data.name = data.name[0].toUpperCase() + data.name.slice(1);
          data.apartmentId = this.props.apartmentId;

          return data;
        },
        btnSubmit: this.props.t('commons.buttons.confirm'),
        btnClass: 'btn_primary mt-50',
        callback: (res) => {
          if (res && res.ok) {
            this.props.data.updateGroups({
              carNumber: res.carNumber,
              carNumberId: res.carNumberId,
              name: res.name,
            });
            this.props.onCloseModal();
          }
        },
      },
    });
  }

  content() {
    if (!this.state.createCarForm) return null;

    return <Form {...this.state.createCarForm} />;
  }
}

export default connect(null, {
  onAccountCreate: accountCreate,
})(CreateUserCar);
