import React from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from 'Actions/dialogWindow';
import { IState } from 'Reducers/dialogWindow';

// const dispatches = {
//   deviceOff: deviceOff,
// };

const DialogWindow = ({
  title,
  content,
  cancelText,
  agreeText,
  agreeCallback,
  cancelCallback,
  dispatchData,
}: {
  title: string | null;
  content: string;
  cancelText: string;
  agreeText: string;
  agreeCallback: (data?: unknown) => Promise<void>;
  cancelCallback: () => Promise<void>;
  dispatchData: { type: string; payload: { deviceId: number; value: number } };
}): JSX.Element => {
  const isLoading = useSelector(
    (state: { dialogWindow: IState }) => state.dialogWindow.isLoading,
  );
  const dispatch = useDispatch();

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        {title && (
          <header className={styles.header}>
            <h3 className={styles.title}>{title}</h3>
          </header>
        )}
        <div className={styles.contentContainer}>
          <p className={styles.content}>{content}</p>
        </div>
        <button
          type='button'
          className={styles.cancel}
          disabled={isLoading}
          onClick={() => {
            cancelCallback().then(() => dispatch(closeDialog()));
          }}
        >
          {cancelText}
        </button>
        <button
          type='button'
          className={styles.agree}
          disabled={isLoading}
          onClick={() => {
            // dispatch(dispatches[callback](dispatchData));
            if (dispatchData) {
              agreeCallback(dispatchData).then(() => dispatch(closeDialog()));
            } else {
              agreeCallback().then(() => {
                dispatch(closeDialog());
              });
            }
          }}
        >
          {agreeText}
        </button>
      </div>
    </div>
  );
};

export default DialogWindow;
