import moment from 'moment';

export const LIST_OF_YEARS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const DATE_FORMAT_DEFAULT = 'default';

const DATE_FORMAT = {
  [DATE_FORMAT_DEFAULT]: 'DD.MM.YYYY',
};

const DataTransform = {
  getDate: (date = new Date(), format = '') => {
    let _format =
      format === DATE_FORMAT_DEFAULT
        ? DATE_FORMAT[DATE_FORMAT_DEFAULT]
        : format;

    return moment(date).format(_format);
  },
  getTimezone: (date = new Date()) => {
    return moment(date).utcOffset();
  },
  getName: (user) => {
    return user.fname ? `${user.fname}` : 'Пользователь';
  },
  getCarName: (car) => {
    return `${car.name} ${car.carNumber}`;
  },
  getServicesPrice: (item) => {
    let str = item.price + ' руб.';

    switch (item.period) {
      case 'day':
        str += ' в день';
        break;
      default:
        str += '';
    }

    return str;
  },
};

export default DataTransform;
