const component = 'Camera'

export const EVENT_CAMERA_LOAD = `${component}/LOAD`
export const EVENT_CAMERA_UPDATE = `${component}/UPDATE`
export const EVENT_CAMERA_ADD = `${component}/ADD`
export const EVENT_CAMERA_PLAY = `${component}/PLAY`
export const EVENT_CAMERA_STOP = `${component}/STOP`

export function cameraLoad(camera) {
  return dispatch => {
    dispatch({
      type: EVENT_CAMERA_LOAD,
      data: camera
    })
  }
}

export function cameraUpdate(camera) {
  return dispatch => {
    dispatch({
      type: EVENT_CAMERA_UPDATE,
      data: camera
    })
  }
}

export function cameraAdd(data) {
  return dispatch => {
    dispatch({
      type: EVENT_CAMERA_ADD,
      data: data
    })
  }
}

export function onPlay(cam) {
  return dispatch => {
    dispatch({
      type: EVENT_CAMERA_PLAY,
      data: cam
    })
  }
}

export function onPlayerClose(cam) {
  return dispatch => {
    dispatch({
      type: EVENT_CAMERA_STOP,
      data: cam
    })
  }
}
