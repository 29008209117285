import {
  EVENT_SCENARIOS_LOAD
} from 'Actions/scenarios';

const initialState = {}

export function scenarios(state = initialState, action) {
  let data = {};

  switch (action.type) {
    case EVENT_SCENARIOS_LOAD:
      data = {
        ...state,
        ...action.event
      }

      return data;
    default:
      return state;
  }
}