import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ControlService from 'Services/Control';
import Button from 'Components/Button';

interface Props {
  intercomId: string;
}

const ButtonOpenIntercom: React.FC<Props> = (intercomId) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleButtonClick = async () => {
    setIsOpen(!isOpen);
    ControlService.executeIntercom(intercomId);

    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  };

  return (
    <Button
      className={` ${isOpen ? ' btn_success' : ''}`}
      buttonText={
        isOpen
          ? t('commons.buttons.opened_door')
          : t('commons.buttons.open_door')
      }
      icon='icon_entry'
      onClick={handleButtonClick}
    />
  );
};

export default ButtonOpenIntercom;
