import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import createRootReducer from 'Reducers'
import UserService from 'Services/User'
import CookieService from 'Services/Cookie'
import { COOKIE_USER_REDIRECT } from 'Config'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

export const isAuth = () => next => action => {
  const uri = CookieService.get(COOKIE_USER_REDIRECT)

  if (!UserService.isAuth()) {
    if (history.location.pathname !== '/login') {
      CookieService.set(COOKIE_USER_REDIRECT, history.location.pathname)

      history.push('/login')
    }
  } else if (uri) {
    CookieService.del(COOKIE_USER_REDIRECT)

    history.push(uri)
  }

  return next(action)
}

enhancers.push(isAuth())

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
)
