import React from 'react'
import ReactTooltip from 'react-tooltip'
import { isMobile } from 'Config'
import BaseComponent from 'Components/BaseComponent'

class Tooltip extends BaseComponent {
  render() {
    if (isMobile) return null

    return <ReactTooltip {...this.props} />
  }
}

export default Tooltip
