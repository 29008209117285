import React, { Component } from 'react';
import { IconLogoNavigation } from 'Components/Icons';

class Preloader extends Component {
  render() {
    return (
      <div className='t-preloader'>
        <div className='t-preloader__container'>
          <div className='t-preloader__circle' />
          <div className='t-preloader__img-container'>{IconLogoNavigation}</div>
        </div>
      </div>
    );
  }
}

export default Preloader;
