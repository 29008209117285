import React from 'react';
import {IconArrowBack} from 'Components/Icons';

import styles from './header.module.scss'

const Header = ({title, goBack}: {title: string, goBack: () => void}) => {
    return (
      <header className={styles.header}>
          <button className={styles.icon} type='button' onClick={goBack}>
            {IconArrowBack}
          </button>
          <span className={styles.title}>{title}</span>
      </header>
    );
}

export default Header;
