import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { navigation } from 'Reducers/navigation';
import { socket } from 'Reducers/socket';
import { messenger } from 'Reducers/messenger';
import { app } from 'Reducers/app';
import { filters } from './filters/filters';
import { push } from 'Reducers/push';
import { notify } from 'Reducers/notify';
import { modal } from 'Reducers/modal';
import { panel } from 'Reducers/panel';
import { devices } from 'Reducers/devices';
import { rooms } from 'Reducers/rooms';
import { scenarios } from 'Reducers/scenarios';
import { wallet } from 'Reducers/wallet';
import { sip } from 'Reducers/sip';
import { controllers } from 'Reducers/controllers';
import { counters } from 'Reducers/counters';
import { accounts } from 'Reducers/accounts';
import { video } from 'Reducers/video';
import { intercom } from 'Reducers/intercom';
import { uk } from 'Reducers/uk';
import { dialogWindow } from 'Reducers/dialogWindow';
import { History } from 'history';
import { language } from 'Reducers/language';
import { userData } from 'Reducers/userData';
import { availableDevices } from 'Reducers/availableDevices';
import { apartments, IApartmentsState } from 'Reducers/apartments';

export type IRootState = {
  apartments: IApartmentsState;
};

const rootReducer = (history: History<any>) =>
  combineReducers<Reducer>({
    router: connectRouter(history),
    app,
    navigation,
    filters,
    messenger,
    socket,
    push,
    notify,
    modal,
    panel,
    devices,
    rooms,
    scenarios,
    wallet,
    sip,
    controllers,
    counters,
    accounts,
    video,
    intercom,
    uk,
    language,
    userData,
    dialogWindow,
    availableDevices,
    apartments,
  });

export default rootReducer;
