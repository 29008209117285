import React from 'react';
import BaseWidget from 'Components/BaseWidget';
import { IconScripts } from 'Components/Icons';
import ControlService from 'Services/Control';

class DeviceScenarioWidget extends BaseWidget {
  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
    };
  }

  onStatusChange = (event) => {
    ControlService.executeScenario({ scenarioId: this.props.scenarioId });

    this.setState({
      status: event.target.checked === true,
    });
  };

  render() {
    // const room = this.props.room ? this.props.room : {}
    const id = this.props.scenarioId;

    return (
      <div
        className={
          'tile-items__item tile-switch tile-switch_led' +
          (this.state.status ? ' active' : ' off')
        }
      >
        <div className='tile-items__fact-value'>
          <div className='tile-items__icon-sensor'>{IconScripts}</div>
          <div className='tile-items__value'>&nbsp;</div>
        </div>
        <div className='tile-items__fact-titles'>
          <div className='tile-items__group'>
            {this.cropText(this.props.title)}
          </div>
          {/*<div className="tile-items__room">*/}
          {/*  {room.title ? room.title : 'Квартира'}*/}
          {/*</div>*/}
        </div>
        <input
          id={id}
          type='checkbox'
          checked={this.state.status}
          className='tile-items__toggle-status'
          onChange={this.onStatusChange.bind(this)}
        />
      </div>
    );
  }
}

export default DeviceScenarioWidget;
