import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { IconArrowBack, IconLogout, IconNotifications } from 'Components/Icons';
import { Link, NavLink } from 'react-router-dom';
import Tooltip from 'Components/Tooltip';
import { IconRefresh } from './Icons';
import SwitchGrid from './navigation/SwitchGrid';
import {
  ROUTE_ACCOUNTS,
  ROUTE_CONTROL,
  ROUTE_CONTROL_ENTRY,
  ROUTE_CONTROL_METER,
  ROUTE_CONTROL_ROOMS,
  ROUTE_CONTROL_VIDEO,
  ROUTE_HOME,
  ROUTE_MESSENGER,
  ROUTE_NEWS,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS,
} from 'Config/routes';
import * as Icons from 'Components/Icons';
import { getTitles } from 'Config/navigation';

class Header extends Component {
  state = {
    notifyOpen: false,
    isOnline: true,
  };

  openNotifications = () => {
    this.props.onNotificationsOpen(true);
  };

  getFiltered() {
    const notices = this.props.notices || [];
    const filtered = [];

    notices.map((n) => {
      let data = {};
      try {
        data = JSON.parse(n.text);
      } catch (e) {
        /*
        * Приходится объект с
           {delay: 2500
           group: "g2"
           important: true
           text: "Lost connection"
           timer: null
           type: "error"}
         * text === string и распарсить его нельзя
        */
        // console.log(e);
      }

      if (n.status === 1 && data) {
        filtered.push(n);
      }

      return null;
    });

    return filtered;
  }

  getSwitchGrid = () => {
    const location = this.props.location.pathname;
    if (
      location.indexOf(ROUTE_MESSENGER) > -1 ||
      location.indexOf(ROUTE_NEWS) > -1 ||
      location.indexOf(ROUTE_CONTROL_METER) > -1
    )
      return null;
    switch (location) {
      case ROUTE_HOME:
      case ROUTE_SETTINGS:
      case ROUTE_SETTINGS_PROFILE:
      case ROUTE_CONTROL:
      case ROUTE_CONTROL_ROOMS:
      case ROUTE_CONTROL_VIDEO:
      case ROUTE_CONTROL_ENTRY:
      case ROUTE_ACCOUNTS:
        return null;
      default:
        return <SwitchGrid />;
    }
  };

  getButtonBack = () => {
    let isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    if (isIOS)
      return (
        <button
          type='button'
          className='btn btn_arrow-back btn-white'
          name='button-back'
          aria-label='button_back'
          onClick={() => this.props.history.goBack()}
        >
          {IconArrowBack}
        </button>
      );
  };

  componentDidMount() {
    const isOnlineInterval = setInterval(() => {
      if (!navigator.onLine) {
        this.setState({ isOnline: false });
      } else {
        this.setState({ isOnline: true });
      }
    }, 5000);
    this.setState({ isOnlineInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.isOnlineInterval);
  }

  render() {
    const notices = this.getFiltered();

    return (
      <div
        className='app-header'
        style={{ zIndex: this.state.notifyOpen ? '111' : '1' }}
      >
        <div className='app-header__nav'>
          <div className='app-header__nav-left'>
            {this.getButtonBack()}
            {this.getSwitchGrid()}
          </div>
          <div className='app-header__nav-right'>
            <ul>
              <li>
                <Link
                  to='#'
                  className={`app-reload ${
                    this.props.active && this.state.isOnline ? 'sync' : 'desync'
                  }`}
                  data-tip='Синхронизация'
                  onClick={() => window.location.reload()}
                >
                  {IconRefresh}
                </Link>
              </li>
              <li>
                <div
                  className={
                    'app-header__messages' +
                    (notices.length ? ' messages-has' : '')
                  }
                >
                  <Link
                    to='#'
                    className='app-messages btn btn_navigation'
                    data-tip='Уведомления'
                    onClick={this.openNotifications}
                  >
                    {IconNotifications}
                    <span className='badge-pulse' />
                  </Link>
                  <Tooltip place='bottom' type='dark' effect='float' />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
