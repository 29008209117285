import React from 'react';
import { connect } from 'react-redux';
import BaseWidget from 'Components/BaseWidget';
import {
  IconDeviceConditioner,
  IconDeviceRele,
  IconDevices,
  IconDeviceServo,
  IconDeviceSmartPod,
  IconDeviceTermostat,
  IconLighting,
  IconSettings,
  IconWarmFloor,
} from 'Components/Icons';
import {
  DEVICE_CONDITIONER_ID,
  DEVICE_LIGHT_CONTROL_ID,
  DEVICE_HEAT_FLOOR_ID,
  DEVICE_LIGHT_RGB_ID,
  DEVICE_LIGHT_TOGGLER_ID,
  DEVICE_RELE_ID,
  DEVICE_SERVO_ID,
  DEVICE_SMART_POD_ID,
  DEVICE_STATUS_OFF,
  DEVICE_STATUS_ON,
  DEVICE_STATUS_WARNING,
  DEVICE_TERMOSTAT_ID,
} from 'Config/devices';
import { panelOpen } from 'Actions/panel';
import { deviceStatusToggle } from 'Actions/devices';

class UnknownDeviceWidget extends BaseWidget {
  getData = () => {
    const { status } = this.props;
    const { deviceTypeId } = this.props.type;
    let className, value, icon;

    switch (status) {
      case DEVICE_STATUS_ON:
        className = ' active';
        break;
      case DEVICE_STATUS_OFF:
        className = ' off';
        break;
      case DEVICE_STATUS_WARNING:
        className = ' warning';
        break;
      default:
        className = '';
    }

    switch (deviceTypeId) {
      case DEVICE_TERMOSTAT_ID:
        icon = IconDeviceTermostat;
        break;
      case DEVICE_LIGHT_CONTROL_ID:
      case DEVICE_LIGHT_RGB_ID:
      // case DEVICE_LIGHT_DIMM_ID:
      case DEVICE_LIGHT_TOGGLER_ID:
        icon = IconLighting;
        break;
      case DEVICE_CONDITIONER_ID:
        icon = IconDeviceConditioner;
        break;
      case DEVICE_HEAT_FLOOR_ID:
        icon = IconWarmFloor;
        break;
      case DEVICE_SERVO_ID:
        icon = IconDeviceServo;
        break;
      case DEVICE_RELE_ID:
        icon = IconDeviceRele;
        break;
      case DEVICE_SMART_POD_ID:
        icon = IconDeviceSmartPod;
        break;
      default:
        icon = IconDevices;
    }

    value = this.props.category.title;

    return {
      className,
      value,
      icon,
    };
  };

  openModal = () => {
    this.props.panelOpen('EditDevice', {
      device: this.props,
      type: 'device',
    });
  };

  render() {
    const room = this.props.room ? this.props.room : {};
    const id = this.props.sensorId || this.props.deviceId;
    const sensorData = this.getData();

    return (
      <div
        className={
          'tile-items__item tile-switch tile-switch_led switcher' +
          sensorData.className
        }
      >
        <div className='tile-items__fact-value'>
          <div className='tile-items__icon-sensor'>{sensorData.icon}</div>
          <span className='tile-items__value'>&nbsp;</span>
        </div>
        <div className='tile-items__fact-titles'>
          <div className='tile-items__group'>
            {this.cropText(this.props.title)}
          </div>
          <div className='tile-items__room'>
            {room.title ? room.title : 'Квартира'}
          </div>
        </div>
        <input
          id={id}
          type='checkbox'
          checked={Number(this.props.status) === DEVICE_STATUS_ON}
          className='tile-items__toggle-status'
          onChange={() => this.props.onStatusChange(id)}
        />
        <button
          type={'button'}
          aria-label={'settings_button'}
          name={'settings_button'}
          className='tile-items__settings-btn'
          onClick={this.openModal}
        >
          {IconSettings}
        </button>
      </div>
    );
  }
}

export default connect(
  null,
  {
    panelOpen,
    onStatusChange: deviceStatusToggle,
  },
)(UnknownDeviceWidget);
