import React from 'react'
import BaseComponent from 'Components/BaseComponent'

class Tracking extends BaseComponent {
  className = 'tracking'
  state = {
    items: []
  }

  render() {
    return <div className={this.getPageClasses()} />
  }
}

export default Tracking
