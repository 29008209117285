import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from 'Components/BaseComponent';
import Validation from 'Services/Validation';
import InputMask from 'react-input-mask';
import { withTranslation } from 'react-i18next';
import Input from 'react-phone-number-input/input';
import BaseSelect from 'Components/BaseSelect';
// eslint-disable-next-line import/default
import DatePicker from 'react-date-picker';
// import RangeSlider from './RangeSlider';
import {
  IconCalendar,
  IconClose,
  IconDocs,
  IconEye,
  IconEyeOff,
  IconCheck,
} from 'Components/Icons';
import ControlService from 'Services/Control';
import {
  // DEVICE_CATEGORY_CLIMATE,
  DEVICE_CONDITIONER_ID,
  DEVICE_HEAT_FLOOR_ID,
  DEVICE_LIGHT_CONTROL_ID,
  DEVICE_LIGHT_DIMM_ID,
  DEVICE_LIGHT_RGB_ID,
  DEVICE_STATUS_ON,
  DEVICE_TERMOSTAT_ID,
} from 'Config/devices';
import ConnectedTileItem from 'Components/TileItem';
// import DeviceRadiatorWidget from 'Widgets/DeviceRadiator';
import ControlSensorWidget from 'Widgets/ControlSensor';
import DeviceLightningWidget from 'Widgets/DeviceLighting';
import DeviceRosetteWidget from 'Widgets/DeviceRosette';
import UnknownDeviceWidget from 'Widgets/UnknownDevice';
import { deviceStatusToggle, editDeviceValue } from 'Actions/devices';
import AdvancedSettings from 'Components/advancedSettings/AdvancedSettings.tsx';
import _ from 'lodash';

import 'react-phone-number-input/style.css';

import styles from 'Assets/styles/components/In-RoomDevices.module.scss';
import { EditActiveApartmentType } from 'Panels/EditActiveApartment';
import ActiveApartmentWidget from 'Widgets/ActiveApartmentWidget';

class Form extends BaseComponent {
  action = function () {};
  formType = '';
  fields = [
    {
      name: 'string',
      type: '',
      label: '',
      id: '',
      className: 'string',
      validationRules: ['required'],
    },
  ];
  btnSubmit = 'Send';
  btnClass = '';
  extendData = {};
  callback = function () {};
  onError = function () {};
  onSuccess = function () {};
  callbackSubmit = function () {};
  callbackAsync = function () {};
  formatBeforeSend = function () {};

  constructor(props = {}) {
    super(props);

    this.action = props.action;
    this.onError = props.onError;
    this.onSuccess = props.onSuccess;
    this.annotation = props.annotation;
    this.fields = props.fields;
    this.formType = props.formType;
    this.callback = props.callback;
    this.onSubmitFinish = props.onSubmitFinish;
    this.extendData = props.extendData;
    this.callbackAsync = props.callbackAsync;
    this.callbackSubmit = props.callbackSubmit;
    this.formatBeforeSend = props.formatBeforeSend;
    this.appendBtn = props.appendBtn;
    this.btnSubmit = props.btnSubmit;
    this.btnClass = props.btnClass;
    this.btnCustomize = props.btnCustomize;
    this.setSelected = props.setSelected;
    this.changeDefaultValue = props.changeDefaultValue;

    this.state = {
      ...props,
      errors: {},
      formLoad: false,
      rangeSliderValue: null,
      isShowPassword: false,
      submitText: props.btnSubmit,
    };
  }

  _isValid() {
    return Object.keys(this.state.errors).length === 0;
  }

  _validate = () => {
    let errors = {};

    Object.keys(this.state.fields).map((fieldName) => {
      let errorList = Validation.getErrors(
        this.state.fields[fieldName],
        this.props.t,
      );

      if (errorList.length > 0) errors[fieldName] = errorList;

      return errorList;
    });

    this.setState({
      errors: errors,
    });

    return Object.keys(errors).length === 0;
  };

  _onChange = (event) => {
    const target = event.target;

    this.state.fields[target.name].value = target.value;

    this.setState({
      fields: this.state.fields,
    });
  };

  _onPhoneChange = (val) => {
    this.setState({
      fields: {
        ...this.state.fields,
        phone: {
          ...this.state.fields.phone,
          value: val,
        },
      },
    });
  };

  _onFileChange = (ref, event) => {
    const target = event.target;
    const _file = ref.current.files[0];

    this.state.fields[target.name].value = _file;

    this.setState({
      fields: this.state.fields,
    });
  };

  _onSelectChange = (fieldName, data) => {
    if (this.changeDefaultValue) this.changeDefaultValue(data);
    const fields = _.cloneDeep(this.state.fields);
    fields[fieldName].value = data;
    fields[fieldName].props.value = data;

    this.setState({
      fields,
    });
  };

  _onRadioChange = (fieldName, value) => {
    this.state.fields[fieldName].value = value;

    this.setState({
      fields: this.state.fields,
    });
  };

  _onCheckboxChange = (fieldName) => {
    this.state.fields[fieldName].value = !this.state.fields[fieldName].value;

    this.setState({
      fields: this.state.fields,
    });
  };

  _onCalendarChange = (fieldName, date) => {
    this.state.fields[fieldName].value = date;

    this.setState({
      fields: this.state.fields,
    });
  };

  _onMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    if (
      value.endsWith('-') &&
      userInput !== '-' &&
      !this.state.value.endsWith('-')
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  _onSubmit = (event) => {
    event.preventDefault();
    if (this._validate()) {
      this.setState({ submitText: this.props.t('commons.buttons.sending') });
      let data = this._prepareData();

      if (typeof this.onSubmitStart === 'function') {
        this.onSubmitStart(data);
      }
      if (typeof this.callbackSubmit === 'function') {
        this.callbackSubmit(data, this);
        this.clearForm();
        return null;
      }

      if (typeof this.formatBeforeSend === 'function') {
        data = this.formatBeforeSend(data);
      }

      this.setState({
        formLoad: true,
      });
      this.state.action(data).then((res) => {
        this.setStateSafe({
          formLoad: false,
        });
        if (
          (res && res.errorList) ||
          (res && res.violationList) ||
          (res && res.ok === false)
        ) {
          this.setState({ submitText: this.props.btnSubmit });
          this.onError && this.onError(res);
          let _errType;
          if (res.violationList) {
            if (res.violationList['email']) _errType = 'email';
            else if (res.violationList['device_type_id'])
              _errType = 'device_type_id';
          } else if (res.errorList) {
            _errType = res.errorList[0];
          }

          let errors = Validation.getAssetMessage(_errType, this.props.t);

          if (errors) {
            this.setStateSafe({
              errors: errors,
            });
          } else {
            this.state.callback(res, data);

            this.clearForm();
          }
        } else {
          if (this.state.fields.device_type_id) {
            switch (this.state.fields.device_type_id.value.value) {
              case DEVICE_LIGHT_CONTROL_ID:
              case DEVICE_LIGHT_RGB_ID:
              case DEVICE_LIGHT_DIMM_ID:
              case DEVICE_CONDITIONER_ID:
              case DEVICE_HEAT_FLOOR_ID:
              case DEVICE_TERMOSTAT_ID:
                ControlService.executeDevice({
                  deviceId: this.extendData.device_id,
                  value: this.state.rangeSliderValue,
                  status: this.state.fields.device_type_id.props.status,
                }).then((r) => {
                  if (r.ok) {
                    this.state.callback(res, data);
                    this.props.editDeviceValue({
                      deviceId: this.extendData.device_id,
                      value: this.state.rangeSliderValue,
                    });
                    this.clearForm();
                  }
                });
                break;
              default:
                return false;
            }
          } else {
            this.state.callback(res, data);
            this.clearForm();
          }

          this.onSuccess(res);
        }
      });

      if (typeof this.callbackAsync === 'function') {
        this.callbackAsync(data);
      }
    } else {
      // TODO: do something
    }
  };

  clearForm() {
    if (this.state.clean !== false)
      Object.keys(this.state.fields).map((i) => {
        return (this.state.fields[i].value = '');
      });
  }

  // ВОТ ЗДЕСЬ СОБИРАЕТСЯ ВСЯ ИНФА
  _prepareData = () => {
    let data = {};
    let formData = new FormData();

    if (this.formType === 'formData') {
      Object.keys(this.state.fields).forEach((fieldName) => {
        if (this.state.fields[fieldName].type === 'file') {
          formData.append(fieldName, this.state.fields[fieldName].value);
        } else {
          let _val = this.state.fields[fieldName].value;

          if (typeof _val === 'object') {
            _val = _val.value;
          }

          formData.append(fieldName, _val);
        }
      });

      if (this.extendData)
        Object.keys(this.extendData).map((key) => {
          return formData.append(key, this.extendData[key]);
        });

      return formData;
    } else {
      Object.keys(this.state.fields).forEach((fieldName) => {
        data[fieldName] = this.state.fields[fieldName].value;

        if (!data[fieldName] && this.state.fields[fieldName].props) {
          data[fieldName] = this.state.fields[fieldName].props.defaultValue;
        }
      });
      return Object.assign(data, this.extendData);
    }
  };

  getFormField(fieldName = '', field = {}) {
    const { t } = this.props;
    let wrapper;

    switch (field.type) {
      case 'calendar':
      case 'radio':
      case 'switch':
      case 'checkbox':
      case 'select':
      case 'button':
      case 'tel':
        wrapper = <div />;
        break;
      default:
        wrapper = <div />;
    }

    field.name = fieldName;

    if (field.mask) {
      wrapper = React.cloneElement(
        wrapper,
        {},
        <InputMask
          id={field.id}
          name={fieldName}
          placeholder={field.placeholder}
          type={field.type}
          mask={field.mask}
          disabled={field.disabled}
          value={field.value || ''}
          onChange={this._onChange}
          beforeMaskedValueChange={this._onMaskedValueChange}
          formatChars={{
            9: '[0-9]',
            a: '[A-Za-z]',
            '*': '[A-Za-z0-9]',
            '.': '[а-яА-Я]',
          }}
          autoComplete='off'
          autoFocus={field.focus}
          className='form-control'
        />,
      );

      return wrapper;
    }

    if (field.type === 'password') {
      wrapper = React.cloneElement(
        wrapper,
        {},
        <>
          <div
            className={`show-password ${
              this.state.isShowPassword
                ? 'show-password--show'
                : 'show-password--hidden'
            }`}
          >
            <InputMask
              id={field.id}
              name={fieldName}
              placeholder={field.placeholder}
              type={this.state.isShowPassword ? 'text' : 'password'}
              mask={field.mask}
              disabled={field.disabled}
              value={field.value || ''}
              onChange={this._onChange}
              beforeMaskedValueChange={this._onMaskedValueChange}
              formatChars={{
                9: '[0-9]',
                a: '[A-Za-z]',
                '*': '[A-Za-z0-9]',
                '.': '[а-яА-Я]',
              }}
              autoComplete='off'
              autoFocus={field.focus}
              className='form-control'
            />
            <label className={'show-password__label'}>
              {!this.state.isShowPassword ? IconEye : IconEyeOff}
              <input
                type='checkbox'
                className='show-password__checkbox'
                onChange={() =>
                  this.setState({ isShowPassword: !this.state.isShowPassword })
                }
                checked={this.state.isShowPassword}
              />
            </label>
          </div>
        </>,
      );

      return wrapper;
    }

    if (field.type === 'tel') {
      const phone = this.state.fields.phone.value;

      wrapper = React.cloneElement(
        wrapper,
        {},
        <Input
          placeholder='+12133734253'
          value={phone}
          onChange={this._onPhoneChange}
          className={'form-control'}
        />,
      );

      return wrapper;
    }

    if (field.type === 'select') {
      field.props.onSelectChange = (data) => {
        field.props.onChange && field.props.onChange(data);
        this._onSelectChange.call(this, fieldName, data);
      };

      if (field.props.value) {
        field.props.defaultValue = '';
      }
      if (field.props.defaultValue) {
        field.props.value = field.props.defaultValue;
      }
      field.value = field.props.value;

      wrapper = React.cloneElement(
        wrapper,
        {},
        <BaseSelect {...field.props} />,
      );

      return wrapper;
    }

    if (field.type === 'textarea') {
      wrapper = React.cloneElement(
        wrapper,
        {},
        <textarea
          id={field.id}
          name={fieldName}
          value={field.value}
          className='form-control'
          onChange={this._onChange.bind(this)}
        />,
      );

      return wrapper;
    }

    if (field.type === 'switch') {
      const tpl = (
        <div className='switch align-items-center'>
          {field.options.map((option) => {
            return (
              <span className='switch__item' key={option.id}>
                <input
                  type='radio'
                  id={option.id}
                  name={fieldName}
                  value={option.value}
                  onChange={this._onRadioChange.bind(
                    this,
                    fieldName,
                    option.value,
                  )}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.id}>{option.label}</label>
              </span>
            );
          })}
        </div>
      );

      wrapper = React.cloneElement(wrapper, {}, tpl);

      return wrapper;
    }

    if (field.type === 'radio') {
      const tpl = (
        <div className='radio-group'>
          {field.options.map((option) => {
            return (
              <span className='radio__item' key={option.id}>
                <input
                  type='radio'
                  id={option.id}
                  name={fieldName}
                  value={option.value}
                  onChange={this._onRadioChange.bind(
                    this,
                    fieldName,
                    option.value,
                  )}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.id}>{option.label}</label>
              </span>
            );
          })}
        </div>
      );

      wrapper = React.cloneElement(wrapper, {}, tpl);

      return wrapper;
    }

    if (field.type === 'file' && field.props) {
      wrapper = React.cloneElement(
        wrapper,
        {},
        <>
          <input
            {...field.props}
            id={field.id}
            name={fieldName}
            type={field.type}
            onChange={this._onFileChange.bind(this, field.props.ref)}
            autoComplete='off'
            className='form-control'
          />
          <label htmlFor={field.id} className='btn btn_secondary btn_icon'>
            {IconDocs}
          </label>
          <span className={field.value ? 'file__value' : 'file__placeholder'}>
            {field.value ? field.value.name : field.placeholder}
          </span>
        </>,
      );

      return wrapper;
    }

    if (field.type === 'checkbox') {
      wrapper = React.cloneElement(
        wrapper,
        {},
        <div className='custom-control custom-control_checkbox'>
          <div key={field.id}>
            <input
              type='checkbox'
              className='custom-control__input'
              id={field.id}
              name={fieldName}
              value={field.value}
              onChange={this._onCheckboxChange.bind(this, fieldName)}
            />
            <label className='custom-control__label' htmlFor={field.id}>
              <span>{field.label}</span>
            </label>
          </div>
        </div>,
      );

      return wrapper;
    }

    if (field.type === 'calendar') {
      const date = field.value ? new Date(field.value) : '';
      wrapper = React.cloneElement(
        wrapper,
        {},
        <DatePicker
          onChange={this._onCalendarChange.bind(this, fieldName)}
          value={date}
          className='form-control'
          calendarIcon={IconCalendar}
          disabled={this.state.disabled}
          clearIcon={null}
          showLeadingZeros={true}
        />,
      );

      return wrapper;
    }

    if (field.type === 'button') {
      const tpl = (
        <div className='radio-group filters'>
          {this.props.fields.filter_btn.buttons.map((item) => (
            <button
              key={item.status}
              type='button'
              onClick={() => this.props.handleClick(item.status)}
              className={this.props.getClassName(item.status)}
            >
              <span className='filters__icon'>
                {this.props.fields.filter_btn.icon}
              </span>
              {item.content}
              <span className='filters__icon-check'>{IconCheck}</span>
            </button>
          ))}
        </div>
      );

      wrapper = React.cloneElement(wrapper, {}, tpl);

      return wrapper;
    }

    if (field.type === EditActiveApartmentType) {
      const tpl = (
        <div className={`in-room-devices ${styles.container}`}>
          {field.apartments.list.length > 0 && (
            <>
              <ul className='tile-items'>
                {field.apartments.list.map((item) => (
                  <ConnectedTileItem
                    key={item.apartmentId}
                    isInRoomDevices={true}
                    className='led'
                  >
                    <ActiveApartmentWidget {...item} />
                  </ConnectedTileItem>
                ))}
              </ul>
            </>
          )}
        </div>
      );

      wrapper = tpl;

      return wrapper;
    }

    if (field.type === 'in-room-devices') {
      const tpl = (
        <div className={`in-room-devices ${styles.container}`}>
          {field.sensors.length > 0 && (
            <>
              <span className={styles.categoryName}>{t('sensors.title')}</span>
              <ul className='tile-items'>
                {field.sensors.map((item) => (
                  <ConnectedTileItem
                    key={item.sensorId}
                    isInRoomDevices={true}
                    className='led'
                  >
                    <ControlSensorWidget {...item} />
                  </ConnectedTileItem>
                ))}
              </ul>
            </>
          )}

          {field.light.length > 0 && (
            <>
              <span className={styles.categoryName}>{t('lighting.title')}</span>
              <ul className='tile-items'>
                {field.light.map((item) => (
                  <ConnectedTileItem
                    key={item.deviceId}
                    isInRoomDevices={true}
                    className='led'
                  >
                    <DeviceLightningWidget
                      {...item}
                      onStatusChange={this.props.onStatusToggle}
                      panelOpen={this.props.panelOpen}
                    />
                  </ConnectedTileItem>
                ))}
                ;
              </ul>
            </>
          )}

          {field.rosette.length > 0 && (
            <>
              <span className={styles.categoryName}>Розетки</span>
              <ul className='tile-items'>
                {field.rosette.map((item) => {
                  return (
                    <ConnectedTileItem
                      key={item.deviceId}
                      isInRoomDevices={true}
                      className='led'
                    >
                      <DeviceRosetteWidget
                        {...item}
                        onStatusChange={this.props.onStatusToggle}
                        panelOpen={() => null}
                      />
                    </ConnectedTileItem>
                  );
                })}
              </ul>
            </>
          )}

          {field.devices.length > 0 && (
            <>
              <span className={styles.categoryName}>{t('devices.title')}</span>
              <ul className='tile-items'>
                {field.devices.map((item) => {
                  return (
                    <ConnectedTileItem
                      key={item.deviceId}
                      isInRoomDevices={true}
                      className='led'
                    >
                      <UnknownDeviceWidget {...item} />
                      {/*{item.category.name !== DEVICE_CATEGORY_CLIMATE ? (*/}
                      {/*  <UnknownDeviceWidget {...item} />*/}
                      {/*) : (*/}
                      {/*  <DeviceRadiatorWidget {...item} />*/}
                      {/*)}*/}
                    </ConnectedTileItem>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      );

      wrapper = tpl;

      return wrapper;
    }

    const addProps = {};
    if (field.maxLength) addProps.maxLength = field.maxLength;

    wrapper = React.cloneElement(
      wrapper,
      {},
      <>
        <input
          {...addProps}
          id={field.id}
          name={fieldName}
          placeholder={field.placeholder}
          type={field.type}
          value={field.value || ''}
          onChange={this._onChange}
          autoFocus={field.focus}
          disabled={field.disabled === true}
          autoComplete='off'
          className='form-control'
        />
        {this.props.onRemoveInput && (
          <button
            type='button'
            className='form-group__remove-input'
            onClick={() =>
              this.props.onRemoveInput(Number(fieldName.replace(/email/, '')))
            }
          >
            {IconClose}
          </button>
        )}
      </>,
    );

    return wrapper;
  }

  onSliderChange = (value) => {
    ControlService.executeDevice({
      device_id: this.state.extendData.device_id,
      value: value,
      status: DEVICE_STATUS_ON,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      Object.keys(this.state.fields).length >
        Object.keys(prevState.fields).length ||
      Object.keys(this.state.fields).length <
        Object.keys(prevState.fields).length
    ) {
      const _fields = { ...this.state.fields };
      for (const key in _fields) {
        if (prevState.fields[key]) {
          _fields[key].value = prevState.fields[key].value;
        }
      }
      this.setState({
        fields: _fields,
      });
    } else if (prevProps.fields !== this.props.fields) {
      this.setState({
        fields: this.props.fields,
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.setSelected) this.setSelected();
  }

  render() {
    if (
      this.state.fields.items &&
      this.state.fields.items.type === 'in-room-devices'
    ) {
      const field = this.state.fields.items;
      return this.getFormField('items', field);
    }

    return (
      <div className={'form ' + this.props.className}>
        {this.annotation ? (
          <div className={`form__annotation`}>{this.annotation}</div>
        ) : null}
        <form onSubmit={this._onSubmit}>
          <div className='form__fact'>
            {Object.keys(this.state.fields).map((fieldName) => {
              let field = this.state.fields[fieldName];

              return (
                <div
                  className={
                    'form-group ' +
                    field.className +
                    (this.state.errors[fieldName] ? ' is-error' : '')
                  }
                  key={field.id}
                >
                  {field.type !== 'checkbox' &&
                    field.type !== 'hidden' &&
                    field.type !== 'radio' && (
                      <label htmlFor={field.id}>{field.label}</label>
                    )}
                  {this.getFormField(fieldName, field)}
                  <p
                    className='input__error'
                    hidden={
                      this._isValid() ||
                      (Validation.getErrors(field, this.props.t).length === 0 &&
                        !this.state.errors[fieldName]) ||
                      field.type === 'checkbox'
                    }
                  >
                    {this.state.errors[fieldName]
                      ? this.state.errors[fieldName][0]
                      : null}
                  </p>
                </div>
              );
            })}
            {this.state.fields.device_type_id ? (
              <AdvancedSettings
                id={this.state.fields.device_type_id.value.value}
                deviceId={this.state.extendData.device_id}
                onRangeChange={(value) =>
                  this.setState({ rangeSliderValue: Math.round(value) })
                }
                rangeSliderValue={
                  this.state.fields.device_type_id.props.curValue || null
                }
                isTurnOn={
                  this.state.fields.device_type_id.props.status !==
                  DEVICE_STATUS_ON
                }
              />
            ) : null}
          </div>
          <div className='form__fact'>
            <div className='btn-form-wrap'>
              {this.props.prependBtn && this.props.prependBtn()}
              <button
                type='submit'
                className={'btn' + (this.btnClass ? ' ' + this.btnClass : '')}
                disabled={this.state.disabled ? true : this.state.formLoad}
              >
                <span>{this.state.submitText}</span>
              </button>
              {this.appendBtn && this.appendBtn(this)}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userData: state.userData,
    language: state.language,
    dialogWindow: state.dialogWindow,
  }),
  {
    onStatusToggle: deviceStatusToggle,
    editDeviceValue: editDeviceValue,
  },
)(withTranslation()(Form));
