import ApiService from 'Services/Api';
import Logger from 'Components/Logger';
import { updateStorage, getStorageKey } from 'Services/Storage';
import { isEmptyObj } from 'Services/Utils';

export default {
  getIntercom(data = {}, cb = function() {}) {
    const storageIntercom = getStorageKey('intercom');
    if (!isEmptyObj(storageIntercom)) {
      return cb(storageIntercom);
    }

    return ApiService.getIntercom(data)
      .then((res) => {
        updateStorage('intercom', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getScenarios(data = {}, cb = function() {}) {
    const storageScenarios = getStorageKey('scenarios');
    if (!isEmptyObj(storageScenarios)) {
      return cb(storageScenarios);
    }

    return ApiService.getControlScenarios(data)
      .then((res) => {
        updateStorage('scenarios', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  executeScenario(data = {}) {
    return ApiService.executeControlScenarios(data);
  },

  getDevices(data = {}, cb) {
    const storageDevices = getStorageKey('devices');
    if (!isEmptyObj(storageDevices)) {
      return cb(storageDevices);
    }

    return ApiService.getDevices(data)
      .then((res) => {
        updateStorage('devices', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  executeDevice(data = {}) {
    return ApiService.executeDevice(data);
  },

  executeDeviceCategory(data = {}) {
    return ApiService.executeDeviceCategory(data);
  },

  getSensors(data = {}, cb) {
    const storageSensors = getStorageKey('sensors');
    if (!isEmptyObj(storageSensors)) {
      return cb(storageSensors);
    }

    return ApiService.getSensors(data)
      .then((res) => {
        updateStorage('sensors', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  switchSensor(data = {}) {
    return ApiService.switchSensor(data);
  },

  executeIntercom(data = {}) {
    return ApiService.executeIntercom(data);
  },

  // getCategories(data = {}, cb = function () {}) {
  //   const storageCategories = getStorageKey('categories');
  //   if (!isEmptyObj(storageCategories)) {
  //     return cb(storageCategories)
  //   }

  //   return ApiService.getDeviceCategories(data).then(res => {
  //       updateStorage('categories', res)
  //       cb(res)
  //     })
  //     .catch(err => {
  //       Logger.log(err, 'error')
  //     })
  // },

  getCategoriesTypes(cb = function() {}) {
    const storageCategories = getStorageKey('categoriesTypes');
    if (!isEmptyObj(storageCategories)) {
      return cb(storageCategories);
    }

    return ApiService.getCategoriesTypes()
      .then((res) => {
        updateStorage('categoriesTypes', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getSensorsTypes(cb = function() {}) {
    const storageCategories = getStorageKey('sensorsTypes');
    if (!isEmptyObj(storageCategories)) {
      return cb(storageCategories);
    }

    return ApiService.getSensorsTypes()
      .then((res) => {
        updateStorage('sensorsTypes', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getDevicesTypes(cb = function() {}) {
    const storageCategories = getStorageKey('devicesTypes');
    if (!isEmptyObj(storageCategories)) {
      return cb(storageCategories);
    }

    return ApiService.getDevicesTypes()
      .then((res) => {
        updateStorage('devicesTypes', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  updateDevice(data = {}, cb = () => {}) {
    return ApiService.updateDeviceInfo(data);
  },

  updateSensor(data = {}, cb = () => {}) {
    return ApiService.updateSensorInfo(data);
  },

  updateRoom(data = {}, cb = () => {}) {
    return ApiService.updateRoom(data);
  },
};
