import React from 'react';
import BaseWidget from 'Components/BaseWidget';
import MeterService from 'Services/Meter';
import { IconSettings, IconClose } from 'Components/Icons';
import Form from 'Components/Form';
import get from 'lodash/get';

class Counters extends BaseWidget {
  state = {
    openedSettings: null,
    currentValue: null,
  };

  renderForm(counter) {
    const formParams = {
      action: MeterService.addCounterValue,
      className: 'form_counter-add-value',
      fields: {
        current_value: {
          id: 'form_counter-add-value__current_value',
          type: 'number',
          value: counter.currentValue,
          label: `${this.props.t('commons.inputs.current_value')}:`,
          className: '',
          validationRules: [
            'required',
            { number: { min: counter.currentValue } },
          ],
        },
      },
      btnSubmit: this.props.t('commons.buttons.save'),
      btnClass: 'btn_primary btn_sm no-margin',
      extendData: {
        telemetry_id: this.state.openedSettings,
      },
      formatBeforeSend: (data) => {
        this.state.currentValue = data.current_value;

        return data;
      },
      callback: (res) => {
        if (res && res.ok) {
          counter.currentValue = this.state.currentValue;
          this.props.onUpdate(this.state.openedSettings, counter);
        }
        this.setState({
          openedSettings: null,
          currentValue: null,
        });
      },
      // appendBtn: formCtx => {
      //   return (
      //     <button
      //       className="btn btn_danger btn_"
      //       disabled={formCtx.state.formLoad}
      //       onClick={(e) => {
      //         e.preventDefault()
      //         e.stopPropagation()
      //         const conf = window.confirm('Вы уверены?')
      //         if(conf) {
      //           console.log('REMOVE') // TODO: запилить удаление
      //         }
      //       }}>
      //       Удалить
      //     </button>
      //   )
      // },
    };

    return <Form {...formParams} />;
  }

  render() {
    return (
      <div className='counters'>
        {Object.keys(this.props.counters).map((i) => {
          const counter = this.props.counters[i] || {};

          return (
            <div
              className={`pane-border-block counters__info ${this.getWidgetClasses(
                counter,
              )}`}
              key={i}
            >
              <div className='meter__image'>{counter.icon}</div>
              <div
                className={`meter-item${
                  this.state.openedSettings === counter.telemetryId
                    ? ' meter-item_open-settings'
                    : ''
                }`}
              >
                {this.state.openedSettings === counter.telemetryId ? (
                  <>
                    <span
                      className='meter-item__settings'
                      onClick={() => {
                        this.setState({
                          openedSettings: null,
                        });
                      }}
                    >
                      {IconClose}
                    </span>
                    {this.renderForm(counter)}
                  </>
                ) : (
                  <>
                    <span
                      className='meter-item__settings'
                      onClick={() => {
                        this.setState({
                          openedSettings: counter.telemetryId,
                        });
                      }}
                    >
                      {IconSettings}
                    </span>
                    <div className='meter-item__name'>
                      {counter.title} №&nbsp;{counter.serialNumber}
                    </div>
                    <div className='meter-item__value'>
                      {counter.currentValue.toLocaleString('ru')}&nbsp;
                      {counter.unit}
                    </div>
                    <div className='meter-item__tariff'>
                      {this.props.t('meters.rate')}: {counter.price}
                    </div>
                    <div
                      className={
                        'meter-item__status' +
                        (get(counter, 'status.value') === 1 ? ' ok' : '')
                      }
                    >
                      {get(counter, 'status.label')}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Counters;
