const component = 'App';

export const EVENT_AUTH_SUCCESS = `AUTH_SUCCESS`;
export const EVENT_APP_LOAD = `APP_LOAD`;
export const EVENT_AGREEMENTS_LOAD = `${component}/AGREEMENTS_LOAD`;
export const EVENT_AVATAR_UPLOAD = `${component}/AVATAR_UPLOAD`;
export const EVENT_FIO_UPDATE = `${component}/FIO_UPDATE`;

export function appLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_APP_LOAD,
      event: event,
    });
  };
}
export function agreementsLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_AGREEMENTS_LOAD,
      event: event,
    });
  };
}
export function uploadAvatar(url) {
  return (dispatch) => {
    dispatch({
      type: EVENT_AVATAR_UPLOAD,
      url: url,
    });
  };
}

export const fioUpdate = (payload) => ({
  type: EVENT_FIO_UPDATE,
  payload,
});
