const component = 'FilterDevice';

export const EVENT_FILTERS_UPDATE = `${component}/UPDATE`;
export const EVENT_FILTERS_SET = `${component}/SET`;

export const filtersUpdate = (data) => ({
  type: EVENT_FILTERS_UPDATE,
  location: data.location,
  room: data.room,
  status: data.status,
  label: data.label,
});

export const setFilters = (data) => {
  return (dispatch) => {
    dispatch({
      type: EVENT_FILTERS_SET,
      data,
    });
  };
};
