import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import { openModal } from 'Actions/modal';
import { panelOpen } from 'Actions/panel';
import Form from 'Components/Form';
import UserService from 'Services/User';
import _ from 'lodash';
import { loadUserData } from 'Actions/userData';
import { setLanguage } from 'Actions/language';

export const childRole = 7;

class ProfileEditEmail extends BaseComponent {
  className = 'profile';
  state = {
    user: null,
    date: null,
    activeItemIndex: 0,
    profileEditForm: null,
    roleIsChild: false,
    showLoader: false,
  };

  generateId(fieldName) {
    let id = `form_${this.className}-edit`;

    if (fieldName) {
      id += `__${fieldName}`;
    }

    return id;
  }

  setData() {
    const { activeApartment } = this.props;
    if (!activeApartment.apartmentId) return;

    const { user } = this.props.app;

    const isReadonly = activeApartment.roleId === childRole; // 7 is child

    this.setState({
      profileEditForm: {
        action: UserService.updateUser,
        className: `form_${this.className}-edit inline-fields submit-right`,
        noFaction: true,
        clean: false,
        fields: {
          fname: {
            id: this.generateId('fname'),
            type: 'text',
            label: `${this.props.t('commons.inputs.first_name')}:`,
            className: 'col',
            value: user.fname,
            disabled: isReadonly,
          },
        },
        btnSubmit: this.props.t('commons.buttons.save'),
        btnClass: 'btn_primary',
        disabled: isReadonly,
        formatBeforeSend: (data) => {
          delete data.language;
          data.id = user.userId;

          return data;
        },
        callback: (data) => {
          this.setStateSafe({
            isLoading: false,
          });
        },
      },
    });
  }

  componentDidMount() {
    if (this.props.language) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.profileEditForm || prevProps.t !== this.props.t) {
      this.setData();
    }
  }

  render() {
    const user = this.props.app.user;
    const { activeApartment } = this.props;

    const { t } = this.props;

    if (!user.agreements) {
      user.agreements = [{ agreement: '—' }];
    }

    if (!this.state.profileEditForm) return null;

    if (this.state.showLoader) return this.getLoader();

    return (
      <div
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        <Form {...this.state.profileEditForm} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    app: state.app,
    uk: state.uk.instance,
    language: state.language.lang,
    activeApartment: state.apartments.active,
  }),
  {
    onLoadUserData: loadUserData,
    onSetLanguage: setLanguage,
  },
)(withTranslation()(ProfileEditEmail));
