import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import {connect} from 'react-redux';

export class TileItem extends BaseComponent {
  state = {
    gridStatus: null,
  };
  tileClass = '';

  constructor(props) {
    super(props);
    this.tileClass = this.props.className || '';
  }

  render() {
    if (this.props.gridStatus >= 0 || this.props.isInRoomDevices)
      return (
        <li
          className={`tile-items__wrap
       ${this.tileClass}
       ${this.props.isInRoomDevices ? 'one-col'
            : this.props.gridStatus === 1 ? 'two-col' : 'one-col'}`}
        >
          {this.props.children}
        </li>
      );
    return null;
  }
}

export default connect(state => ({
  gridStatus: state.navigation.gridStatus,
}))(TileItem);
