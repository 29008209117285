import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';

const ITEM_HEIGHT = 37;
const MAX_HEIGHT = 300;

class BaseSelect extends BaseComponent {
  options = {};
  className = 'react-select';
  prefix = 'react-select';

  constructor(props) {
    super(props);

    if (props.className) this.className += ' ' + props.className;

    this.ScrollBarCfg = new ScrollBarConfig();

    this.counter = 4;

    this.state = {
      ...props,
      optionsLength: 0,
    };
  }

  MenuList = (props) => {
    let height = this.state.optionsLength * ITEM_HEIGHT;

    if (height > MAX_HEIGHT) height = MAX_HEIGHT;
    return (
      <components.MenuList {...props}>
        <div style={{ height: height }}>
          <Scrollbar cfg={this.ScrollBarCfg}>{props.children}</Scrollbar>
        </div>
      </components.MenuList>
    );
  };

  getOptions = () => {
    if (this.state.isMulti) {
      this.counter++;
      this.setStateSafe({ optionsLength: this.counter });
    } else if (this.state.isCreatable) {
      this.state.optionsLength = this.state.options.length;
    } else {
      this.setStateSafe({ optionsLength: this.state.options.length });
    }
  };

  formatCreateLabel = (inputValue) => {
    return 'Добавить: ' + inputValue;
  };

  getDefaultSelect = (options, defVal) => {
    if (!defVal) return null;
    return options.find((item) => item.value === defVal.value);
  };

  render() {
    this.state = {
      ...this.state,
      ...this.props,
    };

    if (this.state.isCreatable) {
      return (
        <CreatableSelect
          className={this.className}
          classNamePrefix={this.prefix}
          optionsLength={this.state.options.length}
          value={this.getDefaultSelect(this.state.options, this.state.value)}
          options={this.state.options}
          components={{ MenuList: this.MenuList }}
          onChange={this.state.onSelectChange}
          //menuIsOpen={true}
          placeholder={this.state.placeholder}
          onClick={(this.counter = 0)}
          isOptionSelected={this.getOptions}
          formatCreateLabel={this.formatCreateLabel}
        />
      );
    } else {
      return (
        <Select
          className={this.className}
          classNamePrefix={this.prefix}
          value={this.getDefaultSelect(this.state.options, this.state.value)}
          optionsLength={this.state.options.length}
          options={this.state.options}
          components={{ MenuList: this.MenuList }}
          onChange={this.state.onSelectChange}
          onClick={(this.counter = 0)}
          placeholder={this.state.placeholder}
          isDisabled={this.state.readonly}
          isOptionSelected={this.getOptions}
          isSearchable={false}
        />
      );
    }
  }
}

export default BaseSelect;
