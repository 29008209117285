import ControlService from 'Services/Control';
import { DEVICE_STATUS_OFF } from 'Config/devices';
import { EVENT_DEVICE_OFF } from 'Actions/devices';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const LOADING_ACTIVE = 'LOADING_ACTIVE';
export const CHANGE_DEVICE_CATEGORY = 'CHANGE_DEVICE_CATEGORY';

export const openDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_DIALOG,
      payload,
    });
  };
};

export const closeDialog = () => {
  return {
    type: CLOSE_DIALOG,
  };
};

export const deviceOff = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_ACTIVE,
    });
    await ControlService.executeDevice({
      deviceId: payload.deviceId,
      status: DEVICE_STATUS_OFF,
      value: payload.value,
    }).then((res) => {
      if (res.ok) {
        dispatch({
          type: EVENT_DEVICE_OFF,
          payload,
        });
        dispatch(closeDialog());
      }
    });
  };
};

export const changeDeviceCategory = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_ACTIVE,
    });
    await ControlService.updateDevice({}, (res) => {
      if (res.ok) {
        dispatch({
          type: EVENT_DEVICE_OFF,
          payload,
        });
        dispatch(closeDialog());
      }
    });
  };
};
