const component = 'wallet';

export const EVENT_WALLET_LOAD = `${component}/LOAD`;

export function walletLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_WALLET_LOAD,
      event: event
    });
  };
}