import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';
import * as Icons from 'Components/Icons';
import ContextMenu from 'Components/ContextMenu';
import ContextMenuProvider from 'Components/ContextMenuProvider';
import { CHAT_USER_TITLE } from 'Config/chat';

class ChatList extends Component {
  state = {
    groupId: 0,
    filter: '',
  };

  constructor(props) {
    super(props);

    this.ScrollBarCfg = new ScrollBarConfig();
  }

  getLogoTxt = (title) => {
    if (!title) return null;

    const parts = title.split(' ');
    let label = '';

    parts.forEach((str) => {
      if (label.length < 2) {
        label += str[0].toUpperCase();
      }
    });

    return label;
  };

  onSearch = (event) => {
    const val = event.target.value.trim();

    this.setState({
      filter: val,
    });
  };

  getActions(currentChat) {
    const actions = [
      {
        text: this.props.t('messenger.clear_history'),
        cb: () => {
          this.props.onClear(currentChat.chat_id, currentChat.name);
          currentChat.latest_msg = null;
          this.setState({
            reload: true,
          });
        },
      },
    ];

    if (!currentChat.name) {
      actions.push({
        text: (
          <span className='text-red'>
            {this.props.t('messenger.delete_chat')}
          </span>
        ),
        cb: () => {
          if (window.confirm(this.props.t('messenger.confirm'))) {
            return this.props.onDelete(currentChat.chat_id);
          }
        },
      });
    }

    return actions;
  }

  chatsFilter = (chats, sort = false) => {
    const { filter } = this.state;
    const filteredChats = {};

    if (sort) {
      if (chats['house']) {
        filteredChats['house'] = chats['house'];
      }
      if (chats['uk']) {
        filteredChats['uk'] = chats['uk'];
      }
      if (chats['support']) {
        filteredChats['support'] = chats['support'];
      }
      if (chats['block']) {
        filteredChats['block'] = chats['block'];
      }
    }

    if (filter.length === 0 && sort) return filteredChats;

    if (filter.length === 0) return chats;

    Object.keys(chats).map((chatId) => {
      const chat = chats[chatId];

      if (chat.title && ~chat.title.toLowerCase().indexOf(filter)) {
        return (filteredChats[chatId] = chat);
      } else if (
        typeof chat.messages === 'object' &&
        chat.messages.length > 0
      ) {
        chat.messages.some(function search(msg) {
          if (~msg.content.toLowerCase().indexOf(filter)) {
            return (filteredChats[chatId] = chat);
          }

          return null;
        });
      }

      return null;
    });

    return filteredChats;
  };

  getGroupTitle = (chat) => {
    if (chat.title === CHAT_USER_TITLE) {
      const userList = chat.to_users_id.split(',');
      let userId;

      userList.some((uid) => {
        if (Number(this.props.user.userId) !== Number(uid))
          return (userId = uid);

        return null;
      });
      if (!userId) userId = userList[0];

      const user = this.props.messenger.peoples[userId];

      return `${user.fname} ${user.sname}`;
    }

    return chat.title;
  };

  render() {
    const { t } = this.props;
    const messenger = this.props.messenger;
    const chats = this.chatsFilter(messenger.chats, true);
    const postamat = messenger.groups['postamat'] || null;

    if (chats.house) chats.house.logo_img = 'Chat';
    if (chats.uk) chats.uk.logo_img = 'House';
    if (chats.support) chats.support.logo_img = 'Support';

    return (
      <Scrollbar cfg={this.ScrollBarCfg}>
        <div className='chat-list-wrapper'>
          <div className='chat-list-container'>
            <div className='submenu-navigation__content'>
              <div className='chat-list' hidden={this.state.groupId !== 0}>
                {Object.keys(chats).map((chat_id) => {
                  const chat = chats[chat_id];

                  if (!chat) return null;

                  return (
                    <ContextMenuProvider
                      className='chat-list__item important'
                      id={`chat-${chat_id}`}
                      key={chat_id}
                    >
                      <ContextMenu
                        id={`chat-${chat_id}`}
                        actions={this.getActions(chat)}
                      />
                      <Link
                        className={`chat-list-item${
                          chat_id === messenger.currentId ? ' active-chat' : ''
                        } chat-list-item_${chat_id}`}
                        to={`/messenger/${chat_id}`}
                        onClick={this.props.onSelect.bind(this, chat_id)}
                      >
                        <span className='chat-list-item__img chat-img'>
                          {chat.logo_img ? (
                            <span className='img'>
                              {Icons[`Icon${chat.logo_img}`]}
                            </span>
                          ) : (
                            <span className='txt'>
                              {this.getLogoTxt(chat.title)}
                            </span>
                          )}
                        </span>
                        <span className='chat-list-item__text'>
                          <span className='chat-list-item__title'>
                            {chat.title}
                          </span>
                          {chat.locked === 1 && (
                            <span className='chat-list-item__icon'>
                              {Icons.IconLock}
                            </span>
                          )}
                          {chat.notification_status === 0 && (
                            <span className='chat-list-item__icon'>
                              {Icons.IconChatSoundOff}
                            </span>
                          )}
                          {chat.notifications && (
                            <span className='chat-list-item__notify-count badge badge_red'>
                              {chat.notifications}
                            </span>
                          )}
                          {chat.latest_msg &&
                          messenger.peoples[chat.latest_msg.user_id] ? (
                            <span className='chat-list-item__last-message'>
                              {messenger.peoples[chat.latest_msg.user_id].fname}
                              &nbsp;
                              {messenger.peoples[chat.latest_msg.user_id].sname}
                              , {t('messenger.apartment_short')}&nbsp;
                              {
                                messenger.peoples[chat.latest_msg.user_id]
                                  .number
                              }
                              :&nbsp;{chat.latest_msg.content}
                            </span>
                          ) : null}
                        </span>
                      </Link>
                    </ContextMenuProvider>
                  );
                })}
                {postamat && (
                  <ContextMenuProvider
                    className='chat-list__item'
                    id='chat-postamat'
                    key='postamat'
                  >
                    <ContextMenu
                      id='chat-postamat'
                      actions={this.getActions(postamat)}
                    />
                    <Link
                      className={`chat-list-item${
                        'postamat' === messenger.currentId ? ' active-chat' : ''
                      } chat-list-item_postamat`}
                      to={`/messenger/postamat`}
                      onClick={this.props.onSelect.bind(this, 'postamat')}
                    >
                      <span className='chat-list-item__img chat-img'>
                        {postamat.logo_img ? (
                          <span className='img'>
                            <img src={postamat.logo_img} alt='' />
                          </span>
                        ) : (
                          <span className='txt'>
                            {this.getLogoTxt(postamat.title)}
                          </span>
                        )}
                      </span>
                      <span className='chat-list-item__text'>
                        <span className='chat-list-item__title'>
                          {postamat.title}
                        </span>
                        {postamat.latest_msg &&
                        postamat.latest_msg.user_id.sname ? (
                          <span className='chat-list-item__last-message'>
                            {
                              messenger.peoples[postamat.latest_msg.user_id]
                                .fname
                            }
                            &nbsp;
                            {
                              messenger.peoples[postamat.latest_msg.user_id]
                                .sname
                            }
                            , кв.&nbsp;
                            {
                              messenger.peoples[postamat.latest_msg.user_id]
                                .number
                            }
                            :&nbsp;{postamat.latest_msg.content}
                          </span>
                        ) : null}
                        <span className='chat-list-item__actions' />
                        {/* <span className="chat-list-item__notify-status" />
                        <span className="chat-list-item__notify-count badge badge_red" /> */}
                        <span className='chat-list-item__users' />
                      </span>
                    </Link>
                  </ContextMenuProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </Scrollbar>
    );
  }
}

export default ChatList;
