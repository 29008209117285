/* eslint-disable */
import {
  EVENT_CONNECT,
  EVENT_RECONNECT,
  EVENT_MESSAGE,
  EVENT_CLOSE,
} from 'Actions/socket';
import AuthService from 'Services/Auth';

const initialState = {};

export function socket(state = initialState, action) {
  switch (action.type) {
    case EVENT_CONNECT:
    case EVENT_RECONNECT:
      const socket = action.event.target || action.socket || {};

      let data = {
        type: 'authorize',
        data: {
          token: AuthService.getToken(),
        },
      };
      socket.send(JSON.stringify(data));

      return state;
    case EVENT_MESSAGE:
      console.log('WebSocket EVENT_MESSAGE: ', action);
      return state;
    case EVENT_CLOSE:
      console.log('WebSocket EVENT_CLOSE: ', action);
      return state;

    default:
      return state;
  }
}
