import {
  EVENT_SIP_LOAD,
  EVENT_CONNECTED,
  EVENT_DISCONNECTED,
  EVENT_NEW_SESSION,
  EVENT_REGISTERED,
  EVENT_UNREGISTERED,
  EVENT_REG_FAILED,
  EVENT_ANSWER,
  EVENT_DECLINE,
  EVENT_HOLD,
  EVENT_VIDEO,
} from 'Actions/sip';

const initialState = {
  config: null,
  client: {
    ringing: false,
    answer: false,
    video: false,
    hold: false,
  },
};

let callSession;
let callOptions;
let ringingSoundInterval;

export function sip(state = initialState, action) {
  let client = {
    ...state.client,
  };
  let data = {};
  const audioElem = document.getElementsByClassName('sip-client__ring')[0];
  const playElem = document.getElementsByClassName('sip-client__audio')[0];

  switch (action.type) {
    case EVENT_SIP_LOAD:
      const eventData = JSON.parse(action.event.data);

      data = {
        ...state,
        config: eventData.data,
      };

      return data;
    case EVENT_CONNECTED:
      return state;
    case EVENT_DISCONNECTED:
      client.answer = false;
      client.ringing = false;

      data = {
        ...state,
        client,
      };

      if (ringingSoundInterval) {
        clearInterval(ringingSoundInterval);
        let playPromise = audioElem.pause();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              console.log(_);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        audioElem.currentTime = 0;
      }

      return data;
    case EVENT_NEW_SESSION:
      callSession = action.event.session;

      let playPromise = audioElem.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            ringingSoundInterval = setInterval(() => {
              let playPromise = audioElem.play();
              if (playPromise !== undefined) {
                playPromise
                  .then((_) => {
                    console.log(_);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }, 6000);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      return {
        ...state,
        client: {
          ...initialState.client,
          ringing: true,
        },
      };
    case EVENT_REGISTERED:
    case EVENT_UNREGISTERED:
    case EVENT_REG_FAILED:
      return {
        ...initialState,
      };
    case EVENT_ANSWER:
      console.log('EVENT_ANSWER');
      callOptions = {
        mediaConstraints: {
          audio: true,
          video: true,
        },
        pcConfig: {
          rtcpMuxPolicy: 'require',
          iceServers: [],
        },
      };

      if (ringingSoundInterval) {
        clearInterval(ringingSoundInterval);
        let playPromise = audioElem.pause();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              console.log(_);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      client.answer = 'audio';
      client.ringing = false;

      callSession.on('peerconnection', (e) => {
        e.peerconnection.addEventListener('addstream', (e) => {
          playElem.srcObject = e.stream;
        });
      });

      callSession.answer(callOptions);

      return {
        ...state,
        client,
      };
    case EVENT_VIDEO:
      console.log('EVENT_VIDEO');
      callOptions = {
        mediaConstraints: {
          audio: true,
          video: true,
        },
        pcConfig: {
          rtcpMuxPolicy: 'require',
          iceServers: [],
        },
      };

      if (ringingSoundInterval) {
        clearInterval(ringingSoundInterval);
        let playPromise = audioElem.pause();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              console.log(_);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      client.answer = 'video';
      client.ringing = false;

      callSession.on('peerconnection', (e) => {
        e.peerconnection.addEventListener('addstream', (e) => {
          const videoElem =
            document.getElementsByClassName('sip-client__video')[0];
          playElem.srcObject = e.stream;
          videoElem.srcObject = e.stream;
        });
      });

      callSession.answer(callOptions);

      return {
        ...state,
        client,
      };
    case EVENT_DECLINE:
      if (callSession._status !== 8) {
        callSession.terminate();
        callSession = undefined;
      }

      if (ringingSoundInterval) {
        clearInterval(ringingSoundInterval);
        let playPromise = audioElem.pause();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              console.log(_);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        audioElem.currentTime = 0;
      }

      return {
        ...initialState,
      };
    case EVENT_HOLD:
      client.hold = !client.hold;

      if (client.hold) {
        callSession.hold();
      } else {
        callSession.unhold();
      }

      return {
        ...state,
        client,
      };
    default:
      return state;
  }
}
