import React from 'react';
import { connect } from 'react-redux';
import Form from 'Components/Form';
import MeterService from 'Services/Meter';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import { counterCreate } from 'Actions/counters';
import { panelClose } from 'Actions/panel';

class AddCounterPanel extends BaseSettingPanel {
  state = {
    deviceTitle: '',
    room: '',
    submitted: false,
  };
  title = this.props.t('panels.add_counter.title');

  getRooms() {
    return Object.keys(this.props.rooms).map((i) => {
      const room = this.props.rooms[i];

      return {
        value: room.roomId,
        label: room.title,
      };
    });
  }

  getTypes() {
    let collection = this.props.devicesTypes;
    const { type } = this.props.panelData;

    if (type === 'sensor') {
      collection = this.props.sensorsTypes;
    }

    return Object.keys(collection).map((i) => {
      const deviceType = collection[i];

      return {
        value: deviceType[`${type}TypeId`],
        label: deviceType.title,
      };
    });
  }

  renderForm() {
    const { apartmentId, t } = this.props;

    const formParams = {
      action: MeterService.addCounter,
      className: 'form_counter-add',
      fields: {
        name: {
          id: 'form_counter-add__name',
          type: 'text',
          label: `${t('commons.inputs.select_title')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
          focus: true,
        },
        type: {
          id: 'form_counter-add__type',
          type: 'select',
          label: `${t('commons.inputs.device_type')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
          props: {
            placeholder: `${t('commons.inputs.select_placeholder')}...`,
            options: t('panels.add_counter.counterTypes', {
              returnObjects: true,
            }),
          },
        },
        serial_number: {
          id: 'form_counter-add__serial_number',
          type: 'text',
          label: `${t('commons.inputs.serial_number')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
        },
        current_value: {
          id: 'form_counter-add__current_value',
          type: 'number',
          label: `${t('commons.inputs.current_value')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
        },
      },
      btnSubmit: t('commons.buttons.confirm'),
      btnClass: 'btn_primary btn_full-width',
      extendData: {
        apartmentId: apartmentId,
      },
      formatBeforeSend: (data) => {
        data.type = data.type.value;

        return data;
      },
      callback: (res) => {
        if (res && res.ok) this.props.onCounterCreate(res);

        this.props.onClose();
      },
    };

    return <Form {...formParams} />;
  }

  content() {
    return this.renderForm.call(this);
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current === 'AddCounter',
    apartmentId: state.apartments.active.apartmentId,
  }),
  {
    onClose: panelClose,
    onCounterCreate: counterCreate,
  },
)(AddCounterPanel);
