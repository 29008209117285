import ApiService from 'Services/Api'
import Logger from 'Components/Logger'

const UkService = {
  addIssue: (data = {}, cb = () => {}) => {
    return ApiService.uk
      .addIssue(data)
      .then(user => {
        cb(user)

        return user
      })
      .catch(err => {
        console.log('err', err)
        Logger.log(err, 'error')
      })
  },
  addUk: (data = {}, cb = () => {}) => {
    return ApiService.uk
      .addUk(data)
      .then(user => {
        cb(user)
      })
      .catch(err => {
        Logger.log(err, 'error')
      })
  }
}

export default UkService
