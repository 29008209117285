import React from 'react';

export default () => (
  <div className='col-md-4 col-lg-3 mb-7 mb-md-0'>
    <ol>
      <li>
        <a href='#p1'> Общие положения </a>
      </li>
      <li>
        <a href='#p2'>Регистрация Пользователя. Учетная запись Пользователя</a>
      </li>
      <li>
        <a href='#p3'> Общие положения об использовании и хранении </a>
      </li>
      <li>
        <a href='#p4'>Контент Пользователя</a>
      </li>
      <li>
        <a href='#p5'>Условия использования ОАСУ T.one</a>
      </li>
      <li>
        <a href='#p6'>Исключительные права на содержание и контент</a>
      </li>
      <li>
        <a href='#p7'>Сайты и контент третьих лиц</a>
      </li>
      <li>
        <a href='#p8'>Реклама в ОАСУ T.one</a>
      </li>
      <li>
        <a href='#p9'>Отсутствие гарантий, ограничение ответственности</a>
      </li>
      <li>
        <a href='#p10'>Иные положения</a>
      </li>
    </ol>

    <div>
      <p>
        <small> Дата публикации: 1 ноября 2019 (ред. 01.05.2020) </small>
      </p>
      <ol>
        <li>
          <strong>
            <a name='p1'></a>
            Общие положения
          </strong>
          <ol>
            <li>
              ООО «Т.один» (далее — «Т.один») предлагает пользователю сети
              Интернет (далее – Пользователь) - использовать свой сервис на
              условиях, изложенных в настоящем Пользовательском соглашении
              (далее — «Соглашение», «ПС»). Соглашение вступает в силу с момента
              выражения Пользователем согласия с его условиями в порядке,
              предусмотренном п. 1.4 Соглашения.
            </li>
            <li>
              Т.один предлагает Пользователям доступ к ОАСУ T.one, включая
              средства навигации, коммуникации, поиска, размещения и хранения
              разного рода информации и материалов (контента), персонализации
              контента, оплаты тарифов и т. д. Все существующие на данный момент
              функции ОАСУ, а также любое развитие их и/или добавление новых
              является предметом настоящего Соглашения.
            </li>
            <li>
              Использование сервисов ООО «Т.один» регулируется настоящим
              Соглашением (
              <a
                href='https://boxt.one/polzovatelskoe-soglashenie-oasu-tone'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://boxt.one/polzovatelskoe-soglashenie-oasu-tone
              </a>
              ), Лицензией на использование ОАСУ T.one (
              <a
                href='https://boxt.one'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://boxt.one
              </a>
              , регистрационный № 2019664821 от 13.11.2019), Политикой
              конфиденциальности (
              <a
                href='https://boxt.one/politika-konfidencialnosti-oasu-tone'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://boxt.one/politika-konfidencialnosti-oasu-tone
              </a>
              ), а также условиями использования отдельных функций. Соглашение
              может быть изменено ООО «Т.один» без какого-либо специального
              уведомления, новая редакция Соглашения вступает в силу с момента
              ее размещения в сети Интернет по указанному в настоящем абзаце
              адресу, если иное не предусмотрено новой редакцией Соглашения.
              Действующая редакция ПС всегда находится на странице по адресу
              <a
                href='https://boxt.one/polzovatelskoe-soglashenie-oasu-tone'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://boxt.one/polzovatelskoe-soglashenie-oasu-tone
              </a>
              .
            </li>
            <li>
              Начиная использовать ОАСУ T.one или его отдельные функции, либо
              пройдя процедуру регистрации, либо заполнив любую форму на сайте
              https://boxt.one Пользователь считается принявшим условия
              Соглашения в полном объеме, без всяких оговорок и исключений. В
              случае несогласия Пользователя с какими-либо из положений
              Соглашения, Пользователь не вправе использовать ОАСУ T.one. В
              случае если ООО «Т.один» были внесены какие-либо изменения в
              Соглашение в порядке, предусмотренном п. 1.3 Соглашения, с
              которыми Пользователь не согласен, он обязан прекратить
              использование ОАСУ T.one.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p2'></a>
            Регистрация Пользователя. Учетная запись Пользователя
          </strong>
          <ol>
            <li>
              Для того чтобы воспользоваться функциями ОАСУ T.one, Пользователю
              необходимо пройти процедуру регистрации, в результате которой для
              Пользователя будет создана уникальная учетная запись.
            </li>
            <li>
              Для регистрации Пользователь обязуется предоставить достоверную и
              полную информацию о себе по вопросам, предлагаемым в форме
              регистрации, и поддерживать эту информацию в актуальном состоянии.
              Если Пользователь предоставляет неверную информацию или у ООО
              «Т.один» есть основания полагать, что предоставленная
              Пользователем информация неполна или недостоверна, ООО «Т.один»
              имеет право по своему усмотрению заблокировать либо удалить
              учетную запись Пользователя и отказать Пользователю в
              использовании ОАСУ T.one (либо их отдельных функций).
            </li>
            <li>
              При регистрации Пользователь может загрузить изображение для
              учетной записи (аватар) с целью размещения в персональном профиле
              Пользователя, доступном другим Пользователям. Т.один не
              заинтересовано и не имеет цели в обработке изображения с целью
              идентификации и (или) аутентификации Пользователя. Т.один
              обрабатывает изображение с целью сопровождения опубликованных
              Пользователей материалов в рамках использования ОАСУ T.one.
              Изображение учетной записи должно соответствовать требованиям
              раздела 4 настоящего Соглашения. В случае использования в качестве
              изображения учетной записи изображения Пользователя, Пользователь
              осознает и соглашается с тем, что ООО «Т.один» вправе проводить
              опубликование и дальнейшее использование изображения Пользователя
              для использования в ОАСУ T.one.
            </li>
            <li>
              Т.один предоставляет функционал мобильного приложения T.one и не
              несёт ответственности за использование данного приложения в случае
              использования 3-ми лицами функциональных возможностей приложения.
              Пользователь вправе несет ответственность за безопасность
              полученных им аутентификационных данных для доступа к Учетной
              записи, а также самостоятельно обеспечивает их конфиденциальность.
              Пользователь самостоятельно несет ответственность за все действия,
              совершенные в Учетной записи Пользователя, включая случаи
              добровольной передачи Пользователем данных для доступа к Учетной
              записи Пользователя третьим лицам. При этом все действия в рамках
              или с использованием Сервисов в Учетной записи Пользователя
              считаются произведенными самим Пользователем, за исключением
              случаев, когда Пользователь уведомил Т.один о несанкционированном
              доступе к Сервисам Т.один с использованием Учетной записи
              Пользователя или о нарушении конфиденциальности аутентификационных
              данных для доступа к Учетной записи Пользователя.
            </li>
            <li>
              ООО «Т.один» оставляет за собой право в любой момент потребовать
              от Пользователя подтверждения данных, указанных при регистрации, и
              запросить в связи с этим подтверждающие документы (в частности -
              документы, удостоверяющие личность), непредоставление которых, по
              усмотрению ООО «Т.один», может быть приравнено к предоставлению
              недостоверной информации и повлечь последствия, предусмотренные п.
              2.2 Соглашения. В случае если данные Пользователя, указанные в
              предоставленных им документах, не соответствуют данным, указанным
              при регистрации, а также в случае, когда данные, указанные при
              регистрации, не позволяют идентифицировать пользователя, ООО
              «Т.один» вправе отказать Пользователю в доступе к учетной записи и
              использовании ОАСУ T.one.
            </li>
            <li>
              Персональная информация Пользователя, содержащаяся в учетной
              записи Пользователя, хранится и обрабатывается ООО «Т.один» в
              соответствии с условиями Политики конфиденциальности (
              <a
                href='https://boxt.one/politika-konfidencialnosti-oasu-tone'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://boxt.one/politika-konfidencialnosti-oasu-tone
              </a>
              ).
            </li>
            <li>
              Средства для доступа к учетной записи Пользователя.
              <ol>
                <li>
                  При регистрации Пользователь самостоятельно выбирает себе
                  логин (уникальное символьное имя учетной записи Пользователя)
                  и пароль для доступа к учетной записи. ООО «Т.один» вправе
                  запретить использование определенных логинов, а также
                  устанавливать требования к логину и паролю (длина, допустимые
                  символы и т.д.).
                </li>
                <li>
                  После регистрации учетной записи Пользователь имеет
                  возможность начать использовать альтернативное средство
                  аутентификации для доступа к учетной записи, заменив им
                  установленный пароль. Доступ к такому средству предоставляется
                  посредствам настройки учетной записью Пользователя.
                </li>
                <li>
                  Пользователь самостоятельно несет ответственность за
                  безопасность (устойчивость к угадыванию) выбранных им средств
                  для доступа к учетной записи, а также самостоятельно
                  обеспечивает их конфиденциальность. Пользователь
                  самостоятельно несет ответственность за все действия (а также
                  их последствия) в рамках или с использованием ОАСУ T.one под
                  учетной записью Пользователя, включая случаи добровольной
                  передачи Пользователем данных для доступа к учетной записи
                  Пользователя третьим лицам на любых условиях (в том числе по
                  договорам или соглашениям). При этом все действия в рамках или
                  с использованием ОАСУ T.one под учетной записью Пользователя
                  считаются произведенными самим Пользователем, за исключением
                  случаев, когда Пользователь, в порядке, предусмотренном п.
                  2.7., уведомил ООО «Т.один» о несанкционированном доступе к
                  ОАСУ T.one с использованием учетной записи Пользователя и/или
                  о любом нарушении (подозрениях о нарушении) конфиденциальности
                  своих средств доступа к учетной записи (пароля или средств
                  двухфакторной аутентификации).
                </li>
              </ol>
            </li>
            <li>
              Пользователь обязан немедленно уведомить ООО «Т.один» о любом
              случае несанкционированного (не разрешенного Пользователем)
              доступа к ОАСУ T.one с использованием учетной записи Пользователя
              и/или о любом нарушении (подозрениях о нарушении)
              конфиденциальности своих средств доступа к учетной записи. В целях
              безопасности, Пользователь обязан самостоятельно осуществлять
              безопасное завершение работы под своей учетной записью (кнопка
              «Выход») по окончании каждой сессии работы с ОАСУ T.one ООО
              «Т.один» не отвечает за возможную потерю или порчу данных, а также
              другие последствия любого характера, которые могут произойти из-за
              нарушения Пользователем положений этой части Соглашения.
            </li>
            <li>
              Использование Пользователем своей учетной записи.
              <ol>
                <li>
                  Пользователь не вправе воспроизводить, повторять и копировать,
                  продавать и перепродавать, а также использовать для каких-либо
                  коммерческих целей какие-либо функции ОАСУ T.one (включая
                  контент, доступный Пользователю посредством работы с ОАСУ
                  T.one), или доступ к ним, кроме тех случаев, когда
                  Пользователь получил такое разрешение от ООО «Т.один» , либо
                  когда это прямо предусмотрено пользовательским соглашением.
                </li>
                <li>
                  Некоторые категории учетных записей Пользователей могут
                  ограничивать или запрещать использование некоторых функций
                  ОАСУ T.one в случае если это предусмотрено при регистрации,
                  либо в условиях использования того или иного сервиса.
                </li>
              </ol>
            </li>
            <li>
              Прекращение регистрации. ООО «Т.один» вправе заблокировать или
              удалить учетную запись Пользователя, а также запретить доступ с
              использованием какой-либо учетной записи к определенным функциям
              T.one, и удалить любой контент без объяснения причин, в том числе
              в случае нарушения Пользователем условий Соглашения или условий
              иных документов, предусмотренных п. 1.3. Соглашения, а также в
              случае неиспользования соответствующего сервиса, в частности:
              <ol>
                <li>Учетная запись не использовалась более 6 месяцев.</li>
              </ol>
            </li>
            <li>
              Удаление учетной записи Пользователя.
              <ol>
                <li>
                  Пользователь вправе в любой момент удалить свою учетную запись
                  в ОАСУ T.one или при наличии соответствующей функции
                  прекратить ее действие в отношении некоторых из них. После
                  погашения задолженностей по счету.
                </li>
              </ol>
            </li>
            <li>
              Удаление учетной записи ООО «Т.один» осуществляется в следующем
              порядке:
              <ol>
                <li>
                  учетная запись блокируется на срок один месяц, в течение
                  которого доступ Пользователя к своей учетной записи становится
                  невозможен, при этом контент размещенный с помощью такой
                  учетной записи может быть удален;
                </li>
                <li>
                  если в течение указанного выше срока учетная запись
                  Пользователя будет восстановлена, доступ к учетной записи для
                  Пользователя восстановится, однако контент, размещенной с ее
                  помощью, может не подлежать восстановлению;
                </li>
                <li>
                  если в течение указанного выше срока учетная запись
                  Пользователя не будет восстановлена, весь контент, размещенный
                  с ее использованием, будет удален. С этого момента
                  восстановление учетной записи, какой-либо информации,
                  относящейся к ней, а равно доступов к сервисам ОАСУ T.one с
                  использованием этой учетной записи - невозможны.
                </li>
                <li>
                  Приведенный в пп. 2.12.1, 2.12.2, 2.12.3 Соглашения (за
                  исключением доступности логина для использования другими
                  пользователями) порядок применим также к запрету доступа с
                  использованием какой-либо учетной записи к определенным
                  сервисам.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p3'></a>
            Общие положения об использовании и хранении
          </strong>
          <ol>
            <li>
              ООО «Т.один» вправе устанавливать ограничения в использовании ОАСУ
              T.one для всех Пользователей, либо для отдельных категорий
              Пользователей (в зависимости от места пребывания Пользователя,
              языка, на котором предоставляется сервис и т.д.), в том числе:
              наличие/отсутствие отдельных функций сервиса, срок хранения
              сообщений, любого другого контента, максимальное количество
              сообщений, которые могут быть отправлены или получены одним
              зарегистрированным пользователем, максимальный размер сообщения
              или дискового пространства, максимальное количество обращений к
              сервису за указанный период времени, максимальный срок хранения
              контента, специальные параметры загружаемого контента и т.д. ООО
              «Т.один» может запретить автоматическое обращение к своим
              сервисам, а также прекратить прием любой информации,
              сгенерированной автоматически (например, почтового спама).
            </li>
            <li>
              Т.один вправе посылать своим пользователям информационные
              сообщения. Используя ОАСУ Т.один, Пользователь также в
              соответствии с ч. 1 ст. 18 Федерального закона «О рекламе» дает
              свое согласие на получение сообщений рекламного характера.
              Пользователь вправе отказаться от получения сообщений рекламного
              характера путем использования соответствующего функционала ОАСУ
              T.one, в рамках которого или в связи с которым пользователем были
              получены сообщения рекламного характера.
            </li>
            <li>
              В целях повышения качества ОАСУ Т.one вправе осуществлять сбор
              мнений и отзывов Пользователей по различным вопросам путем
              направления информационного сообщения при очередном посещении
              Пользователем сервиса. Собранные мнения и отзывы могут быть
              использованы для формирования статистических данных, которые могут
              быть использованы в ОАСУ T.one. Отзывы, оставленные Пользователем
              с использованием его учетной записи, также могут быть опубликованы
              ООО «Т.один» в ОАСУ Т.one либо в сервисах аффилированных с ООО
              «Т.один» лиц, как с указанием имени (логина) пользователя, так и
              без указания. При написании отзывов Пользователь обязуется
              руководствоваться требованиями настоящего Соглашения, в том числе
              требованиями, установленными п. 5 настоящего Соглашения.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p4'></a>
            Контент Пользователя
          </strong>
          <ol>
            <li>
              Пользователь самостоятельно несет ответственность за соответствие
              содержания размещаемого Пользователем контента требованиям
              действующего законодательства, включая ответственность перед
              третьими лицами в случаях, когда размещение Пользователем того или
              иного контента или содержание контента нарушает права и законные
              интересы третьих лиц, в том числе личные неимущественные права
              авторов, иные интеллектуальные права третьих лиц, и/или посягает
              на принадлежащие им нематериальные блага.
            </li>
            <li>
              Пользователь признает и соглашается с тем, что ООО «Т.один» не
              обязан просматривать контент любого вида, размещаемый и/или
              распространяемый Пользователем посредством ОАСУ Т.one, а также то,
              что ООО «Т.один» имеет право (но не обязанность) по своему
              усмотрению отказать Пользователю в размещении и/или
              распространении им контента или удалить любой контент, который
              доступен посредством ОАСУ Т.one. Пользователь осознает и согласен
              с тем, что он должен самостоятельно оценивать все риски, связанные
              с использованием контента, включая оценку надежности, полноты или
              полезности этого контента.
            </li>
            <li>
              Пользователь осознает и соглашается с тем, что технология работы
              ОАСУ Т.one может потребовать копирование (воспроизведение)
              контента Пользователя ОАСУ Т.one, а также переработки его ОАСУ
              Т.one для соответствия техническим требованиям того или иного
              сервиса.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p5'></a>
            Условия использования ОАСУ T.one
          </strong>
          <ol>
            <li>
              Пользователь самостоятельно несет ответственность перед третьими
              лицами за свои действия, связанные с использованием ОАСУ Т.one, в
              том числе, если такие действия приведут к нарушению прав и
              законных интересов третьих лиц, а также за соблюдение
              законодательства при использовании ОАСУ Т.one.
            </li>
            <li>
              При использовании ОАСУ Т.one Пользователь не вправе:
              <ol>
                <li>
                  загружать, посылать, передавать или любым другим способом
                  размещать и/или распространять контент, который является
                  незаконным, вредоносным, клеветническим, оскорбляет
                  нравственность, демонстрирует (или является пропагандой)
                  насилия и жестокости, нарушает права интеллектуальной
                  собственности, пропагандирует ненависть и/или дискриминацию
                  людей по расовому, этническому, половому, религиозному,
                  социальному признакам, содержит оскорбления в адрес каких-либо
                  лиц или организаций, содержит элементы (или является
                  пропагандой) порнографии, детской эротики, представляет собой
                  рекламу (или является пропагандой) услуг сексуального
                  характера (в том числе под видом иных услуг), разъясняет
                  порядок изготовления, применения или иного использования
                  наркотических веществ или их аналогов, взрывчатых веществ или
                  иного оружия;
                </li>
                <li>
                  нарушать права третьих лиц, в том числе несовершеннолетних лиц
                  и/или причинять им вред в любой форме;
                </li>
                <li>
                  выдавать себя за другого человека или представителя
                  организации и/или сообщества без достаточных на то прав, в том
                  числе за сотрудников ООО «Т.один» , за модераторов форумов, за
                  владельца сайта, а также применять любые другие формы и
                  способы незаконного представительства других лиц в сети, а
                  также вводить пользователей или ООО «Т.один» в заблуждение
                  относительно свойств и характеристик каких-либо субъектов или
                  объектов;
                </li>
                <li>
                  загружать, посылать, передавать или любым другим способом
                  размещать и/или распространять контент, при отсутствии прав на
                  такие действия согласно законодательству или каким-либо
                  договорным отношениям;
                </li>
                <li>
                  загружать, посылать, передавать или любым другим способом
                  размещать и/или распространять не разрешенную специальным
                  образом рекламную информацию, спам (в том числе и поисковый),
                  списки чужих адресов электронной почты, схемы «пирамид»,
                  многоуровневого (сетевого) маркетинга (MLM), системы
                  интернет-заработка и e-mail-бизнесов, «письма счастья», а
                  также использовать ОАСУ Т.one для участия в этих мероприятиях,
                  или использовать ОАСУ Т.one, исключительно для перенаправления
                  пользователей на страницы других доменов;
                </li>
                <li>
                  загружать, посылать, передавать или любым другим способом
                  размещать и/или распространять какие-либо материалы,
                  содержащие вирусы или другие компьютерные коды, файлы или
                  программы, предназначенные для нарушения, уничтожения либо
                  ограничения функциональности любого компьютерного или
                  телекоммуникационного оборудования или программ, для
                  осуществления несанкционированного доступа, а также серийные
                  номера к коммерческим программным продуктам и программы для их
                  генерации, логины, пароли и прочие средства для получения
                  несанкционированного доступа к платным ресурсам в Интернете, а
                  также размещения ссылок на вышеуказанную информацию;
                </li>
                <li>
                  несанкционированно собирать и хранить персональные данные
                  других лиц;
                </li>
                <li>нарушать нормальную работу ОАСУ Т.one;</li>
                <li>
                  содействовать действиям, направленным на нарушение ограничений
                  и запретов, налагаемых Соглашением;
                </li>
                <li>
                  другим образом нарушать нормы законодательства, в том числе
                  нормы международного права.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p6'></a>
            Исключительные права на содержание и контент
          </strong>
          <ol>
            <li>
              Все объекты, доступные при помощи ОАСУ Т.one, в том числе элементы
              дизайна, текст, графические изображения, иллюстрации, видео,
              программы для ЭВМ, базы данных, музыка, звуки и другие объекты
              (далее – содержание сервисов), а также любой контент, размещенный
              на ОАСУ Т.one, являются объектами исключительных прав ООО «Т.один»
              , Пользователей и других правообладателей.
            </li>
            <li>
              Использование контента, а также каких-либо иных элементов сервисов
              возможно только в рамках функционала, предлагаемого тем или иным
              сервисом. Никакие элементы содержания ОАСУ Т.one, а также любой
              контент, размещенный на ОАСУ Т.one, не могут быть использованы
              иным образом без предварительного разрешения правообладателя. Под
              использованием подразумеваются, в том числе: воспроизведение,
              копирование, переработка, распространение на любой основе,
              отображение во фрейме и т.д. Исключение составляют случаи, прямо
              предусмотренные законодательством РФ или условиями использования
              ОАСУ Т.one. Использование Пользователем элементов содержания
              сервисов, а также любого контента для личного некоммерческого
              использования, допускается при условии сохранения всех знаков
              охраны авторского права, смежных прав, товарных знаков, других
              уведомлений об авторстве, сохранения имени (или псевдонима)
              автора/наименования правообладателя в неизменном виде, сохранении
              соответствующего объекта в неизменном виде. Исключение составляют
              случаи, прямо предусмотренные законодательством РФ или
              пользовательскими соглашениями ОАСУ Т.one.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p7'></a>
            Сайты и контент третьих лиц
          </strong>
          <ol>
            <li>
              ОАСУ Т.one может содержать ссылки на другие сайты в сети Интернет
              (сайты третьих лиц). Указанные третьи лица и их контент не
              проверяются ООО «Т.один» на соответствие тем или иным требованиям
              (достоверности, полноты, законности и т.п.). ООО «Т.один» не несет
              ответственность за любую информацию, материалы, размещенные на
              сайтах третьих лиц, к которым Пользователь получает доступ с
              использованием сервисов, в том числе, за любые мнения или
              утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а
              также за доступность таких сайтов или контента и последствия их
              использования Пользователем.
            </li>
            <li>
              Ссылка (в любой форме) на любой сайт, продукт, услугу, любую
              информацию коммерческого или некоммерческого характера,
              размещенная на Сайте, не является одобрением или рекомендацией
              данных продуктов (услуг, деятельности) со стороны ООО «Т.один» ,
              за исключением случаев, когда на это прямо указывается на ресурсах
              ООО «Т.один».
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p8'></a>
            Реклама в ОАСУ T.one
          </strong>
          <ol>
            <li>
              ООО «Т.один» несет ответственность за рекламу, размещенную им на
              ОАСУ T.one, в пределах, установленных законодательством РФ.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p9'></a>
            Отсутствие гарантий, ограничение ответственности
          </strong>
          <ol>
            <li>
              Пользователь использует ОАСУ T.one на свой собственный риск.
              Сервисы предоставляются «как есть». ООО «Т.один» не принимает на
              себя никакой ответственности, в том числе за соответствие сервисов
              целям Пользователя;
            </li>
            <li>
              ООО «Т.один» не гарантирует, что: сервисы соответствуют/будут
              соответствовать требованиям Пользователя; сервисы будут
              предоставляться непрерывно, быстро, надежно и без ошибок;
              результаты, которые могут быть получены с использованием сервисов,
              будут точными и надежными и могут использоваться для каких-либо
              целей или в каком-либо качестве (например, для установления и/или
              подтверждения каких-либо фактов); качество какого-либо продукта,
              услуги, информации и пр., полученных с использованием сервисов,
              будет соответствовать ожиданиям Пользователя;
            </li>
            <li>
              Любые информацию и/или материалы (в том числе загружаемое ПО,
              письма, какие-либо инструкции и руководства к действию и т.д.),
              доступ к которым Пользователь получает с использованием ОАСУ
              T.one, Пользователь может использовать на свой собственный страх и
              риск и самостоятельно несет ответственность за возможные
              последствия использования указанных информации и/или материалов, в
              том числе за ущерб, который это может причинить компьютеру
              Пользователя или третьим лицам, за потерю данных или любой другой
              вред;
            </li>
            <li>
              ООО «Т.один» не несет ответственности за любые виды убытков,
              произошедшие вследствие использования Пользователем ОАСУ T.one или
              отдельных частей/функций сервисов;
            </li>
            <li>
              При любых обстоятельствах ответственность ООО «Т.один» в
              соответствии со статьей 15 Гражданского кодекса России ограничена
              10 000 (десятью тысячами) рублей РФ и возлагается на него при
              наличии в его действиях вины.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <a name='p10'></a>
            Иные положения
          </strong>
          <ol>
            <li>
              Настоящее Соглашение представляет собой договор между
              Пользователем и ООО «Т.один» относительно порядка использования
              ОАСУ T.one.
            </li>
            <li>
              Настоящее Соглашение регулируется и толкуется в соответствии с
              законодательством Российской Федерации. Вопросы, не
              урегулированные настоящим Соглашением, подлежат разрешению в
              соответствии с законодательством Российской Федерации. Все
              возможные споры, вытекающие из отношений, регулируемых настоящим
              Соглашением, разрешаются в порядке, установленном действующим
              законодательством Российской Федерации, по нормам российского
              права. Везде по тексту настоящего Соглашения, если явно не указано
              иное, под термином «законодательство» понимается как
              законодательство Российской Федерации, так и законодательство
              места пребывания Пользователя.
            </li>
            <li>
              Ничто в Соглашении не может пониматься как установление между
              Пользователем и ООО «Т.один» агентских отношений, отношений
              товарищества, отношений по совместной деятельности, отношений
              личного найма, либо каких-то иных отношений, прямо не
              предусмотренных Соглашением.
            </li>
            <li>
              Если по тем или иным причинам одно или несколько положений
              настоящего Соглашения будут признаны недействительными или не
              имеющими юридической силы, это не оказывает влияния на
              действительность или применимость остальных положений Соглашения.
            </li>
            <li>
              Бездействие со стороны ООО «Т.один» в случае нарушения
              Пользователем либо иными пользователями положений Соглашений не
              лишает ООО «Т.один» права предпринять соответствующие действия в
              защиту своих интересов позднее, а также не означает отказа ООО
              «Т.один» от своих прав в случае совершения в последующем подобных
              либо сходных нарушений.
            </li>
            <li>Настоящее Соглашение составлено на русском языке.</li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
);
