import React, { Component } from 'react';
import Chat from 'Components/messenger/Chat';
import ChatList from 'Components/messenger/ChatList';
import { connect } from 'react-redux';
import {
  chatConnect,
  sendMessage,
  chatDelete,
  chatDisconnect,
  chatClear,
  chatCreateOrUpdate,
} from 'Actions/messenger';
import { withTranslation } from 'react-i18next';

class Messenger extends Component {
  state = {
    reload: false,
    settingsOpened: false,
    addChatSettingsOpened: false,
    votingOpened: false,
    name: '',
    class: '',
    editChat: false,
    storeValues: {}, // TODO: ЭТО КОСТЫЛЬ!!!
  };

  getCurrentChatInstance = (cId) => {
    const messenger = this.props.messenger;
    const currentId = cId || messenger.currentId;
    const currentChat =
      messenger.chats[currentId] || messenger.groups[currentId];

    return currentChat;
  };

  componentDidMount() {
    this.setState({
      reload: true,
    });
  }

  componentDidUpdate() {
    const currentChat = this.getCurrentChatInstance();

    if (currentChat && this.state.reload === true) {
      this.props.loadMessages(currentChat.chat_id);
      this.setState({
        reload: false,
      });
    }
  }

  onChatSelect(currentId) {
    const currentChat = this.getCurrentChatInstance(currentId);

    if (currentChat && this.props.messenger.currentId !== currentId)
      this.props.loadMessages(currentChat.chat_id);

    this.setState({
      reload: false,
      settingsOpened: false,
      addChatSettingsOpened: false,
      votingOpened: false,
    });
  }

  onMsgSubmit(msg, reply) {
    this.setState({
      reload: true,
    });

    const currentChat = this.getCurrentChatInstance(
      this.props.messenger.currentId,
    );

    if (this.props.messenger.currentId)
      this.props.sendMessage(msg, reply, currentChat);
  }

  onChatDelete(chatId) {
    if (this.props.messenger.currentId) this.props.chatDelete(chatId);
  }

  onChatClear(chatId, chatName) {
    this.setState({
      reload: false,
    });

    if (this.props.messenger.currentId) this.props.chatClear(chatId, chatName);
  }

  onChatDisconnect() {
    if (this.props.messenger.currentId) this.props.chatDisconnect();
  }

  getUsers = () => {
    const currentChat = this.getCurrentChatInstance();
    let created = '';
    let isFixed = false;
    let usersCurrentChat;
    if (currentChat) {
      created = currentChat.created_user_id;
      usersCurrentChat = currentChat.to_users_id.split(',');
    }

    let list = Object.keys(this.props.messenger.peoples).map((userId) => {
      let user = this.props.messenger.peoples[userId];
      if (usersCurrentChat) {
        usersCurrentChat.forEach((item) => {
          if (Number(created) !== this.props.user.userId) {
            isFixed =
              item === userId && Number(userId) !== this.props.user.userId;
          }
        });
      }
      return {
        value: userId,
        label: `${user.fname} ${user.sname}`,
        isFixed: isFixed,
      };
    });

    return list;
  };

  getUsersCurrentChat = () => {
    let list = [];
    if (this.state.editChat) {
      const currentChat = this.getCurrentChatInstance();
      const users = this.getUsers();
      let usersCurrentChat = [];

      if (currentChat) {
        usersCurrentChat = currentChat.to_users_id.split(',');
      }
      users.forEach((user, i) => {
        usersCurrentChat.forEach((item) => {
          if (user.value === item) {
            list.push(i);
          }
        });
      });
    }
    return list;
  };

  onSettingsToggle() {
    this.setState({
      settingsOpened: !this.state.settingsOpened,
      addChatSettingsOpened: false,
      votingOpened: false,
    });
  }

  onVotingToggle() {
    this.setState({
      votingOpened: !this.state.votingOpened,
      addChatSettingsOpened: false,
      settingsOpened: false,
    });
  }

  onAddChatSettingsToggle(edit = false, chat) {
    const currentChat = this.getCurrentChatInstance() || chat;
    let created = '';
    let hiddenClear = '';
    if (currentChat) {
      created = currentChat.created_user_id;
    }
    if (Number(created) !== this.props.user.userId) {
      hiddenClear = 'hiddenClear';
    }
    this.setState({
      addChatSettingsOpened: !this.state.addChatSettingsOpened,
      settingsOpened: false,
      votingOpened: false,
    });

    if (!this.state.addChatSettingsOpened && edit) {
      this.setState({
        name: currentChat.title,
        class: hiddenClear,
        editChat: true,
        // TODO: УБРАТЬ КОСТЫЛЬ
        storeValues: {
          chatId: currentChat.chatId || currentChat.chat_id,
        },
      });
    } else {
      this.setState({
        name: '',
        class: '',
        editChat: false,
        storeValues: {},
      });
    }
  }

  render() {
    return (
      <>
        <div className='component component-messenger row'>
          <div
            className={`component-messenger__chat-list col-xxl-3 col-lg-4${
              this.props.messenger.currentId ? ' hiddenChatList' : ''
            }`}
          >
            <ChatList
              user={this.props.user}
              messenger={this.props.messenger}
              currentId={this.props.messenger.currentId}
              onSelect={this.onChatSelect.bind(this)}
              onDelete={this.props.chatDelete}
              onClear={this.props.chatClear}
              onAddChatSettingsToggle={this.onAddChatSettingsToggle.bind(this)}
              t={this.props.t}
            />
          </div>
          <div
            className={`component-messenger__chat-content col-xxl-9 col-lg-8${
              !this.props.messenger.currentId ? ' hiddenChat' : ''
            }`}
          >
            <Chat
              user={this.props.user}
              messenger={this.props.messenger}
              currentId={this.props.messenger.currentId}
              onSubmit={this.onMsgSubmit.bind(this)}
              chatDisconnect={this.onChatDisconnect.bind(this)}
              onDelete={this.onChatDelete.bind(this)}
              onClear={this.onChatClear.bind(this)}
              onAddChatSettingsToggle={this.onAddChatSettingsToggle.bind(this)}
              onSettingsToggle={this.onSettingsToggle.bind(this)}
              settingsOpened={this.state.settingsOpened}
              onVotingToggle={this.onVotingToggle.bind(this)}
              votingOpened={this.state.votingOpened}
              t={this.props.t}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    user: state.app.user,
    messenger: state.messenger,
  }),
  {
    loadMessages: chatConnect,
    sendMessage: sendMessage,
    chatDelete: chatDelete,
    chatDisconnect: chatDisconnect,
    chatClear: chatClear,
    // onChatGetOrCreate: chatGetOrCreate,
    onChatCreateOrUpdate: chatCreateOrUpdate,
  },
)(withTranslation()(Messenger));
