import { isArray, isString } from 'util';
import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';

const getMsg = (key, t) => {
  const messages = {
    required: t('validation.required'),
    maxLength: t('validation.maxLength'),
    minLength: t('validation.minLength'),
    maxNumber: t('validation.maxNumber'),
    minNumber: t('validation.minNumber'),
    phone: t('validation.phone'),
    email: t('validation.email'),
    inn: t('validation.inn'),
    code: t('validation.code'),
    integer: t('validation.integer'),
    user_not_found: t('validation.user_not_found'),
    'User already exist': t('validation.user_already_exist'),
    'serial number is busy': t('validation.serial_number_busy'),
    'Code does not exist': t('validation.code_does_not_exist'),
    'Login or password is not correct': t(
      'validation.login_or_password_is_not_correct',
    ),
    'email already exist': t('validation.email_already_exist'),
    code_sent: t('validation.code_sent'),
    date: t('validation.date'),
    checkbox: t('validation.checkbox'),
  };

  return messages[key];
};

const validators = {
  required: (str) => {
    if (str === undefined) {
      str = '';
    }

    if (isArray(str)) {
      return str.length > 0;
    } else if (isString(str)) {
      return str.trim().length > 0;
    }

    return str !== undefined;
  },
  integer: (str = '') => {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  },
  phone: (str = '') => {
    return isValidPhoneNumber(str);
  },
  email: (str = '') => {
    return !!str.length && /^.+@.+\..+$/.test(str);
  },
  inn: (str = '') => {
    return !!str.length && /^(\d{10}|\d{12})$/.test(str);
  },
  code: (str = '') => {
    return str.trim().length === 7;
  },
  date: (str = '') => {
    return !moment(str).isSameOrAfter(new Date()) && moment(str).isValid();
  },
  checkbox: (checked) => {
    return checked;
  },
};

const Validation = {
  getErrors(field, t) {
    let errorsList = [];

    if (field.validationRules) {
      field.validationRules.forEach((type) => {
        let val = typeof field.value !== 'undefined' ? field.value : '';
        if (!val && field.props) {
          val = field.props.value;
        }

        if (typeof type === 'object' && type['length']) {
          const rule = type['length'];

          if ((rule.max && rule.max < val.length) || Number(rule) < val.length)
            errorsList.push(
              Validation.getMessage('maxLength', rule.max || rule, t),
            );
          else if (
            (rule.min && rule.min > val.length) ||
            Number(rule) > val.length
          )
            errorsList.push(
              Validation.getMessage('minLength', rule.min || rule, t),
            );
        } else if (typeof type === 'object' && type['number']) {
          const rule = type['number'];

          if (rule.max && rule.max < Number(val))
            errorsList.push(Validation.getMessage('maxNumber', rule.max, t));
          else if (rule.min && rule.min > Number(val))
            errorsList.push(Validation.getMessage('minNumber', rule.min, t));
        } else if (!validators[type](val)) {
          errorsList.push(Validation.getMessage(type, '', t));
        }
      });
    }

    return errorsList;
  },

  getMessage(type = '', fieldLabel = '', t) {
    if (!getMsg(type, t)) {
      return false;
    }

    return getMsg(type, t).replace('%fieldLabel%', fieldLabel);
  },

  getAssetMessage(type = '', t) {
    let field = '';

    switch (type) {
      case 'User already exist':
        field = ['email', 'phone'];
        break;
      case 'Login or password is not correct':
        field = ['email', 'password'];
        break;
      case 'email':
      case 'email already exist':
        field = 'email';
        break;
      case 'user_not_found':
        field = 'phone';
        break;
      case 'sensor_type_id':
        field = 'sensor_type_id';
        break;
      case 'device_type_id':
        field = 'device_type_id';
        break;
      case 'serial number is busy':
        field = 'serial_number';
        break;
      case 'Code does not exist':
        field = 'code';
        break;
      default:
        field = 'field';
    }

    if (!getMsg(type, t)) {
      return false;
    }

    if (Array.isArray(field)) {
      const data = {};

      field.forEach((item) => (data[item] = [getMsg(type, t)]));
      return data;
    } else {
      return {
        [field]: [getMsg(type, t)],
      };
    }
  },
};

export default Validation;
