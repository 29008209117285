import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import ControllerService from 'Services/Controller';
import { controllerAdd, cacheClean } from 'Actions/controllers';
import BaseSelect from 'Components/BaseSelect';
import { IconSettings } from 'Components/Icons';
import { panelOpen } from 'Actions/panel';
import Loader from 'Components/Loader';

class AddDevicesPanel extends BaseComponent {
  state = {
    step: 1,
    content: '',
    category: undefined,
    deviceData: {},
  };

  componentDidMount() {
    this.props.cacheClean('all');
  }

  goStep(step) {
    this.setState({
      step: step,
    });
  }

  onSelectDeviceType = (item) => {
    this.setState({
      category: item,
      errorStep1: false,
    });
  };

  handleChangeDeviceName = (event) => {
    this.setState({
      addDeviceName: event.target.value,
      errorStep4Name: false,
    });
  };

  handleChangeDeviceRoom = (selectedRoom) => {
    this.setState({
      addDeviceRoom: selectedRoom,
      errorStep4Room: false,
    });
  };

  getRoomsOptions() {
    const options = Object.keys(this.props.rooms).map((i) => {
      return {
        value: i,
        label: this.props.rooms[i].title,
      };
    });
    return options;
  }

  selectRoom = (event) => {
    this.setState({
      addDeviceRoom: {
        label: event.target.innerHTML,
        value: event.target.innerHTML,
      },
      errorStep4Room: false,
    });
  };

  startDevicesSearch() {
    // TODO: set current controller from cookies
    const _controller = this.props.controllers.list[0];

    if (!_controller) return this.goStep(0);

    const _data = {
      smarthome_id: _controller.smarthomeId,
      request_id: '0',
      type: 'z_wave',
      command: 'include',
    };

    ControllerService.executeCommand(_data, () => {});

    this.goStep(2);
  }

  startDevicesExclude() {
    // TODO: set current controller from cookies
    const _controller = this.props.controllers.list[0];

    const _data = {
      smarthome_id: _controller.smarthomeId,
      request_id: '0',
      type: 'z_wave',
      command: 'exclude',
    };

    ControllerService.executeCommand(_data, () => {});

    this.goStep(2);
  }

  deviceSearchContent = () => {
    if (this.props.controllers.excludedDevice.request_id) {
      return (
        <div className='form form_addDevice text-center'>
          {this.props.t(
            'panels.add_devices_panel.device_search_content.result',
          )}
          <br />
          <br />
          <span
            className='btn btn_primary btn_sm'
            onClick={() => {
              this.props.cacheClean('exclude');
              this.goStep(1);
            }}
          >
            {this.props.t('commons.buttons.back')}
          </span>
        </div>
      );
    }
    if (
      this.props.controllers.foundedDevice.request_id ||
      this.props.controllers.addedDevice.request_id
    ) {
      return this.deviceFoundContent();
    }
    return (
      <div className='form form_addDevice'>
        <div className='form_addDevice__step3'>
          <Loader
            text={
              <>
                {this.props.t(
                  'panels.add_devices_panel.device_search_content.search',
                )}
                <br />
                {this.props.t(
                  'panels.add_devices_panel.device_search_content.devices',
                )}
              </>
            }
          />
          <p className='form_addDevice__desc'>
            {this.props.t(
              'panels.add_devices_panel.device_search_content.info',
            )}
          </p>
        </div>
        <div className='form_addDevice__buttons'>
          <button
            type='button'
            className='btn btn_secondary btn_sm'
            onClick={this.goStep.bind(this, 1)}
          >
            {this.props.t('commons.buttons.cancel')}
          </button>
        </div>
      </div>
    );
  };

  getType = (device, type) => {
    let deviceType = {};

    if (type === 'device') {
      this.props.devicesTypes.some((item) => {
        if (Number(device.type_id) === Number(item.deviceTypeId)) {
          deviceType = item;
        }

        return null;
      });
    } else if (type === 'sensor') {
      this.props.sensorsTypes.some((item) => {
        if (Number(device.type_id) === Number(item.sensorTypeId)) {
          deviceType = item;
        }

        return null;
      });
    }

    return deviceType;
  };

  getCategory = (device) => {
    let deviceCategory = {};

    this.props.categoriesTypes.some((item) => {
      if (Number(device.category_id) === Number(item.categoryId)) {
        deviceCategory = item;
      }

      return null;
    });

    return deviceCategory;
  };

  onDeviceEdit(item, deviceType) {
    this.props.panelOpen('EditDevice', {
      device: item,
      type: deviceType,
    });
  }

  deviceFoundContent = () => {
    return (
      <div className='form form_addDevice'>
        <div
          className='preloader-wrapper'
          hidden={this.props.controllers.addedDevice.request_id}
        >
          <div className='preloader preloader_circle-bounce'>
            <div className='preloader-child preloader-circle-1' />
            <div className='preloader-child preloader-circle-2' />
            <div className='preloader-child preloader-circle-3' />
            <div className='preloader-child preloader-circle-4' />
            <div className='preloader-child preloader-circle-5' />
            <div className='preloader-child preloader-circle-6' />
            <div className='preloader-child preloader-circle-7' />
            <div className='preloader-child preloader-circle-8' />
            <div className='preloader-child preloader-circle-9' />
            <div className='preloader-child preloader-circle-10' />
            <div className='preloader-child preloader-circle-11' />
            <div className='preloader-child preloader-circle-12' />
          </div>
          <div className='preloader__text'>
            {this.props.t(
              'panels.add_devices_panel.device_found_content.setting_up',
            )}
            <br />
            {this.props.t(
              'panels.add_devices_panel.device_found_content.device',
            )}
          </div>
        </div>
        <div
          className='form_addDevice__result result-successful'
          hidden={this.props.controllers.addedDevice.request_id}
        >
          {this.props.t('panels.add_devices_panel.device_found_content.result')}
        </div>
        <div
          className='founded-things'
          hidden={!this.props.controllers.addedDevice.request_id}
        >
          <div hidden={this.props.controllers.addedDevice.result === true}>
            {this.props.t(
              'panels.add_devices_panel.device_found_content.error',
            )}
          </div>
          <div
            className='founded-things__devices'
            hidden={
              !this.props.controllers.addedDevice.devices ||
              this.props.controllers.addedDevice.devices.length === 0
            }
          >
            <h4>
              {this.props.t(
                'panels.add_devices_panel.device_found_content.found_devices',
              )}
            </h4>
            {Object.keys(this.props.controllers.addedDevice.devices || []).map(
              (id) => {
                const device = this.props.controllers.addedDevice.devices[id];
                const type = this.getType(device, 'device');
                const category = this.getCategory(device);

                device.title = device.name;
                device.type = type;
                device.deviceId = device.device_id;
                device.category = category;

                return (
                  <div
                    key={id}
                    className='form_addDevice__result result-successful'
                  >
                    <b>{device.name}</b>,{' '}
                    {this.props.t(
                      'panels.add_devices_panel.device_found_content.type',
                    )}{' '}
                    <b>{type.title}</b>,
                    {this.props.t(
                      'panels.add_devices_panel.device_found_content.category',
                    )}{' '}
                    <b>{category.title}</b>
                    <span
                      className='btn btn_circle btn_primary btn_min'
                      onClick={this.onDeviceEdit.bind(this, device, 'device')}
                    >
                      {IconSettings}
                    </span>
                  </div>
                );
              },
            )}
          </div>
          <div
            className='founded-things__sensors'
            hidden={
              !this.props.controllers.addedDevice.sensors ||
              this.props.controllers.addedDevice.sensors.length === 0
            }
          >
            <h4>
              {this.props.t(
                'panels.add_devices_panel.device_found_content.found_sensors',
              )}
            </h4>
            {Object.keys(this.props.controllers.addedDevice.sensors || []).map(
              (id) => {
                const sensor = this.props.controllers.addedDevice.sensors[id];
                const type = this.getType(sensor, 'sensor');
                const category = this.getCategory(sensor);

                sensor.title = sensor.name;
                sensor.type = type;
                sensor.sensorId = sensor.sensor_id;
                sensor.category = category;

                return (
                  <div
                    key={id}
                    className='form_addDevice__result result-successful'
                  >
                    <b>{sensor.name}</b>,{' '}
                    {this.props.t(
                      'panels.add_devices_panel.device_found_content.type',
                    )}{' '}
                    <b>{type.title}</b>,
                    {this.props.t(
                      'panels.add_devices_panel.device_found_content.category',
                    )}{' '}
                    <b>{category.title}</b>
                    <span
                      className='btn btn_circle btn_primary btn_min'
                      onClick={this.onDeviceEdit.bind(this, sensor, 'sensor')}
                    >
                      {IconSettings}
                    </span>
                  </div>
                );
              },
            )}
          </div>
        </div>
        <div className='form_addDevice__buttons'>
          <button
            type='button'
            className='btn btn_secondary btn_sm'
            hidden={this.props.controllers.addedDevice.request_id}
            onClick={() => {
              this.props.cacheClean('all');
              this.goStep(1);
            }}
          >
            {this.props.t('commons.buttons.cancel')}
          </button>
          <button
            type='button'
            className='btn btn_primary btn_sm'
            hidden={!this.props.controllers.addedDevice.request_id}
            onClick={() => {
              this.props.cacheClean('all');
              this.goStep(1);
            }}
          >
            {this.props.t('commons.buttons.continue')}
          </button>
        </div>
      </div>
    );
  };

  controllerNotFound = () => {
    return (
      <div className='form form_addDevice'>
        <p className='form_addDevice__info'>
          {this.props.t('panels.add_devices_panel.controller_not_found.result')}
        </p>
        <div className='form_addDevice__buttons'>
          <a href='/' className='btn btn_secondary btn_sm'>
            {this.props.t('commons.buttons.skip')}
          </a>
          <button
            type='button'
            className='btn btn_primary btn_sm'
            onClick={this.props.callback.bind(this, 'addController')}
          >
            {this.props.t('commons.buttons.connect')}
          </button>
        </div>
      </div>
    );
  };

  deviceConfigurationForm = () => {
    const { t } = this.props;
    return (
      <div className='form form_addDevice'>
        <div className='form_addDevice__step4'>
          <div
            className={
              'form-group m-0' + (this.state.errorStep4Name ? ' is-error' : '')
            }
          >
            <label htmlFor='addDeviceName'>
              {t(
                'panels.add_devices_panel.device_configuration_form.name_device',
              )}
            </label>
            <input
              id='addDeviceName'
              className='form-control'
              type='text'
              value={this.state.addDeviceName}
              onChange={this.handleChangeDeviceName}
              maxLength='20'
            />
            <p className='input__error' hidden={!this.state.errorStep4Name}>
              {t('commons.inputs.required')}
            </p>
          </div>
          <div className='form-group-info mb-20'>
            {t('panels.add_devices_panel.device_configuration_form.max_20')}
          </div>
          <div
            className={
              'form-group m-0' + (this.state.errorStep4Room ? ' is-error' : '')
            }
          >
            <label>
              {t(
                'panels.add_devices_panel.device_configuration_form.define_room',
              )}
            </label>
            <BaseSelect
              isCreatable='true'
              isClearable='true'
              options={this.getRoomsOptions()}
              value={this.state.addDeviceRoom}
              onChange={this.handleChangeDeviceRoom.bind(this)}
              placeholder={`${t('commons.buttons.select_placeholder')}...`}
            />
            <p className='input__error' hidden={!this.state.errorStep4Room}>
              {t('commons.inputs.required')}
            </p>
          </div>
          <div className='tags-rooms mb-20'>
            {t('panels.config_controller.add_device_panel.rooms', {
              returnObjects: true,
            }).map((room) => {
              return (
                <button
                  key={room}
                  type='button'
                  className='tags-rooms__item btn btn_secondary btn_xs'
                  onClick={this.selectRoom.bind(this)}
                >
                  {room}
                </button>
              );
            })}
          </div>
          <p className='form_addDevice__info'>
            {t('panels.add_devices_panel.device_configuration_form.info')}
          </p>
          <div className='form_addDevice__buttons'>
            <button
              type='button'
              className='btn btn_secondary btn_sm'
              onClick={this.goStep.bind(this, 3)}
            >
              {t('commons.buttons.back')}
            </button>
            <button
              type='button'
              className='btn btn_primary btn_sm'
              onClick={this.goStep.bind(this, 5)}
            >
              {t('commons.buttons.add_device')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  instructionsContent = (
    <div className='form form_addDevice'>
      <p className='form_addDevice__desc'>
        {this.props.t('panels.add_devices_panel.instructions_content.first')}
        <br />
        <span className='text-description text-blue add-icon-attention'>
          {this.props.t('panels.add_devices_panel.instructions_content.second')}
        </span>
        <br />
        <span className='text-description text-blue add-icon-attention'>
          {this.props.t('panels.add_devices_panel.instructions_content.third')}
        </span>
      </p>

      <div className='form_addDevice__buttons'>
        <button
          type={'button'}
          className='btn btn_secondary btn_sm'
          onClick={this.startDevicesExclude.bind(this)}
        >
          {this.props.t('commons.buttons.delete')}
        </button>
        <button
          type={'button'}
          className='btn btn_primary btn_sm'
          onClick={this.startDevicesSearch.bind(this)}
        >
          {this.props.t('commons.buttons.find_device')}
        </button>
      </div>
    </div>
  );

  resultSearchContent = (
    <div className='form_addDevice__step5'>
      <div className='form_addDevice__result result-successful'>
        {this.props.t('panels.add_devices_panel.result_search_content.result')}
      </div>
      <h4 className='text-center'>
        {this.props.t('panels.add_devices_panel.result_search_content.title')}
      </h4>
      <div className='form_addDevice__buttons'>
        <button
          type='button'
          className='btn btn_secondary btn_sm'
          onClick={this.props.callback.bind(this, 'close')}
        >
          {this.props.t('commons.buttons.complete')}
        </button>
        <button
          type='button'
          className='btn btn_primary btn_sm'
          onClick={this.goStep.bind(this, 1)}
        >
          {this.props.t('commons.buttons.continue')}
        </button>
      </div>
    </div>
  );

  render() {
    const _step = this.state.step;

    if (_step === 1) {
      return this.instructionsContent;
    } else if (_step === 2) {
      return this.deviceSearchContent();
    } else if (_step === 0) {
      return this.controllerNotFound();
    } else if (_step === 3) {
      return this.deviceConfigurationForm();
    } else if (_step === 4) {
      return this.resultSearchContent;
    }

    return null;
  }
}

export default connect(
  (state) => ({
    user: state.app.user,
    controllers: state.controllers,
    rooms: state.rooms,
    devicesTypes: state.devices.devicesTypes,
    sensorsTypes: state.devices.sensorsTypes,
    categoriesTypes: state.devices.categoriesTypes,
  }),
  {
    onControllerAdd: controllerAdd,
    cacheClean: cacheClean,
    panelOpen: panelOpen,
  },
)(AddDevicesPanel);
