const component = 'Intercom';

export const EVENT_INTERCOM_LOAD = `${component}/LOAD`;

export function intercomLoad(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_INTERCOM_LOAD,
      data: data
    });
  };
}