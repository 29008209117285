// типы датчиков
export const SENSOR_VOLTAGE_ID = '9';
export const SENSOR_DOOR_OPEN_ID = '10';
export const SENSOR_HUMIDITY_ID = '2';
export const SENSOR_SMOKE_ID = '6';
export const SENSOR_ATMOSPHERE_PRESSURE_ID = '4';
export const SENSOR_LIGHT = '3';
export const SENSOR_TEMP_ID = '1';
export const SENSOR_LEAKS_ID = '8';
export const SENSOR_COOLING_ID = '11';
export const SENSOR_UNDEFINED_ID = '100';
export const SENSOR_CO2_ID = '5';
export const SENSOR_MOVE_ID = '7';

// типы устройств
export const DEVICE_LOCK_ID = '1';
export const DEVICE_TERMOSTAT_ID = '2';
export const DEVICE_LIGHT_CONTROL_ID = '3';
export const DEVICE_SERVO_ID = '4';
export const DEVICE_RELE_ID = '5';
export const DEVICE_UNKNOW_ID = '100';
export const DEVICE_ROSETTE_ID = '6';
export const DEVICE_LIGHT_TOGGLER_ID = '7';
export const DEVICE_LIGHT_DIMM_ID = '8';
export const DEVICE_LIGHT_RGB_ID = '9';
export const DEVICE_HEAT_FLOOR_ID = '10';
export const DEVICE_SMART_POD_ID = '11';
export const DEVICE_CONDITIONER_ID = '12';
export const DEVICE_IK_HUB_ID = '13';

// Status Text
export const SENSOR_DEFAULT_TURN_ON = (t) =>
  t('commons.statuses.SENSOR_DEFAULT_TURN_ON');
export const SENSOR_DEFAULT_TURN_OFF = (t) =>
  t('commons.statuses.SENSOR_DEFAULT_TURN_OFF');
export const SENSOR_MOVE_TEXT_ACTIVE = (t) =>
  t('commons.statuses.SENSOR_DEFAULT_TURN_ACTIVE');
export const SENSOR_MOVE_TEXT_INACTIVE = (t) =>
  t('commons.statuses.SENSOR_DEFAULT_TURN_ON');
export const SENSOR_REED_SWITCH_TEXT_ACTIVE = (t) =>
  t('commons.statuses.SENSOR_REED_SWITCH_TEXT_ACTIVE');
export const SENSOR_REED_SWITCH_TEXT_INACTIVE = (t) =>
  t('commons.statuses.SENSOR_REED_SWITCH_TEXT_INACTIVE');
export const SENSOR_LEAKAGE_TEXT_ACTIVE = (t) =>
  t('commons.statuses.SENSOR_LEAKAGE_TEXT_ACTIVE');
export const SENSOR_LEAKAGE_TEXT_INACTIVE = (t) =>
  t('commons.statuses.SENSOR_LEAKAGE_TEXT_INACTIVE');

// Category
export const DEVICE_CATEGORY_OTHER = 'other';
export const DEVICE_CATEGORY_LIGHT = 'light';
export const DEVICE_CATEGORY_ROSETTE = 'rosette';
export const DEVICE_CATEGORY_CLIMATE = 'climate';
export const DEVICE_CATEGORY_SECURITY = 'security';

// Status devices
export const DEVICE_STATUS_ON = 1;
export const DEVICE_STATUS_OFF = 2;
export const DEVICE_STATUS_WARNING = 3;

// OLD STATUS WITHOUT VALUE, DELETE IT NOW
// export const SENSORS_STATUS_WARNING = 1
// export const SENSORS_STATUS_ON = 2
// export const SENSORS_STATUS_OFF = 3

// Status sensors without value
export const SENSORS_STATUS_ON = 1;
export const SENSORS_STATUS_OFF = 2;
export const SENSORS_STATUS_WARNING = 3;

// Status sensors with value
/* PS: status sensor (for example sensor DeviceTemperature has a value of +25 C)
   has a different status value ON\OFF (without WARNING)
*/
export const SENSORS_STATUS_WITH_VAL_ON = 1;
export const SENSORS_STATUS_WITH_VAL_OFF = 2;

// Counter Type
export const COUNTER_TYPE_WATER = 'water';
export const COUNTER_TYPE_WATER_HOT = 'hot_water';
export const COUNTER_TYPE_WATER_COLD = 'cool_water';
export const COUNTER_TYPE_ELECTRICITY = 'electricity';
export const COUNTER_TYPE_HEATING = 'heating';
export const COUNTER_TYPE_ODN_WATER_HOT = 'odn_hot_water';
export const COUNTER_TYPE_ODN_WATER_COLD = 'odn_cool_water';
export const COUNTER_TYPE_ODN_ELECTRICITY = 'odn_electricity';
export const COUNTER_TYPE_ODN_HEATING = 'odn_heating';
