import React, { useEffect, useState } from 'react';
import UserService from 'Services/User';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import WebtrcVideo from './WebrtcVideo';

const IntercomCall = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const cameraId = urlParams.get('id');

  const activeApartment = useSelector((state) => state.apartments.active);

  const [dnsName, setDnsName] = useState(null);

  useEffect(() => {
    UserService.getUser(async (user) => {
      const apartment = user.apartments.find(
        (x) => x.apartmentId === activeApartment.apartmentId,
      );
      setDnsName(apartment.building.dnsName);
    });
  }, []);

  return (
    <>
      {dnsName && cameraId && (
        <WebtrcVideo cameraId={cameraId} dnsName={dnsName} />
      )}
    </>
  );
};

export default connect((state) => ({
  activeApartment: state.apartments.active,
}))(IntercomCall);
