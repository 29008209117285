import React, { useEffect, useState } from 'react';

import { IconBrightness, IconColor } from 'Components/Icons';
import BrightnessToggler from 'Components/advancedSettings/Light/BrightnessToggler';

import styles from './styles.module.scss';
import ColorPicker from 'Components/advancedSettings/Light/ColorPicker';
import { DEVICE_LIGHT_CONTROL_ID, DEVICE_LIGHT_RGB_ID } from 'Config/devices';

const tabs: { title: JSX.Element; id: number; type: string[] }[] = [
  {
    title: IconBrightness,
    id: 0,
    type: [DEVICE_LIGHT_CONTROL_ID],
  },
  { title: IconColor, id: 1, type: [DEVICE_LIGHT_RGB_ID] },
];

const LightSettings = ({
  type,
  onRangeChange,
  rangeSliderValue,
  isDisabled,
}: {
  type: string;
  onRangeChange: (value: number) => React.SetStateAction<number>;
  rangeSliderValue: number | null;
  isDisabled: boolean;
}) => {
  const [activeTab, setActiveTab] = useState<number>(3);

  useEffect(() => {
    switch (type) {
      case DEVICE_LIGHT_CONTROL_ID:
        setActiveTab(0);
        break;
      case DEVICE_LIGHT_RGB_ID:
        setActiveTab(1);
        break;
      default:
        setActiveTab(3);
    }
  }, [type]);

  const onTabClick = (id: number) => {
    setActiveTab(id);
  };

  const getSettings = (id) => {
    switch (id) {
      case 0:
        return (
          <BrightnessToggler
            rangeSliderValue={rangeSliderValue}
            onRangeChange={onRangeChange}
            isDisabled={isDisabled}
          />
        );
      case 1:
        return (
          <ColorPicker onRangeChange={onRangeChange} isDisabled={isDisabled} />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            type={'button'}
            onClick={() => onTabClick(tab.id)}
            className={`
                  btn
                  btn_full-width
                  ${styles.tab}  
                  ${index === tabs.length - 1 ? styles.lastTab : ''}
                  ${activeTab === tab.id ? ' btn_primary' : ' btn-grey'}
                  `}
            disabled={!tab.type.includes(type)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      {getSettings(activeTab)}
    </div>
  );
};

export default LightSettings;
