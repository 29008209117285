import React from 'react'
import Messenger from 'Components/messenger'
import BaseComponent from 'Components/BaseComponent'

class Messages extends BaseComponent {
  className = 'messenger page_chat'

  render() {
    return (
      <div className={this.getPageClasses()}>
        <Messenger />
      </div>
    )
  }
}

export default Messages
