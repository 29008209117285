import { EVENT_SET_LANGUAGE } from 'Actions/language';

export function language(state = {}, action) {
  switch (action.type) {
    case EVENT_SET_LANGUAGE: {
      return {
        ...state,
        lang: action.language,
      };
    }
    default: {
      return state;
    }
  }
}
