import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Loader extends Component {
  text = this.props.t('loader.loading');

  constructor(props) {
    super(props);

    if (props.text) this.text = props.text;
  }

  render() {
    return (
      <div className='preloader-wrapper'>
        <div className='preloader preloader_circle-bounce'>
          <div className='preloader-child preloader-circle-1' />
          <div className='preloader-child preloader-circle-2' />
          <div className='preloader-child preloader-circle-3' />
          <div className='preloader-child preloader-circle-4' />
          <div className='preloader-child preloader-circle-5' />
          <div className='preloader-child preloader-circle-6' />
          <div className='preloader-child preloader-circle-7' />
          <div className='preloader-child preloader-circle-8' />
          <div className='preloader-child preloader-circle-9' />
          <div className='preloader-child preloader-circle-10' />
          <div className='preloader-child preloader-circle-11' />
          <div className='preloader-child preloader-circle-12' />
        </div>
        <div className='preloader__text'>{this.text}</div>
      </div>
    );
  }
}

export default withTranslation()(Loader);
