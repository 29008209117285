import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { IconDrag, IconClimate } from 'Components/Icons';
import { Link } from 'react-router-dom';
import BaseSelect from 'Components/BaseSelect';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

const cond1 = [
  { value: 'c1r1', label: 'Режим 1' },
  { value: 'c1r2', label: 'Режим 2' },
  { value: 'c1r3', label: 'Режим 3' },
  { value: 'c1r4', label: 'Режим 4' },
  { value: 'c1r5', label: 'Режим 5' },
];
const cond2 = [
  { value: 'c2r1', label: 'Режим 1' },
  { value: 'c2r2', label: 'Режим 2' },
  { value: 'c2r3', label: 'Режим 3' },
  { value: 'c2r4', label: 'Режим 4' },
  { value: 'c2r5', label: 'Режим 5' },
];
const cond3 = [
  { value: 'c3r1', label: 'Режим 1' },
  { value: 'c3r2', label: 'Режим 2' },
  { value: 'c3r3', label: 'Режим 3' },
  { value: 'c3r4', label: 'Режим 4' },
  { value: 'c3r5', label: 'Режим 5' },
];

function degFormatter(v) {
  return `${v}°`;
}

const SliderWithTooltip = createSliderWithTooltip(Slider);

class AirConditioner extends BaseComponent {
  className = 'air-conditioner';
  state = {
    items: [],
    activated: true,
    conditioner1: true,
    conditioner2: true,
    conditioner3: false,
    selectedModeCond1: { value: 'c1r1', label: 'Режим 1' },
    selectedModeCond2: { value: 'c2r5', label: 'Режим 5' },
    selectedModeCond3: { value: 'c3r3', label: 'Режим 3' },
  };

  handleChangeConditioner0(event) {
    this.setState({ activated: event.target.checked });
  }
  handleChangeConditioner1(event) {
    this.setState({ conditioner1: event.target.checked });
  }
  handleChangeConditioner2(event) {
    this.setState({ conditioner2: event.target.checked });
  }
  handleChangeConditioner3(event) {
    this.setState({ conditioner3: event.target.checked });
  }
  handleChangeSelectCond1 = (selectedModeCond1) => {
    this.setState({ selectedModeCond1 });
  };
  handleChangeSelectCond2 = (selectedModeCond2) => {
    this.setState({ selectedModeCond2 });
  };
  handleChangeSelectCond3 = (selectedModeCond3) => {
    this.setState({ selectedModeCond3 });
  };

  render() {
    const { selectedModeCond1, selectedModeCond2, selectedModeCond3 } =
      this.state;
    return (
      <div className={this.getPageClasses()}>
        <div className='submenu-navigation text-center'>
          <Link to='/control/climate' className='submenu-navigation__item'>
            Радиаторы
          </Link>
          <span className='submenu-navigation__item active'>Кондиционеры</span>
        </div>
        <div
          className={
            'tile-items' + (this.state.activated ? '' : ' deactivated')
          }
        >
          <div className='tile-items__wrap tile-main'>
            <div
              className={
                'tile-items__item tile-switch' +
                (this.state.activated ? ' active' : '')
              }
            >
              <div className='tile-items__room'>Общее управление</div>
              <div className='tile-items__image'>{IconClimate}</div>
              <div className='tile-items__group'>Все кондиционеры</div>
              <div className='tile-items__switch switch-ios switch-ios_min on-off color-yellow'>
                <input
                  id='light-all'
                  type='checkbox'
                  checked={this.state.activated}
                  onChange={this.handleChangeConditioner0.bind(this)}
                />
                <label className='tgl-btn' htmlFor='light-all'>
                  <span />
                </label>
              </div>
            </div>
          </div>
          <div className='tile-items__wrap'>
            <div
              className={
                'tile-items__item tile-switch tile-conditioner' +
                (this.state.conditioner1 ? ' active' : '')
              }
            >
              <div className='tile-items__room'>Гостиная</div>
              <div className='tile-items__drag'>{IconDrag}</div>
              <div className='tile-items__group'>Кондиционер 1</div>
              <div className='tile-items__switch switch-ios switch-ios_min'>
                <input
                  id='conditioner1'
                  type='checkbox'
                  checked={this.state.conditioner1}
                  onChange={this.handleChangeConditioner1.bind(this)}
                />
                <label className='tgl-btn' htmlFor='conditioner1'>
                  <span />
                </label>
              </div>
              <BaseSelect
                className='dropdown'
                value={selectedModeCond1}
                onChange={this.handleChangeSelectCond1}
                options={cond1}
                placeholder={null}
              />
              <SliderWithTooltip
                tipFormatter={degFormatter}
                onChange={() => {}}
                min={16}
                max={25}
              />
            </div>
          </div>
          <div className='tile-items__wrap'>
            <div
              className={
                'tile-items__item tile-switch tile-conditioner' +
                (this.state.conditioner2 ? ' active' : '')
              }
            >
              <div className='tile-items__room'>Спальня</div>
              <div className='tile-items__drag'>{IconDrag}</div>
              <div className='tile-items__group'>Кондиционер 2</div>
              <div className='tile-items__switch switch-ios switch-ios_min'>
                <input
                  id='conditioner2'
                  type='checkbox'
                  checked={this.state.conditioner2}
                  onChange={this.handleChangeConditioner2.bind(this)}
                />
                <label className='tgl-btn' htmlFor='conditioner2'>
                  <span />
                </label>
              </div>
              <BaseSelect
                className='dropdown'
                value={selectedModeCond2}
                onChange={this.handleChangeSelectCond2}
                options={cond2}
                placeholder={null}
              />
              <SliderWithTooltip
                tipFormatter={degFormatter}
                min={16}
                max={25}
              />
            </div>
          </div>
          <div className='tile-items__wrap'>
            <div
              className={
                'tile-items__item tile-switch tile-conditioner' +
                (this.state.conditioner3 ? ' active' : '')
              }
            >
              <div className='tile-items__room'>Детская</div>
              <div className='tile-items__drag'>{IconDrag}</div>
              <div className='tile-items__group'>Кондиционер 3</div>
              <div className='tile-items__switch switch-ios switch-ios_min'>
                <input
                  id='conditioner3'
                  type='checkbox'
                  checked={this.state.conditioner3}
                  onChange={this.handleChangeConditioner3.bind(this)}
                />
                <label className='tgl-btn' htmlFor='conditioner3'>
                  <span />
                </label>
              </div>
              <BaseSelect
                className='dropdown'
                value={selectedModeCond3}
                onChange={this.handleChangeSelectCond3}
                options={cond3}
                placeholder={null}
              />
              <SliderWithTooltip
                tipFormatter={degFormatter}
                min={16}
                max={25}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AirConditioner;
