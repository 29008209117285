import React from 'react';

import LightToggler from 'Components/advancedSettings/common/LightToggler';
import { useTranslation } from 'react-i18next';

const bg =
  'transparent linear-gradient(180deg, #F58F36 0%, #FCCE38 100%) 0 0 no-repeat padding-box';

const BrightnessToggler = ({
  onRangeChange,
  rangeSliderValue,
  isDisabled,
}: {
  onRangeChange: (value: number) => React.SetStateAction<number>;
  rangeSliderValue: number;
  isDisabled: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <LightToggler
      title={t('panels.edit_device.brightness')}
      backgroundToggler={isDisabled ? '#AFB1B4' : bg}
      onRangeChange={onRangeChange}
      children={null}
      rangeSliderValue={rangeSliderValue}
      isDisabled={isDisabled}
    />
  );
};

export default BrightnessToggler;
