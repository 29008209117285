import React, { useEffect, useState, useRef } from 'react';
import AuthService from 'Services/Auth';
import BaseSelect from 'Components/BaseSelect';
import SocketWebrtc from 'Services/SocketWebrtc';
import ButtonOpenIntercom from './ButtonOpenIntercom';

interface Button {
  intercomId: string;
}

interface Camera {
  buttons?: Button[];
  cameraId: number;
  hasPlateRecognition: boolean;
  hasRecognition: boolean;
  name: string;
  rtspUrl: string;
  sort: number;
  url: string;
}

interface Option {
  label: string;
  value: number;
}

interface Props {
  dnsName: string;
  options: Option[];
  cameras: Camera[];
}

const Webtrc: React.FC<Props> = ({ dnsName, options, cameras }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isError, setIsError] = useState<boolean>(false);
  const [option, setOption] = useState<Option | null>(null);
  const [intercomId, setIntercomId] = useState<string | null>(null);

  const handleCameraChange = async (selectedCamera: Option) => {
    const intercomCandidate = cameras.find(
      (x) => x.cameraId === selectedCamera.value,
    );
    if (intercomCandidate && intercomCandidate.buttons?.length) {
      setIntercomId(intercomCandidate.buttons[0].intercomId);
    }
    setOption(selectedCamera);
    setIsError(false);

    await SocketWebrtc.init(
      dnsName,
      videoRef.current as HTMLVideoElement,
      selectedCamera.value,
      AuthService.getToken() as string,
      changeVisability,
    );
  };

  useEffect(() => {
    handleCameraChange(options[0]);
  }, []);

  function changeVisability() {
    setIsError(true);
  }

  return (
    <div>
      {isError ? (
        <div className={`video-player__content no-signal`}>
          <div className='video-player__text'>Нет сигнала</div>
          <video></video>
        </div>
      ) : (
        <video
          ref={videoRef}
          playsInline
          controls
          muted
          style={{
            width: '100%',
            backgroundColor: 'black',
          }}
        ></video>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '10px',
        }}
      >
        <BaseSelect
          isCreatable={false}
          isClearable={false}
          isSearchable={false}
          options={options}
          value={option}
          placeholder='Выберите камеру'
          onSelectChange={handleCameraChange}
        />
        {intercomId && (
          <ButtonOpenIntercom key={intercomId} intercomId={intercomId} />
        )}
      </div>
    </div>
  );
};

export default Webtrc;
