import React from "react"

export default () => {
  return (
    <div className='text-center'>
      <p className='text-center'>
        {' '}
        Сервис T.one доступен только на платформах Android и IOS
      </p>

      <a
        href='https://play.google.com/store/apps/details?id=com.tone.client'
        className='user-login-page__link-store btn btn_primary mb-20'
        target='_blank'
        rel='noopener noreferrer'
      >
        Android
      </a>
      <a
        href='https://apps.apple.com/ru/app/t-one-app/id1479967254'
        className='user-login-page__link-store btn btn_primary mb-20'
        target='_blank'
        rel='noopener noreferrer'
      >
        IOS
      </a>
    </div>
  )
}