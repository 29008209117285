import React, { useEffect } from 'react';
import iro from '@jaames/iro';

import styles from './styles.module.scss';

// const setColor = (rgb) => ({
//   backgroundColor: rgb,
// });

const defaultColor = 'rgb(255, 0, 0)';
// const maxColors = 4;

type IProps = {
  onRangeChange: (value: number) => React.SetStateAction<number>;
  isDisabled: boolean;
};

const ColorPicker = ({ onRangeChange }: IProps) => {
  // const [colors, setColors] = useState<string[]>([]);
  // const [activeColor, setActiveColor] = useState<string>('');
  // const [colorPicker, setColorPicker] = useState<string>('');

  useEffect(() => {
    // устанавливаем значение по дефолту
    onRangeChange(255000000);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wheelPicker = new iro.ColorPicker('#wheelPicker', {
      width: 250,
      height: 250,
      color: defaultColor,
      borderWidth: 1,
      borderColor: '#fff',
      layout: [
        {
          component: iro.ui.Wheel,
        },
      ],
    });

    wheelPicker.on('color:change', function (item) {
      // setColorPicker(color.rgbString);
      const color = item.rgbString
        .split(',')
        .map((elem) => elem.match(/[0-9]/g).join(''))
        .map((item) => {
          if (item.length === 3) return item;
          if (item.length === 2) return `0${item}`;
          return `00${item}`;
        });

      onRangeChange(Number(color.join('')));
    });
  }, []);

  return (
    <div className={styles.container}>
      <div id='wheelPicker' />
      {/*<div className={styles.colorsContainer}>*/}
      {/*  {colors &&*/}
      {/*    colors.map((item) => (*/}
      {/*      <button*/}
      {/*        key={item}*/}
      {/*        className={*/}
      {/*          styles.colorBtn +*/}
      {/*          ` ${activeColor === item ? styles.activeColor : ''}`*/}
      {/*        }*/}
      {/*        style={setColor(item)}*/}
      {/*        type={'button'}*/}
      {/*        onClick={() => {*/}
      {/*          setActiveColor(item);*/}
      {/*          onRangeChange(Number(item.match(/[0-9]/g).join('')));*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  <button*/}
      {/*    className={styles.addColor}*/}
      {/*    type={'button'}*/}
      {/*    onClick={() => {*/}
      {/*      if (colors.includes(colorPicker)) {*/}
      {/*        const newState = [...colors];*/}
      {/*        const idx = newState.indexOf(colorPicker);*/}
      {/*        newState.splice(idx, 1);*/}
      {/*        newState.push(colorPicker);*/}
      {/*        setColors(newState);*/}
      {/*      } else {*/}
      {/*        if (colors.length === maxColors) {*/}
      {/*          const newState = colors.slice(1);*/}
      {/*          newState.push(colorPicker);*/}
      {/*          setColors(newState);*/}

      {/*          return false;*/}
      {/*        }*/}
      {/*        setColors([...colors, colorPicker]);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default ColorPicker;
