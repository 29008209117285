import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import { openModal } from 'Actions/modal';
import { panelOpen } from 'Actions/panel';
import Tooltip from 'Components/Tooltip';
import Form from 'Components/Form';
import UserService from 'Services/User';
import { IconEdit, IconAlert, IconClose, IconArrow } from 'Components/Icons';
import DataTransform from 'Services/DataTransform';
import _ from 'lodash';
import { loadUserData } from 'Actions/userData';
import { setLanguage } from 'Actions/language';
import { NavLink } from 'react-router-dom';
import {
  ROUTE_CHANGE_NAME,
  ROUTE_CHANGE_EMAIL,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CHANGE_PHONE,
  ROUTE_ACCOUNTS,
} from 'Config/routes';

export const childRole = 7;
const languages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
];

class SettingsProfile extends BaseComponent {
  className = 'profile';
  state = {
    user: null,
    date: null,
    activeItemIndex: 0,
    profileEditForm: null,
    roleIsChild: false,
    showLoader: false,
  };

  generateId(fieldName) {
    let id = `form_${this.className}-edit`;

    if (fieldName) {
      id += `__${fieldName}`;
    }

    return id;
  }

  _getDefaultLanguage() {
    return (
      _.find(languages, (obj) => {
        if (obj.value === this.props.language) {
          return obj;
        }
      }) || {
        value: 'en',
        label: 'English',
      }
    );
  }

  setData() {
    const { activeApartment } = this.props;
    if (!activeApartment.apartmentId) return;

    const { user } = this.props.app;

    const isReadonly = activeApartment.roleId === childRole; // 7 is child

    this.setState({
      profileEditForm: {
        action: UserService.updateUser,
        className: `form_${this.className}-edit inline-fields submit-right`,
        noFaction: true,
        clean: false,
        fields: {
          fname: {
            id: this.generateId('fname'),
            type: 'text',
            label: `${this.props.t('commons.inputs.first_name')}:`,
            className: 'col',
            validationRules: ['required'],
            value: user.fname,
            disabled: isReadonly,
          },
          email: {
            id: this.generateId('email'),
            type: 'email',
            label: `${this.props.t('commons.inputs.email')}:`,
            className: 'col',
            validationRules: ['required', 'email'],
            value: user.email,
            disabled: isReadonly,
          },
          password: {
            id: this.generateId('password'),
            type: 'password',
            label: `${this.props.t('commons.inputs.password')}:`,
            className: 'col',
            validationRules: [{ length: { min: 8 } }],
          },
          language: {
            id: this.generateId('language'),
            type: 'select',
            label: `${this.props.t('commons.inputs.language')}:`,
            className: 'col',
            props: {
              placeholder: 'English...',
              value: this._getDefaultLanguage(),
              options: languages,
              className: 'basic-multi-select',
              onChange: (data) => {
                const jsonData = {
                  ...this.props.userData,
                  language: data.value,
                };
                this.setState({ showLoader: true });
                this.props.onLoadUserData(jsonData);
                UserService.updateSettings({
                  json_data: JSON.stringify(jsonData),
                }).then((res) => {
                  if (res) {
                    this.props.onSetLanguage(data.value);
                    this.setState({ showLoader: false });
                  }
                });
              },
            },
          },
        },
        btnSubmit: this.props.t('commons.buttons.save'),
        btnClass: 'btn_primary',
        disabled: isReadonly,
        formatBeforeSend: (data) => {
          delete data.language;
          data.id = user.userId;
          data.fname = user.fname;

          return data;
        },
        callback: () => {
          this.setStateSafe({
            isLoading: false,
          });
        },
      },
    });
  }

  componentDidMount() {
    if (this.props.language) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.profileEditForm || prevProps.t !== this.props.t) {
      this.setData();
    }
  }

  render() {
    const user = this.props.app.user;
    const { activeApartment } = this.props;
    // const tabs = this.getTabs();
    const { t } = this.props;

    if (!user.agreements) {
      user.agreements = [{ agreement: '—' }];
    }

    if (!this.state.profileEditForm) return null;

    if (this.state.showLoader) return this.getLoader();

    return (
      <div className={this.getPageClasses()}>
        <div className='row'>
          <div className='col-xxl-12'>
            <div className='user-info bx-sh'>
              <div className='react-tabs react-tabs_swipe'>
                <div
                  className={
                    'react-tabs__tab-panel' +
                    (this.state.activeItemIndex === 0 ? ' active' : '')
                  }
                >
                  <div className='user-info-title-wrap'>
                    <div className='container container_xl'>
                      <div className='user-info-title'>
                        <div className='user-info-title__image-wrap'>
                          <div className='user-info-title__image app-content__bg bglogo' />
                        </div>
                        <div className='user-info-title__name'>
                          <div
                            style={{
                              padding: '10px',
                              borderRadius: '10px',
                              backgroundColor: '#ffffff',
                              margin: '10px 0',
                              position: 'relative',
                            }}
                          >
                            <span>{IconAlert}</span>
                            <p
                              style={{
                                color: '#000000',
                                fontSize: '12px',
                                margin: '0px',
                                marginTop: '10px',
                              }}
                            >
                              {t('screen.profile.infoAvatar')}
                            </p>
                          </div>
                          <span className='label label_success'>
                            {activeApartment.roleName}
                          </span>
                          <div>
                            <Tooltip
                              place='bottom'
                              type='dark'
                              effect='solid'
                            />
                          </div>
                        </div>
                        <div className='user-info-title__contract'>
                          <div
                            hidden={
                              !activeApartment || !activeApartment.stringAddress
                            }
                            onClick={() => {
                              this.props.onPanelOpen('EditActiveApartment', {
                                title: t('panels.apartments.title'),
                              });
                            }}
                          >
                            <p className='user-info-title__string-address'>
                              <span>{t('profile.address')}:&nbsp;</span>
                              {activeApartment.stringAddress}
                            </p>
                          </div>
                          <p className='user-info-title__contract-name'>
                            <span>{t('profile.contract')}:&nbsp;</span>
                            {user.agreements[0].agreement}
                          </p>
                          <p className='user-info-title__date-activate'>
                            <span>{t('profile.activation_date')}:&nbsp;</span>
                            {DataTransform.getDate(
                              user.createdTs,
                              'DD.MM.YYYY',
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '15px',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <NavLink
                      to={ROUTE_ACCOUNTS}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        ':hover': {
                          background: '#f5f5f5',
                        },
                        gap: '6px',
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                        }}
                      >
                        {t('screen.residents.title')}
                      </span>
                      <span
                        style={{
                          width: '10px',
                        }}
                      >
                        {IconArrow}
                      </span>
                    </NavLink>
                    <NavLink
                      to={ROUTE_CHANGE_NAME}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        ':hover': {
                          background: '#f5f5f5',
                        },
                        gap: '6px',
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                        }}
                      >
                        {t('label.name')}
                      </span>
                      <span
                        style={{
                          width: '10px',
                        }}
                      >
                        {IconArrow}
                      </span>
                    </NavLink>
                    <NavLink
                      to={ROUTE_CHANGE_EMAIL}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        ':hover': {
                          background: '#f5f5f5',
                        },
                        gap: '6px',
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                        }}
                      >
                        {t('label.email')}
                      </span>
                      <span
                        style={{
                          width: '10px',
                        }}
                      >
                        {IconArrow}
                      </span>
                    </NavLink>
                    <NavLink
                      to={ROUTE_CHANGE_PHONE}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        ':hover': {
                          background: '#f5f5f5',
                        },
                        gap: '6px',
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                        }}
                      >
                        {t('label.phone')}
                      </span>
                      <span
                        style={{
                          width: '10px',
                        }}
                      >
                        {IconArrow}
                      </span>
                    </NavLink>
                    <NavLink
                      to={ROUTE_CHANGE_PASSWORD}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        ':hover': {
                          background: '#f5f5f5',
                        },
                        gap: '6px',
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                        }}
                      >
                        {t('label.changePassword')}
                      </span>
                      <span
                        style={{
                          width: '10px',
                        }}
                      >
                        {IconArrow}
                      </span>
                    </NavLink>
                  </div>
                </div>
                <div
                  className={
                    'react-tabs__tab-panel' +
                    (this.state.activeItemIndex === 2 ? ' active' : '')
                  }
                >
                  <div>
                    <p className='text-right'>
                      <button
                        className='btn btn-settings btn-white btn-icon btn-short-mobile'
                        onClick={() => {
                          this.props.onPanelOpen('addUk', {
                            uk: this.props.uk,
                          });
                        }}
                      >
                        {IconEdit}&nbsp;{t('profile.edit')}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className='btn btn_success btn_default add-btn'
                        onClick={() => {
                          this.props.onPanelOpen('UkAddIssue');
                        }}
                      >
                        <span>Отправить заявку</span>
                        <span className='btn btn_circle'>+</span>
                      </button>
                    </p>
                    <h4>{t('profile.basic_information')}:</h4>
                    <br />
                    <p>
                      <span hidden={!this.props.uk.title}>
                        Название: <b>{this.props.uk.title}</b>
                        <br />
                      </span>
                      <span>
                        ИНН: <b>{this.props.uk.inn}</b>
                        <br />
                      </span>
                      <span>
                        Почта: <b>{this.props.uk.email}</b>
                        <br />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChange = (date) => this.setState({ date });

  onOpenModal = () => {
    this.props.onModalOpen('modalUserPhoto');
  };

  onOpenModalChangeFIO = () => {
    this.props.onModalOpen('modalChangeFIO');
  };
}

export default connect(
  (state) => ({
    app: state.app,
    // wallet: state.wallet,
    uk: state.uk.instance,
    language: state.language.lang,
    activeApartment: state.apartments.active,
  }),
  {
    onModalOpen: openModal,
    onPanelOpen: panelOpen,
    onOpenModalChangeFIO: openModal,
    onLoadUserData: loadUserData,
    onSetLanguage: setLanguage,
  },
)(withTranslation()(SettingsProfile));
