import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import store, { history } from './store';
import App from 'Components/App';

import './i18n';

import 'sanitize.css/sanitize.css';
import 'Assets/styles/app.scss';

ReactDOM.render(
  <Suspense fallback={false}>
    <Provider store={store}>
      <Router history={history}>
        <ThroughProvider>
          <App history={history} />
        </ThroughProvider>
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);
