const component = 'rooms';

export const EVENT_ROOMS_LOAD = `${component}/LOAD`;
export const EVENT_ROOM_ADD = `${component}/ROOM_ADD`;
export const EVENT_ROOM_UPDATE = `${component}/ROOM_UPDATE`;
export const EVENT_ROOM_DELETE = `${component}/ROOM_DELETE`;

export function roomsLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ROOMS_LOAD,
      event: event,
    });
  };
}

export function roomAdd(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ROOM_ADD,
      room: data,
    });
  };
}

export const roomUpdate = (title, id) => ({
  type: EVENT_ROOM_UPDATE,
  title,
  id,
});

export const roomDelete = (data) => {
  return (dispatch) => {
    // находим все сенсоры и девайсы у которых указана эта комната
    const {devices, sensors} = data.devices;
    const {
      updateSensor,
      updateDevice,
      deleteRoom,
      deleteRoomDevices,
      onClose,
    } = data;
    const roomId = data.roomId.toString();
    const sortedSensors = [];
    const sortedDevices = [];

    devices.forEach((item) => {
      if (item.room && item.room.roomId === roomId) {
        sortedDevices.push({
          id: item.deviceId,
          typeId: item.type.deviceTypeId,
          title: item.title,
          categoryId: item.category.categoryId,
        });
      }
    });
    sensors.forEach((item) => {
      if (item.room && item.room.roomId === roomId) {
        sortedSensors.push({
          id: item.sensorId,
          typeId: item.type.sensorTypeId,
          title: item.title,
        });
      }
    });

    async function processArray(flag, array, cb) {
      if (array.length > 0) {
        if (flag === 1) {
          for (const item of array) {
            await cb({
              sensor_id: JSON.stringify(item.id),
              sensor_type_id: item.typeId,
              title: item.title,
              room: {},
            });
          }
        }
        if (flag === 2) {
          for (const item of array) {
            await cb({
              device_id: JSON.stringify(item.id),
              device_type_id: item.typeId,
              category_id: item.categoryId,
              title: item.title,
              room: {},
            });
          }
        }
      }
    }

    processArray(1, sortedSensors, (data) => updateSensor(data));
    processArray(2, sortedDevices, (data) => updateDevice(data));

    deleteRoom({room_id: roomId}, () => {
      dispatch({
        type: EVENT_ROOM_DELETE,
        roomId: data.roomId,
      });
      deleteRoomDevices();
      onClose();
    });
  };
};
