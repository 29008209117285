import { Component } from 'react';
import UserService from 'Services/User';

class UserLogout extends Component {
  render() {
    UserService.logout();
    window.sendMessageToWebview({ action: 'logout' });
    window.location.pathname = '/login';

    return null;
  }
}

export default UserLogout;
