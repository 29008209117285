import JsSIP from 'jssip'
import Config, { SIP_CONFIG } from 'Config'

const socket = new JsSIP.WebSocketInterface(SIP_CONFIG.host)
let configuration = {
  ...SIP_CONFIG,
  sockets: [socket]
}
let connection = undefined

export const SipConnect = (params = {}) => {
  if (Config.NO_SIP) return null

  if (params.config) {
    configuration = {
      ...configuration,
      ...params.config
    }

    if (!~configuration.uri.indexOf('@'))
      configuration.uri = configuration.sipId + '@' + configuration.uri
  }

  connection = new JsSIP.UA(configuration)



  connection.on('connected', e => {
    if (params.actions && params.actions.onConnected)
      return params.actions.onConnected(e)

    console.log('JsSIP connected: ', e)
  })
  connection.on('disconnected', e => {
    if (params.actions && params.actions.onDisconnected)
      return params.actions.onDisconnected(e)

    console.log('JsSIP disconnected: ', e)
  })
  connection.on('newRTCSession', e => {
    if (params.actions && params.newSessionCallback)
      params.newSessionCallback(e.session)

    if (params.actions && params.actions.onNewRTCSession)
      return params.actions.onNewRTCSession(e)

    console.log('JsSIP newRTCSession: ', e.response)
  })
  connection.on('registered', e => {
    if (params.actions && params.actions.onRegistered)
      return params.actions.onRegistered(e)

    console.log('JsSIP registered: ', e)
  })
  connection.on('unregistered', e => {
    if (params.actions && params.actions.onUnregistered)
      return params.actions.onUnregistered(e)

    console.log('JsSIP unregistered: ', e)
  })
  connection.on('registrationFailed', e => {
    if (params.actions && params.actions.onRegistrationFailed)
      return params.actions.onRegistrationFailed(e)

    console.log('JsSIP registrationFailed: ', e)
  })

  connection.start()

  return connection
}
