import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import ApiService from 'Services/Api';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Link } from 'react-router-dom';
import Pagination, { getParams } from 'Components/Pagination';
import moment from 'moment';
import 'moment/locale/ru';
import { IconArrow, IconTile, IconEye } from 'Components/Icons';

export class News extends BaseComponent {
  className = 'news';
  state = { news: [], paging: {} };

  fetchData(params = {}) {
    ApiService.getNews(params).then((res) => {
      return this.onPageLoad(res);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchData(getParams());
  }

  onPageLoad = (res) => {
    if (!res.newsList) return;

    this.setStateSafe({ news: res.newsList, paging: res.page });
  };

  cropText = (text) => {
    if (text.length > 24 && document.documentElement.clientWidth < 375)
      return text.slice(0, 24) + '...';
    if (text.length > 37 && document.documentElement.clientWidth < 560)
      return text.slice(0, 37) + '...';
    return text;
  };

  render() {
    if (this.state.news.length === 0) return this.getLoader();

    return (
      <div className={this.getPageClasses()}>
        {/* <div className="submenu-navigation text-center">
            <span className="submenu-navigation__item active">Новости дома</span>
            <Link
                to="#"
                className="submenu-navigation__item">
                Популярные
            </Link>
            <Link to="#" className="submenu-navigation__item">
                Все новости
            </Link>
        </div> */}

        <ul className='tile-items'>
          {this.state.news.map((newsItem, i) => {
            return (
              <li className='tile-items__wrap one-col' key={i}>
                <Link
                  className='tile-items__item '
                  to={`/news/${newsItem.newsId}`}
                >
                  <div className='tile-items__image'>
                    <img src={newsItem.previewImg} alt={newsItem.title} />
                  </div>
                  <div className='tile-items__txt'>
                    <h4 className='tile-items__title'>
                      {this.cropText(newsItem.title)}
                    </h4>
                    <div className='tile-items__date'>
                      {moment(newsItem.createdTs).format('DD.MM.YYYY')}
                    </div>
                    <div className='tile-items__icon-eye color-primary'>
                      {IconEye}
                    </div>
                  </div>
                  {/* <Link to="#" className="tile-items__repost">
                    <span className="icon icon_repost dark-bg" />
                  </Link>
                  <div className="tile-items__bottom">
                    <div className="tile-items__date">
                      {moment(newsItem.createdTs.date).format('DD.MM.YYYY')}
                    </div>
                    <Link
                      to="#"
                      className={
                        'tile-items__likes' +
                        (newsItem.newsId === 2 ? ' mark' : '')
                      }>
                      15
                      <span className="icon icon_thumb" />
                    </Link>
                  </div> */}
                </Link>
              </li>
            );
          })}
        </ul>

        {this.state.paging.pageMax ? (
          <Pagination
            totalPages={this.state.paging.pageMax}
            pageSize={this.state.paging.size}
            cb={this.fetchData.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export class NewsView extends BaseComponent {
  className = 'news-detail';
  state = {
    newsView: {},
  };

  componentDidMount() {
    const { newsId } = this.props.match.params;

    ApiService.getNewsView({ newsId: newsId }).then((res) => {
      this.setStateSafe({
        newsId: newsId,
        newsView: res,
      });
    });

    /*ApiService.getNews().then(res => {
      this.setStateSafe({
        pageCount: res.page.count
      })
    })*/
  }

  createMarkup() {
    return { __html: this.state.newsView.content };
  }

  goToPage = (newsId) => {
    ApiService.getNewsView({ newsId: newsId }).then((res) => {
      this.setStateSafe({
        newsId: newsId,
        newsView: res,
      });
    });
  };

  render() {
    if (!this.state.newsView.content) return this.getLoader();

    return (
      <div className={this.getPageClasses()}>
        {this.state.newsView.title ? (
          <div>
            <BreadcrumbsItem to={`/news/${this.state.newsId}`}>
              {this.state.newsView.title}
            </BreadcrumbsItem>
            <Link to='/news/' className='all-news'>
              {IconTile}
              <span className='text'>Все новости</span>
            </Link>
            <div className='row justify-content-center'>
              <div className='col-xxl-8'>
                <h1 className='page__title'>{this.state.newsView.title}</h1>
                <div className='page-news__date'>
                  {moment(this.state.newsView.createdTs).format('DD MMMM YYYY')}
                </div>
                <div
                  className='page-news__content'
                  dangerouslySetInnerHTML={this.createMarkup()}
                />
                <div className='page-news__nav'>
                  <div className='row'>
                    <div className='col-6 text-right'>
                      {this.state.newsView.prevId && (
                        <Link
                          to={`/news/${this.state.newsView.prevId}`}
                          className='page-news__prev text-center'
                          onClick={() =>
                            this.goToPage(this.state.newsView.prevId)
                          }
                        >
                          {IconArrow}
                          <span className='text'>Предыдущая новость</span>
                        </Link>
                      )}
                    </div>
                    <div className='col-6 text-left'>
                      {this.state.newsView.nextId && (
                        <Link
                          to={`/news/${this.state.newsView.nextId}`}
                          className='page-news__next text-center'
                          onClick={() =>
                            this.goToPage(this.state.newsView.nextId)
                          }
                        >
                          <span className='text'>Следующая новость</span>
                          {IconArrow}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
