const LOGGER_TRACE_ERROR = 'error'
const LOGGER_TRACE_WARNING = 'warning'
const LOGGER_TRACE_SUCCESS = 'success'

const Logger = {
  log: (message, type, category) => {
    let _msg = message

    if (category) {
      _msg = `${category}: \n ${message}`
    }
    switch (type) {
      case LOGGER_TRACE_ERROR:
        console.error(_msg)
        break
      case LOGGER_TRACE_WARNING:
        console.warn(_msg)
        break
      case LOGGER_TRACE_SUCCESS:
        console.log(`%c ${_msg}`, 'color: green')
        break
      default:
        console.log(_msg)
    }
    console.log(_msg)
  }
}

export default Logger
