import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import ControlService from 'Services/Control';
import { NoVideoSignal } from 'Components/Icons';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

class VideoCamera extends BaseComponent {
  state = {
    isError: false,
    isOpen: false,
  };

  setError = () => {
    this.setState({ isError: true });
  };

  componentDidUpdate(prevProps) {
    if (this.props.camera !== prevProps.camera) {
      this.setState({ isError: false });
    }
  }

  _executeIntercom(intercomId) {
    ControlService.executeIntercom({ intercomId });
  }

  render() {
    const { t } = this.props;

    return (
      <div className='video-player'>
        {this.props.camera.url && !this.state.isError ? (
          <>
            {this.props.showTitle ? (
              <div className='video-player__title'>
                {this.props.camera.title}
              </div>
            ) : null}

            <div className='video-player__content'>
              <ReactPlayer
                url={this.props.camera.url}
                controls
                playing
                onError={(err, data) => {
                  if (data && data.fatal) {
                    this.setError();
                  }
                }}
                muted
                width='100%'
                height='100%'
                playsinline
                config={{
                  file: {
                    forceHLS: Hls.isSupported(),
                  },
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`video-player__content ${
                this.props.camera.cameraId ? 'no-signal' : 'no-camera'
              }`}
            >
              <div className='video-player__text'>
                {this.props.camera.cameraId
                  ? t('video_camera.no_signal')
                  : t('video_camera.no_camera')}
              </div>
              {!this.props.camera.cameraId && NoVideoSignal}
              <video ref={this.videoRef} />
            </div>
          </>
        )}
        {this.props.camera.buttons &&
          this.props.camera.buttons.map((item, index) => {
            return (
              <button
                className={`btn btn_primary btn_entry ${
                  this.state.isOpen ? ' btn_success' : ''
                }`}
                key={index}
                onClick={() => {
                  item.intercomId && this._executeIntercom(item.intercomId);
                  this.setState({ isOpen: true });
                  setTimeout(() => this.setState({ isOpen: false }), 3000);
                }}
              >
                <span className='icon icon_entry' />
                <span className='text'>{t('commons.buttons.open_door')}</span>
              </button>
            );
          })}
      </div>
    );
  }
}

export default VideoCamera;
