const component = 'Account';

export const EVENT_ACCOUNTS_LOAD = `${component}/LOAD`;
export const EVENT_ACCOUNT_CREATE = `${component}/CREATE`;
export const EVENT_ACCOUNT_UPDATE = `${component}/UPDATE`;
export const EVENT_ACCOUNT_DELETE = `${component}/DELETE`;

export function accountsLoad(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ACCOUNTS_LOAD,
      data: data
    });
  };
}

export function accountCreate(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ACCOUNT_CREATE,
      data: data
    });
  };
}

export function accountUpdate(accountId, data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ACCOUNT_UPDATE,
      accountId: accountId,
      data: data
    });
  };
}

export function accountDelete(accountId) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ACCOUNT_DELETE,
      accountId: accountId
    });
  };
}