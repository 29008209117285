import React, { createRef } from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import { isEmptyObj } from 'Services/Utils';
import SwipeableTabs from 'react-swipeable-tabs';
import CountersMeter from 'Widgets/CountersMeter';
import { COUNTER_TYPE_ELECTRICITY } from 'Config/devices';

// import ReceiptContainer from 'Containers/control/meter/receiptContainer'
import ValuesHistoryContainer from 'Containers/control/meter/common/ValueHistory';
import { counterUpdate } from 'Actions/counters';
import { withTranslation } from 'react-i18next';
import Calculation from 'Containers/control/meter/common/Calculation';

const getCounters = (data) => {
  let counters = [];

  Object.keys(data).map((groupId) => {
    const group = data[groupId];

    if (isEmptyObj(group)) return null;

    return Object.keys(group).map((counterId) => {
      const counter = group[counterId];

      if (isEmptyObj(counter)) return null;

      counter.group = groupId;

      counters.push(counter);
      return counter;
    });
  });
  return counters || [];
};

class MeterElectricity extends BaseComponent {
  className = 'electricity';
  container = createRef();
  state = {
    data: null,
    isLoading: false,
    inProgress: false,
    error: null,
    activeItemIndex: 0,
    items: null,
  };

  fetchData = () => {
    this.setState({
      data: getCounters({
        [COUNTER_TYPE_ELECTRICITY]:
          this.props.counters[COUNTER_TYPE_ELECTRICITY],
      }),
    });
  };

  componentDidMount() {
    super.componentDidMount();

    this.fetchData();

    if (this.props.counters[COUNTER_TYPE_ELECTRICITY].length > 0) {
      this.setState({
        items: [
          <div key={'Счетчики'}>{this.props.t('meters.counters')}</div>,
          <div key={'История'}>{this.props.t('meters.history_readings')}</div>,
          <div key={'Расчет'}>{this.props.t('meters.calculation')}</div>,
        ],
      });
    } else {
      this.setState({
        data: [],
        items: [<div key={'Счетчики'}>{this.props.t('meters.counters')}</div>],
      });
    }
  }

  render() {
    const { data, isLoading } = this.state;

    if (isLoading || !data) {
      return this.getLoader();
    }

    return (
      <div className='pane-block'>
        <div className='react-tabs react-tabs_swipe'>
          <div className='react-tabs__nav'>
            <SwipeableTabs
              noFirstLeftPadding={false}
              noLastRightPadding={false}
              fitItems={false}
              alignCenter={false}
              borderWidthRatio={1}
              activeItemIndex={this.state.activeItemIndex}
              onItemClick={(item, index) =>
                this.setState({ activeItemIndex: index })
              }
              items={this.state.items}
              itemClassName='react-tabs__tab'
              borderPosition='bottom'
              borderThickness={4}
              borderColor='transparent'
              activeStyle={{
                color: '#383b44',
              }}
            />
          </div>
          <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 0 ? ' active' : '')
            }
          >
            <CountersMeter
              counters={data}
              onUpdate={this.props.onCounterUpdate}
              t={this.props.t}
            />
          </div>
          <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 1 ? ' active' : '')
            }
          >
            <ValuesHistoryContainer
              type={COUNTER_TYPE_ELECTRICITY}
              counters={data}
              apartmentId={this.props.apartmentId}
              t={this.props.t}
            />
          </div>
          <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 2 ? ' active' : '')
            }
          >
            <Calculation
              type={'electricity'}
              unit={this.props.t('meters.history.kwt')}
            />
          </div>
          {/* <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 2 ? ' active' : '')
            }>
            <ReceiptContainer />
          </div> */}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    counters: state.counters,
  }),
  {
    onCounterUpdate: counterUpdate,
  },
)(withTranslation()(MeterElectricity));
