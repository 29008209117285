import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import { openModal } from 'Actions/modal';
import { uploadAvatar } from 'Actions/app';
import { sensorNotifyToggle, deviceStatusToggle } from 'Actions/devices';
import { panelOpen } from 'Actions/panel';
import UpdateUserAccountModal from 'Modals/UpdateUserAccount';
import CreateUserAccountModal from 'Modals/CreateUserAccount';
import RoomDevicesModal from 'Modals/RoomDevices';
import UserPhotoModal from 'Modals/ChangeUserPhoto';
import CreateUserCar from 'Modals/CreateUserCar';
import { withTranslation } from 'react-i18next';
import { openDialog } from 'Actions/dialogWindow';

class Modals extends BaseComponent {
  closeModal() {
    this.props.onModalOpen(null);
  }

  defaultData() {
    return {
      data: this.props.modal.data,
      onCloseModal: this.closeModal.bind(this),
      isOpen: this.props.modal.isModalOpen,
    };
  }

  render() {
    return (
      <div>
        {this.props.modal.isModalOpen === 'modalUserPhoto' && (
          <UserPhotoModal
            {...this.defaultData()}
            app={this.props.app}
            updateAvatar={this.props.updateAvatar}
            onDialogWindow={this.props.onDialogWindow}
            t={this.props.t}
          />
        )}

        {this.props.modal.isModalOpen === 'carCreateModal' && (
          <CreateUserCar
            {...this.defaultData()}
            apartmentId={this.props.apartmentId}
            t={this.props.t}
          />
        )}

        {this.props.modal.isModalOpen === 'userCreateModal' && (
          <CreateUserAccountModal
            {...this.defaultData()}
            apartmentId={this.props.apartmentId}
            t={this.props.t}
          />
        )}

        {this.props.modal.isModalOpen === 'modalChangeFIO' && (
          <UpdateUserAccountModal
            {...this.defaultData()}
            userData={this.props.app.user}
            t={this.props.t}
          />
        )}
        <RoomDevicesModal
          {...this.defaultData()}
          onPanelOpen={this.props.onPanelOpen}
          onNotifyToggle={this.props.onNotifyToggle}
          onStatusToggle={this.props.onStatusToggle}
          onModalClose={this.props.onModalOpen.bind(this.null)}
          t={this.props.t}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.modal,
    app: state.app,
    apartmentId: state.apartments.active.apartmentId,
  }),
  {
    onPanelOpen: panelOpen,
    onModalOpen: openModal,
    onNotifyToggle: sensorNotifyToggle,
    onStatusToggle: deviceStatusToggle,
    updateAvatar: uploadAvatar,
    onDialogWindow: openDialog,
  },
)(withTranslation()(Modals));
