import Config from 'Config';
import AuthService from 'Services/Auth';
import UserService from 'Services/User';
import { ensureValueInRange } from 'rc-slider/lib/utils';

const BASE_URL = `${Config.urls.api}/${Config.api_version}/`;

window.sendMessageToWebview = (data) => {
  !!window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
};

const request = (action, data = {}, method = 'POST') => {
  let body = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getToken(),
    },
    body: JSON.stringify(data),
  };

  return fetch(BASE_URL + action, body).then((res) => {
    if (res.ok) {
      return res.json();
    } else if (res.status === 401) {
      if (UserService.isAuth() && UserService.getUserToken()) {
        UserService.refreshToken(() => {
          window.location.reload();
        });
      }
    } else if (res.status === 400 || res.status === 403 || res.status === 500) {
      return res.json();
    }
  });
};

const fileRequest = (action, data = {}, method = 'POST') => {
  let body = {
    method: method,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: AuthService.getToken(),
    },
    body: data,
  };
  delete body.headers['Content-Type'];

  return fetch(BASE_URL + action, body).then((res) => {
    if (res.status === 401) {
      if (UserService.isAuth() && UserService.getUserToken()) {
        UserService.refreshToken(() => {
          window.location.reload();
        });
      }

      return
    }

    return res.json();
  });
};

const getRequest = (action) => {
  let body = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getToken(),
    },
  };

  return fetch(BASE_URL + action, body)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else if (res.status === 401) {
        if (UserService.isAuth() && UserService.getUserToken()) {
          UserService.refreshToken(() => {
            window.location.reload();
          });
        }
      }
    })
    .then((data) => {
      return data;
    });
};

const ApiService = {
  meter: {
    getElectricity: (endpoint = '', data = {}) => {
      return request(`${endpoint}/electricity`, data);
    },
    getWaterSupply: (endpoint = '', data = {}) => {
      return request(`${endpoint}/water`, data);
    },
    getHeating: (endpoint = '', data = {}) => {
      return request(`${endpoint}/heating`, data);
    },
    getHistory: (endpoint = '', data = {}) => {
      return request(`${endpoint}/history/${data.type}`, data);
    },
  },

  uk: {
    addIssue: (data) => {
      const action = 'user/create/issue';

      return fileRequest(action, data, 'POST');
    },
    addUk: (data) => {
      const action = `user/invite-company`;

      return request(action, data);
    },
  },

  getNews(data) {
    const action = 'news/index';
    return request(action, data);
  },

  getNewsView(data) {
    const action = `news/view/${data.newsId}`;

    return request(action);
  },

  getAccounts() {
    const action = 'user/accounts';

    return getRequest(action);
  },

  getUserData() {
    const action = 'user/index';

    return getRequest(action);
  },

  getUserAvatar(avatars) {
    const v = Math.floor(Math.random() * 1000000000000001);
    if (!avatars) return null;

    let avatar = null;

    if (avatars.length > 1) {
      avatar = avatars.sort(
        (a, b) => Number(b.imagesFilesId) - Number(a.imagesFilesId),
      )[0];
    } else if (avatars.length === 1) {
      avatar = avatars[0];
    }

    return avatar
      ? `https://api.boxt.one/uploads/${avatar.file.path}?v=${v}`
      : null;
  },

  getUserSettings() {
    const action = 'user/settings';

    return getRequest(action);
  },

  updateUserSettings(data) {
    const action = 'user/settings/update';

    return request(action, data);
  },

  getUserWallet(data) {
    const action = 'wallet/index';

    return request(action, data);
  },

  getUserInvoices(data) {
    const action = 'wallet/history';

    return request(action, data);
  },

  getUserServices(data) {
    const action = 'amenities/list';

    return request(action, data);
  },

  updateUserServices(data) {
    const action = 'amenities/switch';

    return request(action, data);
  },

  createUser(data) {
    const action = `user/create`;

    return request(action, data);
  },

  updateUserData(data) {
    const action = `user/update/${data.id}`;

    delete data.id;

    return request(action, data);
  },

  deleteUserAccount(userId) {
    const action = `user/delete/${userId}`;

    return request(action, {}, 'DELETE');
  },

  getHomeRooms() {
    const action = `room/index`;

    return request(action);
  },

  getControlScenarios(data) {
    const action = 'scenario/index';

    return request(action, data);
  },

  executeControlScenarios(data) {
    const action = 'scenario/execute';

    return request(action, data);
  },

  getDevices(data) {
    const action = 'device/index';

    return request(action, data);
  },

  executeDevice(data) {
    const action = 'device/execute';

    return request(action, data);
  },

  executeDeviceCategory(data) {
    const action = 'device/execute/category';

    return request(action, data);
  },

  switchSensor(data) {
    const action = 'sensor/switch';

    return request(action, data);
  },

  getSensors(data) {
    const action = 'sensor/index';

    return request(action, { ...data, page: 2 });
  },

  getIntercom(data) {
    const action = 'intercom/index';

    return request(action, data);
  },

  getIntercomConnect(data) {
    const action = 'intercom/connect';

    return request(action, data);
  },

  executeIntercom(data) {
    const action = 'intercom/unlock';

    return request(action, data);
  },

  updateUserAvatar(data = {}) {
    const action = 'user/avatar/upload';

    return fileRequest(action, data, 'POST');
  },
  sendToken() {
    const action = 'user/activate';

    return getRequest(action);
  },
  getDeviceCategories(data = {}) {
    const action = 'types/index';

    return request(action, data);
  },
  getCategoriesTypes(data = {}) {
    const action = 'types/category';

    return getRequest(action, data);
  },
  getDevicesTypes(data = {}) {
    const action = 'types/device';

    return getRequest(action, data);
  },
  getSensorsTypes(data = {}) {
    const action = 'types/sensor';

    return getRequest(action, data);
  },
  getUserNotifications(data) {
    const action = 'notify/list';

    return request(action, data);
  },
  getUserNotificationsTypes() {
    const action = 'notify/types';

    return request(action);
  },
  getUserAgreements() {
    const action = 'user/agreements';

    return getRequest(action);
  },
  addUserNotification(data) {
    const action = 'notify/create';

    return request(action, data);
  },
  readUserNotification(data = '') {
    const action = 'notify/readed/' + data;

    return request(action);
  },
  addControllerZWave(data) {
    const action = 'smarthome/z-wave';

    return request(action, data);
  },
  removeControllerZWave(data) {
    const action = 'smarthome/delete-z-wave';

    return request(action, data);
  },
  controllerExecuteCommand(data) {
    const action = 'smarthome/execute';

    return request(action, data);
  },
  getControllers(data) {
    const action = 'smarthome/index';

    return request(action, data);
  },
  createRoom(data) {
    const action = 'room/create';

    return request(action, data);
  },
  updateRoom(data) {
    const action = 'room/update';

    return request(action, data);
  },
  deleteRoom(data) {
    const action = 'room/delete';

    return request(action, data);
  },
  updateDeviceInfo(data) {
    const action = 'device/update';

    return request(action, data, 'PUT');
  },
  updateSensorInfo(data) {
    const action = 'sensor/update';

    return request(action, data, 'PUT');
  },
  addCounter(data) {
    const action = 'counters/create';

    return request(action, data, 'POST');
  },
  addCounterValue(data) {
    const action = 'counters/add-value';

    return request(action, data, 'POST');
  },
  getCounterReceipt(type, data) {
    const action = `counters/receipt/${type}`;

    return request(action, data, 'POST');
  },
  getVideoCameras(data) {
    const action = 'camera/index';

    return request(action, data, 'POST');
  },
  getCars(data) {
    const action = 'car-number/index';

    return request(action, data, 'POST');
  },
  createCar(data) {
    const action = 'car-number/create';

    return request(action, data, 'POST');
  },
  deleteCar(data) {
    const action = 'car-number/delete';

    return request(action, data, 'POST');
  },
  addFeedback(data) {
    const action = 'user/add-feedback';

    return request(action, data, 'POST');
  },
  skipFeedback(data = {}) {
    const action = 'user/skip-feedback';

    return request(action, data, 'POST');
  },
  getFeedback() {
    const action = 'user/get-feedback';

    return getRequest(action);
  },
  getVersion(data = {}) {
    const action = 'user/get-version';

    return request(action, data, 'POST');
  },
  inviteUser(data = {}) {
    const action = 'user/invite-users';

    return request(action, data, 'POST');
  },

  getAvailableDevices(data = {}) {
    const action = 'user/counts';

    return request(action, data, 'POST');
  },

  getApartments(data = {}) {
    const action = 'apartment/index';

    return request(action, data, 'POST');
  },
  getPromo() {
    const action = 'user/promo';

    return getRequest(action);
  },
  updatePromo(value) {
    const action = 'user/promo';

    return request(action, { promo: value }, 'POST');
  },
};

window.sendMessageToWebview({ action: 'setUrl', payload: BASE_URL });

export default ApiService;
