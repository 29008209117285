import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  DEVICE_CATEGORY_CLIMATE,
  // DEVICE_CATEGORY_CLIMATE,
  DEVICE_CATEGORY_LIGHT,
  DEVICE_CATEGORY_ROSETTE,
} from 'Config/devices';
import ConnectedTileItem from 'Components/TileItem';
import UnknownDeviceWidget from 'Widgets/UnknownDevice';
import { IFilters } from 'Reducers/filters/filters';
import FilterDevice from 'Components/FilterDevice';
import { IItem } from 'Containers/control/Interfaces/interfaces';
import DeviceRadiatorWidget from 'Widgets/DeviceRadiator';
import ControlService from 'Services/Control';
import { devicesLoad } from 'Actions/devices';
import { IApartment } from 'Actions/apartments';

class Device extends BaseComponent {
  className = 'sensor';
  state = {
    items: [],
    isLoading: true,
  };

  prepareData = (items: {
    filter: (arg0: { (item: any): boolean; (item: any): any }) => never[];
    forEach: (arg0: (item: any) => void) => void;
  }) => {
    const filters = this.getFiltersData(
      this.props.filters,
      this.props.location.pathname,
    );
    const { statusFilter, roomFilter } = filters;

    if (statusFilter !== null) {
      // для девайсов света и розеток отдельные страницы
      const deviceItems = items.filter(
        (item: { category: { name: string } }) =>
          item.category.name !== DEVICE_CATEGORY_LIGHT &&
          item.category.name !== DEVICE_CATEGORY_ROSETTE,
      );
      let filteredItems: IItem[] = [];

      if (roomFilter !== null) {
        filteredItems = items.filter((item) => {
          if (
            item.room &&
            item.room.title === roomFilter &&
            item.status === statusFilter
          )
            return item;
          return false;
        });
      } else {
        filteredItems = deviceItems.filter((item: { status: number }) => {
          if (statusFilter === item.status) return item;
          return false;
        });
      }

      if (filteredItems.length > 0) {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={this.props.t('devices.title')} />
            <ul className='tile-items'>
              {filteredItems.map((item) => {
                return (
                  <ConnectedTileItem key={item.deviceId} className='led'>
                    {/*<UnknownDeviceWidget {...item} />*/}
                    {item.category.name !== DEVICE_CATEGORY_CLIMATE ? (
                      <UnknownDeviceWidget {...item} />
                    ) : (
                      <DeviceRadiatorWidget {...item} />
                    )}
                  </ConnectedTileItem>
                );
              })}
            </ul>
          </div>
        );
      } else {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={this.props.t('devices.title')} />
          </div>
        );
      }
    } else {
      const categories: any = [];

      this.props.categories.forEach(
        (category: { categoryId: string | number }) => {
          categories[category.categoryId] = {
            ...category,
            items: [],
          };
        },
      );

      items.forEach((item: { category: any }) => {
        const category = item.category ? item.category : { categoryId: 0 };

        if (
          category.name !== DEVICE_CATEGORY_LIGHT &&
          category.name !== DEVICE_CATEGORY_ROSETTE
        ) {
          categories[category.categoryId].items.push(item);
        }
      });

      return (
        <div className={this.getPageClasses()}>
          <FilterDevice title={this.props.t('devices.title')} />
          <ul className='tile-items'>
            {Object.keys(categories).map((i) => {
              const category = categories[i];

              if (category.items && category.items.length > 0) {
                return Object.keys(category.items).map((itemId) => {
                  const item = category.items[itemId];
                  return (
                    <ConnectedTileItem key={item.deviceId} className='led'>
                      {/*<UnknownDeviceWidget {...item} />*/}
                      {item.category.name !== DEVICE_CATEGORY_CLIMATE ? (
                        <UnknownDeviceWidget {...item} />
                      ) : (
                        <DeviceRadiatorWidget {...item} />
                      )}
                    </ConnectedTileItem>
                  );
                });
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      );
    }
  };

  componentDidMount() {
    super.componentDidMount();

    ControlService.getDevices(
      { apartmentId: this.props.apartmentId },
      (res) => {
        this.props.onDevicesLoad(res.deviceList, 'devices');
        this.setState({ isLoading: false });
      },
    );
  }

  render() {
    if (this.state.isLoading) return this.getLoader();

    if (
      this.props.items &&
      this.props.items.length > 0 &&
      this.props.categories.length > 0
    ) {
      return this.prepareData(this.props.items);
    }

    return (
      <div className={this.getPageClasses()}>
        <FilterDevice title={this.props.t('devices.title')} />
      </div>
    );
  }
}

const mapStateToProps = (state: {
  devices: {
    devices: unknown;
    categoriesTypes: unknown;
  };
  filters: IFilters;
  apartments: {
    active: IApartment;
  };
}) => {
  return {
    items: state.devices.devices,
    categories: state.devices.categoriesTypes,
    filters: state.filters.pages,
    apartmentId: state.apartments.active.apartmentId,
  };
};

export default connect(mapStateToProps, {
  onDevicesLoad: devicesLoad,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
})(withTranslation()(withRouter(Device)));
