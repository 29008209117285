import {
  EVENT_ROOMS_LOAD,
  EVENT_ROOM_ADD,
  EVENT_ROOM_UPDATE,
  EVENT_ROOM_DELETE,
} from 'Actions/rooms/rooms';
import { cloneDeep } from 'lodash';

const initialState = {};

export function rooms(state = initialState, action) {
  let data = {};

  switch (action.type) {
    case EVENT_ROOMS_LOAD:
      data = {
        ...state,
        ...action.event,
      };

      return data;
    case EVENT_ROOM_ADD:
      data = {
        ...state,
      };

      data[Object.keys(data).length++] = action.room;

      return data;

    case EVENT_ROOM_UPDATE: {
      const newState = Object.values(JSON.parse(JSON.stringify(state)));
      data = newState.map((item) => {
        if (item.roomId === action.id) item.title = action.title;
        return item;
      });
      return { ...data };
    }

    case EVENT_ROOM_DELETE: {
      const newState = Object.values(cloneDeep(state)).filter(
        (item) => item.roomId !== action.roomId,
      );
      return { ...newState };
    }
    default:
      return state;
  }
}
