const component = 'navigation';
const ANIMATION_DURATION = 200;

export const EVENT_TOGGLE = `${component}/TOGGLE`;
export const EVENT_TOGGLE_REQUESTED = `${component}/TOGGLE_REQUESTED`;
export const EVENT_SWITCH_GRID = `${component}/SWITCH_GRID`;

export function mainNavigationToggle(bool) {
  return (dispatch) => {
    dispatch({
      type: EVENT_TOGGLE_REQUESTED,
      isMinimized: bool
    });

    return setTimeout(() => {
      dispatch({
        type: EVENT_TOGGLE
      })
    }, ANIMATION_DURATION)
  };
}

export function switchGrid(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_SWITCH_GRID,
      data
    })
  }
}
