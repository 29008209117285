import {
  EVENT_MODAL_OPEN
} from 'Actions/modal'

const initialState = {
  isModalOpen: null,
  data: {}
}

export function modal(state = initialState, action) {
  switch (action.type) {
    case EVENT_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
        data: action.data
      }

    default:
      return state
  }
}