/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TweenLite } from 'gsap';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import Counter from 'Components/advancedSettings/common/DeviceTemperature/Counter';
import { openDialog } from 'Actions/dialogWindow';

const sliderMinX = 0;
const sliderMaxX = 200;
const distanceDegrees = Math.round(sliderMaxX / 40); // расстояние между градусами по координате Х

const coldGradient = { start: '#5C69DF', end: '#4553CF' };
const neutralGradient = { start: '#F5AE43', end: '#DF8029' };
const hotGradient = { start: '#EE4E62', end: '#CE3548' };
const temperatureGrades = [10, 20, 30, 40, 50];

interface IProps extends WithTranslation {
  rangeSliderValue: number;
  deviceId: number;
  onRangeChange: (value: number) => void;
  openDialog: (payload: {
    content: string;
    agreeText: string;
    cancelText: string;
    callback: string;
    dispatchData: {
      deviceId: number;
      value: number;
    };
  }) => void;
  icon: React.ReactSVGElement;
  WithTranslation;
  isDisabled: boolean;
}

type IState = {
  dragging: boolean;
  sliderX: number;
  gradientStart: string;
  gradientEnd: string;
};

class DeviceTemperature extends Component<IProps, IState> {
  private initialMouseX: number;
  private initialSliderX: number;

  constructor(props) {
    super(props);

    this.startDrag = this.startDrag.bind(this);
    this.startTouchDrag = this.startTouchDrag.bind(this);
    this.mouseMoving = this.mouseMoving.bind(this);
    this.touchMoving = this.touchMoving.bind(this);
    this.stopDrag = this.stopDrag.bind(this);
    this.bgStyle = this.bgStyle.bind(this);
    this.initialMouseX = 0;
    this.initialSliderX = 0;

    this.state = {
      dragging: false,
      sliderX: 0,
      gradientStart: coldGradient.start,
      gradientEnd: coldGradient.end,
    };
  }

  get gradientStart() {
    return this.state.gradientStart;
  }

  set gradientStart(value) {
    this.setState({ gradientStart: value });
  }

  get gradientEnd() {
    return this.state.gradientEnd;
  }

  set gradientEnd(value) {
    this.setState({ gradientEnd: value });
  }

  setGradientBg() {
    const temp = this.currentTemperature;
    let targetGradient = coldGradient;

    if (temp >= 21) {
      targetGradient = neutralGradient;
    }

    if (temp >= 29) {
      targetGradient = hotGradient;
    }

    // gradient changed
    TweenLite.to(this, 0.7, {
      gradientStart: targetGradient.start,
      gradientEnd: targetGradient.end,
    });
  }

  commonStartDrag(pageX) {
    this.initialMouseX = pageX;
    this.setState({ dragging: true });
    this.initialSliderX = this.state.sliderX;
  }

  startDrag(e) {
    const pageX = e.pageX;
    this.commonStartDrag(pageX);
  }

  startTouchDrag(e) {
    e.preventDefault();
    const pageX = e.changedTouches[0].pageX;
    this.commonStartDrag(pageX);
  }

  stopDrag() {
    this.setState({ dragging: false });
    // if (this.state.sliderX === 0) {
    //   this.props.openDialog({
    //     content: this.props.t('dialog_window.temperature.content'),
    //     agreeText: this.props.t('dialog_window.temperature.agree'),
    //     cancelText: this.props.t('dialog_window.temperature.cancel'),
    //     callback: 'deviceOff',
    //     dispatchData: {
    //       deviceId: this.props.deviceId,
    //       value: 10,
    //     },
    //   });
    // } else {
    this.props.onRangeChange(this.currentTemperature);
    // }
  }

  commonMoving(pageX) {
    if (this.state.dragging) {
      const dragAmount = pageX - this.initialMouseX;
      const targetX = this.initialSliderX + dragAmount;

      // keep slider inside limits
      const sliderX = Math.max(Math.min(targetX, sliderMaxX), sliderMinX);

      this.setState({ sliderX: sliderX });
      this.setGradientBg();
    }
  }

  mouseMoving(e) {
    const pageX = e.pageX;
    this.commonMoving(pageX);
  }

  touchMoving(e) {
    e.preventDefault();
    if (!this.props.isDisabled) {
      const pageX = e.changedTouches[0].pageX;
      this.commonMoving(pageX);
    }
  }

  get currentTemperature() {
    const tempRangeStart = 10;
    const tempRange = 40; // from 10 - 50

    return (this.state.sliderX / sliderMaxX) * tempRange + tempRangeStart;
  }

  tempElementStyle(temp) {
    const nearDistance = 3;
    const liftDistance = 12;

    // lifts up the element when the current DeviceTemperature is near it
    const diff = Math.abs(this.currentTemperature - temp);
    const distY = diff / nearDistance - 1;

    // constrain the distance so that the element doesn't go to the bottom
    const elementY = Math.min(distY * liftDistance, 0);

    return { top: `${elementY}px` };
  }

  bgStyle() {
    if (this.props.isDisabled)
      return {
        background: '#afb1b4',
      };

    return {
      background:
        'linear-gradient(to bottom right,' +
        `${this.gradientStart}, ${this.gradientEnd})`,
    };
  }

  componentDidMount() {
    this.setState(
      {
        sliderX: this.props.rangeSliderValue * distanceDegrees - 50,
      },
      () => {
        this.setGradientBg();
      },
    );
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={`${styles.upperContainer} `} style={this.bgStyle()}>
          <div className={styles.header}>
            <div className={styles.iconCategory}>{this.props.icon}</div>
            <div className={styles.temperatureContainer}>
              <Counter currValue={Math.round(this.currentTemperature)} />
            </div>
          </div>

          <div className={styles.temperatureGraduation}>
            {temperatureGrades.map((temp, i) => (
              <div
                className={styles.temperatureElement}
                style={this.tempElementStyle(temp)}
                key={i}
              >
                <span className={styles.temperatureElementNumber}>{temp}</span>
                <br />
                <span className={styles.temperatureElementLine}>|</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.lowerContainer}>
          <div
            className={
              `${styles.sliderContainer} ` +
              (this.state.dragging ? styles.grabbing : '')
            }
            onMouseMove={this.mouseMoving}
            onTouchMove={this.touchMoving}
            onMouseUp={this.stopDrag}
            onTouchEnd={this.stopDrag}
            style={{ left: this.state.sliderX }}
          >
            <svg
              width='140'
              height='30'
              viewBox='0 0 150 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M74.3132 0C47.0043 2.44032e-05 50.175 30
                7.9179 30H144.27C99.4571 30 101.622 -2.44032e-05 74.3132 0Z'
                transform='translate(-7.38794 0.5)'
                fill='#fff'
              />
            </svg>

            <div
              className={
                `${styles.sliderButton} ` +
                (this.state.dragging ? styles.grabbing : '')
              }
              style={this.bgStyle()}
              onMouseDown={this.startDrag}
              onTouchStart={this.startTouchDrag}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  openDialog,
  //@ts-ignore
})(withTranslation()(DeviceTemperature));
