const component = 'sip';

// default events
export const EVENT_CONNECTED = `${component}/CONNECTED`;
export const EVENT_DISCONNECTED = `${component}/DISCONNECTED`;
export const EVENT_NEW_SESSION = `${component}/NEW_SESSION`;
export const EVENT_REGISTERED = `${component}/REGISTERED`;
export const EVENT_UNREGISTERED = `${component}/UNREGISTERED`;
export const EVENT_REG_FAILED = `${component}/REG_FAILED`;

// custom events
export const EVENT_SIP_LOAD = `${component}/LOAD`;
export const EVENT_ANSWER = `${component}/CLIENT_ANSWER`;
export const EVENT_DECLINE = `${component}/CLIENT_DECLINE`;
export const EVENT_VIDEO = `${component}/CLIENT_VIDEO`;
export const EVENT_HOLD = `${component}/CLIENT_HOLD`;

export function onConnected(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CONNECTED,
      event: event
    });
  };
}
export function onDisconnected(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DISCONNECTED,
      event: event
    });
  };
}
export function onNewRTCSession(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_NEW_SESSION,
      event: event
    });
  };
}
export function onAnswer(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ANSWER,
      event: event
    });
  };
}
export function onDecline(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DECLINE,
      event: event
    });
  };
}
export function onVideo(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_VIDEO,
      event: event
    });
  };
}
export function onHold(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_HOLD,
      event: event
    });
  };
}
export function onRegistered(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_REGISTERED,
      event: event
    });
  };
}
export function onUnregistered(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_UNREGISTERED,
      event: event
    });
  };
}
export function onRegistrationFailed(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_REG_FAILED,
      event: event
    });
  };
}