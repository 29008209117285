import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import SwipeableTabs from 'react-swipeable-tabs';
import CountersMeter from 'Widgets/CountersMeter';
import {
  COUNTER_TYPE_WATER,
  COUNTER_TYPE_WATER_HOT,
  COUNTER_TYPE_WATER_COLD,
} from 'Config/devices';
import ValuesHistoryContainer from 'Containers/control/meter/common/ValueHistory';
import { counterUpdate } from 'Actions/counters';
import { withTranslation } from 'react-i18next';
import Calculation from 'Containers/control/meter/common/Calculation';

class MeterWaterSupply extends BaseComponent {
  className = 'water-supply';
  state = {
    data: null,
    error: null,
    activeItemIndex: 0,
    items: null,
  };

  componentDidMount() {
    super.componentDidMount();

    const { counters } = this.props;
    if (
      counters[COUNTER_TYPE_WATER_HOT].length > 0 ||
      counters[COUNTER_TYPE_WATER_COLD].length > 0
    ) {
      const hot = counters[COUNTER_TYPE_WATER_HOT] || [];
      const cold = counters[COUNTER_TYPE_WATER_COLD] || [];

      this.setState({
        data: hot.concat(cold),
        items: [
          <div key={'Счетчики'}>{this.props.t('meters.counters')}</div>,
          <div key={'История'}>{this.props.t('meters.history_readings')}</div>,
          <div key={'Расчет'}>{this.props.t('meters.calculation')}</div>,
        ],
      });
    } else {
      this.setState({
        data: [],
        items: [<div key={'Счетчики'}>{this.props.t('meters.counters')}</div>],
      });
    }
  }

  render() {
    const { data } = this.state;
    if (data === null) {
      return this.getLoader();
    }

    return (
      <div className='pane-block'>
        <div className='react-tabs react-tabs_swipe'>
          <div className='react-tabs__nav'>
            <SwipeableTabs
              noFirstLeftPadding={false}
              noLastRightPadding={false}
              fitItems={false}
              alignCenter={false}
              borderWidthRatio={1}
              activeItemIndex={this.state.activeItemIndex}
              onItemClick={(item, index) =>
                this.setState({ activeItemIndex: index })
              }
              items={this.state.items}
              itemClassName='react-tabs__tab'
              borderPosition='bottom'
              borderThickness={4}
              borderColor='transparent'
              activeStyle={{
                color: '#383b44',
              }}
            />
          </div>
          <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 0 ? ' active' : '')
            }
          >
            <CountersMeter
              counters={data}
              onUpdate={this.props.onCounterUpdate}
              t={this.props.t}
            />
          </div>
          <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 1 ? ' active' : '')
            }
          >
            <ValuesHistoryContainer
              type={COUNTER_TYPE_WATER}
              counters={data}
              apartmentId={this.props.apartmentId}
              t={this.props.t}
            />
          </div>
          <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 2 ? ' active' : '')
            }
          >
            <Calculation
              type={'water'}
              unit={this.props.t('meters.history.m3')}
            />
          </div>
          {/* <div
            className={
              'react-tabs__tab-panel' +
              (this.state.activeItemIndex === 2 ? ' active' : '')
            }>
            <ReceiptContainer />
          </div> */}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    counters: state.counters,
  }),
  {
    onCounterUpdate: counterUpdate,
  },
)(withTranslation()(MeterWaterSupply));
