import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import DeviceScenarioWidget from 'Widgets/DeviceScenario';
import ConnectedTileItem from 'Components/TileItem';
import ScenarioPanel from 'Components/ScenarioPanel';
import ControlService from 'Services/Control';
import { scenariosLoad } from 'Actions/scenarios';
import FilterDevice from 'Components/FilterDevice';

class Scenario extends BaseComponent {
  className = 'scenario';
  state = {
    items: [],
    scenarioPanelOpened: false,
    isLoading: true,
  };

  onAddScenarioPanelToggle = () => {
    this.setState({
      scenarioPanelOpened: !this.state.scenarioPanelOpened,
    });
  };

  // getRoomsOptions() {
  //   const options = Object.keys(this.props.rooms).map((i) => {
  //     return {
  //       value: i,
  //       label: this.props.rooms[i].title,
  //     };
  //   });
  //   return options;
  // }

  getScenario = (scenarioId) => {
    let list = [];
    Object.keys(this.props.scenarios).map((itemId) => {
      const item = this.props.scenarios[itemId];
      if (item.scenarioId === scenarioId) {
        list = item;
      }
      return null;
    });
    return list;
  };

  prepareData = (items) => {
    const { t } = this.props;
    const filters = this.getFiltersData(
      this.props.filters,
      this.props.location.pathname,
    );
    const { statusFilter } = filters;

    if (statusFilter !== null) {
      let filteredItems = [];

      filteredItems = items.filter((item) => {
        if (statusFilter === item.status) return item;
        return false;
      });

      if (filteredItems.length > 0) {
        return (
          <div className={this.getPageClasses()}>
            <p>qweqwe</p>
            <FilterDevice title={t('scripts.title')} />
            <ScenarioPanel
              isOpened={this.state.scenarioPanelOpened}
              onClose={this.onAddScenarioPanelToggle.bind(this)}
              // rooms={this.getRoomsOptions()}
              currentScenario={this.getScenario(this.state.scenarioId)}
              title={this.state.scenarioPanelTitle}
            />
            <ul className='tile-items'>
              {filteredItems.map((item) => {
                return (
                  <ConnectedTileItem key={item.scenarioId} className='led'>
                    <DeviceScenarioWidget {...item} />
                  </ConnectedTileItem>
                );
              })}
            </ul>
          </div>
        );
      } else {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={t('scripts.title')} />
          </div>
        );
      }
    } else {
      return (
        <div className={this.getPageClasses()}>
          <FilterDevice title={t('scripts.title')} />
          <ScenarioPanel
            isOpened={this.state.scenarioPanelOpened}
            onClose={this.onAddScenarioPanelToggle.bind(this)}
            // rooms={this.getRoomsOptions()}
            currentScenario={this.getScenario(this.state.scenarioId)}
            title={this.state.scenarioPanelTitle}
          />
          {/* <button
              className="add-scenario btn btn_success btn_big add-btn"
              onClick={this.onAddScenarioPanelToggle.bind(this)}
          >
            <span>Создать сценарий</span>
            <span className="btn btn_circle">+</span>
          </button> */}
          <ul className='tile-items'>
            {Object.keys(this.props.scenarios).map((itemId) => {
              return (
                <ConnectedTileItem key={itemId} className='led'>
                  <DeviceScenarioWidget {...this.props.scenarios[itemId]} />
                </ConnectedTileItem>
              );
            })}
          </ul>
        </div>
      );
    }
  };

  componentDidMount() {
    super.componentDidMount();

    ControlService.getScenarios(
      { apartmentId: this.props.apartmentId },
      (res) => {
        this.props.onScenariosLoad(res.scenarioList);
        this.setState({ isLoading: false });
      },
    );
  }

  render() {
    if (this.state.isLoading) return this.getLoader();

    const items = Object.values(this.props.scenarios);

    if (items.length > 0) {
      return this.prepareData(items);
    }
    return (
      <div className={this.getPageClasses()}>
        <FilterDevice title={this.props.t('scripts.title')} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scenarios: state.scenarios,
    rooms: state.rooms,
    filters: state.filters.pages,
    apartmentId: state.apartments.active.apartmentId,
  };
};

export default connect(mapStateToProps, {
  onScenariosLoad: scenariosLoad,
})(withTranslation()(Scenario));
