import React from 'react';
import { connect } from 'react-redux';
import Form from 'Components/Form';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import { panelClose } from 'Actions/panel';
import ControllerService from 'Services/Controller';
import Preloader from 'Components/Preloader';
import { removeController } from 'Actions/controllers';

class AddZwave extends BaseSettingPanel {
  state = {
    isLoading: false,
  };
  title = this.props.t('panels.add_zwave.title');

  componentDidMount() {
    super.componentDidMount();

    // разобраться, нахуя я это писал
    const removeControllers = async () => {
      let isErr = false;
      const unactivatedControllers = this.props.controllers.filter(
        (item) => !item.zwaveStatus,
      );

      if (unactivatedControllers.length > 0) {
        await this.setState({ isLoading: true });
        for (const item of unactivatedControllers) {
          await ControllerService.removeController({
            smarthome_id: item.smarthomeId,
          }).then((res) => {
            if (res.ok) {
              this.props.removeController(item);
            } else {
              isErr = true;
            }
          });
        }

        if (!isErr) {
          await this.setState({ isLoading: false });
        }
      }
    };
  }

  renderForm() {
    let action = ControllerService.addZWave;

    const formParams = {
      action: action,
      className: 'form_components-edit',
      fields: {
        title: {
          id: 'form_components-edit__device-title',
          type: 'text',
          label: `${this.props.t('panels.add_zwave.enter_new_name')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
          focus: true,
        },
      },
      btnSubmit: this.props.t('commons.buttons.confirm'),
      btnClass: 'btn_primary btn_full-width',
      formatBeforeSend: (data) => {
        return {
          title: data.title,
          apartment_id: this.props.apartment_id,
        };
      },
      callback: (res) => {
        if (res && res.ok) {
          window.sendMessageToWebview({
            action: 'openBrowser',
            url: `https://z-wave.me/oauth2/?state=${res.smarthomeId}&redirect_uri=http://oauth.boxt.one/z-wave&response_type=code&client_id=2G6JORVLDU4hfGDbhpmD4TtirHAyio33znrizICh`,
          });

          // this.props.onClose();
        }
      },
    };

    return <Form {...formParams} />;
  }

  content() {
    if (!this.state.isLoading) return this.renderForm.call(this);
    return <Preloader />;
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current === 'AddZwave',
    apartment_id: state.apartments.active.apartmentId,
    controllers: state.controllers.list,
  }),
  {
    onClose: panelClose,
    removeController: removeController,
  },
)(AddZwave);
