import React from 'react';

import styles from '../styles.module.scss';

const elementHeight = 72;

const discharges = [
  {
    first: '1',
    second: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    first: '2',
    second: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    first: '3',
    second: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    first: '4',
    second: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    first: '5',
    second: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
];

const styleFirstDischarges = (firstDischarges) => ({
  transform: `translateY(${-firstDischarges * elementHeight +
    elementHeight +
    'px'})`,
});

const styleSecondDischarges = (currValue) => ({
  transform: `translateY(${-currValue * elementHeight + 720 + 'px'})`,
});

const Counter = ({ currValue }: { currValue: number }) => {
  const stringNumber: string = currValue.toString();

  const firstDischarges = Number(stringNumber[0]);

  return (
    <div
      className={
        `${styles.temperatureContainer} ` + styles.temperatureSubContainer
      }
    >
      <div
        className={styles.factionValue}
        style={styleFirstDischarges(firstDischarges)}
      >
        {discharges.map((item) => {
          return (
            <span key={item.first} className={styles.temperatureText}>
              {item.first}
            </span>
          );
        })}
      </div>

      <div
        className={styles.factionValue}
        style={styleSecondDischarges(currValue)}
      >
        {discharges.map((item) => {
          return item.second.map((i) => (
            <span key={i} className={styles.temperatureText}>
              {i}
            </span>
          ));
        })}
      </div>
      <span className={styles.iconCelsius}>°C</span>
    </div>
  );
};

export default Counter;
