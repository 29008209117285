import Config, {
  COOKIE_APPLICATION_TOKEN,
  COOKIE_APPLICATION_TOKEN_EXP,
} from 'Config/index';
import CookieService from 'Services/Cookie';
import Logger from 'Components/Logger';

const request = (action, data = {}) => {
  let url = `${Config.urls.auth}/${action}`;
  let body = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetch(url, body).then((res) => {
    return res.json();
  });
};

const AuthService = {
  activatedCode(data = {}) {
    const action = 'activate-code';

    return request(action, data).then((res) => {
      // if (res && res.ok === true) {
      //   const { token = '' } = res;
      //   AuthService.setToken(token);
      // }

      return res;
    });
  },

  login(data = {}) {
    const action = 'login';

    return request(action, data).then((res) => {
      if (res && res.ok === true) {
        AuthService.setToken(res.token);
      }

      return res;
    });
  },

  register(data = {}) {
    const action = 'register';

    return request(action, data);
  },

  recover(data = {}) {
    const action = 'password-reset';

    return request(action, data);
  },

  logout() {
    return CookieService.del(COOKIE_APPLICATION_TOKEN);
  },

  accept() {},

  sendCode(data = {}) {
    const action = 'send-code';

    return request(action, data);
  },

  setToken(token) {
    if (!token) {
      return null;
    }

    return CookieService.set(COOKIE_APPLICATION_TOKEN, token, {
      expires: COOKIE_APPLICATION_TOKEN_EXP,
    });
  },

  getToken() {
    const token = CookieService.get(COOKIE_APPLICATION_TOKEN);
    window.sendMessageToWebview({ action: 'getToken', payload: token });

    return token;
  },

  refreshToken(data, cb) {
    const action = 'refresh';

    return request(action, data).then((res) => {
      if (res && res.ok) {
        const { token = '' } = res;
        window.sendMessageToWebview({
          action: 'refreshToken',
          payload: token,
        });

        AuthService.setToken(token);
        cb(res);
      } else if (!res || res.ok !== 200 || !res.token) {
        AuthService.logout();
        window.sendMessageToWebview({ action: 'logout' });
        window.location.pathname = '/login';
        window.location.reload();
      }
    });
  },

  qrCodeActivate(data = {}, cb) {
    const action = 'token-activate';

    return request(action, data)
      .then((res) => {
        cb(res);
      })
      .catch((e) => {
        console.log();
        AuthService.addLog(
          {
            log: {
              text: e.toString(),
            },
          },
          () => Logger.log(e + ' error'),
        );
      });
  },

  inviteRegistration(data = {}, cb = () => {}) {
    const action = 'accept-invite';

    return request(action, data).then((res) => {
      cb(res);
    });
  },

  checkAccess() {
    return true;
  },

  addLog: (data, cb = () => {}) => {
    const action = 'add-log';
    const prepareData = { log: JSON.stringify(data) };

    let url = `${Config.urls.auth}/${action}`;
    let body = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(prepareData),
    };

    const token = AuthService.getToken();

    if (token) {
      body.headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
    }

    return fetch(url, body)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res && res.ok) {
          cb(res);
        }
      });
  },
};

export default AuthService;
