import Socket from './SocketWebrtc';

interface onIceCandidateFoundMessage {
  id: string;
  candidate: RTCIceCandidate;
  token: string;
}

export default class PeerConnection {
  public instance: RTCPeerConnection;
  constructor() {
    const configuration: RTCConfiguration = {};

    if ('sdpSemantics' in configuration) {
      (configuration as any).sdpSemantics = 'unified-plan';
    }

    this.instance = new RTCPeerConnection(configuration);

    const transceiverInitializer: RTCRtpTransceiverInit = {
      direction: 'recvonly',
    };
    this.instance.addTransceiver('audio', transceiverInitializer);
    this.instance.addTransceiver('video', transceiverInitializer);
  }

  public async createOffer() {
    const offer = await this.instance.createOffer();
    await this.instance.setLocalDescription(offer);
    if (!this.instance.localDescription) {
      throw new Error('Local description is not set');
    }
    return this.instance.localDescription.sdp;
  }

  public initOnIceCandidate(jwt: string) {
    this.instance.onicecandidate = function (event: RTCPeerConnectionIceEvent) {
      if (event.candidate) {
        const message: onIceCandidateFoundMessage = {
          id: 'onICECandidateFound',
          candidate: event.candidate,
          token: jwt,
        };
        Socket.send(message);
      }
    };
  }

  public initOnTrack(video: HTMLVideoElement) {
    this.instance.ontrack = function (event: RTCTrackEvent) {
      if (video.srcObject !== event.streams[0]) {
        video.srcObject = event.streams[0];
        video.play();
      }
    };
  }
}
