import React, { Component } from 'react';
import { IconArrow } from 'Components/Icons';

class Top extends Component {
  handleGoTop = () => {
    this.props.scrollbar.toTop();
  };

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className='go-up' onClick={this.handleGoTop}>
        {IconArrow}
      </div>
    );
  }
}

export default Top;
