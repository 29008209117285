import {
  GET_AVAILABLE_DEVICES,
  IAvailableDevices,
} from 'Actions/availableDevices';

const initialState: IAvailableDevices | null = null;

export const availableDevices = (
  state = initialState,
  action: { type: 'GET_AVAILABLE_DEVICES'; payload: IAvailableDevices },
) => {
  if (action.type === GET_AVAILABLE_DEVICES) {
    return {
      ...action.payload,
    };
  }

  return state;
};
