import React from 'react'
import BaseComponent from 'Components/BaseComponent'

class Docs extends BaseComponent {
  className = 'docs'

  render() {
    return (
      <div className={this.getPageClasses()}>
        <h3>Списки</h3>
        <ul className="list">
          <li>
            Активируя аккаунт, вы подтверждаете согласие с договором оффертой и
            довором на передачу персональных данных. Ниже вы можете ознакомится
            с договорами
          </li>
          <li>
            Первый месяц работы в системе Т.один - бесплатный. Последующие
            месяцы рассчитываются исходя из <a href="/docs">Тарифов</a>
          </li>
        </ul>
        <ol className="list">
          <li>
            Активируя аккаунт, вы подтверждаете согласие с договором оффертой и
            довором на передачу персональных данных. Ниже вы можете ознакомится
            с договорами
          </li>
          <li>
            Первый месяц работы в системе Т.один - бесплатный. Последующие
            месяцы рассчитываются исходя из <a href="/docs">Тарифов</a>
          </li>
        </ol>
        <ol className="list list_zero">
          <li>
            Активируя аккаунт, вы подтверждаете согласие с договором оффертой и
            довором на передачу персональных данных. Ниже вы можете ознакомится
            с договорами
          </li>
          <li>
            Первый месяц работы в системе Т.один - бесплатный. Последующие
            месяцы рассчитываются исходя из <a href="/docs">Тарифов</a>
          </li>
        </ol>
        <h3>Checkboxes & radio</h3>
        <div className="mb-20">
          <div className="custom-control custom-control_checkbox">
            <input
              type="checkbox"
              className="custom-control__input"
              id="customCheck1"
            />
            <label className="custom-control__label" htmlFor="customCheck1">
              Договор офферта с компанией Т.один (<a href="/docs">читать</a>)
            </label>
          </div>
          <div className="custom-control custom-control_checkbox">
            <input
              type="checkbox"
              className="custom-control__input"
              id="customCheck2"
            />
            <label className="custom-control__label" htmlFor="customCheck2">
              Договор на передачу персональных данных (
              <a href="/docs">читать</a>)
            </label>
          </div>
        </div>
        <div className="mb-20">
          <div className="custom-control custom-control_radio">
            <input
              type="radio"
              className="custom-control__input"
              id="customRadio1"
              name="customRadio1"
            />
            <label className="custom-control__label" htmlFor="customRadio1">
              Вариат 1
            </label>
          </div>
          <div className="custom-control custom-control_radio">
            <input
              type="radio"
              className="custom-control__input"
              id="customRadio2"
              name="customRadio1"
            />
            <label className="custom-control__label" htmlFor="customRadio2">
              Вариат 2
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default Docs
