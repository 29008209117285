import React from 'react';
import { connect } from 'react-redux';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';
import UkService from 'Services/Uk';
import { ukAdd, ukEdit } from 'Actions/uk';
import { addNotice } from 'Actions/push';
import { panelClose } from 'Actions/panel';

class AddUKPanel extends BaseSettingPanel {
  className = 'scenario-panel';

  addUKFormProps = {
    action: UkService.addUk,
    className: 'form_addUK',
    fields: {
      inn: {
        id: 'form_addUK-inn',
        type: 'text',
        label: 'ИНН:',
        validationRules: ['required', 'inn'],
        focus: true,
      },
      email: {
        id: 'form_addUK-email',
        type: 'email',
        label: 'E-mail:',
        validationRules: ['required', 'email'],
      },
    },
    extendData: {
      apartmentId: this.props.apartmentId,
    },
    btnSubmit: 'Отправить',
    btnClass: 'btn_primary btn_full-width',
    callback: () => {
      this.props.onClose();
      this.props.onAddNotice({
        group: 'g2',
        type: 'success',
        text: 'УК успешно добавлена',
      });
    },
  };

  getPanelTitle() {
    if (this.props.panelProps.uk) return 'Редактирование УК';
    else return 'Добавление УК';
  }

  content() {
    if (this.props.panelProps.uk) {
      const _uk = this.props.panelProps.uk;

      this.addUKFormProps.action = UkService.addUk;
      this.addUKFormProps.callback = (data) => {
        if (data.ok && data.uk) {
          this.props.onAddNotice({
            group: 'g2',
            type: 'success',
            text: 'Данные УК обновлены',
          });
          this.props.onUkEdit(data.uk);
        }

        this.props.onClose();
      };
      this.addUKFormProps.extendData = {
        ukId: _uk.ukId,
        title: _uk.title,
      };
      this.addUKFormProps.fields.inn.value = _uk.inn;
      this.addUKFormProps.fields.email.value = _uk.email;
    }

    return <Form {...this.addUKFormProps} />;
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    panelProps: state.panel.props,
    isOpened: state.panel.current === 'addUk',
  }),
  {
    onAddNotice: addNotice,
    onUkAdd: ukAdd,
    onUkEdit: ukEdit,
    onClose: panelClose,
  },
)(AddUKPanel);
