import { statuses } from 'Config/filters';

declare global {
  interface Window {
    sendMessageToWebview: (data: { action: string; url?: string }) => void;
  }
}

export function isEmptyObj(obj) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function parseQuery(queryString) {
  const query = {};

  if (!queryString) return query;

  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function base64ArrayBuffer(arrayBuffer) {
  let base64 = '';
  const encodings =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a, b, c, d;
  let chunk;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }

  return base64;
}

export function compareArrays(arr1, arr2) {
  if (!arr1 || !arr2) return;

  let result;

  arr1.forEach((e1) =>
    arr2.forEach((e2) => {
      if (e1.length > 1 && e2.length) {
        result = compareArrays(e1, e2);
      } else if (e1 !== e2) {
        result = false;
      } else {
        result = true;
      }
    }),
  );

  return result;
}

export function getNoun(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export const getFiltersData = (filters, location) => {
  let statusFilter: number | null = null;
  let roomFilter = '';

  for (const item of filters) {
    if (item.location === location) {
      switch (item.filter.status) {
        case statuses.active:
          statusFilter = 1;
          break;
        case statuses.off:
          statusFilter = 2;
          break;
        case statuses.warning:
          statusFilter = 3;
          break;
        default:
          statusFilter = null;
      }
      /*
      фильтруем по title (label) комнаты, т.к при добавлении комнаты в ручную в фильтрах,
      roomId = label, соответсвенно не всегда существует roomId,
      а label (название комнтаы) - всегда
      */
      roomFilter = item.filter.label !== null ? item.filter.label : null;
    }
  }

  return { statusFilter, roomFilter };
};

// export function sortNotifications(items) {
//   const sortedItems = items.slice(0);
//   return sortedItems.sort((a: {createdTs: string}, b: {createdTs: string}) =>
//     new Date(b.createdTs).valueOf() - new Date(a.createdTs).valueOf());
// }

export const cropText = (title: string) => {
  if (
    title.length > 18 &&
    document.documentElement.clientWidth > 560 &&
    document.documentElement.clientWidth < 700
  )
    return title.slice(0, 18) + '...';
  if (title.length > 18 && document.documentElement.clientWidth < 374)
    return title.slice(0, 14) + '...';
  return title;
};

export const getRoles = (t, idx) => {
  const roles = [
    { value: 1, label: t('roles.owner') },
    { value: 2, label: t('roles.citizen') },
    // { value: childRole, label: 'Ребенок' },
    // { value: 3, label: 'Гость' }//,
    //{ value: 4, label: 'Арендатор' }
  ];
  if (idx) return roles[idx];
  return roles;
};
