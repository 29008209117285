import React from 'react'
import BaseComponent from 'Components/BaseComponent'
import Scrollbar from 'Components/BaseScrollbar'
import { IconClose } from 'Components/Icons'

/**
 * id,
 * title,
 * titleCenter,
 * headerCls
 * header()
 * content()
 */

class BaseModal extends BaseComponent {
  id = 'baseModal'
  title = ''
  titleCenter = false
  className = 'base'

  getHeader() {
    return (
      this.headerClassName && (
        <div className={'modal-header modal-header_' + this.headerClassName}>
          {this.header()}
        </div>
      )
    )
  }

  getBody() {
    return (
      <div className="modal-body">
        <button
          type="button"
          className="modal-close"
          onClick={this.props.onCloseModal}>
          {IconClose}
        </button>
        <h2 className={`modal-title${this.titleCenter ? ' text-center' : ''}`}>
          {this.getTitle()}
        </h2>
        {this.content()}
      </div>
    )
  }

  isOpen() {
    return this.props.isOpen === this.id
  }

  getTitle() {
    return this.title
  }

  render() {
    if (!this.isOpen()) return null

    return (
      <div
        className={
          'modal fade' +
          this.className +
          (this.isOpen() ? ' in' : '') +
          (this.headerClassName ? ' with-header' : ' without-header')
        }
        id={this.id}
        style={{
          display: this.isOpen() ? 'block' : 'none'
        }}
        onClick={this.onClickModal}
        tabIndex="-1">
        <Scrollbar noDefaultStyles>
          <div className="modal-dialog">
            <div className="modal-content">
              {this.getHeader()}
              {this.getBody()}
            </div>
          </div>
        </Scrollbar>
      </div>
    )
  }

  onClickModal = e => {
    if (
      e.target.classList.contains('content') ||
      e.target.classList.contains('modal-dialog')
    ) {
      this.props.onCloseModal()
    }
  }
}

export default BaseModal
