import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Lightning from 'Containers/control/lighting/index';
import AirConditioner from 'Containers/control/air-conditioner/index';
import Rosette from 'Containers/control/rosette/index';
import Device from 'Containers/control/device/index';
import Sensor from 'Containers/control/sensor/index';
import Video from 'Containers/control/video/index';

import Entry from 'Containers/control/entry/index';
import Tracking from 'Containers/control/tracking/index';
import Scenario from 'Containers/control/scenario/index';
import { News, NewsView } from 'Containers/news/index';
import Meter from 'Containers/control/meter/index';
import Settings from 'Containers/settings/index';
import Rooms from '../containers/control/rooms/index';
import Docs from 'Containers/docs/index';
import Messenger from 'Containers/messenger/index';
import UserLogout from 'Containers/user/logout/index';
import {
  ROUTE_ACCOUNTS,
  ROUTE_CONTROL,
  ROUTE_CONTROL_AIR_CONDITIONER,
  ROUTE_CONTROL_DEVICE,
  ROUTE_CONTROL_ENTRY,
  ROUTE_CONTROL_LIGHTING,
  ROUTE_CONTROL_METER,
  ROUTE_CONTROL_ROOMS,
  ROUTE_CONTROL_ROSETTE,
  ROUTE_CONTROL_SCENARIO,
  ROUTE_CONTROL_SENSOR,
  ROUTE_CONTROL_TRACKING,
  ROUTE_CONTROL_VIDEO,
  ROUTE_DOCS,
  ROUTE_EDIT,
  ROUTE_NEWS_ID,
  ROUTE_HOME,
  ROUTE_LOGOUT,
  ROUTE_MESSENGER,
  ROUTE_MESSENGER_CHAT_ID,
  ROUTE_NEWS,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS,
  ROUTE_INTERCOM_CALL,
  ROUTE_CHANGE_NAME,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CHANGE_EMAIL,
  ROUTE_CHANGE_PHONE,
} from 'Config/routes';
import AccountsList from 'Components/AccountsList/index';
import IntercomCall from './IntercomCall';
import ProfileScreen from '../screens/ProfileScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ProfileEditName from '../screens/ProfileEditName';
import ProfileEditPassword from '../screens/ProfileEditPassword';
import ProfileEditEmail from '../screens/ProfileEditEmail.jsx';
import ProfileEditPhone from '../screens/ProfileEditPhone';
import Control from '../screens/ControlsScreen';

const Router = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_HOME} component={Control} />
      <Route path={ROUTE_EDIT} component={Control} />
      <Route path={ROUTE_LOGOUT} component={UserLogout} />
      <Route exact path={ROUTE_NEWS} component={News} />
      <Route path={ROUTE_NEWS_ID} component={NewsView} />
      <Route path={ROUTE_DOCS} component={Docs} />
      <Route exact path={ROUTE_MESSENGER} component={Messenger} />
      <Route path={ROUTE_MESSENGER_CHAT_ID} component={Messenger} />
      <Route exact path={ROUTE_SETTINGS} component={SettingsScreen} />
      <Route path={ROUTE_SETTINGS_PROFILE} component={ProfileScreen} />
      <Route path={ROUTE_CHANGE_NAME} component={ProfileEditName} />
      <Route path={ROUTE_CHANGE_PASSWORD} component={ProfileEditPassword} />
      <Route path={ROUTE_CHANGE_EMAIL} component={ProfileEditEmail} />
      <Route path={ROUTE_CHANGE_PHONE} component={ProfileEditPhone} />
      <Route exact path={ROUTE_CONTROL} component={Control} />
      <Route path={ROUTE_CONTROL_LIGHTING} component={Lightning} />
      <Route path={ROUTE_CONTROL_AIR_CONDITIONER} component={AirConditioner} />
      <Route path={ROUTE_CONTROL_ROSETTE} component={Rosette} />
      <Route path={ROUTE_CONTROL_DEVICE} component={Device} />
      <Route path={ROUTE_CONTROL_SENSOR} component={Sensor} />
      <Route path={ROUTE_CONTROL_TRACKING} component={Tracking} />
      <Route path={ROUTE_CONTROL_VIDEO} component={Video} />
      <Route path={ROUTE_CONTROL_ENTRY} component={Entry} />
      <Route path={ROUTE_CONTROL_ENTRY} component={Entry} />
      <Route path={ROUTE_CONTROL_SCENARIO} component={Scenario} />
      <Route path={ROUTE_CONTROL_METER} component={Meter} />
      <Route path={ROUTE_CONTROL_ROOMS} component={Rooms} />

      <Route path={ROUTE_ACCOUNTS} component={AccountsList} />
      <Route path={ROUTE_INTERCOM_CALL} component={IntercomCall} />
    </Switch>
  );
};

export default Router;
