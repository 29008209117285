import ApiService from 'Services/Api';
import UserService from 'Services/User';

const VideoService = {
  getCameras: (apartment_id, cb = () => {}) => {
    ApiService.getVideoCameras({ apartment_id })
      .then(({ cameraList, ok }) => {
        if (ok) cb(cameraList);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

export default VideoService;
