import React from 'react';
import { connect } from 'react-redux';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';
import UserService from 'Services/User';
import { getRoles } from 'Services/Utils';
import { IconAccounts } from 'Components/Icons';
import { addNotice } from 'Actions/push';
import { panelClose } from 'Actions/panel';

class InviteUsers extends BaseSettingPanel {
  title = (
    <>
      {IconAccounts} {this.props.t('panels.invite_users.title')}
    </>
  );
  state = {
    submitted: false,
    currentRoleId: null,
  };

  getFilteredApartmentList = () =>
    this.props.apartmentList.filter(
      (item) => item.apartmentId !== this.props.apartmentId,
    );

  inviteUserFormProps = {
    action: UserService.inviteUser,
    className: 'form_invite-users invite-users',
    fields: {
      email: {
        id: 'form_invite-users__email',
        type: 'email',
        label: `${this.props.t('commons.inputs.email')}:`,
        className: 'input_full-width',
        validationRules: ['required', 'email'],
        focus: true,
        value: '',
      },
      role: {
        id: 'form_invite-users__role',
        type: 'select',
        label: `${this.props.t('commons.inputs.select_role')}:`,
        className: 'input_full_width',
        props: {
          isCreatable: false,
          isClearable: false,
          defaultValue: getRoles(this.props.t, 1),
          options: [
            { value: 0, label: this.props.t('roles.transfer') },
            ...getRoles(this.props.t),
          ],
          className: 'basic-multi-select',
          onChange: (data) => {
            this.setState({ currentRoleId: data.value });
            if (data && data.value === 0) {
              const newApartmentList = this.getFilteredApartmentList();
              alert(
                `Вы уверены? ${
                  newApartmentList.length > 0
                    ? `Ваша активная квартира станет ${newApartmentList[0].stringAddress}`
                    : 'К вашему аккаунту прикреплена только одна квартира, вы выйдете из аккаунта автоматически.'
                }`,
              );
            }
          },
        },
      },
    },
    formatBeforeSend: (data) => {
      this.setState({ email: data.email });
      data.invites = [
        {
          email: data.email,
          roleId: data.role.value,
        },
      ];
      data.apartment_id = this.props.apartmentId;

      delete data.email;
      delete data.role;
      return data;
    },
    annotation: (
      <div>
        <p>{this.props.t('panels.invite_users.annotation')}</p>
      </div>
    ),
    btnSubmit: this.props.t('commons.buttons.confirm'),
    btnClass: 'btn_primary btn_full-width',
    callback: (res) => {
      if (res.ok) {
        const newApartmentList = this.getFilteredApartmentList();
        // передача квартиры
        if (this.state.currentRoleId === 0) {
          if (!newApartmentList.length) {
            UserService.logout();
          } else {
            UserService.updateSettings({
              json_data: JSON.stringify({
                ...this.props.userData,
                apartmentId: newApartmentList[0].apartmentId,
              }),
            }).then(() => {
              this.props.onSetActiveApartment(newApartmentList[0]);
              this.props.onLoadUserData({
                apartmentId: newApartmentList[0].apartmentId,
              });
            });
          }
        }
        this.setState({ submitted: true });
        this.props.onAddNotice({
          group: 'g2',
          type: 'success',
          text: this.props.t('notices.application_success_sent'),
        });
      }
    },
  };

  content() {
    const { t } = this.props;

    if (this.state.submitted) {
      return (
        <div className='form'>
          <form>
            <div className='form__fact'>
              <div className='text-center'>
                {t('panels.invite_users.after_submit')}
                <b>{this.state.email}</b>
              </div>
            </div>
            <div className='form__fact'>
              <div className='text-center'>
                <button
                  type='button'
                  className='btn btn_primary btn_full-width'
                  onClick={(e) => {
                    this.setState({
                      submitted: false,
                    });
                    this.inviteUserFormProps.fields.email.value = '';
                  }}
                >
                  {t('commons.buttons.invite_more')}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
    return <Form {...this.inviteUserFormProps} />;
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current === 'InviteUsers',
    panelData: state.panel.props,
    apartmentId: state.apartments.active.apartmentId,
    apartmentList: state.apartments.list,
    userData: state.userData,
  }),
  {
    onClose: panelClose,
    onAddNotice: addNotice,
  },
)(InviteUsers);
