import ICECandidateFound from '../events/ICECandidateFound';
import started from '../events/started';
import start from '../actions/start';

let socket: WebSocket | null = null;
let queue: string[] = [];

async function init(
  dnsName: string | null,
  videoElement: HTMLVideoElement,
  selectedCameraId: number,
  jwt: string,
  changeVisability: () => void,
) {
  if (!videoElement) {
    return;
  }

  if (!dnsName) {
    return;
  }

  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.close();
    queue = [];
  }
  socket = new WebSocket('wss://' + dnsName);

  const pc = await start(videoElement, selectedCameraId, jwt);

  socket.onmessage = async function (event: MessageEvent) {
    const message = JSON.parse(event.data);
    switch (message.id) {
      case 'unavailable':
        changeVisability();
      case 'errorAppeared':
        console.error(message.error);
        break;
      case 'ICECandidateFound':
        await ICECandidateFound(pc, message);
        break;
      case 'started':
        await started(pc, message);
        break;
    }
  };

  socket.onopen = function () {
    queue.forEach((message: string) => {
      if (!socket) {
        return;
      }
      socket.send(message);
    });
  };

  // socket.onclose = function (event: Event) {
  //   setTimeout(() => {
  //     init(videoElement, selectedCameraId, jwt);
  //   }, 1000);
  // };
}

async function send(message: any) {
  if (!socket) {
    return;
  }

  if (socket.readyState === WebSocket.CONNECTING) {
    queue.push(JSON.stringify(message));
    return;
  }
  socket.send(JSON.stringify(message));
}

export default {
  init,
  send,
};
