import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';
import { panelClose } from 'Actions/panel';
import { IconDeviceRouter, IconFilterDevice } from 'Components/Icons';
import UserService from 'Services/User';
import { filtersUpdate } from 'Actions/filters/filters';
import { IFilters } from 'Reducers/filters/filters';
import { loadUserData } from 'Actions/userData';
import { statuses } from 'Config/filters';

export const updateFilter = (
  state,
  {
    location,
    room,
    status,
    label,
  }: {
    location: string;
    room: string | null;
    label: string | null;
    status: string | null;
  },
) => {
  return {
    pages: [
      ...JSON.parse(JSON.stringify(state)).pages.map((item) => {
        if (item.location === location) {
          item.location = location;
          item.filter.room = room;
          item.filter.status = status;
          item.filter.label = label;
        }
        return item;
      }),
    ],
  };
};

class FilterDevice extends BaseSettingPanel {
  state = {
    selected: '',
    currentRoom: {
      room: null,
      label: null,
    },
    location: '',
  };

  title = (
    <>
      {IconFilterDevice} {this.props.t('panels.filter_device.title')}
    </>
  );

  getRooms() {
    return Object.keys(this.props.rooms).map((i) => {
      const room = this.props.rooms[i];

      return {
        value: room.roomId,
        label: room.title,
      };
    });
  }

  addStatusToState = (status: string | null) =>
    this.setState({ selected: status });

  shouldComponentUpdate(
    nextProps: Readonly<{
      isOpened: string;
      panelData: { props: { currentRoom: { room: string; label: string } } };
    }>,
    nextState: Readonly<{
      selected: string;
      currentRoom: { label: string | null; room: string | null };
    }>,
    _nextContext: any,
  ): boolean {
    if (nextProps.isOpened !== this.props.isOpened) return true;
    return nextState.selected !== this.state.selected;
  }

  componentDidMount() {
    super.componentDidMount();

    this.setState({
      currentRoom: {
        value: this.props.panelData.props.currentRoom.room,
        label: this.props.panelData.props.currentRoom.label,
      },
    });

    console.log(this.props);
  }

  renderForm() {
    const { t } = this.props;
    let defaultValue;

    if (
      !this.state.currentRoom ||
      (!this.state.currentRoom.room && !this.state.currentRoom.label)
    ) {
      defaultValue = null;
    } else {
      defaultValue = this.state.currentRoom;
    }

    const formParams = {
      action: UserService.updateSettings,
      className: 'form_filtered-devices',
      fields: {
        room_id: {
          id: 'form_components-edit__device-room',
          type: 'select',
          label: `${t('commons.inputs.select_room')}:`,
          className: 'input_full-width',
          props: {
            placeholder: `${t('commons.inputs.select_placeholder')}...`,
            isCreatable: true,
            defaultValue: defaultValue,
            options: this.getRooms(),
          },
        },
        filter_btn: {
          id: 'form_components-edit_filter-btn',
          type: 'button',
          label: t('commons.inputs.device_activity'),
          icon: IconDeviceRouter,
          buttons: [
            {
              status: statuses.active,
              content: t('panels.filter_device.statuses.active'),
            },
            {
              status: statuses.off,
              content: t('panels.filter_device.statuses.off'),
            },
            {
              status: statuses.warning,
              content: t('panels.filter_device.statuses.warning'),
            },
          ],
        },
      },
      btnSubmit: t('commons.buttons.confirm'),
      btnClass: 'btn_primary btn_sm',
      prependBtn: () => (
        <button
          type='button'
          className='btn btn_secondary btn_sm'
          onClick={() => {
            this.props.filtersUpdate({
              location: this.props.location.pathname,
              room: null,
              status: null,
              label: null,
            });
            this.setState({ currentRoom: { value: null, label: null } });
            this.addStatusToState(null);
            const newState: IFilters = updateFilter.call(
              this,
              this.props.filters,
              {
                location: this.props.location.pathname,
                room: null,
                status: null,
                label: null,
              },
            );

            UserService.updateSettings({
              json_data: JSON.stringify({
                ...this.props.userData,
                filters: newState,
              }),
            }).then((res) => {
              if (res) {
                this.props.onLoadUserData({
                  ...this.props.userData,
                  filters: newState,
                });
                this.props.onClose();
              }
            });
          }}
        >
          {t('commons.buttons.reset')}
        </button>
      ),
      changeDefaultValue: (data: { label: string; value: number }) =>
        this.setState({ currentRoom: data }),
      setSelected: () =>
        this.setState({ selected: this.props.panelData.props.selected }),
      handleClick: (status: string) => this.setState({ selected: status }),
      getClassName: (status: string) => {
        let className = 'filters__btn ';
        if (status === statuses.active) className += 'active';
        if (status === statuses.off) className += 'off';
        if (status === statuses.warning) className += 'warning';
        if (status === this.state.selected) className += ' selected';
        return className;
      },
      formatBeforeSend: (data): { json_data: string } => {
        const room =
          data.room_id !== null && data.room_id.value
            ? data.room_id.value
            : null;
        const label =
          data.room_id !== null && data.room_id.label
            ? data.room_id.label
            : null;
        const status = this.state.selected ? this.state.selected : null;

        this.setState({ currentRoom: { room, label } });
        const newState: IFilters = updateFilter.call(this, this.props.filters, {
          location: this.props.location.pathname,
          room,
          status,
          label,
        });

        return {
          json_data: JSON.stringify({
            ...this.props.userData,
            filters: newState,
          }),
        };
      },
      callback: () => {
        this.props.filtersUpdate({
          location: this.props.location.pathname,
          room: this.state.currentRoom.room,
          status: this.state.selected,
          label: this.state.currentRoom.label,
        });

        this.props.onLoadUserData({
          ...this.props.userData,
          filters: this.props.filters,
        });

        this.props.onClose();
      },
    };

    return <Form {...formParams} />;
  }

  content() {
    return this.renderForm.call(this);
  }
}

const mapStateToProps = (state) => {
  return {
    isOpened: state.panel.current === 'FilterDevice',
    panelData: state.panel,
    rooms: state.rooms,
    filters: state.filters,
    userData: state.userData,
  };
};

export default connect(mapStateToProps, {
  onClose: panelClose,
  onLoadUserData: loadUserData,
  filtersUpdate,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
})(withRouter(FilterDevice));
