import React, { Component } from 'react';
import { cropText } from 'Services/Utils';
// import { IconSettings } from 'Components/Icons'

class BaseWidget extends Component {
  _isMounted = true;
  classNameBase = 'widget';
  classNameError = 'error';

  constructor(props) {
    super(props);
    this.cropText = cropText;
  }

  setStateSafe(props, loaded = true) {
    if (loaded) {
      props.isLoading = false;
    }
    if (this._isMounted !== false) {
      this.setState(props);
    }
  }

  getWidgetClasses(ctr) {
    let cls = this.classNameBase;

    if (this.className !== undefined) {
      cls += ` ${this.className}`;
    }

    if (this.hasError(ctr)) {
      cls += ` ${this.classNameBase}_${this.classNameError}`;
    }

    return cls;
  }

  hasError(ctr) {
    const props = ctr || this.props;

    return props.status && props.status.value === 0;
  }

  getLoader() {
    if (this.isLoading || this.hasError() === false) {
      return null;
    }

    return (
      <div className='preloader preloader_circle-bounce'>
        <div className='preloader-child preloader-circle-1' />
        <div className='preloader-child preloader-circle-2' />
        <div className='preloader-child preloader-circle-3' />
        <div className='preloader-child preloader-circle-4' />
        <div className='preloader-child preloader-circle-5' />
        <div className='preloader-child preloader-circle-6' />
        <div className='preloader-child preloader-circle-7' />
        <div className='preloader-child preloader-circle-8' />
        <div className='preloader-child preloader-circle-9' />
        <div className='preloader-child preloader-circle-10' />
        <div className='preloader-child preloader-circle-11' />
        <div className='preloader-child preloader-circle-12' />
      </div>
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (
      this.prototype &&
      typeof this.prototype.componentWillUnmount == 'function'
    ) {
      this.prototype.componentWillUnmount();
    }
  }

  componentDidMount() {
    if (
      this.prototype &&
      typeof this.prototype.componentDidMount == 'function'
    ) {
      this.prototype.componentDidMount();
    }
  }

  // что это?
  // getControls() {
  //   // return null
  //   return this.props.isEdit && this.props.onWidgetEdit ? (
  //     <span
  //       className="widget-wrapper__settings"
  //       onClick={() => {
  //         this.props.onWidgetEdit(this)
  //       }}>
  //       {IconSettings}
  //     </span>
  //   ) : null
  // }

  render() {
    return (
      <>
        <div className={this.getWidgetClasses()}>{this.content()}</div>
      </>
    );
  }
}

export default BaseWidget;
