import React from 'react';

import styles from './styles.module.scss';

type IProps = {
  image: string;
  children: ChildNode;
  onClose: () => void;
  closable?: boolean;
};

const Promo = ({ image, children, onClose, closable = true }: IProps): JSX.Element => {
  return (
    <div className={styles.Container}>
      <div className={styles.Fact} style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}>
        <img className={styles.Image} src={image} alt='Promo' style={{
          maxWidth: '140px',
          marginLeft: 'auto',
          marginRight: 'auto',
          aspectRatio: 1 / 1
        }} />

        <div className={styles.Content}>{children}</div>
        {
          closable && <button
            type='button'
            className={`btn btn_full-width btn_primary`}
            onClick={onClose}
          >
            Закрыть
          </button>
        }

      </div>

    </div>
  );
};

export default Promo;
