import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

import { IconMessage, IconRFID, IconSensorsNotif } from 'Components/Icons';
import { SENSOR_AND_DEVICE_KEY, RFID_KEY, CHAT_KEY } from 'Config/notify';

const Tab = ({
  groupKey,
  isNewNotify,
}: {
  groupKey: string;
  isNewNotify: boolean;
}) => {
  const { t } = useTranslation();

  switch (groupKey) {
    case SENSOR_AND_DEVICE_KEY: {
      return (
        <div className='sensor'>
          {IconSensorsNotif}
          <span className={isNewNotify ? styles.newMessage : ''}>
            {t('notify.tabs.apartment')}
          </span>
        </div>
      );
    }
    case RFID_KEY: {
      return (
        <div className='rfid'>
          {IconRFID}
          <span className={isNewNotify ? styles.newMessage : ''}>
            {t('notify.tabs.intercom')}
          </span>
        </div>
      );
    }
    case CHAT_KEY: {
      return (
        <div className='message'>
          {IconMessage}
          <span className={isNewNotify ? styles.newMessage : ''}>
            Мои сообщения
          </span>
        </div>
      );
    }
    default:
      return true;
  }
};

export default Tab;
