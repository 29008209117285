import { EVENT_ADD } from './notify';
import { EVENT_ADD_PUSH } from 'Actions/push';
import {
  NOTIFY_CATEGORY_ACCESS,
  NOTIFY_CATEGORY_DEVICE,
  NOTIFY_CATEGORY_SENSOR,
} from 'Config/notify';
import i18next from 'i18next';

const component = 'Socket';

export const EVENT_CONNECT = `${component}/CONNECT`;
export const EVENT_RECONNECT = `${component}/RECONNECT`;
export const EVENT_MESSAGE = `${component}/MESSAGE`;
export const EVENT_CLOSE = `${component}/CLOSE`;
export const EVENT_ERROR = `${component}/ERROR`;
export const EVENT_AUTH = `${component}/AUTH`;

export function socketConnect(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CONNECT,
      event: event,
    });
  };
}
export function socketAuth(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_AUTH,
      event: event,
    });
  };
}
export function socketReConnect(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_RECONNECT,
      event: event,
    });
  };
}
export function socketMessage(event) {
  const data = JSON.parse(event.data);
  let event_type = data.type || EVENT_MESSAGE;

  if (data.category && data.category) {
    event_type = data.category;
  } else if (data.data && data.data.type) {
    event_type = data.data.type;
  }

  // собираем данные о пуше из сокета
  let notify = {};
  if (data) {
    notify.notifyId = data.notify_id;
    notify.text = event.data;
    notify.createdTs = data.timestamp;
    notify.status = 1;
  }

  return async (dispatch) => {
    if (notify.text && notify.createdTs) {
      const category = JSON.parse(notify.text).data.category;

      if (
        category === NOTIFY_CATEGORY_DEVICE ||
        category === NOTIFY_CATEGORY_SENSOR ||
        category === NOTIFY_CATEGORY_ACCESS
      ) {
        dispatch({
          type: EVENT_ADD,
          notice: notify,
        });

        dispatch({
          type: EVENT_ADD_PUSH,
          notice: {
            group: 'g2',
            type: 'success',
            text: i18next.t('notices.new_notification'),
            delay: 3000,
          },
        });
      }
    }

    dispatch({
      type: event_type,
      event: event,
    });
  };
}
export function socketError(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ERROR,
      event: event,
    });
  };
}
export function socketClose(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CLOSE,
      event: event,
    });
  };
}
