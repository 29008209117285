import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BaseComponent from 'Components/BaseComponent';
import Form from 'Components/Form';
import CookieService from 'Services/Cookie';
import AuthService from 'Services/Auth';
import {
  COOKIE_LOGIN_CODE_TIMER,
  isMobile,
  isTablet,
  minWidth,
} from 'Config/index';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';
import UserRegistration from 'Components/UserRegistration';
import {
  IconLogoDefault,
  ImageLoginStart,
  ImageSignIn,
} from 'Components/Icons';
import Header from 'Containers/user/Header/Header';
import SectionNotMobile from 'Components/SectionNotMobile';

const AUTH_STEP_LOGIN = 2;
const AUTH_STEP_REGISTRATION = 3;
const AUTH_STEP_RECOVER = 4;

class UserLogin extends BaseComponent {
  state = {
    email: '',
    phone: '',
    step: AUTH_STEP_LOGIN,
    token: '',
    qrToken: null,
    inviteToken: null,
    phoneFormOpts: {
      action: AuthService.login,
      className: 'form_user-login',
      fields: {
        email: {
          id: 'form_user-login__name',
          type: 'email',
          label: `${this.props.t('commons.inputs.email')}:`,
          className: 'input_full-width',
          validationRules: ['required', 'email'],
          focus: true,
        },
        password: {
          id: 'form_user-login__password',
          type: 'password',
          label: `${this.props.t('commons.inputs.password')}:`,
          className: 'input_full-width',
          validationRules: ['required'],
        },
      },
      btnSubmit: this.props.t('label.login'),
      btnClass: 'btn_primary btn-full',
      formatBeforeSend: (data) => {
        this.setState({
          email: data.email,
          password: data.password,
        });

        return data;
      },
      callback: (res) => {
        if (res.active) {
          if (!CookieService.get(COOKIE_LOGIN_CODE_TIMER))
            CookieService.set(COOKIE_LOGIN_CODE_TIMER, Date.now(), {
              expires: 120,
            });
          window.location.reload();
        } else if (res.errorList[0] === 'You should confirm your email') {
          this.goToReg(this.state.email, this.state.password);
        }
      },
    },
    recoverFormOpts: {
      action: AuthService.recover,
      className: 'form_user-recover',
      fields: {
        email: {
          id: 'form_user-recover__email',
          type: 'email',
          label: `${this.props.t('commons.inputs.email')}:`,
          className: 'input_full-width',
          validationRules: ['required', 'email'],
          focus: true,
        },
      },
      btnSubmit: this.props.t('commons.buttons.restore'),
      btnClass: 'btn_primary btn-full',
      callback: (res) => {
        if (!res || !res.ok)
          this.props.onAddNotice({
            group: 'g1',
            type: 'error',
            text: this.props.t('notices.recovery_error'),
          });
        else {
          this.props.onAddNotice({
            group: 'g1',
            type: 'success',
            text: this.props.t('notices.recovery_success'),
          });

          this.goToAuth();
        }
      },
    },
  };

  constructor(props) {
    super(props);

    this.ScrollBarCfg = new ScrollBarConfig();
    this.checkQRInterval = null;
  }

  getHeader = () => {
    switch (this.state.step) {
      case AUTH_STEP_LOGIN: {
        return (
          <>
            <h1
              style={{
                textAlign: 'center',
                fontSize: '1.4rem',
              }}
            >
              {this.props.t('auth.step_login.title')}
            </h1>
            <div className='user-login-page-bg user-login-page-bg--signin '>
              {ImageSignIn}
            </div>
          </>
        );
      }
      case AUTH_STEP_RECOVER: {
        return (
          <>
            <Header
              title={this.props.t('auth.step_restore.title')}
              goBack={this.goToAuth}
            />
            <div className='user-login-page__notice'>
              <p>{this.props.t('auth.step_restore.text')}</p>
            </div>
          </>
        );
      }
      case AUTH_STEP_REGISTRATION: {
        return <Header title={'Регистрация'} goBack={this.goToStart} />;
      }
      default: {
        return (
          <>
            <div className='user-login-page__logo'>{IconLogoDefault}</div>
            <div className='user-login-page-bg user-login-page-bg--start '>
              {ImageLoginStart}
            </div>
          </>
        );
      }
    }
  };

  goToStart = () => {
    this.setState({
      step: AUTH_STEP_LOGIN,
      qrToken: null,
      inviteToken: null,
    });
    window.token = null;
    window.inviteToken = null;
  };

  goToAuth = () => {
    this.setState({
      step: AUTH_STEP_LOGIN,
    });
  };

  goToReg = () => {
    this.setState({
      step: AUTH_STEP_REGISTRATION,
    });
  };

  goToRecover = () => {
    this.setState({
      step: AUTH_STEP_RECOVER,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // this.setData();

    if (
      this.state.step === AUTH_STEP_LOGIN &&
      prevState.step !== AUTH_STEP_LOGIN
    ) {
      this.setState({
        phoneFormOpts: {
          action: AuthService.login,
          className: 'form_user-login',
          fields: {
            email: {
              id: 'form_user-login__name',
              type: 'email',
              label: `${this.props.t('commons.inputs.email')}:`,
              className: 'input_full-width',
              validationRules: ['required', 'email'],
              focus: true,
            },
            password: {
              id: 'form_user-login__password',
              type: 'password',
              label: `${this.props.t('commons.inputs.password')}:`,
              className: 'input_full-width',
              validationRules: ['required'],
            },
          },
          btnSubmit: this.props.t('label.login'),
          btnClass: 'btn_primary btn-full',
          formatBeforeSend: (data) => {
            this.setState({
              email: data.email,
              password: data.password,
            });

            return data;
          },
          callback: (res) => {
            if (res.active) {
              if (!CookieService.get(COOKIE_LOGIN_CODE_TIMER))
                CookieService.set(COOKIE_LOGIN_CODE_TIMER, Date.now(), {
                  expires: 120,
                });
              window.location.reload();
            } else if (res.errorList[0] === 'You should confirm your email') {
              this.goToReg(this.state.email, this.state.password);
            }
          },
        },
      });
    }

    if (
      this.state.step === AUTH_STEP_RECOVER &&
      prevState.step !== AUTH_STEP_RECOVER
    ) {
      this.setState({
        recoverFormOpts: {
          action: AuthService.recover,
          className: 'form_user-recover',
          fields: {
            email: {
              id: 'form_user-recover__email',
              type: 'email',
              label: `${this.props.t('commons.inputs.email')}:`,
              className: 'input_full-width',
              validationRules: ['required', 'email'],
              focus: true,
            },
          },
          btnSubmit: this.props.t('commons.buttons.restore'),
          btnClass: 'btn_primary btn_full-width user-login-page__submit',
          callback: (res) => {
            if (!res || !res.ok)
              this.props.onAddNotice({
                group: 'g1',
                type: 'error',
                text: this.props.t('notices.recovery_error'),
              });
            else {
              this.props.onAddNotice({
                group: 'g1',
                type: 'success',
                text: this.props.t('notices.recovery_success'),
              });

              this.goToAuth();
            }
          },
        },
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();
    const { t, i18n } = this.props;

    const changeLang = (lang) => {
      i18n.changeLanguage(lang);
    };
    changeLang(window.navigator.language.slice(0, 2));
    if (this.state.qrToken === null) {
      this.checkQRInterval = setInterval(() => {
        if (window.token) {
          this.setState({ qrToken: window.token });
        }
      }, 60);
    }

    if (this.state.inviteToken === null) {
      this.checkInviteToken = setInterval(() => {
        if (window.inviteToken) {
          this.setState({ inviteToken: window.inviteToken });
        }
      }, 60);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.checkQRInterval) clearInterval(this.checkQRInterval);
  }

  render() {
    if (
      this.state.step === AUTH_STEP_REGISTRATION ||
      this.state.qrToken ||
      this.state.inviteToken
    ) {
      return (
        <UserRegistration
          qrToken={this.state.qrToken}
          inviteToken={this.state.inviteToken}
          onCancel={this.goToStart}
          password={this.state.password || null}
          email={this.state.email || null}
        />
      );
    }

    if (!this.state.step) {
      return this.getLoader();
    }

    const { t } = this.props;

    return (
      <Scrollbar cfg={this.ScrollBarCfg}>
        <div className='user-login-page-wrapper'>
          <div className={`user-login-page`}>
            <div className='user-login-page__fact'>{this.getHeader()}</div>
            {this.state.step === AUTH_STEP_RECOVER && (
              <Form {...this.state.recoverFormOpts} />
            )}
            <div className='user-login-page__fact'>
              <div className='user-login-page__wrap'>
                <div className='content-align'>
                  {!isTablet && !isMobile && !minWidth ? (
                    <SectionNotMobile />
                  ) : this.state.step === AUTH_STEP_LOGIN ? (
                    <>
                      <Form {...this.state.phoneFormOpts} />
                      <br />
                      <div
                        style={{
                          width: '100%',
                          padding: '0 15px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        <button
                          type='button'
                          className='btn btn_link btn-full'
                          style={{
                            padding: '10px 18px',
                            fontWeight: 'bold',
                          }}
                          onClick={() => {
                            window.token = null;
                            window.inviteToken = null;
                            window.sendMessageToWebview({
                              action: 'handleQRScanner',
                            });
                          }}
                        >
                          {this.props.t('auth.first_step.sign_up')}
                        </button>
                        <button
                          type='button'
                          className='btn btn_link btn-full'
                          style={{
                            padding: '10px 18px',
                          }}
                          onClick={this.goToRecover}
                        >
                          {this.props.t('commons.buttons.forgot_password')}
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbar>
    );
  }
}

export default withTranslation()(withRouter(UserLogin));
