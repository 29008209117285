import _ from 'lodash';
import {
  EVENT_LOAD,
  // EVENT_TYPES_LOAD,
  EVENT_ADD,
  EVENT_DELETE,
  // EVENT_DELETE_ALL,
  EVENT_TOGGLE_NOTIFICATIONS,
  EVENT_ADD_MESSAGES_NOTICE,
  EVENT_ADD_RFID_NOTICE,
  EVENT_ADD_APARTAMENT_NOTICE,
  SWITCH_ACTIVE_CATEGORY,
  EVENT_DELETE_ALL,
  EVENT_CLEAR_STATE_NOTICES,
} from 'Actions/notify';
import { EVENT_DELETE_PUSH } from 'Actions/push';
import NotifyService from 'Services/Notify';
import {
  NOTIFY_CATEGORY_DEVICE,
  NOTIFY_CATEGORY_SENSOR,
  NOTIFY_STATUS_ACTIVE,
  NOTIFY_STATUS_DISACTIVE,
} from 'Config/notify';
// import { sortNotifications } from 'Services/Utils';

const initialState = {
  notices: [],
  // types: {},
  notificationsOpen: false,
  ApartamentNotices: [],
  RfidNotices: [],
  MessagesNotices: [],
  activeCategory: {
    name: 'ApartamentNotices',
    types: [NOTIFY_CATEGORY_SENSOR, NOTIFY_CATEGORY_DEVICE],
  },
};

// const addNotice = (action, category, state) => {
//   const { notice } = action;
//   const newNotices = notice.filter(
//     (newNotice) =>
//       !_.find(
//         state[category],
//         (notice) => notice.notifyId === newNotice.notifyId,
//       ),
//   );
//   const sortedNotices = sortNotifications([...newNotices, ...state[category]]);
//   return { ...state, [category]: sortedNotices };
// };

export function notify(state = initialState, action) {
  let notices = [];

  switch (action.type) {
    case EVENT_LOAD:
      notices = action.notices || [];
      return {
        ...state,
        notices,
      };
    // case EVENT_TYPES_LOAD:
    //   return {
    //     ...state,
    //     types: action.types,
    //   };
    case EVENT_ADD:
      notices = state.notices.slice();
      notices.push(action.notice);

      return {
        ...state,
        notices,
      };
    case EVENT_DELETE:
      if (action.noticeId !== 0) {
        const items = JSON.parse(JSON.stringify(state[action.name])).slice(0);
        // const lastTwentyItems = JSON.parse(JSON.stringify(action.notices));
        items.map((item) => {
          if (item.status !== NOTIFY_STATUS_DISACTIVE) {
            item.status = NOTIFY_STATUS_DISACTIVE;
            NotifyService.readNotifications(item.notifyId);
          }
          return item;
        });

        const _state = {
          ...state,
          notices: state.notices.filter(
            (notice) => !_.find(items, (i) => i.notifyId === notice.notifyId),
          ),
        };

        switch (action.name) {
          case 'ApartamentNotices':
            return {
              ..._state,
              ApartamentNotices: [...items],
            };
          case 'RfidNotices':
            return {
              ..._state,
              RfidNotices: [...items],
            };
          case 'MessagesNotices':
            return {
              ..._state,
              MessagesNotices: [...items],
            };
          default: {
            return true;
          }
        }
      }

      break;
    case EVENT_DELETE_PUSH:
      notices = state.notices.slice();

      if (action.save && action.notice.important) {
        notices.push(action.notice);
      }

      return {
        ...state,
        notices,
      };
    case EVENT_DELETE_ALL:
      notices = state.notices.slice();
      notices.splice(0);

      const changeStatusToRead = (arr) =>
        arr.map((item) => {
          if (item.status !== NOTIFY_STATUS_ACTIVE) {
            item.status = NOTIFY_STATUS_DISACTIVE;
          }
          return item;
        });

      const apartamentNotices = changeStatusToRead(
        JSON.parse(JSON.stringify(state.ApartamentNotices)),
      );
      const rfidNotices = changeStatusToRead(
        JSON.parse(JSON.stringify(state.RfidNotices)),
      );
      const messagesNotices = changeStatusToRead(
        JSON.parse(JSON.stringify(state.MessagesNotices)),
      );

      NotifyService.readNotifications(0);

      return {
        ...state,
        notices,
        ApartamentNotices: apartamentNotices,
        RfidNotices: rfidNotices,
        MessagesNotices: messagesNotices,
      };
    case EVENT_TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        notificationsOpen: action.notificationsOpen,
        activeCategory: {
          name: 'ApartamentNotices',
          types: [NOTIFY_CATEGORY_SENSOR, NOTIFY_CATEGORY_DEVICE],
        },
      };

    case EVENT_ADD_APARTAMENT_NOTICE: {
      return {
        ...state,
        ApartamentNotices: [...state.ApartamentNotices, ...action.notice],
      };
      // return addNotice(action, 'ApartamentNotices', state);
    }

    case EVENT_ADD_RFID_NOTICE: {
      return {
        ...state,
        RfidNotices: [...state.RfidNotices, ...action.notice],
      };
      // return addNotice(action, 'RfidNotices', state);
    }

    case EVENT_ADD_MESSAGES_NOTICE: {
      return {
        ...state,
        MessagesNotices: [...state.MessagesNotices, ...action.notice],
      };
      // return addNotice(action, 'MessagesNotices', state);
    }

    case SWITCH_ACTIVE_CATEGORY: {
      return {
        ...state,
        activeCategory: {
          name: action.category.name,
          types: action.category.types,
        },
      };
    }

    case EVENT_CLEAR_STATE_NOTICES: {
      return {
        ...state,
        ApartamentNotices: [],
        RfidNotices: [],
        MessagesNotices: [],
      };
    }

    default:
      return state;
  }
}
