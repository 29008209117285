export const SET_APARTMENTS = 'SET_APARTMENTS';
export const SET_ACTIVE_APARTMENT = 'SET_ACTIVE_APARTMENT';

export type IApartment = {
  apartmentId: number;
  createdTs: string;
  isAutoPay: boolean;
  isPrivate: boolean;
  kladrAddress: string;
  number: number;
  ok: boolean;
  status: number;
  stringAddress: string;
  tokenActivated: boolean;
  tokenActivatedTs: string;
};

export const setApartments = (payload: IApartment[]) => ({
  type: SET_APARTMENTS,
  payload,
});

export const setActiveApartment = (payload: IApartment) => ({
  type: SET_ACTIVE_APARTMENT,
  payload,
});
