import React, { createRef } from 'react';
import { connect } from 'react-redux';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';
import UkService from 'Services/Uk';
import { addNotice } from 'Actions/push';
import { panelClose } from 'Actions/panel';

class UkAddIssue extends BaseSettingPanel {
  title = this.props.t('panels.uk_issue.title');
  state = {
    fileRef: createRef(),
    formData: {
      subject: '',
      message: '',
      file: '',
    },
  };

  issueFormProps = {
    formType: 'formData',
    action: UkService.addIssue,
    className: 'form_uk-issue',
    fields: {
      subject: {
        id: 'form_uk-issue__subject',
        type: 'select',
        label: `${this.props.t('panels.uk_issue.type_application')}:`,
        value: this.state.formData.subject,
        className: 'input_full-width',
        validationRules: ['required'],
        props: {
          placeholder: `${this.props.t(
            'commons.inputs.select_placeholder',
          )}...`,
          options: this.props.t('panels.uk_issue.types', {
            returnObjects: true,
          }),
          onChange: () => {},
        },
      },
      message: {
        id: 'form_uk-issue__message',
        type: 'textarea',
        label: `${this.props.t('panels.uk_issue.enter_text')}:`,
        value: this.state.formData.message,
        className: 'input_full-width textarea',
        validationRules: ['required'],
      },
      image: {
        id: 'form_uk-issue__image',
        type: 'file',
        label: `${this.props.t('panels.uk_issue.attach_photo')}:`,
        value: this.state.formData.image,
        className: 'input_full-width file',
        placeholder: `${this.props.t('commons.inputs.select_placeholder')}...`,
        props: {
          ref: this.state.fileRef,
          accept: '.jpg, .jpeg',
        },
      },
    },
    extendData: {
      apartmentId: this.props.apartmentId,
    },
    btnSubmit: this.props.t('commons.buttons.confirm'),
    btnClass: 'btn_primary btn_full-width',
    annotation: (
      <div>
        <p>{this.props.t('panels.uk_issue.annotation.text')}</p>
        <a
          className='link'
          href='#'
          onClick={(e) => {
            e.preventDefault();
            window.sendMessageToWebview({
              action: 'openBrowser',
              url: 'https://boxt.one/est-vopros/#tp_app',
            });
          }}
        >
          {this.props.t('panels.uk_issue.annotation.link')}
        </a>
        <br />
        <a
          className='link'
          href='#'
          onClick={(e) => {
            e.preventDefault();
            window.sendMessageToWebview({
              action: 'openBrowser',
              url: 'https://boxt.one/pr/new_instructions.pdf',
            });
          }}
        >
          {this.props.t('panels.uk_issue.annotation.how_to')}
        </a>
        <br />
        <a
          className='link'
          href='#'
          onClick={(e) => {
            e.preventDefault();
            window.sendMessageToWebview({
              action: 'openBrowser',
              url: 'https://boxt.one/pr/push_instructions.pdf',
            });
          }}
        >
          {this.props.t('panels.uk_issue.annotation.settings')}
        </a>
      </div>
    ),
    onError: (res) => {
      let error = '';
      if (res.violationList) {
        error = JSON.stringify(res.violationList);
      } else if (res.errorList) {
        error = JSON.stringify(res.errorList);
      }

      this.props.onAddNotice({
        group: 'g2',
        type: 'error',
        text: error,
      });

      this.setState({
        formData: {
          subject: '',
          message: '',
          file: '',
        },
      });
    },
    onSuccess: () => {
      this.state.formData = {
        subject: '',
        message: '',
        file: '',
      };

      this.props.onClose();

      this.props.onAddNotice({
        group: 'g2',
        type: 'success',
        text: this.props.t('notices.application_success_sent'),
      });
    },
    callback: () => {},
  };

  content() {
    return <Form {...this.issueFormProps} />;
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    isOpened: state.panel.current === 'UkAddIssue',
  }),
  {
    onAddNotice: addNotice,
    onClose: panelClose,
  },
)(UkAddIssue);
