import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BaseComponent from 'Components/BaseComponent';
import EditDevicePanel from 'Panels/EditDevice';
import AddCounterPanel from 'Panels/AddCounter';
import ConfigControllerPanel from 'Panels/ConfigController';
import UkAddIssue from 'Panels/UkAddIssue';
import AddUKPanel from 'Panels/AddUk';
import FilterDevice from 'Panels/FilterDevice';
import EditRooms from 'Panels/EditRooms';
import EditDevicesInRoom from './EditDevicesInRoom';
import ShowAgreements from './ShowAgreements';
import AddZwave from 'Panels/AddZwave';
import InviteUsers from 'Panels/InviteUsers';
import EditActiveApartment from 'Panels/EditActiveApartment';

class Panels extends BaseComponent {
  render() {
    return (
      <>
        {this.props.isOpened === 'InviteUsers' && (
          <InviteUsers t={this.props.t} />
        )}
        {this.props.isOpened === 'EditDevice' && (
          <EditDevicePanel t={this.props.t} />
        )}
        {this.props.isOpened === 'AddCounter' && (
          <AddCounterPanel t={this.props.t} />
        )}
        {/*<DashboardEditPanel />*/}
        {this.props.isOpened === 'ConfigController' && (
          <ConfigControllerPanel t={this.props.t} />
        )}
        {this.props.isOpened === 'UkAddIssue' && (
          <UkAddIssue t={this.props.t} />
        )}
        {this.props.isOpened === 'addUk' && <AddUKPanel t={this.props.t} />}
        {this.props.isOpened === 'FilterDevice' && (
          <FilterDevice t={this.props.t} />
        )}
        {this.props.isOpened === 'EditRooms' && <EditRooms t={this.props.t} />}
        {this.props.isOpened === 'EditDevicesInRoom' && (
          <EditDevicesInRoom t={this.props.t} />
        )}
        {this.props.isOpened === 'ShowAgreements' && (
          <ShowAgreements t={this.props.t} />
        )}
        {this.props.isOpened === 'EditActiveApartment' && (
          <EditActiveApartment t={this.props.t} />
        )}
        {this.props.isOpened === 'AddZwave' && <AddZwave t={this.props.t} />}
      </>
    );
  }
}

export default connect(
  (state) => ({
    isOpened: state.panel.current,
  }),
  null,
)(withTranslation()(Panels));
