import React, { useState, useRef, useEffect } from 'react';
import BaseSelect from 'Components/BaseSelect';
import ButtonOpenIntercom from './ButtonOpenIntercom';
import AuthService from 'Services/Auth';

interface Button {
  intercomId: string;
}

interface Camera {
  buttons?: Button[];
  cameraId: number;
  hasPlateRecognition: boolean;
  hasRecognition: boolean;
  name: string;
  rtspUrl: string;
  sort: number;
  url: string;
}

interface Option {
  label: string;
  value: number;
}

interface Props {
  dnsName: string;
  options: Option[];
  cameras: Camera[];
}

const WebtrcTwo: React.FC<Props> = ({ dnsName, options, cameras }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [option, setOption] = useState<Option | null>(null);
  const [webrtc, setWebrtc] = useState<RTCPeerConnection | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [intercomId, setIntercomId] = useState<string | null>(null);
  const [time, setTime] = useState<number | null>(null); // хранение предыдущего значения времени

  useEffect(() => {
    return () => {
      if (webrtc) {
        webrtc.close();
      }
    };
  }, [webrtc]);

  const handleCameraChange = (selectedCamera: Option) => {
    const intercomCandidate = cameras.find(
      (x) => x.cameraId === selectedCamera.value,
    );
    if (intercomCandidate && intercomCandidate.buttons?.length) {
      setIntercomId(intercomCandidate.buttons[0].intercomId);
    }
    setIsError(false);
    setOption(selectedCamera);
    startPlay(selectedCamera.value);
  };

  function startPlay(cameraId: number): void {
    if (webrtc) {
      webrtc.close();
    }

    const newWebrtc = new RTCPeerConnection({
      iceServers: [
        {
          urls: ['stun:stun.l.google.com:19302'],
        },
      ],
    });

    newWebrtc.ontrack = function (event: RTCTrackEvent) {
      if (videoRef.current) {
        videoRef.current.srcObject = event.streams[0];
        videoRef.current.play();
      }
    };

    newWebrtc.addTransceiver('video', { direction: 'sendrecv' });

    newWebrtc.onnegotiationneeded = async function handleNegotiationNeeded() {
      const offer = await newWebrtc.createOffer();
      await newWebrtc.setLocalDescription(offer);

      const headers = new Headers();
      headers.append('Authorization', 'Basic ' + btoa('demo:demo'));

      fetch(
        `https://${dnsName}/stream/camera${cameraId}/channel/0/webrtc?token=${
          AuthService.getToken() as string
        }`,
        {
          method: 'POST',
          body: new URLSearchParams({
            data: btoa(newWebrtc.localDescription!.sdp),
          }),
          headers: headers,
        },
      )
        .then((response) => response.text())
        .then((data) => {
          try {
            newWebrtc.setRemoteDescription(
              new RTCSessionDescription({
                type: 'answer',
                sdp: atob(data),
              }),
            );
          } catch (e) {
            if (option?.value === cameraId) {
              setIsError(true);
            }

            console.warn(e);
          }
        });
    };

    const webrtcSendChannel = newWebrtc.createDataChannel(
      'rtsptowebSendChannel',
    );
    webrtcSendChannel.onopen = (_event) => {
      console.log(`${webrtcSendChannel.label} has opened`);
      webrtcSendChannel.send('ping');
    };
    webrtcSendChannel.onclose = (_event) => {
      console.log(`${webrtcSendChannel.label} has closed`);
    };
    webrtcSendChannel.onmessage = () => {};

    setWebrtc(newWebrtc);
  }

  const restartConnection = () => {
    console.log('restarting connection');

    startPlay(option ? option.value : cameras[0].cameraId);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current && videoRef.current.currentTime !== null) {
        if (!videoRef.current.currentTime) {
          return;
        }
        if (time !== null && time === videoRef.current.currentTime) {
          restartConnection();
          return;
        }
        setTime(videoRef.current.currentTime);
      }
    }, 2500);

    return () => clearInterval(interval);
  }, [videoRef, time]);

  useEffect(() => {
    handleCameraChange(options[0]);
  }, [options]);

  return (
    <div>
      {isError ? (
        <div className={`video-player__content no-signal`}>
          <div className='video-player__text'>Нет сигнала</div>
          <video></video>
        </div>
      ) : (
        <video
          ref={videoRef}
          playsInline
          controls
          muted
          style={{
            width: '100%',
            backgroundColor: 'black',
          }}
        ></video>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '10px',
        }}
      >
        <BaseSelect
          isCreatable={false}
          isClearable={false}
          isSearchable={false}
          options={options}
          value={option}
          placeholder='Выберите камеру'
          onSelectChange={handleCameraChange}
        />
        {intercomId && (
          <ButtonOpenIntercom key={intercomId} intercomId={intercomId} />
        )}
      </div>
    </div>
  );
};

export default WebtrcTwo;
