import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  LOADING_ACTIVE,
  CHANGE_DEVICE_CATEGORY,
} from 'Actions/dialogWindow';

export type IState = {
  isOpen: boolean;
  isLoading: boolean;
};

const initialState: IState = {
  isOpen: false,
  isLoading: false,
};

export const dialogWindow = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case CLOSE_DIALOG:
      return {
        isOpen: false,
        isLoading: false,
      };
    case LOADING_ACTIVE:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_DEVICE_CATEGORY:
      return;
  }

  return state;
};
