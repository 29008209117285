import React from 'react'
import BaseModal from 'Components/BaseModal'
import { RoomUndefined } from 'Components/Icons'

class RoomDevicesModal extends BaseModal {
  id = 'RoomDevicesModal'
  state = {
    values: {}
  }

  executeDevice(device, cId) {
    const state = { ...this.state }

    if (device.type === 'device') {
      this.props.onStatusToggle(device.id)
    } else if (device.type === 'sensor') {
      this.props.onNotifyToggle(device.id)
    }

    state.values[cId] = !state.values[cId]

    this.setState(state)
  }

  getTitle() {
    return this.props.data.options ? this.props.data.options.title : ''
  }

  content() {
    const { data } = this.props

    if (!data.options) return null

    return (
      <>
        <div className="room-info">
          {data.options.icon ? data.options.icon : RoomUndefined}
        </div>
        <div className="room-control">
          {data.devices && data.devices.length > 0 ? (
            Object.keys(data.devices).map(i => {
              const item = data.devices[i]
              const cId = `${item.type}_${item.id}`

              if (this.state.values[cId] === undefined)
                this.state.values[cId] = item.value === 1

              return (
                <div className="room-control__item" key={i}>
                  <div className="room-control__label">{item.label}</div>
                  <div className="room-control__switch switch-ios">
                    <input
                      id={cId}
                      type="checkbox"
                      onChange={this.executeDevice.bind(this, item, cId)}
                      checked={this.state.values[cId]}
                    />
                    <label className="tgl-btn" htmlFor={cId}>
                      <span />
                    </label>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="room-control__item">
              Нет устройств,
              <span
                className="link"
                onClick={() => {
                  this.props.onPanelOpen('ConfigController', {
                    type: 'addDevice',
                    title: 'Добавление устройства'
                  })
                  this.props.onModalClose()
                }}>
                &nbsp;добавим?
              </span>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default RoomDevicesModal
