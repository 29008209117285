import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
  StarBroken,
  StarFilled,
  StarUnfilled,
  SuccessFeedback,
} from 'Components/Icons';
import UserService from 'Services/User';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'Config/index';

const steps = {
  start: 0,
  middle: 1,
  end: 2,
};

type IProps = {
  onHiddenModal: () => void;
};

const Rating = ({ onHiddenModal }: IProps) => {
  const [step, setStep] = useState<number>(steps.start);
  const [rate, setRate] = useState<number>(0);
  const [lastRate, setLastRate] = useState<number | void>();
  const [textarea, setTextarea] = useState<string>('');
  const [t] = useTranslation();

  const onSetRate = (val: number) => {
    setLastRate(rate);
    if (val > 0 && val < 4) {
      setStep(steps.middle);
    } else {
      setStep(steps.start);
    }
    setRate(val);
  };

  const _onLater = () => {
    UserService.skipFeedback((res) => {
      if (res.ok) {
        onHiddenModal();
      }
    });
  };

  const _saveRating = (content, rating) => {
    UserService.addFeedback({ content, rating }, (res) => {
      if (res.ok) {
        setStep(steps.end);
        setTextarea('');
      }
    });
  };

  const _onSubmit = ({ content = '', rating = 0 }) => {
    _saveRating(content, rating);
  };

  const getButtons = () => {
    if (rate > 0) {
      if (step === steps.middle) {
        return (
          <button
            className='btn btn_primary btn_sm'
            type='button'
            onClick={() => _onSubmit({ content: textarea, rating: rate })}
          >
            {t('commons.buttons.send')}
          </button>
        );
      } else if (isIOS) {
        return (
          <button
            className='btn btn_primary btn_sm'
            onClick={() => {
              setTextarea('');
              window.sendMessageToWebview({
                action: 'openBrowser',
                url: 'https://apps.apple.com/ru/app/t-one-app/id1479967254',
              });
              _onSubmit({ content: textarea, rating: rate });
            }}
          >
            {t('commons.buttons.rate')}
          </button>
        );
      } else {
        return (
          <button
            className='btn btn_primary btn_sm'
            onClick={() => {
              setTextarea('');
              window.sendMessageToWebview({
                action: 'openBrowser',
                url: 'https://play.google.com/store/apps/details?id=com.tone.client',
              });
              _onSubmit({ content: textarea, rating: rate });
            }}
          >
            {t('commons.buttons.rate')}
          </button>
        );
      }
    } else {
      return (
        <button
          className='btn btn_primary btn_sm'
          type='button'
          disabled={true}
        >
          {t('commons.buttons.send')}
        </button>
      );
    }
  };

  if (step === steps.start || step === steps.middle)
    return (
      <div className={styles.wrapper}>
        <div className={styles.containerPanel}>
          <div className={styles.bodyPanel}>
            <h3 className={styles.titlePanel}>{t('rating.title_panel')}</h3>
            <div className={styles.rateWrapper}>
              <p className={styles.descriptionPanel}>
                {t('rating.description_panel_top')}:
              </p>
              <ul className={styles.listStars}>
                {[1, 2, 3, 4, 5].map((idx) => (
                  <li className={styles.starWrap} key={idx}>
                    <button type='button' onClick={() => onSetRate(idx)}>
                      <span
                        className={rate < idx ? styles.visible : styles.hidden}
                      >
                        {StarUnfilled}
                      </span>
                      <span
                        className={rate >= idx ? styles.visible : styles.hidden}
                      >
                        {StarFilled}
                      </span>
                      <span
                        className={
                          lastRate && lastRate > idx - 1 && rate < idx
                            ? styles.brokenStarShow
                            : styles.hidden
                        }
                      >
                        {StarBroken}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
              {rate > 3 && (
                <div className={styles.textWrapper}>
                  <p className={styles.descriptionPanel}>
                    {t('rating.description_panel_bottom')}
                  </p>
                </div>
              )}
              {step === steps.middle && (
                <div className={styles.textWrapper}>
                  <p className={styles.descriptionPanel}>
                    {t('rating.description_panel_textarea')}
                  </p>
                  <textarea
                    value={textarea}
                    onChange={(e) => setTextarea(e.currentTarget.value)}
                    maxLength={500}
                    className={styles.textarea}
                  />
                </div>
              )}
            </div>
            <div className='btn-form-wrap'>
              <button
                className='btn btn_secondary btn_sm'
                type='button'
                onClick={_onLater}
              >
                {t('commons.buttons.later')}
              </button>
              {getButtons()}
            </div>
          </div>
        </div>
      </div>
    );

  if (step === steps.end) {
    return (
      <div className={styles.wrapper} onClick={() => onHiddenModal()}>
        <div className={styles.bodyModal}>
          <h3 className={styles.titleModal}>{t('rating.title_modal')}</h3>
          <div>{SuccessFeedback}</div>
          <p className={styles.descriptionModal}>
            {t('rating.description_modal')}
          </p>
        </div>
      </div>
    );
  }
};

export default Rating;
