class ScrollBarConfig {
  scrollbar
  props = {}

  constructor(props = {}) {
    this.props = {
      style: {
        width: '100%',
        height: '100%'
      },
      noScrollX: true,
      removeTracksWhenNotUsed: false,
      trackYProps: {
        style: {
          borderRadius: '2px',
          background: 'none',
          width: '6px',
          height: 'calc(100% - 10px)',
          top: '5px',
          right: '3px',
          opacity: 0
        },
        onMouseOver: this.showTracks.bind(this),
        onMouseOut: this.hideTracks.bind(this)
      },
      trackXProps: {
        style: {
          borderRadius: '2px',
          background: 'none',
          height: '6px',
          width: 'calc(100% - 10px)',
          left: '5px',
          bottom: '3px',
          opacity: 0
        },
        onMouseOver: this.showTracks,
        onMouseOut: this.hideTracks
      },
      thumbXProps: {
        style: {
          borderRadius: '2px',
          background: 'rgba(29, 33, 53, 0.25)',
          height: '4px',
          marginTop: '1px'
        },
        onDragEnd: this.handleDragEnd.bind(this)
      },
      thumbYProps: {
        style: {
          borderRadius: '2px',
          background: 'rgba(29, 33, 53, 0.25)',
          width: '4px',
          marginLeft: '1px'
        },
        onDragEnd: this.handleDragEnd.bind(this)
      },
      ref: ref => {
        this.scrollbar = ref

        if (typeof props.onLoad === 'function') {
          props.onLoad(ref)
        }
      },
      onScrollStart: this.showTracks.bind(this),
      onScrollStop: this.hideTracks.bind(this),
      onScroll: this.handleGoUpBtn.bind(this),
      scrollDetectionThreshold: 500
    }
  }

  toBottom = () => {
    if (!this.scrollbar) return

    this.scrollbar.scrollToBottom()
  }

  toTop = () => {
    if (!this.scrollbar) return

    this.scrollbar.scrollToTop()
  }

  showTracks = () => {
    if (!this.scrollbar) return

    this.scrollbar.trackXEl.style.opacity = 1
    this.scrollbar.trackYEl.style.opacity = 1

    this.dragEndTO && clearTimeout(this.dragEndTO)
    this.dragEndTO = null
  }

  hideTracks = () => {
    if (
      !this.scrollbar ||
      this.scrollbar.thumbXEl.classList.contains('dragging') ||
      this.scrollbar.thumbYEl.classList.contains('dragging')
    ) {
      return
    }

    this.scrollbar.trackXEl.style.opacity = 0
    this.scrollbar.trackYEl.style.opacity = 0

    this.dragEndTO && clearTimeout(this.dragEndTO)
    this.dragEndTO = null
  }

  handleDragEnd = () => {
    if (!this.scrollbar) return

    this.dragEndTO = setTimeout(this.hideTracks.bind(this), 500)
  }

  handleGoUpBtn = () => {
    const el = document.querySelector('.go-up')
    if (el) {
      if (this.scrollbar.scrollTop > 100) {
        el.classList.add('show')
      } else {
        el.classList.remove('show')
      }
    }
  }
}

export default ScrollBarConfig
