const component = 'scenarios';

export const EVENT_SCENARIOS_LOAD = `${component}/LOAD`;

export function scenariosLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_SCENARIOS_LOAD,
      event: event
    });
  };
}