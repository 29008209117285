import React from 'react';

export default () => (
  <div>
    <ol>
      <li>
        <a href='#p1'>Общие положения</a>
      </li>
      <li>
        <a href='#p2'>Понятия определения, используемые в Условиях</a>
      </li>
      <li>
        <a href='#p3'>Правила использования Услуг</a>
      </li>
      <li>
        <a href='#p4'>Права и обязанности клиента</a>
      </li>
      <li>
        <a href='#p5'>Права и обязанности Исполнителя</a>
      </li>
      <li>
        <a href='#p6'>Стоимость Услуг и порядок расчетов</a>
      </li>
      <li>
        <a href='#p7'>Ответственность сторон</a>
      </li>
      <li>
        <a href='#p8'>Блокирование и удаление Аккаунта</a>
      </li>
      <li>
        <a href='#p9'>Техническая поддержка</a>
      </li>
      <li>
        <a href='#p10'>Прочие условия</a>
      </li>
      <li>
        <a href='#p11'>Адрес и реквизиты Исполнителя</a>
      </li>
      <li>
        <a href='#p12'>Обновление настоящей Оферты</a>
      </li>
    </ol>

    <div className='col-md-8 col-lg-9'>
      <div id='intro' className='space-bottom-1'>
        <div className='container custom documents'>
          <p>
            Настоящий документ (далее – «Оферта») представляет собой предложение
            Общество с ограниченной ответственностью «Т.один» (ОГРН
            1181832019544) (далее — «Т.один»), адресованное физическим лицам,
            достигшим возраста 18 лет, являющимся пользователем ОАСУ T.one
            (далее – «Пользователь»), заключить возмездный договор на
            предоставление Пользователю расширенных возможностей Сервиса ОАСУ
            T.one на изложенных ниже условиях.
          </p>
          <p id='p1'>
            <strong>I Общие положения</strong>
          </p>
          <ol start=''>
            <li>
              Настоящие Условия в соответствии со ст. 435, 437 Гражданского
              кодекса РФ являются публичной офертой (предложением) Исполнителя в
              адрес физических лиц, содержащей существенные условия договора на
              оказание и использование Услуг.
            </li>
            <li>
              Использование расширенных возможностей Сервиса Пользователем
              возможно при условии приобретения Пользователем подписки «BOX+»
              (далее - «Подписка») на условиях настоящей Оферты.
            </li>
            <li>
              Полным и безоговорочным акцептом настоящей оферты являются любые
              действия по выполнению указанных в данной оферте условий, в т. ч.
              авторизации в качестве Клиента Исполнителя, оплата Услуг любым
              предусмотренным настоящей офертой способом, а также иные
              аналогичные действия, подтверждающие намерения лица пользоваться
              возмездными и безвозмездными Услугами.
            </li>
            <li>
              С момента совершения акцепта Клиент считается ознакомившимся и
              согласившимся с настоящей офертой и в соответствии с Гражданским
              кодексом РФ считается вступившим с Исполнителем в договорные
              отношения в соответствии с настоящими Условиями.
            </li>
            <li>
              Обязанности Исполнителя ограничиваются условиями настоящей оферты,
              в частности, в обязанности Исполнителя не входит оказание услуг
              Клиенту по предоставлению доступа в сеть Интернет. Доступ к сети
              Интернет Клиент обеспечивает за свой счет самостоятельно. Также
              Исполнитель не предоставляет настройку и/или диагностику
              компьютерного оборудования, мобильных устройств и программного
              обеспечения, а также обучение Клиента и/или сотрудника Клиента
              навыкам работы с программным и аппаратным обеспечением.
            </li>
          </ol>
          <p id='p2'>
            <strong>II. Понятия определения, используемые в Условиях</strong>
          </p>
          <ol start=''>
            <li>
              Понятия и определения, используемые в настоящих Условиях, имеют
              следующее значение:
            </li>
            <li>
              УСЛУГИ — предоставляемые Исполнителем услуги удаленного доступа к
              общедомовому и квартирному оборудованию, посредством приложения
              ОАСУ T.one и иные сопутствующие услуги.
            </li>
            <li>
              КЛИЕНТ — физическое или юридическое лицо или индивидуальный
              предприниматель, совершившее акцепт настоящей оферты в
              соответствии со ст. 1 настоящих Условий.
            </li>
            <li>
              ЛИЦЕВОЙ СЧЕТ — виртуальный индивидуальный счет Аккаунта, на
              котором ведется баланс оплаченных и использованных Услуг, включая
              объем списанных за оказанные Услуги денежных средств и остаток на
              счете.
            </li>
            <li>
              ТАРИФ – стоимость конкретной Услуги за определенный период ее
              использования, обусловленный спецификой соответствующей Услуги.
            </li>
            <li>
              АККАУНТ — структура конкретных данных, информации, объема Услуг и
              Лицевого счета, совокупно относящихся к единому элементу базы
              данных системы ОАСУ T.one и индивидуализируемая учетными данными,
              указанными Клиентом при заключении настоящего Договора оферты.
              Доступ в аккаунт доступен через мобильное приложение ОАСУ T.one.
            </li>
            <li>
              Прочие понятия и определения, прямо не определенные в настоящих
              Условиях, трактуются в соответствии с законодательством РФ,
              обычаями делового оборота, в частности, в соответствии с Нормами
              пользования Сетью (http://www.ofisp.org/documents/ofisp-008.html)
            </li>
          </ol>
          <p id='p3'>
            <strong>III. Правила использования Услуг</strong>
          </p>
          <ol>
            <li>
              Активация в системе ОАСУ T.one осуществляется Клиентом
              самостоятельно путем регистрации через QR-код и указания своих
              учетных данных в соответствии с инструкцией системы. Основными
              элементами, идентифицирующими Клиента в системе ОАСУ T.one,
              являются логин (e-mail) и пароль. Исполнитель не осуществляет
              проверку данных Клиента, однако, имеет право в любое время
              запросить подтверждение правильности введенных данных или
              предоставление не введенных данных (путем предоставления копий
              либо оригиналов подтверждающих документов) и приостановить
              оказание Услуг до момента предоставления подтверждающих документов
              и изменения и/или дополнения Клиентом запрашиваемых данных.
            </li>
            <li>
              Восстановление пароля с помощью инструкций ОАСУ T.one может быть
              осуществлено Клиентом самостоятельно через приложение ОАСУ T.one.
              Исполнитель восстанавливает пароль только при предоставлении
              Клиентом информации по аккаунту (ФИО, e-mail, телефон),
              идентифицирующих Клиента, и соответствии данных, указанных при
              регистрации Аккаунта. В противном случае пароль, используемый
              Клиентом для доступа к Аккаунту, Исполнителем не
              восстанавливается.
            </li>
            <li>
              Переход на расширенную версию «BOX+», осуществляется через личный
              кабинет в мобильном приложении (вкладка Профиль). Для активации
              необходимо дать согласие, нажав на кнопку "Подключить BOX+" и
              следуя инструкции оплатить первый месяц пользования тарифа. По
              окончанию оплаченного срока, система выдаст напоминание об оплате,
              а также появится возможность подключить авто платёж (необходимо
              проставить дополнительно галочку). Если после месяца использования
              вы решите не использовать платный тариф, вы можете проигнорировать
              сообщение об оплате, тогда система автоматически переведет ваше
              приложение в базовый режим.
            </li>
            <li>
              После активации Аккаунта и оплаты Услуг (с соблюдением условий,
              установленных настоящими Правилами), Клиент получает возможность
              пользоваться Услугами с момента пополнения Лицевого счета и после
              списания оплаты за пользование Услугами. Списание средств (оплата
              за Услуги) осуществляется за последующий период равный месяцу.
              Если в течении суток биллинговая система Сервиса не обнаруживает
              необходимую сумму для списания, аккаунт автоматически переходит в
              базовую версию, без доступа к дополнительным услугам.
            </li>
            <li>
              Клиент несет полную ответственность за действия и/или бездействие,
              приведшие к разглашению, потере, краже и т. п. его учетных данных
              и иной информации, индивидуализирующей Клиента, а также за любые
              действия и/или бездействие третьих лиц, использующих учетные
              данные Клиента. Исполнитель не несет ответственности за
              вышеуказанные действия Клиента и/или третьих лиц, использующих его
              учетные данные.
            </li>
            <li>
              Исполнителем осуществляется резервное копирование данных Клиента,
              за исключением лог-файлов Клиента. При этом резервное копирование
              данных Клиента осуществляется Исполнителем в сроки и периоды,
              устанавливаемые Исполнителем самостоятельно, если иное не
              предусмотрено подключенными Клиентом Услугами.
            </li>
            <li>
              При использовании Услуг запрещаются любые действия, направленные
              на получение несанкционированного доступа к ресурсам сервера и
              других технических средств Исполнителя, персональным учетным и
              иным данным других Клиентов, а также к любым другим данным,
              доступным через сеть Интернет, в частности, уничтожение или
              модификация программного обеспечения и/или данных, не
              принадлежащих Клиенту, без согласования с владельцем такого
              программного обеспечения и/или данных. Под несанкционированным
              доступом понимаются действия, направленные на получение доступа к
              ресурсам способом, отличным от предполагавшегося владельцем
              ресурса в качестве разрешенного.
            </li>
            <li>
              При использовании Услуг Клиенту запрещается осуществлять массовую
              рассылку «спама», т. е. электронных сообщений коммерческого,
              рекламного и иного характера, не согласованных (не запрошенных)
              получателем информации.
            </li>
            <li>
              Запрещается рассылка «спама» через ресурсы, не принадлежащие
              Исполнителю, в том случае, если в сообщении указаны координаты
              (адрес сайта, электронной почты и т. д.), поддерживаемые
              Исполнителем.
            </li>
            <li>
              Исполнителем вводятся следующие ограничения на размещаемую
              информацию («контент»):
              <ul>
                <li>
                  Использование Услуг должно осуществляться Клиентом только для
                  законных целей и законными способами.
                </li>
                <li>
                  Исполнитель не осуществляет предварительного контроля за
                  содержанием размещаемой и/или распространяемой Клиентом
                  информации, однако когда размещение и распространение такой
                  информации противоречит текущему законодательству, Исполнитель
                  вправе заблокировать и/или удалить соответствующий Аккаунт.
                </li>
                <li>
                  Запрещается размещение и распространение информации
                  оскорбительного, нецензурного, эротического и
                  порнографического характера, призывов к насилию, свержению
                  существующей власти и т. п., а также использование чата для
                  отправки сообщений указанного характера. В рамках настоящих
                  Условий Исполнитель самостоятельно определяет характер
                  размещенной и распространенной информации и критерии отнесения
                  ее к типам информации, запрещенной к размещению и
                  распространению в соответствии с настоящим пунктом.
                </li>
                <li>
                  Запрещается передавать, воспроизводить или распространять
                  любым способом программное обеспечение или любые другие
                  материалы, полностью или частично защищенные авторскими или
                  другими правами, без разрешения владельца.
                </li>
                <li>
                  Запрещается распространение любой информации или программного
                  обеспечения, которое содержит в себе компьютерные вирусы или
                  другие компоненты, приравненные к ним.
                </li>
                <li>
                  Запрещается рекламировать и продавать услуги, товары и иные
                  материалы, распространение которых ограничено или запрещено
                  действующим законодательством.
                </li>
                <li>
                  Запрещается публиковать или иным образом размещать информацию
                  о третьих лицах, прямо или косвенно затрагивающую честь и
                  достоинство либо деловую репутацию таких третьих лиц, а также
                  размещать персональную идентифицирующую третьих лиц информацию
                  без их согласия.
                </li>
              </ul>
            </li>
            <li>
              Запрещается осуществление любых действий, направленных на
              сканирование сетей, обнаружение уязвимостей в безопасности
              программного обеспечения, подбор пароля и иных идентифицирующих
              данных, обнаружение списков открытых портов, незащищенных разделов
              ресурсов и пр. без явно выраженного согласия Исполнителя, а также
              использование анонимных прокси-серверов при использовании Услуги.
            </li>
            <li>
              Запрещается осуществление иных действий, противоречащих
              действующему законодательству и нарушающих права и законные
              интересы Исполнителя и/или третьих лиц.
            </li>
            <li>
              Клиент не вправе использовать предоставленные Исполнителем ресурсы
              для запуска программного обеспечения, работа которого может
              привести к нарушению работоспособности программно-аппаратного
              комплекса Исполнителя, а также сетей, программных, аппаратных
              и/или информационных ресурсов третьих лиц либо для получения
              незаконного доступа напрямую или косвенно к информации третьих
              лиц, при этом не учитывается, используется ли такое программное
              обеспечение Клиентом с умыслом или без такового.
            </li>
          </ol>
          <p id='p4'>
            <strong>IV. Права и обязанности клиента</strong>
          </p>
          <ol>
            <li>Клиент обязуется:</li>
            <li>
              Безусловно выполнять настоящие Условия и следить за изменениями и
              дополнениями в них и Приложениях к ним, публикуемыми на веб-сайте
              Исполнителя (https://boxt.one/publichnaya-ofreta), а также Нормы
              пользования Сетью (http://www.ofisp.org/documents/ofisp-008.html)
              и иные обычаи делового оборота, существующие в области Услуг.
            </li>
            <li>
              При активации Аккаунта предоставить правдивую, точную и полную
              информацию о себе по всем вопросам, запрашиваемым при активации, и
              поддерживать эту информацию в актуальном состоянии.
            </li>
            <li>
              Следить за изменением стоимости Услуг, предусмотренной Тарифами.
              Изменения стоимости Услуг публикуются на веб-сайте Исполнителя
              (http://www.boxt.one).
            </li>
            <li>
              Осуществлять предоплату за Услуги в размере, не меньшем размера
              минимального единоразового платежа. При осуществлении предоплаты в
              размере, меньшем размера минимального единоразового платежа,
              Клиент обязуется осуществить доплату таким образом, чтобы
              суммарный объем осуществленной предоплаты был не меньше, чем
              установленный размер минимального единоразового платежа.
            </li>
            <li>
              Самостоятельно следить за балансом Лицевого счета и своевременно
              осуществлять его пополнение в размерах и порядке, предусмотренных
              настоящими Условиями.
            </li>
            <li>
              Использовать Услуги и размещать информацию в рамках использования
              Услуг только в соответствии с требованиями действующего
              законодательства и настоящих Условий, в том числе не размещать в
              ОАСУ T.one Исполнителя информацию, запрещенную к хранению,
              использованию и/или распространению или ограниченные в хранении,
              использовании и/или распространении согласно законодательству РФ,
              другому национальному и международному законодательству.
            </li>
            <li>
              Соблюдать авторские и иные права на предоставляемые Исполнителем
              и/или третьими лицами программное обеспечение, информацию и
              документацию. Самостоятельно нести ответственность за нарушение
              любых авторских/патентных и прочих прав, а также своевременно
              отвечать на перенаправленные Исполнителем жалобы о нарушении
              соответствующих прав.
            </li>
            <li>
              Соблюдать конфиденциальность своих учетных данных и принимать
              меры, направленные на их защиту от утери, кражи, повреждения и т.
              п.
            </li>
            <li>
              Своевременно уведомлять Исполнителя о сбоях, чтобы избежать потери
              информации в случаях перебоев в оказании Услуг, вызванных аварией
              и иными, не зависящими от Исполнителя причинами.
            </li>
            <li>
              Выполнить в полном объеме другие обязательства, требования и
              ограничения, предусмотренные настоящими Условиями.
            </li>
          </ol>
          <p id='p5'>
            <strong>V. Права и обязанности Исполнителя</strong>
          </p>
          <ol>
            <li>Исполнитель обязуется:</li>
            <li>
              Обеспечивать предоставление Услуг в объеме, соответствующем сумме
              предоплаты Клиентом за Услуги в соответствии с настоящими
              Условиями, круглосуточно 7 дней в неделю, включая выходные и
              праздничные дни. При оказании Услуг Исполнитель гарантирует
              качество Услуг, соответствующее стандартам и техническим нормам,
              установленным в РФ.
            </li>
            <li>
              Вести учет предоплаты и потребления Клиентом Услуг на Лицевом
              счете Аккаунта.
            </li>
            <li>
              Публиковать на сайте Исполнителя (https://boxt.one/) изменения и
              дополнения в настоящих Условиях, Тарифах и иных документах,
              относящихся к оказанию Услуг, в срок не позднее 5 календарных дней
              до даты введения их в действие.
            </li>
            <li>
              Соблюдать конфиденциальность учетных данных Клиента. Исполнитель
              имеет доступ к информации Клиента в целях технического обеспечения
              Услуг, а также имеет право на доступ к такой информации в случаях
              получения претензий от третьих лиц, касающихся противозаконных
              и/или вредоносных, а также иных действий Клиента, наносящих вред
              Исполнителю и/или третьим лицам. Исполнитель вправе сообщить
              вышеуказанные данные только компетентным государственным органам в
              случаях, предусмотренных действующим законодательством РФ.
            </li>
            <li>
              Резервное копирование данных Клиента в целях предотвращения потери
              информации не является нарушением конфиденциальности информации
              Клиента.
            </li>
            <li>Исполнитель имеет право:</li>
            <li>
              Приостанавливать предоставление Услуг для проведения необходимых
              плановых профилактических и ремонтных работ на технических
              ресурсах Исполнителя, а также внеплановых работ в аварийных
              ситуациях. Сообщение о производстве вышеуказанных работ, влекущих
              за собой приостановление в оказании Услуг, публикуется
              Исполнителем на своем веб-сайте или в одной или всех соц. сетях
              (https://www.instagram.com/ask.boxt.one/, https://vk.com/boxtone,
              https://twitter.com/askboxtone), с указанием предположительной
              продолжительности таких работ (за исключением аварийных ситуаций,
              когда информация о приостановлении в оказании Услуг публикуется
              Исполнителем по мере возможности).
            </li>
            <li>
              Прерывать оказание Услуг, если это обусловлено, в частности, но не
              ограничиваясь нижеизложенным, невозможностью использования
              информационно-транспортных каналов, не являющихся собственными
              ресурсами Исполнителя, либо действием и/или бездействием третьих
              лиц, если это непосредственно влияет на оказание Услуг в рамках
              данных Условий, в том числе при аварийной ситуации. Исполнитель не
              несет ответственности перед Клиентом и не возмещает Клиенту
              убытки, возникшие или могущие возникнуть у Клиента в связи с
              задержками, перебоями в работе и невозможностью полноценного
              использования ресурсов и Услуг Исполнителя, возникших по
              вышеперечисленным причинам.
            </li>
            <li>
              Вносить в одностороннем порядке изменения и дополнения в настоящие
              Условия и Тарифы путем публикации этих изменений и дополнений на
              сайте Исполнителя (https://boxt.one/).
            </li>
            <li>
              Приостанавливать предоставление Услуг либо отменить активацию
              Аккаунта и отказать в дальнейшем в предоставлении и использовании
              Услуг в случае предоставления Клиентом неверной информации либо
              неосуществления ее актуализации при изменении данных, а также в
              случае несвоевременного представления подтверждающих документов
              либо если у Исполнителя возникают сомнения в том, что
              предоставленная Клиентом информация верна, полна или точна, а
              также осуществлять в безакцептном порядке списание остатка
              денежных средств на Лицевом счете в случаях и порядке,
              предусмотренных настоящими Условиями.
            </li>
            <li>
              В соответствии со ст. 44 Федерального закона «О связи»
              приостанавливать оказание Услуг в случае нарушения Клиентом
              настоящих Условий, а также действующего законодательства.
            </li>
            <li>
              Блокировать и/или удалять Аккаунт, а также осуществлять в
              безакцептном порядке списание остатка денежных средств на Лицевом
              счете в случаях и порядке, предусмотренных настоящими Условиями.
            </li>
            <li>
              При поступлении к Исполнителю денежных средств в размере, меньшем
              суммы единоразового платежа, предусмотренного настоящими
              Условиями, Исполнитель вправе не зачислять полученные средства на
              Лицевой счет Клиента до момента осуществления Клиентом доплаты.
              Возврат Клиенту денежной суммы, оплаченной в размере, меньшем
              размера минимального единоразового платежа, осуществляется
              Исполнителем в безналичном порядке только по письменному заявлению
              Клиента, полученному Исполнителем в соответствии в п. 10.3
              настоящих Условий, в котором Клиент обязан указать все данные
              Аккаунта, дату, размер осуществленной предоплаты, реквизиты
              банковского счета Клиента, с приложением копии документа,
              подтверждающего внесение предоплаты.
            </li>
          </ol>
          <p id='p6'>
            <strong>VI. Стоимость Услуг и порядок расчетов</strong>
          </p>
          <ol>
            <li>
              Стоимость Услуг определяется в соответствии с Тарифами,
              являющимися неотъемлемой частью настоящих Условий. Тарифы
              размещаются Исполнителем на сайте https://www.boxt.one или в
              мобильном приложении ОАСУ T.one.
            </li>
            <li>
              Общая стоимость Услуг определяется как суммарный объем денежных
              средств, списанных с Лицевого счета Аккаунта за оказанные Клиенту
              Услуги в соответствии с Тарифами, действующими на момент оказания
              Услуг.
            </li>
            <li>
              Услуги оплачиваются Клиентом на условиях предоплаты, при этом
              способ предоплаты Услуг определяется Клиентом самостоятельно.
              Способами предоплаты являются:
              <ul>
                <li>
                  Предоплата Услуг через Сбербанк РФ (для физических лиц);
                </li>
                <li>
                  Предоплата Услуг иным, не запрещенным действующим
                  законодательством РФ, способом (например, WebMoney,
                  Яндекс-деньги, терминалы оплаты «ОСМП» и пр.).
                </li>
                <li>
                  При осуществлении предоплаты минимальный единоразовый платеж
                  для физических лиц составляет : 50руб. + 10руб. (20% НДС) +
                  18руб.(сбор 30% в Google Play Market и Apple Storе) = 78 руб.
                  (Итоговая сумма: семьдесят восемь рублей 00 копеек).
                  Банковские комиссии в расчет не входят и оплачиваются
                  пользователем отдельно.
                </li>
                <li>
                  Датой начала оказания Услуг Исполнителем является дата
                  подключения Клиентом тарифа «BOX+» и своевременная предоплата
                  по данному тарифу.
                </li>
              </ul>
            </li>
            <li>
              Исполнитель имеет право на списание остатка денежных средств на
              соответствующем Лицевом счете в случае удаления Аккаунта в размере
              и порядке, предусмотренных настоящими Условиями.
            </li>
            <li>
              В случае если Клиент в предусмотренный настоящими Условиями срок
              письменно не уведомил Исполнителя о своем несогласии с новыми
              Тарифами, Клиент считается согласившимся с изменением Тарифов, и
              списание денежных средств с Лицевого счета Аккаунта за оказанные
              Услуги осуществляется Исполнителем по новым Тарифам.
            </li>
            <li>
              Все банковские либо иные комиссионные платежи за оплату Услуг
              оплачиваются Клиентом самостоятельно, если конкретным способом
              оплаты не предусмотрено иное.
            </li>
          </ol>
          <p id='p7'>
            <strong>VII. Ответственность сторон</strong>
          </p>
          <ol>
            <li>
              По всем вопросам, неурегулированными настоящими Условиями, а также
              при разрешении споров, возникших в процессе их исполнения, стороны
              руководствуются действующим законодательством РФ. В случае
              невозможности разрешения сторонами спора в досудебном
              претензионном порядке, он подлежит разрешению в соответствующем
              суде г. Ижевск в соответствии с действующим законодательством РФ.
            </li>
            <li>
              Клиент несет полную ответственностью за сохранность своего пароля
              и иных учетных данных, также как и за убытки, которые могут
              возникнуть у Клиента и/или третьих лиц по причине
              несанкционированного использования (разглашение, потеря, кража и
              т. д.) таких учетных данных.
            </li>
            <li>
              Клиент несет ответственность за любые неправомерные действия и/или
              бездействие Клиента и/или лиц, использующих его учетные данные,
              повлекшие причинение любого вреда Исполнителю, включая утрату
              деловой репутации, и возмещает Исполнителю убытки. Исполнитель
              также не несет ответственности за действия третьих лиц, повлекшие
              ущерб для Клиента.
            </li>
            <li>
              Исполнитель не несет ответственности за содержание размещаемой
              Клиентом информации, и не осуществляет какой-либо предварительной
              проверки и цензуры.
            </li>
            <li>
              Клиент несет ответственность за любые свои действия и/или
              бездействие, как умышленные, так и неумышленные, а равно за любые
              действия и/или бездействие лиц, использующих его учетные данные,
              связанные с размещением и/или распространением информации в сети
              Интернет, а также получением посредством использования ресурсов
              Исполнителя доступа к ресурсам третьих лиц.
            </li>
            <li>
              Клиент самостоятельно несет ответственность за умышленные и/или
              неумышленные действия и/или бездействие Клиента и/или лиц,
              использующих его учетные данные, повлекшие нарушение любого
              национального законодательства (Российской Федерации или других
              стран) и/или международного законодательства, а также за любой
              ущерб, причинный вышеуказанными действиями и/или бездействием
              Исполнителю, третьим лицам, а также за последствия таких действий
              и/или бездействия.
            </li>
            <li>
              Исполнитель не несет ответственности за прямой и/или косвенный
              ущерб, причиненный Клиенту, а также не возмещает Клиенту убытки
              (включая упущенную выгоду), понесенные Клиентом в результате
              использования или неиспользования ресурсов и Услуг Исполнителя, а
              также за убытки, понесенные в результате пропусков, перерывов в
              работе или задержек в передаче данных, ошибок, дефектов, удаления
              файлов, изменений функций и других причин.
            </li>
            <li>
              Исполнитель не несет ответственности за качество каналов связи
              общего пользования, политику обмена трафиком между провайдерами,
              нормальное функционирование сети Интернет, ее частей или за
              качество линий связи, не имеющих отношения к собственным ресурсам
              Исполнителя, и за их доступность для Клиента, за качество
              прокладки слаботочных сетей, за качество установленного
              оборувадония и за монтаж оборудования на объекте и на территориии
              объекта, а также не несет ответственности за изменение свойств,
              функций и качества Услуг, предоставляемых Клиенту, если таковые
              изменения связаны с функционированием сети Интернет либо с другими
              обстоятельствами, находящимися вне зоны компетенции, влияния и
              контроля Исполнителя.
            </li>
            <li>
              Исполнитель соблюдает технические стандарты качества Услуг и
              предпринимает все разумные усилия, предотвращающие либо
              пресекающие попытки несанкционированного доступа к ресурсам
              Исполнителя и Клиента, сбоев и неполадок в оборудовании
              Исполнителя, проникновения вредоносных компонентов и т. д. При
              возникновении аварийной или чрезвычайной ситуации Исполнитель
              принимает все зависящие от него меры для скорейшей нормализации
              ситуации. При этом Исполнитель в силу специфики оборудования и
              программного обеспечения Услуги не гарантирует абсолютную
              постоянную стопроцентную безошибочность и бесперебойность работы
              всей системы и Услуг, а также не гарантирует безошибочность,
              бесперебойность и абсолютную безопасность функционирования
              выпущенного любыми третьими лицами программного обеспечения и/или
              иных материалов, используемых для предоставления Услуг.
            </li>
            <li>
              Клиент несет все риски, связанные с использованием сети Интернет
              через ресурсы и/или Услуги Исполнителя, в том числе за оценку
              полезности, полноты, точности любой информации, Исполнитель не
              несет ответственности за качество, правомерность, пригодность для
              конкретной цели любых товаров, работ, услуг, запрашиваемых и/или
              полученных путем использования сети Интернет, в том числе в случае
              их размещения на сервере Исполнителя (за исключением Услуг,
              предоставляемых Исполнителем).
            </li>
            <li>
              Исполнитель несет ответственность перед Клиентом только в пределах
              оплаченных, но не оказанных Услуг. Исполнитель не несет перед
              Клиентом финансовой ответственности и не возвращает Клиенту
              уплаченные в рамках данных Условий денежные средства, в случае
              если Услуги не были оказаны по вине Клиента.
            </li>
            <li>
              В случае если надлежащее исполнение сторонами настоящих Условий
              невозможно в силу объективных причин, которые стороны не могли ни
              предвидеть, ни предотвратить (стихийные бедствия, изменения
              текущего законодательства РФ, действия органов государственной
              власти и управления, военные действия всех видов и т. п.) ни одна
              из сторон не вправе требовать от другой стороны возмещения
              причиненных ненадлежащим исполнением или неисполнением настоящих
              Условий убытков (включая упущенную выгоду).
            </li>
          </ol>
          <p id='p8'>
            <strong>VIII. Блокирование и удаление Аккаунта</strong>
          </p>
          <ol>
            <li>
              Исполнитель имеет право на блокирование и/или удаление Аккаунта в
              случаях, порядке и на условиях, предусмотренных настоящими
              Условиями.
            </li>
            <li>
              Блокирование Аккаунта означает временную невозможность
              использования Клиентом Услуг независимо от баланса Лицевого счета
              при возможности доступа (с ограниченной возможностью) Клиента к
              Аккаунту. Блокирование Аккаунта осуществляется Исполнителем до
              момента устранения Клиентом нарушений, приведших к блокированию
              Аккаунта, если иное не предусмотрено настоящими Условиями.
              Одновременно с блокированием Аккаунта на контактный e-mail Клиента
              может направляться уведомление о нарушении Условий и необходимости
              устранения допущенных Клиентом нарушений.
            </li>
            <li>
              Удаление Аккаунта означает невозможность доступа Клиента к
              Контрольной панели Аккаунта и невозможность использования Услуг в
              рамках соответствующего Аккаунта независимо от баланса Лицевого
              счета.
            </li>
            <li>
              При блокировании или удалении Аккаунта происходит прекращение
              доступа Клиента к ОАСУ T.one, с одновременным выводом
              соответствующего предупреждения в соответствии с настоящими
              Условиями.
            </li>
            <li>
              Исполнитель имеет право на блокирование Аккаунта в следующих
              случаях:
            </li>
            <li>
              При введении Клиентом неверных данных, не введении Клиентом
              данных, запрашиваемых при активации Аккаунта, несвоевременной
              актуализация данных Клиента.
            </li>
            <li>
              При непредставлении подтверждающих документов, при этом к
              вышеуказанным действиям приравнивается бездействие Клиента в
              течение 3 (трех) календарных дней с момента направления
              Исполнителем соответствующего запроса и/или требования.
            </li>
            <li>
              В случае получения Исполнителем жалобы/требования от третьих лиц
              и/или требования от компетентных органов, в отношении действий
              и/или бездействия Клиента, в том числе по поводу нарушения
              авторских прав, рассылки «спама» и прочих нарушений настоящих
              Условий или требований действующего законодательства РФ — до
              момента устранения допущенных нарушений или удаления Аккаунта.
            </li>
            <li>
              Исполнитель имеет право на удаление Аккаунта в следующих случаях:
            </li>
            <li>
              По собственному желанию Клиента либо при одностороннем отказе
              Клиента от исполнения настоящих Условий;
            </li>
            <li>В случае блокирования Аккаунта более трех раз;</li>
            <li>
              При грубом нарушении Клиентом настоящих Условий либо при отказе
              Клиента от устранения допущенных нарушений либо при не устранении
              нарушений Клиентом в течение 3 (трех) календарных дней с момента
              направления Исполнителем требования об устранении таких нарушений;
            </li>
            <li>
              В случае если в течение 6 (шести) месяцев с момента регистрации
              Аккаунта Клиент не использует Услуги;
            </li>
            <li>
              В случае обнаружения Исполнителем самостоятельно либо по
              жалобам/запросам/требованиям третьих лиц нарушений Условий.
            </li>
            <li>
              Одновременно с удалением Аккаунта в соответствии с настоящими
              Условиями Исполнитель списывает остаток денежных средств на
              соответствующем Лицевом счете в безакцептном порядке в полном
              объеме в качестве возмещения Исполнителю дополнительно понесенных
              расходов, связанных с удалением Аккаунта, и иных сопутствующих
              расходов.
            </li>
            <li>
              В случае очевидного, с точки зрения Исполнителя, нарушения
              Клиентом законодательства РФ, оказание ему Услуг может быть
              приостановлено, а Аккаунт заблокирован и/или удален без
              предварительного предупреждения со стороны Исполнителя, при этом
              остаток денежных средств на соответствующем Лицевом счете Клиенту
              не возвращается и считается возмещением Исполнителю понесенных
              расходов.
            </li>
          </ol>
          <p id='p9'>
            <strong>IX. Техническая поддержка</strong>
          </p>
          <ol>
            <li>
              В целях оказания и использования Услуг Исполнитель обеспечивает
              функционирование Службы технической поддержки (далее по тексту -
              СТП) в порядке, предусмотренном настоящими Условиями.
            </li>
            <li>
              СТП осуществляет управление и контроль над работой технического
              оборудования (серверного) и системных программных средств ( ОАСУ
              T.one), а также осуществляет обработку запросов Клиента на
              изменения в конфигурационных файлах, если такие изменения
              допускаются и не могут быть выполнены Клиентом самостоятельно, а
              также запросов по устранению проблем, связанных с
              функционированием предоставляемых Услуг.
            </li>
            <li>
              При обращении в СТП Клиент понимает, что должен обладать
              минимально необходимым объемом знаний и навыков для общения с
              техническими специалистами. При обращении в СТП Клиенту необходимо
              заранее собрать информацию о причинах ошибки, четко и понятно
              объяснить суть проблемы и обстоятельства ее возникновения.
            </li>
            <li>
              СТП не осуществляет консультирование Клиентов по вопросам
              программирования, веб-дизайна, установок, настроек скриптов и
              программ Клиента или их оптимизации, а также обучение началам
              компьютерной грамотности, создания и/или продвижения сайтов,
              работе сервисов, предоставляемых третьими лицами, поиску, а также
              по другим аналогичным вопросам. Подобные консультации могут
              предоставляться Клиенту по дополнительной договоренности за
              дополнительную плату.
            </li>
            <li>
              Обращение в СТП осуществляется Клиентом через e-mail:
              info@boxt.one или тех. заявки на сайте
              https://boxt.one/est-vopros. Вопросы и заявки направленные через
              другие средства связи, такие как социальные сети, мессенджеры,
              обрабатываются позже и не являются официальными и не влекут за
              собой обязанности Исполнителя по ответу и/или совершению
              каких-либо действий по ним. Исполнитель оставляет за собой право
              самостоятельно принимать решение о возможности или невозможности
              отвечать на вопросы, заданные иным способом, отличным от обращения
              через e-mail: info@boxt.one или тех. заявки на сайте
              https://boxt.one/est-vopros.
            </li>
            <li>
              Тех. поддержка по телефону +7 (3412) 230-005 работает с 09.00 -
              18.00 по мск (в будни). В выходные дни мы принимаем заявки по
              почте и на сайте https://boxt.one/est-vopros.
            </li>
            <li>
              В случае обращения в СТП по телефону, сотрудник СТП имеет право
              зафиксировать телефонное или иное обращение Клиента в базе данных
              с возможностью просмотра его содержимого Клиентом. При этом ответы
              СТП на заявки Клиента, полученные посредством телефонной связи,
              осуществляются Исполнителем только по рабочим дням в рабочее
              время.
            </li>
            <li>
              Обработка запросов осуществляется Исполнителем по мере их
              поступления. Время ответа СТП на запрос Клиента зависит от
              количества поступивших в СТП запросов, и по возможности
              Исполнитель отвечает на запросы в разумный срок не более 48 часов,
              однако, при необходимости время ответа на вопрос может быть
              увеличено Исполнителем.
            </li>
            <li>
              Не принимаются к рассмотрению и обработке запросы в СТП,
              содержащие:
              <ul>
                <li>
                  информацию, составленную на иных языках, кроме русского языка;
                </li>
                <li>
                  нецензурные выражения, грубость, оскорбления, хамство и иные
                  выражения, противоречащие общераспространенным правилам и
                  обычаям делового общения;
                </li>
                <li>
                  вопросы, не относящиеся к Услугам и/или не относящиеся к
                  техническим особенностям Услуг;
                </li>
                <li>
                  вопросы, сформулированные неясно, нечетко или иным подобным
                  образом, в связи с чем смысл вопроса становится непонятен;
                </li>
                <li>
                  требования по настройке или установке, доработки скриптов и т.
                  д.;
                </li>
                <li>
                  Не принимаются к рассмотрению и обработке запросы, а также в
                  выполнении принятой к рассмотрению заявки может быть отказано
                  по причине приостановления предоставления Услуг по основаниям,
                  предусмотренным настоящими Условиями.
                </li>
              </ul>
            </li>
            <li>
              В случае если в СТП поступает стандартный запрос или запрос, ответ
              на который уже содержится на сайте Исполнителя в разделе «Вопросы
              и ответы», ответ на запрос может быть дан в виде ссылки на
              соответствующую страницу на сайте Исполнителя, содержащую раздел
              «Вопросы и ответы».
            </li>
            <li>
              В случае если в СТП поступает запрос, ответ на который может быть
              найден на других сайтах сети Интернет, ответ на запрос может быть
              дан в виде ссылки на соответствующий сайт.
            </li>
          </ol>
          <p id='p10'>
            <strong>X. Прочие условия</strong>
          </p>
          <ol>
            <li>
              Письменные заявления Клиента, предусмотренные настоящими
              Условиями, должны содержать все данные Аккаунта, а также при
              необходимости иные данные, позволяющие Исполнителю
              идентифицировать Клиента.
            </li>
            <li>
              Стороны признают юридическую силу уведомлений и сообщений,
              направленных Исполнителем в адрес Клиента на указанные им в
              персональных данных Аккаунта адреса электронной почты. Такие
              уведомления и сообщения приравниваются к сообщениям и
              уведомлениям, исполненным в простой письменной форме, направляемым
              Исполнителем на почтовый адрес Клиента. В случае возникновения
              каких-либо разногласий по фактам отправления, получения сообщений,
              времени их направления и содержания, стороны договорились считать
              свидетельства технических средств Исполнителя достоверными и
              окончательными для разрешения разногласий между указанными лицами.
            </li>
            <li>
              Стороны признают, что уведомления и сообщения Клиента в адрес
              Исполнителя, за исключением случаев обращения в СТП настоящих
              Условий, считаются совершенными надлежащим образом только при
              составлении их в письменном виде с указанием всех необходимых для
              идентификации Аккаунта и Клиента данных, и направлении их по почте
              заказным письмом.
            </li>
            <li>
              Исполнитель обязуется не передавать и не делать доступными третьим
              лицам сведения о Клиенте, предоставленные последним при
              регистрации Аккаунта, за исключением предоставления таких сведений
              государственным органам и/или иным органам и организациям по их
              запросам, а также иным третьим лицам при получении от них
              запросов/жалоб/претензий по вопросам нарушения Клиентом их прав
              и/или действующего законодательства РФ.
            </li>
            <li>
              Клиент дает свое согласие на обработку персональных данных,
              предоставленных им при активации Аккаунта, путем заполнения полей
              формы на сайте (в мобильном приложении) https://www.boxt.one, в
              том числе в виде информирования Исполнителем Клиента об окончании
              срока, балансе Лицевого счета и иных фактах, связанных с оказанием
              Услуг, путем направления сообщений по электронной почте, SMS и
              других сообщений на контактные номера и/или адреса электронной
              почты владельца домена и Аккаунта. Клиент подтверждает, что давая
              такое согласие, он действует свободно, своей волей и в своем
              интересе.
            </li>
            <li>
              Стороны обязуются не разглашать, не передавать и не делать
              доступными третьим лицам конфиденциальные сведения, содержащиеся в
              документах, относящихся к настоящим Условиям и иным документам,
              относящимся к ним, без письменного разрешения другой стороны, при
              этом факт совершения акцепта и предмет настоящих Условий не
              являются сведениями, которые стороны приняли считать
              конфиденциальными.
            </li>
            <li>
              Положения, предусмотренные предыдущим абзацем настоящего пункта,
              не распространяются на случаи, когда требование о предоставлении
              таких сведений предъявляется следующими лицами: органами
              государственной власти, иными государственным органами, органами
              местного самоуправления в целях выполнения их функций в
              соответствии с действующим законодательством РФ; банками и/или
              иными организациями, осуществляющими операции с денежными
              средствами или иным имуществом, в соответствии с Федеральным
              законом РФ от 07.08.2001 г. № 115-ФЗ «О противодействии
              легализации (отмыванию) доходов, полученных преступным путем и
              финансированию терроризма».
            </li>
            <li>
              Исполнитель вправе при необходимости привлекать для оказания Услуг
              третьих лиц, имеющих соответствующие лицензии и/или разрешения.
            </li>
            <li>
              Наименование и нумерация статей настоящих Условий приведены для
              удобства прочтения и не имеют значения при толковании настоящих
              Условий.
            </li>
            <li>
              В случае если какое-либо из положений настоящих Условий окажется
              не подлежащим буквальному исполнению, оно толкуется в соответствии
              с действующим законодательством РФ с учетом первоначальных
              интересов сторон, при этом оставшаяся часть Условий продолжает
              действовать в полной мере.
            </li>
            <li>
              Настоящие Условия составлены в соответствии с Гражданским кодексом
              Российской Федерации и иными нормативными актами РФ, действующими
              в области предоставления Услуг.
            </li>
          </ol>
          <p id='p11'>
            <strong>XI. Адрес и реквизиты Исполнителя</strong>
          </p>
          <p>
            <strong>ОГРН</strong> 1181832019544
          </p>
          <p>
            <strong>ИНН</strong> 1831191571
          </p>
          <p>
            <strong>КПП</strong> 183201001
          </p>
          <p>
            <strong>Юридический адрес/Фактический адрес:</strong>
          </p>
          <p>
            426006, Удмуртская Республика, г. Ижевск, ул. Проезд Дерябина, д. 3,
            офис 201
          </p>
          <p>
            <strong>Банковские реквизиты:</strong>
          </p>
          <p>
            <strong>БИК</strong> 049401601
          </p>
          <p>
            <strong>к/сч</strong> 30101810400000000601
          </p>
          <p>
            <strong>р/сч</strong> 40702810568000017350
          </p>
          <p>УДМУРТСКОЕ ОТДЕЛЕНИЕ N8618 ПАО СБЕРБАНК г. ИЖЕВСК </p>
          <p id='p12'>
            <strong>XII. Обновление настоящей Оферты</strong>
          </p>
          <p>Последнее обновление: 29.04.2021 (Пункт V.8, IX.2)</p>
          <p>Последнее обновление: 10.01.2021 </p>
          <p>Обновление: 01.05.2020 </p> <p>Публикация: 01.11.2019 </p>
        </div>
      </div>
    </div>
  </div>
);
