import React from 'react'
import BaseComponent from 'Components/BaseComponent'
import { Link } from 'react-router-dom'
import { parseQuery } from 'Services/Utils'
import { connect } from 'react-redux'
import { IconArrow } from 'Components/Icons'

const DEFAULT_PAGE = 1

export function getParams() {
  let query = window.location.search
  const { page } = parseQuery(query)

  return {
    page: page || DEFAULT_PAGE
  }
}

class Pagination extends BaseComponent {
  currentPage = DEFAULT_PAGE

  getPage() {
    let query = this.props.location.search

    const { page } = parseQuery(query)

    return Number(page) || DEFAULT_PAGE
  }

  onPageChange(pageNum) {
    if (typeof this.props.cb === 'function') {
      this.props.cb(getParams())
    }
    this.currentPage = pageNum
  }

  getNavLink(pageNum) {
    let link = this.props.location.pathname

    link += `?page=${pageNum}`

    return link
  }

  renderPages() {
    let pages = []

    for (let i = 1; i <= this.props.totalPages; i++) {
      pages.push(
        <li
          className={this.currentPage === i ? 'active' : ''}
          key={i}
          onClick={this.onPageChange.bind(this, i)}>
          <Link to={this.getNavLink(i)}>{i}</Link>
        </li>
      )
    }

    return pages
  }

  render() {
    this.currentPage = this.getPage()

    return (
      <div className="pagination">
        {this.currentPage - 1 ?
            <Link
                to={this.getNavLink(this.currentPage - 1)}
                className="pagination__arrow arrow_prev btn btn-white btn_circle"
                onClick={this.onPageChange.bind(this, this.currentPage - 1)}
            >
              {IconArrow}
            </Link> :
            <span className="pagination__arrow arrow_prev btn btn-white btn_circle">{IconArrow}</span>
        }
        <ul>{this.renderPages()}</ul>
        {this.currentPage < this.props.totalPages ?
            <Link
                to={this.getNavLink(this.currentPage + 1)}
                className="pagination__arrow arrow_next btn btn-white btn_circle"
                onClick={this.onPageChange.bind(this, this.currentPage + 1)}
            >
              {IconArrow}
            </Link> :
            <span className="btn btn-white btn_circle pagination__arrow arrow_next">{IconArrow}</span>
        }
      </div>
    )
  }
}

export default connect(state => ({
  location: state.router.location
}))(Pagination)
