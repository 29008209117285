export const ROUTE_HOME = '/';
export const ROUTE_EDIT = '/edit';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_NEWS = '/news';
export const ROUTE_NEWS_ID = '/news/:newsId';
export const ROUTE_DOCS = '/docs';
export const ROUTE_MESSENGER = '/messenger';
export const ROUTE_MESSENGER_CHAT_ID = '/messenger/:chatId';

export const ROUTE_CONTROL = '/control';
export const ROUTE_CONTROL_LIGHTING = '/control/lighting';
export const ROUTE_CONTROL_CLIMATE = '/control/climate';
export const ROUTE_CONTROL_AIR_CONDITIONER = '/control/air-conditioner';
export const ROUTE_CONTROL_ROSETTE = '/control/rosette';
export const ROUTE_CONTROL_DEVICE = '/control/device';
export const ROUTE_CONTROL_SENSOR = '/control/sensor';
export const ROUTE_CONTROL_TRACKING = '/control/tracking';
export const ROUTE_CONTROL_VIDEO = '/control/video';
export const ROUTE_CONTROL_ENTRY = '/control/entry';
export const ROUTE_CONTROL_SCENARIO = '/control/scenario';
export const ROUTE_CONTROL_METER = '/control/meter';
export const ROUTE_CONTROL_ROOMS = '/control/rooms';
export const ROUTE_ACCOUNTS = '/accounts';
export const ROUTE_INTERCOM_CALL = '/intercom/call';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_PROFILE = '/settings/profile';
export const ROUTE_CHANGE_NAME = '/settings/name';
export const ROUTE_CHANGE_PASSWORD = '/settings/password';
export const ROUTE_CHANGE_EMAIL = '/settings/email';
export const ROUTE_CHANGE_PHONE = '/settings/phone';
