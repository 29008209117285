import React, { Component } from 'react';
import { connect } from 'react-redux';
import MeterWaterSupply from 'Containers/control/meter/water/index';
import MeterElectricity from 'Containers/control/meter/electricity/index';
import MeterHeating from 'Containers/control/meter/heating/index';
import { withTranslation } from 'react-i18next';
import MeterService from 'Services/Meter';
import { countersLoad } from 'Actions/counters';
import moment from 'moment';
import {
  COUNTER_TYPE_ELECTRICITY,
  COUNTER_TYPE_HEATING,
  COUNTER_TYPE_WATER_COLD,
  COUNTER_TYPE_WATER_HOT,
} from 'Config/devices';
import Preloader from 'Components/Preloader';
import { panelOpen } from 'Actions/panel';

class Meter extends Component {
  state = {
    activeTab: 0,
    isHotWaterLoading: false,
    isColdWaterLoading: false,
    isElectricityLoading: false,
    isHeatingLoading: false,
  };

  getClasses = (i) => {
    let cls = 'submenu-navigation__item';

    if (i === this.state.activeTab) {
      cls += ' active';
    }

    return cls;
  };

  countersExists() {
    let res = false;
    Object.keys(this.props.counters).map((n) => {
      if (n) res = true;

      return null;
    });
    return res;
  }

  componentDidMount() {
    const apartment_id = this.props.apartmentId;
    MeterService.getCounterReceipt(
      COUNTER_TYPE_WATER_HOT,
      {
        apartment_id,
        date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to: moment(new Date()).format('YYYY-MM-DD'),
      },
      (res) => {
        this.props.onCountersLoad({
          [COUNTER_TYPE_WATER_HOT]: res.counters ? res.counters : [],
        });

        this.setState({ isHotWaterLoading: true });
      },
    );

    MeterService.getCounterReceipt(
      COUNTER_TYPE_WATER_COLD,
      {
        apartment_id,
        date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to: moment(new Date()).format('YYYY-MM-DD'),
      },
      (res) => {
        this.props.onCountersLoad({
          [COUNTER_TYPE_WATER_COLD]: res.counters ? res.counters : [],
        });

        this.setState({ isColdWaterLoading: true });
      },
    );

    MeterService.getCounterReceipt(
      COUNTER_TYPE_ELECTRICITY,
      {
        apartment_id,
        date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to: moment(new Date()).format('YYYY-MM-DD'),
      },
      (res) => {
        this.props.onCountersLoad({
          [COUNTER_TYPE_ELECTRICITY]: res.counters ? res.counters : [],
        });

        this.setState({ isElectricityLoading: true });
      },
    );

    MeterService.getCounterReceipt(
      COUNTER_TYPE_HEATING,
      {
        apartment_id,
        date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to: moment(new Date()).format('YYYY-MM-DD'),
      },
      (res) => {
        this.props.onCountersLoad({
          [COUNTER_TYPE_HEATING]: res.counters ? res.counters : [],
        });

        this.setState({ isHeatingLoading: true });
      },
    );
  }

  render() {
    if (
      !this.state.isHotWaterLoading ||
      !this.state.isColdWaterLoading ||
      !this.state.isElectricityLoading ||
      !this.state.isHeatingLoading
    )
      return <Preloader />;

    const { t } = this.props;

    return (
      <div className='page page-meter'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <h1
            style={{
              fontSize: '1.5rem',
              margin: 0,
              padding: 0,
            }}
          >
            {this.props.t('screen.meters.title')}
          </h1>
          <button
            type='button'
            className='btn btn_light-blue btn_add'
            onClick={() => this.props.onPanelOpen('AddCounter')}
          >
            +
          </button>
        </div>
        {this.countersExists() ? (
          <>
            <div className='submenu-navigation text-center'>
              <button
                className={this.getClasses(0)}
                onClick={() => {
                  this.setState({ activeTab: 0 });
                }}
              >
                {t('meters.water')}
              </button>
              <button
                className={this.getClasses(1)}
                onClick={() => {
                  this.setState({ activeTab: 1 });
                }}
              >
                {t('meters.electricity')}
              </button>
              <button
                className={this.getClasses(2)}
                onClick={() => {
                  this.setState({ activeTab: 2 });
                }}
              >
                {t('meters.heat')}
              </button>
            </div>

            {this.state.activeTab === 0 && <MeterWaterSupply />}

            {this.state.activeTab === 1 && <MeterElectricity />}

            {this.state.activeTab === 2 && <MeterHeating />}
          </>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    apartmentId: state.apartments.active.apartmentId,
    counters: state.counters,
  }),
  {
    onPanelOpen: panelOpen,
    onCountersLoad: countersLoad,
  },
)(withTranslation()(Meter));
