import ApiService from 'Services/Api'
import Logger from 'Components/Logger'

const NotifyService = {
  async getNotifications(data = {}, cb = function() {}) {
    return ApiService.getUserNotifications(data)
      .then(res => {
        cb(res)
      })
      .catch(err => {
        Logger.log(err, 'error')
      })
  },
  async getNotificationTypes(cb = function() {}) {
    return ApiService.getUserNotificationsTypes()
      .then(res => {
        cb(res)
      })
      .catch(err => {
        Logger.log(err, 'error')
      })
  },
  async addNotification(data = {}, cb = function() {}) {
    return ApiService.addUserNotification(data)
      .then(res => {
        cb(res)
      })
      .catch(err => {
        Logger.log(err, 'error')
      })
  },
  async readNotifications(data = '', cb = function() {}) {
    return ApiService.readUserNotification(data)
      .then(res => {
        cb(res)
      })
      .catch(err => {
        Logger.log(err, 'error')
      })
  }
}

export default NotifyService
