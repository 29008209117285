import { EVENT_ADD_PUSH, EVENT_DELETE_PUSH } from 'Actions/push';
import { EVENT_CONNECT, EVENT_CLOSE } from 'Actions/socket';
import { EVENT_CHAT_MESSAGE } from 'Actions/messenger';
import i18next from 'i18next';

const initialState = {
  notifications: [],
};

export function push(state = initialState, action) {
  let notice = {};
  let notifications = [...state.notifications];
  switch (action.type) {
    case EVENT_CONNECT:
      notice = {
        group: 'g2',
        type: 'success',
        text: i18next.t('notices.connect'),
        isConnected: true,
      };
      notifications.push(notice);

      return {
        ...state,
        notifications,
      };
    case EVENT_CLOSE:
      notice = {
        group: 'g2',
        type: 'error',
        text: i18next.t('notices.disconnect'),
        delay: 2500,
        important: true,
      };
      notifications.push(notice);

      return {
        ...state,
        notifications,
      };
    case EVENT_ADD_PUSH:
      notifications.push(action.notice);

      return {
        ...state,
        notifications,
      };
    case EVENT_DELETE_PUSH:
      notice = notifications.splice(action.noticeId, 1);

      return {
        ...state,
        notifications,
      };
    case EVENT_CHAT_MESSAGE:
      const event_data = JSON.parse(action.event.data).data;
      // TODO: think about it
      if (
        !~window.location.pathname.indexOf(event_data.chat_id) &&
        !~window.location.pathname.indexOf(event_data.chatName)
      ) {
        notice = {
          group: 'g4',
          type: 'default',
          text: i18next.t('notices.new_msg'),
          delay: 5000,
          data: event_data,
          important: true,
        };
        notifications.push(notice);

        return {
          ...state,
          notifications,
        };
      }

      return state;
    default:
      return state;
  }
}
