interface Message {
  sdp: string;
}

export default async function started(pc: RTCPeerConnection, message: Message) {
  const answer = new RTCSessionDescription({
    type: 'answer',
    sdp: message.sdp,
  });

  await pc.setRemoteDescription(answer);
}
