import {
  EVENT_ACCOUNTS_LOAD,
  EVENT_ACCOUNT_CREATE,
  EVENT_ACCOUNT_UPDATE,
  EVENT_ACCOUNT_DELETE,
} from 'Actions/accounts';

const initialState = [];

export function accounts(state = initialState, action) {
  let data = [];

  switch (action.type) {
    case EVENT_ACCOUNTS_LOAD:
      return [...action.data];
    case EVENT_ACCOUNT_CREATE:
    case EVENT_ACCOUNT_UPDATE:
      return [...state, action.data];
    case EVENT_ACCOUNT_DELETE:
      state.some((acc) => {
        if (acc.userId !== action.accountId) data.push(acc);

        return null;
      });

      return data;
    default:
      return state;
  }
}
