import React from 'react';

interface Props {
  buttonText: string;
  icon?: string;
  onClick: () => void;
  className?: string;
}

const Button: React.FC<Props> = ({ buttonText, icon, onClick, className }) => {
  const buttonClasses = `btn btn_primary btn_entry ${className || ''}`;

  return (
    <button
      className={buttonClasses}
      style={{
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      }}
      onClick={onClick}
    >
      {icon && <i className={`icon ${icon}`} />}
      {buttonText}
    </button>
  );
};

export default Button;
