import React, { FC } from 'react';
import moment from 'moment';
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type IProps = {
  chartData: {
    x: string;
    y: number;
  }[];
  unit: string;
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  const [t] = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className={styles.CustomTooltip}>
        {payload.map((counter) => {
          return (
            <div key={counter.color} className={styles.Fact}>
              <p className={styles.Text}>
                {t('meters.history.tooltip_date')}&nbsp;{counter.payload.name}
                :&nbsp;
                <span className={`${styles.Text} ${styles.Value}`}>
                  {counter.value.toLocaleString('ru')} {counter.name}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

const CustomChart: FC<IProps> = ({ chartData, unit }): JSX.Element => {
  const data = chartData.map((item) => ({
    name: moment(item.x).format('DD.MM'),
    [unit]: item.y,
  }));

  return (
    <ResponsiveContainer width='100%' height={300}>
      <LineChart height={300} data={data} margin={{ top: 20 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis width={25} />
        <Line type='monotone' dataKey={unit} strokeWidth={2} stroke='#3759ca' />
        <Tooltip content={<CustomTooltip />} />
        <Brush stroke={'#73757c'} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomChart;
