import {
  EVENT_DEVICES_LOAD,
  EVENT_STATUS_TOGGLE_DEVICE,
  EVENT_NOTIFY_TOGGLE_SENSOR,
  EVENT_SENSORS_TYPES_LOAD,
  EVENT_DEVICES_TYPES_LOAD,
  EVENT_CATEGORIES_TYPES_LOAD,
  EVENT_DEVICE_OFF,
  EVENT_DEVICE_EDIT,
  EVENT_SENSOR_EDIT,
  RENAME_ROOM_DEVICE,
  DELETE_ROOM_DEVICE,
  EVENT_DEVICE_EDIT_VALUE,
} from 'Actions/devices';
import {
  EVENT_DEVICE_EXCLUDED,
  EVENT_DEVICE_CONNECTED,
} from 'Actions/controllers';
import { EVENT_NOTIFY_DEVICE, EVENT_NOTIFY_SENSOR } from 'Actions/notify';
import { DEVICE_STATUS_ON, DEVICE_STATUS_OFF } from 'Config/devices';
import { NOTIFY_STATUS_ON, NOTIFY_STATUS_OFF } from 'Config/notify';
import ControlService from 'Services/Control';
import { cloneDeep } from 'lodash';

const initialState = {
  sensors: [],
  devices: [],
  categoriesTypes: [],
  devicesTypes: [],
  sensorsTypes: [],
};

export function devices(state = initialState, action) {
  let data = {};
  let eventData = {};
  let key;
  let val;
  let _sensor;
  let _device;
  const sensors = [];
  const devices = [];

  switch (action.type) {
    case EVENT_DEVICES_LOAD:
      if (!action.event || !action.dataType) return state;
      data = {
        ...state,
        [action.dataType]: action.event,
      };

      return data;
    case EVENT_NOTIFY_DEVICE:
      try {
        eventData = JSON.parse(action.event.data);
      } catch (e) {
        console.log(e);
      }

      key = eventData.data.key;
      val = eventData.data.value;

      Object.keys(state.devices).map((d) => {
        if (parseInt(d) === 'NaN') return null;

        _device = state.devices[d];

        if (Number(_device.key) === Number(key) || _device.key === key) {
          _device.status = eventData.data.status;
          _device.value = val;
        }

        if (typeof _device === 'object') devices.push(_device);

        return null;
      });

      return {
        ...state,
        devices,
      };
    case EVENT_NOTIFY_SENSOR: {
      try {
        eventData = JSON.parse(action.event.data);
      } catch (e) {
        console.log(e);
      }

      key = eventData.data.key;
      val = eventData.data.value;

      Object.keys(state.sensors).map((d) => {
        if (isNaN(parseInt(d))) return null;

        _sensor = state.sensors[d];
        if (Number(_sensor.key) === Number(key) || _sensor.key === key) {
          _sensor.status = eventData.data.status;
          _sensor.value = val;
        }
        if (typeof _sensor === 'object') sensors.push(_sensor);

        return null;
      });

      return { ...state, sensors };
    }
    case EVENT_DEVICE_EDIT:
      Object.keys(state.devices).map((d) => {
        if (parseInt(d) === 'NaN') return null;

        _device = state.devices[d];

        if (
          _device &&
          action.device &&
          Number(_device.deviceId) === Number(action.device.deviceId)
        ) {
          _device.title = action.device.title;
          _device.room = action.device.room;
          _device.category = action.device.category;
          _device.type = action.device.type;
        }

        if (typeof _device === 'object') devices.push(_device);

        return null;
      });

      return {
        ...state,
        devices,
      };
    case EVENT_SENSOR_EDIT:
      Object.keys(state.sensors).map((d) => {
        if (parseInt(d) === 'NaN') return null;

        _sensor = state.sensors[d];

        if (
          _sensor &&
          action.sensor &&
          Number(_sensor.sensorId) === Number(action.sensor.sensorId)
        ) {
          _sensor.title = action.sensor.title;
          _sensor.room = action.sensor.room;
          _sensor.category = action.sensor.category;
          _sensor.type = action.sensor.type;
        }

        if (typeof _sensor === 'object') sensors.push(_sensor);

        return null;
      });

      return {
        ...state,
        sensors,
      };
    case EVENT_NOTIFY_TOGGLE_SENSOR:
      state.sensors.some((s) => {
        if (Number(s.sensorId) === Number(action.sensorId)) {
          s.isNotify =
            s.isNotify === NOTIFY_STATUS_ON
              ? NOTIFY_STATUS_OFF
              : NOTIFY_STATUS_ON;

          ControlService.switchSensor({
            sensorId: action.sensorId,
            action: s.isNotify,
          });
        }
        sensors.push(s);
        return null;
      });

      return {
        ...state,
        sensors: sensors,
      };
    case EVENT_DEVICE_OFF:
      return {
        ...state,
        devices: state.devices.map((d) => {
          if (Number(d.deviceId) === Number(action.payload.deviceId)) {
            return { ...d, status: DEVICE_STATUS_OFF };
          }
          return d;
        }),
      };
    case EVENT_STATUS_TOGGLE_DEVICE:
      state.devices.some((d) => {
        if (Number(d.deviceId) === Number(action.deviceId)) {
          d.status =
            d.status === DEVICE_STATUS_ON
              ? DEVICE_STATUS_OFF
              : DEVICE_STATUS_ON;

          ControlService.executeDevice({
            deviceId: action.deviceId,
            status: d.status,
          });
        }
        devices.push(d);
        return null;
      });

      return {
        ...state,
        devices: devices,
      };
    case EVENT_CATEGORIES_TYPES_LOAD:
      data = {
        ...state,
        categoriesTypes: action.event,
      };

      return data;
    case EVENT_SENSORS_TYPES_LOAD:
      data = {
        ...state,
        sensorsTypes: action.event,
      };

      return data;
    case EVENT_DEVICES_TYPES_LOAD:
      data = {
        ...state,
        devicesTypes: action.event,
      };

      return data;
    case EVENT_DEVICE_EXCLUDED:
      eventData = JSON.parse(action.event.data);
      data = {
        ...state,
      };

      if (eventData.data.deviceKeys && eventData.data.deviceKeys.length > 0) {
        data.devices.some((item, index) => {
          if (!~eventData.data.deviceKeys.indexOf(item.key) === false)
            delete data.devices[index];

          return null;
        });
      }
      if (eventData.data.sensorKeys && eventData.data.sensorKeys.length > 0) {
        data.sensors.some((item, index) => {
          if (!~eventData.data.sensorKeys.indexOf(item.key) === false)
            delete data.sensors[index];

          return null;
        });
      }

      return data;
    case EVENT_DEVICE_CONNECTED:
      eventData = JSON.parse(action.event.data);
      data = {
        ...state,
      };

      if (eventData.data.devices && eventData.data.devices.length > 0) {
        eventData.data.devices.map((device) => {
          data.devices.push({
            deviceId: device.device_id,
            deviceTypeId: device.type_id,
            title: device.name,
            status: DEVICE_STATUS_ON,
            isNotify: NOTIFY_STATUS_ON,
            category: {
              categoryId: device.category_id,
            },
          });

          return null;
        });
      }
      if (eventData.data.sensors && eventData.data.sensors.length > 0) {
        eventData.data.sensors.map((sensor) => {
          data.sensors.push({
            sensorId: sensor.sensor_id,
            sensorTypeId: sensor.type_id,
            title: sensor.name,
            status: DEVICE_STATUS_ON,
            isNotify: NOTIFY_STATUS_ON,
            category: {
              categoryId: sensor.category_id,
            },
          });

          return null;
        });
      }

      return data;
    case RENAME_ROOM_DEVICE: {
      const newState = cloneDeep(state);
      const id = action.id.toString();
      const { title } = action;
      newState.devices.map((item) => {
        if (item.room && item.room.roomId === id) {
          item.room.title = title;
        }
        return item;
      });
      newState.sensors.map((item) => {
        if (item.room && item.room.roomId === id) {
          item.room.title = title;
        }
        return item;
      });
      return newState;
    }
    case DELETE_ROOM_DEVICE: {
      const newState = cloneDeep(state);
      const id = action.id.toString();

      newState.devices.map((item) => {
        if (item.room && item.room.roomId === id) {
          delete item.room;
        }
        return item;
      });
      newState.sensors.map((item) => {
        if (item.room && item.room.roomId === id) {
          delete item.room;
        }
        return item;
      });
      return newState;
    }
    case EVENT_DEVICE_EDIT_VALUE:
      return {
        ...state,
        devices: state.devices.map((d) => {
          if (Number(d.deviceId) === Number(action.payload.deviceId)) {
            return { ...d, value: action.payload.value };
          }
          return d;
        }),
      };
    default:
      return state;
  }
}
