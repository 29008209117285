import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconFilterDevice } from './Icons';
import { panelOpen } from 'Actions/panel';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

class FilterDevice extends Component {
  state = {
    isActive: false,
    selected: '',
    currentRoom: {
      label: null,
      room: null,
    },
  };

  openModal = () => {
    // eslint-disable-next-line react/prop-types
    this.props.panelOpen('FilterDevice', {
      selected: this.state.selected,
      currentRoom: this.state.currentRoom,
    });
  };

  setSelected = () => {
    // eslint-disable-next-line react/prop-types
    this.props.filters.pages.every((item) => {
      if (
        // eslint-disable-next-line react/prop-types
        item.location === this.props.location.pathname &&
        item.filter.status !== null
      ) {
        this.setState({ selected: item.filter.status });
        this.setState({ isActive: true });
        return false;
      } else {
        this.setState({ selected: null });
        this.setState({ isActive: false });
        return true;
      }
    });
  };

  getCurrentRoom = () => {
    // eslint-disable-next-line react/prop-types
    this.props.filters.pages.forEach((item) => {
      if (item.location === this.props.location.pathname) {
        if (item.filter.room === null) {
          return this.setState({
            currentRoom: {
              room: null,
              label: null,
            },
          });
        } else {
          return this.setState({
            currentRoom: {
              room: item.filter.room,
              label: item.filter.label,
            },
          });
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters !== this.props.filters ||
      prevProps.language !== this.props.language
    ) {
      this.getCurrentRoom();
      this.setSelected();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.filters !== nextProps.filters ||
      nextProps.language !== this.props.language
    )
      return true;
    return this.state !== nextState;
  }

  componentDidMount() {
    this.getCurrentRoom();
    this.setSelected();
  }

  render() {
    return (
      <div className='filters filters--container'>
        {/* eslint-disable-next-line react/prop-types */}
        <h3 className='filters__page-title'>{this.props.title}</h3>
        <button
          type='button'
          className={`filters__open-modal btn btn_navigation${
            this.state.isActive ? ' active' : ''
          }`}
          aria-label='open filter'
          name='openFilter'
          onClick={this.openModal}
        >
          {IconFilterDevice}
          {this.props.t('panels.filter_device.title')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    language: state.language,
  };
};

export default connect(mapStateToProps, { panelOpen: panelOpen })(
  withTranslation()(withRouter(FilterDevice)),
);
