import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateAppImage } from 'Components/Icons';

import styles from './styles.module.scss';

const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const UpdateApp = (): JSX.Element => {
  const [t] = useTranslation();

  const _onClick = () => {
    if (isIOS) {
      window.sendMessageToWebview({
        action: 'openBrowser',
        url: 'https://apps.apple.com/ru/app/t-one-app/id1479967254',
      });
    } else {
      window.sendMessageToWebview({
        action: 'openBrowser',
        url: 'https://play.google.com/store/apps/details?id=com.tone.client',
      });
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <div className={styles.fact}>
            <div className={styles.image}>{UpdateAppImage}</div>
            <p className={styles.text}>{t('modal_update_app.content')}</p>
          </div>
          <div className={styles.fact}>
            <button
              type='button'
              className='btn btn_primary btn_full-width'
              onClick={_onClick}
            >
              {t('commons.buttons.update')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateApp;
