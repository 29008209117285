import ApiService from 'Services/Api';

const ControllerService = {
  getControllers(data = {}, cb = function() {}) {
    return ApiService.getControllers(data).then((res) => cb(res));
  },
  addZWave(data = {}) {
    return ApiService.addControllerZWave(data);
  },
  removeController(data = {}) {
    return ApiService.removeControllerZWave(data);
  },
  executeCommand(data = {}, cb = () => {}) {
    return ApiService.controllerExecuteCommand(data).then((res) => {
      return cb(res);
    });
  },
  getControllerId(url, cb = function() {}) {
    return fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return cb(data);
      })
      .catch((err) => {
        return cb({}, err);
      });
  },
  setControllerUser(url, data = {}, cb = function() {}) {
    return fetch(url, data).then((res) => {
      cb(res);
    });
  },
};

export default ControllerService;
