import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteNotice } from 'Actions/push';
import { IconClose, IconSuccessPush } from 'Components/Icons';
import { Redirect } from 'react-router-dom';

const TIME_TO_CLOSE = 3000;

class Push extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  close = (notice, index, save) => {
    if (notice.timer) {
      clearTimeout(notice.timer);
      notice.timer = null;
    }

    this.props.closeNotice(notice, index, save);
  };

  setTimer = (notice, index) => {
    notice.timer = setTimeout(() => {
      this.close(notice, index, true);
    }, notice.delay || TIME_TO_CLOSE);
  };

  onClick(notice, index) {
    if (notice.data && notice.data.type) {
      switch (notice.data.type) {
        case 'chatMessage':
          this.setState({
            redirect: '/messenger/' + notice.data.chatId,
          });
          break;
        default:
          break;
      }
    }
    this.close(notice, index);
  }

  renderRedirect() {
    if (this.state.redirect) {
      const url = this.state.redirect;

      this.setState({
        redirect: null,
      });

      return <Redirect to={url} />;
    }
  }

  render() {
    const { isPanelOpen, userExist } = this.props;
    const notice = this.props.notifications[0] || null;
    const index = 0;

    if (notice !== null) {
      return this.props.ignoreGroup &&
        notice.group === this.props.ignoreGroup ? null : (
        <div
          className={`push-container ${
            isPanelOpen || userExist === null ? 'push-container--full' : ''
          }`}
        >
          {this.renderRedirect()}
          <div
            key={index}
            onClick={this.onClick.bind(this, notice, index)}
            className={`push-notification push-notification_${notice.type}`}
          >
            {this.setTimer(notice, index)}
            {notice.isConnected && (
              <span className='push-notification__success-icon'>
                {IconSuccessPush}
              </span>
            )}
            {notice.text}
            <span
              className='push-notification__close'
              onClick={this.close.bind(this, notice, index, false)}
            >
              {IconClose}
            </span>
          </div>
        </div>
      );
    }
    return <div className='push-container'>{this.renderRedirect()}</div>;
  }
}

export default connect(
  (state) => ({
    notifications: state.push.notifications,
    isPanelOpen: state.panel.current,
    userExist: state.app.user.active,
  }),
  {
    closeNotice: deleteNotice,
  },
)(Push);
