const component = 'Controller';

// App events
export const EVENT_CONTROLLER_ADD = `${component}/ADD`;
export const EVENT_CONTROLLERS_LOAD = `${component}/LOAD`;
export const EVENT_CONTROLLERS_REMOVE = `${component}/REMOVE`;
export const EVENT_CONTROLLER_CACHE_CLEAN = `${component}/CacheClean`;
// Socket events
export const EVENT_DEVICE_CONNECTING = `${component}/DeviceConnecting`;
export const EVENT_DEVICE_CONNECTED = `${component}/DeviceConnected`;
export const EVENT_DEVICE_EXCLUDED = `${component}/DeviceExcluded`;

export function controllersLoad(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CONTROLLERS_LOAD,
      event: event,
    });
  };
}

export function controllerAdd(data) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CONTROLLER_ADD,
      controller: data,
    });
  };
}

export function cacheClean(type) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CONTROLLER_CACHE_CLEAN,
      cacheType: type,
    });
  };
}

export function removeController(payload) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CONTROLLERS_REMOVE,
      payload,
    });
  };
}
