import React from 'react'
import Scrollbar from 'react-scrollbars-custom'
import BaseComponent from 'Components/BaseComponent'
import { isMobile } from 'Config'

class BaseScrollbar extends BaseComponent {
  render() {
    if (isMobile) return this.props.children

    let props = { ...this.props }

    if (props.cfg) props = { ...props, ...props.cfg.props }

    return <Scrollbar {...props} />
  }
}

export default BaseScrollbar
