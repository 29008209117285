import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { panelOpen } from 'Actions/panel';
import BaseComponent from 'Components/BaseComponent';
import {
  ControlRoom,
  IconBathRoom,
  IconBedRoom,
  IconChildRoom,
  IconHallwayRoom,
  IconKitchenRoom,
  IconLivingRoom,
  IconOfficeRoom,
} from 'Components/Icons';
import { ROUTE_CONTROL_ROOMS } from 'Config/routes';
import { createTileItems } from '../../../screens/ControlsScreen';
import { IDevicesState, IRoomState } from 'Reducers/interfaces';
import {
  IDeviceItem,
  IRoomItem,
  ISensorItem,
} from 'Containers/control/Interfaces/interfaces';
import {
  DEVICE_HEAT_FLOOR_ID,
  DEVICE_LIGHT_RGB_ID,
  DEVICE_LIGHT_TOGGLER_ID,
  DEVICE_ROSETTE_ID,
} from 'Config/devices';
import { Link } from 'react-router-dom';
import ControlService from 'Services/Control';
import { devicesLoad } from 'Actions/devices';

class Rooms extends BaseComponent {
  className = 'rooms';
  state = { withoutRoom: 0, isLoadDevices: false, isLoadSensors: false };

  createTileItems: (
    items: {
      title: string;
      image: JSX.Element;
      text: string;
      link: string;
      props: IRoomItem;
      inRoomDevices: number;
    }[],
    bool: boolean,
    foo1: Function,
    foo2: Function,
    t: (str: string) => string,
  ) => JSX.Element;

  constructor(props: any) {
    super(props);
    this.createTileItems = createTileItems;
  }

  openModalEditRoom = (room: IRoomItem) => {
    this.props.onPanelOpen('EditRooms', {
      room,
      type: 'rooms',
    });
  };

  openModalEditDevices = (props: { title: string; roomId?: number }) => {
    const items: {
      devices: IDeviceItem[];
      sensors: ISensorItem[];
      light: IDeviceItem[];
      rosette: IDeviceItem[];
    } = { devices: [], sensors: [], light: [], rosette: [] };

    this.props.sensors.forEach((item: ISensorItem) => {
      if (item.room && Number(item.room.roomId) === props.roomId)
        items.sensors.push(item);

      if (!props.roomId) items.sensors.push(item);
    });
    this.props.devices.forEach((item: IDeviceItem) => {
      switch (item.type.deviceTypeId) {
        case DEVICE_ROSETTE_ID: {
          if (item.room && Number(item.room.roomId) === props.roomId)
            items.rosette.push(item);

          if (!props.roomId) items.rosette.push(item);
          break;
        }
        case DEVICE_LIGHT_TOGGLER_ID:
        // case DEVICE_LIGHT_DIMM_ID:
        case DEVICE_LIGHT_RGB_ID:
        case DEVICE_HEAT_FLOOR_ID: {
          if (item.room && Number(item.room.roomId) === props.roomId)
            items.light.push(item);

          if (!props.roomId) items.rosette.push(item);
          break;
        }
        default: {
          if (item.room && Number(item.room.roomId) === props.roomId)
            items.devices.push(item);

          if (!props.roomId) items.rosette.push(item);
        }
      }
    });

    // eslint-disable-next-line react/prop-types
    this.props.onPanelOpen('EditDevicesInRoom', { ...props, ...items });
  };

  prepareData = () =>
    Object.values<IRoomItem>(this.props.rooms).map((room) => {
      const title = room.title.toLowerCase();
      let image = ControlRoom;
      const inRoomDevices: string[] = [];

      this.props.sensors.forEach(
        (sensor: ISensorItem) =>
          sensor.room &&
          parseInt(sensor.room.roomId) === room.roomId &&
          inRoomDevices.push(sensor.title),
      );
      this.props.devices.forEach(
        (device: IDeviceItem) =>
          device.room &&
          parseInt(device.room.roomId) === room.roomId &&
          inRoomDevices.push(device.title),
      );

      if (title.indexOf('ванная') !== -1 || title.indexOf('bathroom') !== -1)
        image = IconBathRoom;
      if (title.indexOf('спальня') !== -1 || title.indexOf('sleeping') !== -1)
        image = IconBedRoom;
      if (title.indexOf('детская') !== -1 || title.indexOf('child room') !== -1)
        image = IconChildRoom;
      if (title.indexOf('кухня') !== -1 || title.indexOf('kitchen') !== -1)
        image = IconKitchenRoom;
      if (title.indexOf('кабинет') !== -1 || title.indexOf('office') !== -1)
        image = IconOfficeRoom;
      if (
        title.indexOf('гостинная') !== -1 ||
        title.indexOf('living room') !== -1
      )
        image = IconLivingRoom;
      if (title.indexOf('прихожая') !== -1 || title.indexOf('hall') !== -1)
        image = IconHallwayRoom;

      return {
        title: room.title,
        image,
        text: '',
        link: ROUTE_CONTROL_ROOMS,
        props: room,
        inRoomDevices: inRoomDevices.length,
      };
    });

  componentDidUpdate(
    prevProps: Readonly<{ sensors: ISensorItem[]; devices: IDeviceItem }>,
  ) {
    if (
      this.props.devices !== prevProps.devices ||
      this.props.sensors !== prevProps.sensors
    ) {
      let counter = 0;
      const devices = [...this.props.devices, ...this.props.sensors];

      devices.forEach((device) => !device.room && counter++);

      this.setState({ withoutRoom: counter });
    }
  }

  componentDidMount() {
    super.componentDidMount();

    ControlService.getDevices(
      { apartmentId: this.props.apartmentId },
      (res) => {
        this.props.onDevicesLoad(res.deviceList, 'devices');
        this.setState({ isLoadDevices: true });
      },
    );

    ControlService.getSensors(
      { apartmentId: this.props.apartmentId },
      (res) => {
        this.props.onDevicesLoad(res.sensorList, 'sensors');
        this.setState({ isLoadSensors: true });
      },
    );
  }

  render() {
    const { t } = this.props;
    if (!this.state.isLoadDevices && !this.state.isLoadSensors)
      return this.getLoader();
    if (!this.state.isLoadDevices && !this.state.isLoadSensors)
      return this.getLoader();

    return (
      <div className={this.getPageClasses()}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 15px',
          marginBottom: '20px',
        }}>
          <h1 style={{
            fontSize: '1.5rem',
            margin: 0,
            padding: 0
          }}>{this.props.t('rooms.title')}</h1>
          <button
            type='button'
            className='btn btn_light-blue btn_add'
            onClick={() => this.props.onPanelOpen('ConfigController', {
              type: 'addRoom',
              title: t('panels.config_controller.add_room.title'),
            })}
          >
            +
          </button>
        </div>
        <ul className='tile-items tile-items_main-menu'>
          <li key='common' className={`tile-items__wrap two-col`}>
            <Link
              to={ROUTE_CONTROL_ROOMS}
              className={`tile-items__item tile-items__icon-type tile-items__in-room-devices`}
              onClick={() => {
                if (this.state.withoutRoom > 0)
                  this.openModalEditDevices({
                    title: t('rooms.common_room'),
                  });
              }}
            >
              <div className='tile-items__fact'>
                <span className='tile-items__image'>{ControlRoom}</span>
              </div>
              <div className='tile-items__fact'>
                <span className='tile-items__title'>
                  {t('rooms.common_room')}
                </span>
                {this.state.withoutRoom > 1 && (
                  <span className='tile-items__text'>
                    {this.state.withoutRoom}{' '}
                    {t('control_menu.qty_devices.more_one')}
                  </span>
                )}
                {this.state.withoutRoom === 1 && (
                  <span className='tile-items__text'>
                    {this.state.withoutRoom} {t('control_menu.qty_devices.one')}
                  </span>
                )}
              </div>
            </Link>
          </li>

          {this.createTileItems(
            this.prepareData(),
            true,
            this.openModalEditRoom,
            this.openModalEditDevices,
            this.props.t,
          )}
        </ul>
      </div>
    );
  }
}

export default connect(
  (state: { rooms: IRoomState; devices: IDevicesState }) => ({
    rooms: state.rooms,
    sensors: state.devices.sensors,
    devices: state.devices.devices,
  }),
  {
    onPanelOpen: panelOpen,
    onDevicesLoad: devicesLoad,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
)(withTranslation()(Rooms));
