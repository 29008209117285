const component = 'Panel';

export const EVENT_PANEL_OPEN = `${component}/PANEL_OPEN`;
export const EVENT_PANEL_CLOSE = `${component}/PANEL_OPEN`;

export function panelOpen(type, props = {}) {
  return (dispatch) => {
    dispatch({
      type: EVENT_PANEL_OPEN,
      panelType: type,
      props: props,
    });
  };
}

export function panelClose() {
  return (dispatch) => {
    dispatch({
      type: EVENT_PANEL_CLOSE,
    });
  };
}
