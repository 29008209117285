import {
  SET_APARTMENTS,
  IApartment,
  SET_ACTIVE_APARTMENT,
} from 'Actions/apartments';

export type IApartmentsState = {
  active: IApartment | null;
  list: IApartment[];
};

const initialState: IApartmentsState = {
  active: null,
  list: [],
};

export const apartments = (
  state = initialState,
  action:
    | { type: 'SET_APARTMENTS'; payload: IApartment[] }
    | { type: 'SET_ACTIVE_APARTMENT'; payload: IApartment },
) => {
  if (action.type === SET_APARTMENTS) {
    return {
      active: action.payload[0],
      list: action.payload,
    };
  }

  if (action.type === SET_ACTIVE_APARTMENT) {
    return {
      ...state,
      active: action.payload,
    };
  }

  return state;
};
