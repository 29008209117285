import React, { useState } from 'react';
import styles from 'Components/advancedSettings/Light/styles.module.scss';
import { IconSun } from 'Components/Icons';

type Props = {
  children: JSX.Element | null;
  title: string;
  backgroundToggler: string;
  onRangeChange: (value: number) => React.SetStateAction<number>;
  rangeSliderValue: number;
  isDisabled: boolean;
};

const LightToggler = ({
  children,
  title,
  backgroundToggler,
  onRangeChange,
  rangeSliderValue,
  isDisabled,
}: Props): JSX.Element => {
  const [value, setValue] = useState<number>(rangeSliderValue);

  return (
    <div className={styles.brightnessContainer}>
      {children ? (
        children
      ) : (
        <h3>
          {title} {value}%
        </h3>
      )}
      <input
        className={styles.rangeSlider}
        type='range'
        value={value}
        min={0}
        max={100}
        step={1}
        disabled={isDisabled}
        onChange={(event) => {
          setValue(Number(event.target.value));
          onRangeChange(Number(event.target.value));
        }}
      />
      <div className={styles.brightnessWrapper}>
        <div
          className={styles.overlayToggler}
          style={{
            height: `${100 - value}%`,
          }}
        />
        <div
          className={styles.brightnessToggler}
          style={{
            background: `${value === 0 ? '#f6f6f6' : backgroundToggler}`,
          }}
        >
          {/*<div*/}
          {/*  className={styles.brightnessTogglerFilled}*/}
          {/*  style={{*/}
          {/*    height: `${value}%`,*/}
          {/*  }}*/}
          {/*/>*/}
          <span className={styles.iconSun} role='img'>
            {IconSun}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LightToggler;
