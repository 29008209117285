import React from 'react';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Tooltip from 'Components/Tooltip';

class ChatVotingPanel extends BaseSettingPanel {
  title = this.props.t('panels.chat_voting.title');
  className = 'chat-voting-panel';

  state = {
    addAnswer: 0,
  };

  onAddAnswer = () => {
    if (this.state.addAnswer < 4) {
      this.setState({ addAnswer: this.state.addAnswer + 1 });
    }
  };

  content() {
    const { t } = this.props;

    return (
      <div className='form form_chat-edit'>
        <form>
          <div className='form-group'>
            <div className='form-control textarea'>
              <textarea placeholder={t('panels.chat_voting.question')} />
            </div>
          </div>
          <div className='form-group'>
            <input
              className='form-control'
              type='text'
              placeholder={t('panels.chat_voting.answer_1')}
              required
            />
          </div>
          <div className='form-group'>
            <input
              className='form-control'
              type='text'
              placeholder={t('panels.chat_voting.answer_2')}
              required
            />
          </div>
          {this.state.addAnswer > 0 && (
            <div className='form-group'>
              <input
                className='form-control'
                type='text'
                placeholder={t('panels.chat_voting.answer_3')}
              />
            </div>
          )}
          {this.state.addAnswer > 1 && (
            <div className='form-group'>
              <input
                className='form-control'
                type='text'
                placeholder={t('panels.chat_voting.answer_4')}
              />
            </div>
          )}
          {this.state.addAnswer > 2 && (
            <div className='form-group'>
              <input
                className='form-control'
                type='text'
                placeholder={t('panels.chat_voting.answer_5')}
              />
            </div>
          )}
          {this.state.addAnswer > 3 && (
            <div className='form-group'>
              <input
                className='form-control'
                type='text'
                placeholder={t('panels.chat_voting.answer_6')}
              />
            </div>
          )}
          <div className='btn-form-wrap'>
            {this.state.addAnswer < 4 && (
              <button
                type='button'
                className='btn btn_secondary btn_circle'
                onClick={this.onAddAnswer}
                data-tip={t('panels.chat_voting.add_answer')}
              >
                +
              </button>
            )}
            <Tooltip place='top' type='dark' effect='solid' />
            <button type='submit' className='btn btn_primary'>
              {t('panels.chat_voting.create')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ChatVotingPanel;
