import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import Cameras from 'Components/Cameras';
import ActivateToken from 'Components/forms/ActivateToken';
import find from 'lodash/find';
import { cameraLoad, cameraUpdate } from 'Actions/video';
import VideoService from 'Services/Video';
import ControlService from 'Services/Control';
import { intercomLoad } from 'Actions/intercom';
import Preloader from 'Components/Preloader';

class Entry extends BaseComponent {
  state = {
    isCameraLoaded: false,
    isIntercomLoaded: false,
  };

  className = 'entry';

  intercomFilter() {
    return (this.props.cameras || []).length
      ? (this.props.intercom || [])
          .filter((item) => {
            const camera = this.getCamera(item);
            return Object.keys(camera).length !== 0;
          })
          .map((item) => {
            return {
              ...this.getCamera(item),
              buttons: [{ intercomId: item.intercomId }],
            };
          })
      : [];
  }

  getCamera(item) {
    return (
      find(this.props.cameras, (cam) => cam.cameraId === item.cameraId) || {}
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.cameras !== this.props.cameras || this.state !== nextState;
  }

  componentDidMount() {
    super.componentDidMount();
    VideoService.getCameras(this.props.activeApartment.apartmentId, (res) => {
      this.props.onCameraLoad(res);
      if (res.ok) {
        this.setState({ isCameraLoaded: true });
      }
    });
    const apartmentId = this.props.activeApartment.apartmentId;

    ControlService.getIntercom({ apartmentId }, (res) => {
      this.props.onIntercomLoad(res.intercomList);
      if (res.ok) {
        this.setState({ isIntercomLoaded: true });
      }
    });
  }

  render() {
    if (!this.state.isCameraLoaded && !this.state.isIntercomLoaded)
      return <Preloader />;

    const _apartment = this.props.activeApartment || { apartment: {} };

    if (!this.intercomFilter().length) return null;

    if (_apartment.tokenActivated === false) return <ActivateToken />;

    return (
      <div className={this.getPageClasses()}>
        <Cameras
          cameras={this.intercomFilter()}
          activeApartmentId={_apartment.apartmentId}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cameras: state.video.cameras,
    intercom: (state.intercom || {}).list || [],
    activeApartment: state.apartments.active,
  }),
  {
    onCameraLoad: cameraLoad,
    onCameraUpdate: cameraUpdate,
    onIntercomLoad: intercomLoad,
  },
)(Entry);
