import AuthService from 'Services/Auth';
import ApiService from 'Services/Api';
import MessengerService from 'Services/Messenger';
import { updateStorage, getStorageKey } from 'Services/Storage';
import Logger from 'Components/Logger';
import { isEmptyObj, parseJwt } from 'Services/Utils';

const isAuthorized = !!AuthService.getToken();

const UserService = {
  isAuth: () => {
    return !!AuthService.getToken();
  },

  checkSupportRole: () => {
    const token = parseJwt(UserService.getUserToken());
    return token.roles[0] === 'ROLE_SUPPORT';
  },

  checkToken: () => {
    if (!UserService.getUserToken()) return null; // token отсутствует в принципе
    const token = parseJwt(UserService.getUserToken());
    return Number(token.exp) * 1000 - Date.now() > 0;
  },

  getUser: (cb, skip = false) => {
    if (isAuthorized !== true && !skip) {
      cb({ ok: false });
      return null;
    }

    const storageUser = getStorageKey('user');
    if (!isEmptyObj(storageUser)) {
      cb(storageUser);
    }

    return ApiService.getUserData()
      .then((user) => {
        updateStorage('user', user);
        cb(user);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getUserToken: () => {
    return AuthService.getToken();
  },

  refreshToken: (cb = () => {}) => {
    return AuthService.refreshToken(
      {
        token: AuthService.getToken(),
      },
      cb,
    );
  },

  addRoom: (data) => {
    if (!AuthService.checkAccess()) return null;

    return ApiService.createRoom(data);
  },

  deleteRoom: (data, cb = function () {}) => {
    if (!AuthService.getToken()) return null;

    return ApiService.deleteRoom(data)
      .then(() => cb())
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getSettings: (cb) => {
    // const storageDashboard = getStorageKey('dashboard');
    // if (!isEmptyObj(storageDashboard) && !cacheClear) {
    //   cb(storageDashboard);
    // }

    return ApiService.getUserSettings()
      .then((_cfg) => {
        let res = _cfg || {};
        if (res.jsonData) {
          res = { ...res, jsonData: JSON.parse(res.jsonData) };
        }
        // updateStorage('dashboard', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  updateSettings: (cfg) => {
    return ApiService.updateUserSettings(cfg);
  },

  createUser: (data) => {
    if (!AuthService.checkAccess()) return null;

    return ApiService.createUser(data);
  },

  updateUser: (data, cb = function () {}) => {
    if (!AuthService.getToken()) return null;

    return ApiService.updateUserData(data)
      .then((user) => cb(user))
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getAccounts: (cb = function () {}) => {
    const storageAccounts = getStorageKey('accounts');
    if (!isEmptyObj(storageAccounts)) {
      cb(storageAccounts);
    }

    return ApiService.getAccounts()
      .then((res) => {
        const items = res.accounts || [];

        updateStorage('accounts', items);
        cb(items);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  deleteAccount: (userId, cb = function () {}) => {
    if (!AuthService.checkAccess()) return null;

    return ApiService.deleteUserAccount(userId)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  login: (data) => {
    return AuthService.login(data);
  },

  logout: () => {
    return AuthService.logout();
  },

  sendCode: (data) => {
    return AuthService.sendCode(data);
  },

  getChats: () => {
    return MessengerService.getUserChats();
  },

  getRooms: (cb) => {
    const storageRooms = getStorageKey('rooms');
    if (!isEmptyObj(storageRooms)) {
      return cb(storageRooms);
    }

    return ApiService.getHomeRooms()
      .then((res) => {
        updateStorage('rooms', res);
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  uploadAvatar: (data) => {
    return ApiService.updateUserAvatar(data);
  },

  sendToken: (data) => {
    return ApiService.sendToken(data);
  },

  getAvatar: (avatars) => {
    return ApiService.getUserAvatar(avatars);
  },

  getInvoices: (data, cb) => {
    return ApiService.getUserInvoices(data)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getAgreements: (cb) => {
    return ApiService.getUserAgreements()
      .then((res) => {
        if (res) cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getServices: (data, cb) => {
    return ApiService.getUserServices(data)
      .then((res) => {
        if (res) cb(res.amenitiesList);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  updateServices: (data, cb) => {
    return ApiService.updateUserServices(data)
      .then((res) => {
        if (typeof cb == 'function') cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getCars: (data, cb) => {
    if (!AuthService.checkAccess()) return null;

    return ApiService.getCars(data)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  createCars: (data) => {
    if (!AuthService.checkAccess()) return null;

    return ApiService.createCar(data);
  },

  deleteCar: (data, cb) => {
    if (!AuthService.checkAccess()) return null;

    return ApiService.deleteCar(data)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  addFeedback: (data, cb) => {
    if (!UserService.isAuth()) return null;

    return ApiService.addFeedback(data)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  skipFeedback: (cb) => {
    if (!UserService.isAuth()) return null;

    return ApiService.skipFeedback()
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  getFeedback: (cb) => {
    return ApiService.getFeedback()
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  getVersion: (data, cb) => {
    if (!UserService.isAuth()) return null;

    return ApiService.getVersion(data)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  inviteUser: (data) => {
    return ApiService.inviteUser(data).catch((err) => {
      Logger.log(err, 'error');
    });
  },

  getAvailableDevices: (data, cb) => {
    return ApiService.getAvailableDevices(data)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },

  getApartments: (cb) => {
    return ApiService.getApartments()
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  getPromo: (cb) => {
    return ApiService.getPromo()
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
  updatePromo: (value, cb) => {
    return ApiService.updatePromo(value)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        Logger.log(err, 'error');
      });
  },
};

export default UserService;
