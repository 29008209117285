import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseComponent from 'Components/BaseComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DEVICE_CATEGORY_LIGHT } from 'Config/devices';
import DeviceLightningWidget from 'Widgets/DeviceLighting';
import ConnectedTileItem from 'Components/TileItem';
import { devicesLoad, deviceStatusToggle } from 'Actions/devices';
import FilterDevice from 'Components/FilterDevice';
import { IFilters } from 'Reducers/filters/filters';
import { panelOpen } from 'Actions/panel';
import ControlService from 'Services/Control';
import { IItem } from 'Containers/control/Interfaces/interfaces';
import { IApartment } from 'Actions/apartments';

class Lighting extends BaseComponent {
  className = 'lighting';
  state = {
    items: [],
    isLoading: true,
  };

  prepareData = (items) => {
    const { t } = this.props;
    const filters = this.getFiltersData(
      this.props.filters,
      this.props.location.pathname,
    );
    const { statusFilter, roomFilter } = filters;

    if (statusFilter !== null) {
      const lightsItems = items.filter(
        (item) => item.category.name === DEVICE_CATEGORY_LIGHT,
      );
      let filteredItems: IItem[] = [];

      if (roomFilter !== null) {
        filteredItems = items.filter(
          (item: { room: { title: string }; status: number }) => {
            if (
              item.room &&
              item.room.title === roomFilter &&
              item.status === statusFilter
            ) {
              return item;
            }
            return false;
          },
        );
      } else {
        filteredItems = lightsItems.filter((item: { status: number }) => {
          if (statusFilter === item.status) return item;
          return false;
        });
      }

      if (filteredItems.length > 0) {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={t('lighting.title')} />
            <ul className='tile-items'>
              {filteredItems.map((item) => {
                return (
                  <ConnectedTileItem key={item.deviceId} className='led'>
                    <DeviceLightningWidget
                      {...item}
                      onStatusChange={this.props.onStatusToggle}
                      panelOpen={this.props.panelOpen}
                    />
                  </ConnectedTileItem>
                );
              })}
            </ul>
          </div>
        );
      } else {
        return (
          <div className={this.getPageClasses()}>
            <FilterDevice title={t('lighting.title')} />
          </div>
        );
      }
    } else {
      const categories: any = [];

      this.props.categories.forEach((category) => {
        categories[category.categoryId] = {
          ...category,
          items: [],
        };
      });

      items.forEach((item) => {
        const category = item.category ? item.category : { categoryId: 0 };

        if (category.name === DEVICE_CATEGORY_LIGHT) {
          categories[category.categoryId].items.push(item);
        }
      });

      return (
        <div className={this.getPageClasses()}>
          <FilterDevice title={t('lighting.title')} />
          <ul className='tile-items'>
            {Object.keys(categories).map((i) => {
              const category = categories[i];

              if (category.items && category.items.length > 0) {
                return Object.keys(category.items).map((itemId) => {
                  const item = category.items[itemId];
                  return (
                    <ConnectedTileItem key={itemId} className='led'>
                      <DeviceLightningWidget
                        {...item}
                        onStatusChange={this.props.onStatusToggle}
                        panelOpen={this.props.panelOpen}
                      />
                    </ConnectedTileItem>
                  );
                });
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      );
    }
  };

  shouldComponentUpdate(nextProps, _nextState) {
    if (this.state.isLoading !== _nextState.isLoading) return true;
    return (
      nextProps.items !== this.props.items ||
      nextProps.filters !== this.props.filters
    );
  }

  componentDidMount() {
    super.componentDidMount();

    ControlService.getDevices(
      { apartmentId: this.props.apartmentId },
      (res) => {
        this.props.onDevicesLoad(res.deviceList, 'devices');
        this.setState({ isLoading: false });
      },
    );
  }

  render() {
    if (this.state.isLoading) return this.getLoader();

    if (
      this.props.items &&
      this.props.items.length > 0 &&
      this.props.categories.length > 0
    ) {
      return this.prepareData(this.props.items);
    }
    return (
      <div className={this.getPageClasses()}>
        <FilterDevice title={this.props.t('lighting.title')} />
      </div>
    );
  }
}

const mapStateToProps = (state: {
  devices: {
    devices: unknown;
    categoriesTypes: unknown;
  };
  filters: IFilters;
  apartments: {
    active: IApartment;
  };
}) => {
  return {
    items: state.devices.devices,
    categories: state.devices.categoriesTypes,
    filters: state.filters.pages,
    apartmentId: state.apartments.active.apartmentId,
  };
};

export default connect(mapStateToProps, {
  onStatusToggle: deviceStatusToggle,
  onDevicesLoad: devicesLoad,
  panelOpen,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
})(withTranslation()(withRouter(Lighting)));
