export const NOTIFY_PAGE_COUNT = 20;

export const NOTIFY_CATEGORY_UNKNOW = 0; // неправильная категория смартхома
export const NOTIFY_CATEGORY_DEVICE = 1;
export const NOTIFY_CATEGORY_SENSOR = 2;
export const NOTIFY_CATEGORY_MESSAGE = 3;
export const NOTIFY_CATEGORY_INTERCOM = 4;
export const NOTIFY_CATEGORY_PAYMENT = 5;
export const NOTIFY_CATEGORY_DEVICE_INCLUDE = 6;
export const NOTIFY_CATEGORY_ACCESS = 7;

export const NOTIFY_STATUS_NEW = 0; // TODO
export const NOTIFY_STATUS_ACTIVE = 1; // NEW
export const NOTIFY_STATUS_DISACTIVE = 2; // READ
export const NOTIFY_STATUS_DELETE = 3; // DELETED

export const NOTIFY_TEXT_ACCESS_TITLE = (t) =>
  t('commons.statuses.NOTIFY_TEXT_ACCESS_TITLE');
export const NOTIFY_TEXT_ACCESS_OPENED = (t) =>
  t('commons.statuses.NOTIFY_TEXT_ACCESS_OPENED');
export const NOTIFY_TEXT_ACCESS_CALLED = (t) =>
  t('commons.statuses.NOTIFY_TEXT_ACCESS_CALLED');

// Devices notify statuses
export const NOTIFY_STATUS_ON = true;
export const NOTIFY_STATUS_OFF = false;

export const NOTIFY_TYPE_WARNING = 1;
export const NOTIFY_TYPE_SUCCESS = 2;
export const NOTIFY_TYPE_NOTICE = 3;

export const SENSOR_AND_DEVICE_KEY = 'ApartamentNotices';
export const RFID_KEY = 'RfidNotices';
export const CHAT_KEY = 'MessagesNotices';

export const NOTIFY_GROUPS = [
  // {
  //   id: 0,
  //   key: 'phone',
  //   category: NOTIFY_CATEGORY_INTERCOM,
  //   tabTitle: (
  //     <div className="phone">
  //       {IconPhoneDoor}
  //       <span>Домофония</span>
  //     </div>
  //   )
  // },
  {
    key: SENSOR_AND_DEVICE_KEY,
    category: [NOTIFY_CATEGORY_SENSOR, NOTIFY_CATEGORY_DEVICE],
  },
  {
    key: RFID_KEY,
    category: NOTIFY_CATEGORY_ACCESS,
  },
  // {
  //   key: CHAT_KEY,
  //   category: NOTIFY_CATEGORY_MESSAGE
  // }
];
