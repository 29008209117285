import React from 'react'
import BaseComponent from 'Components/BaseComponent'
import { MenuProvider } from 'react-contexify'
import LongPress from 'react-long'

class ContextMenuProvider extends BaseComponent {
  render() {
    return (
      <LongPress time={1000}>
        <MenuProvider {...this.props} />
      </LongPress>
    )
  }
}

export default ContextMenuProvider
