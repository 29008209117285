import {
  EVENT_CONTROLLER_ADD,
  EVENT_CONTROLLERS_LOAD,
  EVENT_DEVICE_CONNECTING,
  EVENT_DEVICE_CONNECTED,
  EVENT_DEVICE_EXCLUDED,
  EVENT_CONTROLLER_CACHE_CLEAN,
  EVENT_CONTROLLERS_REMOVE,
} from 'Actions/controllers';

const initialState = {
  list: [],
  foundedDevice: {},
  addedDevice: {},
  excludedDevice: {},
};

export function controllers(state = initialState, action) {
  let data = {};
  let eventData = {};

  switch (action.type) {
    case EVENT_CONTROLLERS_LOAD:
      data = {
        ...state,
      };
      if (!action.event) return data;

      data.list = [...action.event];

      return data;
    case EVENT_CONTROLLER_ADD:
      data = {
        ...state,
      };
      data.list.unshift(action.controller);

      return data;
    case EVENT_DEVICE_CONNECTING:
      eventData = JSON.parse(action.event.data);
      data = {
        ...state,
      };
      data.foundedDevice = eventData.data;

      return data;
    case EVENT_DEVICE_CONNECTED:
      eventData = JSON.parse(action.event.data);
      data = {
        ...state,
      };
      data.addedDevice = eventData.data;
      data.foundedDevice = {};
      data.excludedDevice = {};

      return data;
    case EVENT_DEVICE_EXCLUDED:
      eventData = JSON.parse(action.event.data);
      data = {
        ...state,
      };
      data.excludedDevice = eventData.data;
      data.addedDevice = {};

      return data;
    case EVENT_CONTROLLERS_REMOVE:
      const controllers = [
        ...state.list.filter(
          (controller) => action.payload.smarthomeId !== controller.smarthomeId,
        ),
      ];

      return {
        ...state,
        list: controllers,
      };
    case EVENT_CONTROLLER_CACHE_CLEAN:
      data = {
        ...state,
      };

      switch (action.cacheType) {
        case 'exclude':
          data.excludedDevice = {};
          break;
        case 'add':
          data.addedDevice = {};
          break;
        case 'find':
          data.foundedDevice = {};
          break;
        default:
          data.excludedDevice = {};
          data.addedDevice = {};
          data.foundedDevice = {};
      }

      return data;
    default:
      return state;
  }
}
