import React from 'react';
import { connect } from 'react-redux';
import BaseModal from 'Components/BaseModal';
import Form from 'Components/Form';
import UserService from 'Services/User';
import moment from 'moment';
import { fioUpdate } from 'Actions/app';

class UpdateUserAccountModal extends BaseModal {
  id = 'modalChangeFIO';
  title = this.props.t('modals.user_account_settings.title');
  state = {
    title: '',
    selectedPeoples: [],
    updateUserForm: null,
  };

  generateId(fieldName) {
    let id = `form_${this.className}-edit`;

    if (fieldName) {
      id += `__${fieldName}`;
    }

    return id;
  }

  componentDidMount() {
    super.componentDidMount();
    const { t } = this.props;
    if (this.state.updateUserForm || !this.props.userData.userId) return;

    const user = this.props.userData;

    this.setState({
      updateUserForm: {
        action: UserService.updateUser,
        className: 'form_user-edit',
        clean: false,
        fields: {
          fname: {
            id: this.generateId('fname'),
            type: 'text',
            label: `${t('commons.inputs.first_name')}:`,
            className: 'input_full-width',
            validationRules: ['required'],
            value: user.fname,
          },
        },
        formatBeforeSend: (data) => {
          data.id = user.userId;
          data.birthDate = moment(user.birthDate).format('YYYY-MM-DD');
          data.email = user.email;
          data.phone = user.phone.trim();
          data.gender = user.gender;

          return data;
        },
        btnSubmit: t('commons.buttons.confirm'),
        btnClass: 'btn_primary',
        callback: (res, data) => {
          this.props.onFioUpdate({
            fname: data.fname,
          });
          this.props.onCloseModal();
        },
      },
    });
  }

  onPeoplesChange = (peoples) => {
    this.setState({
      selectedPeoples: peoples,
    });
  };

  getPeoples = () => {
    let list = Object.keys(this.props.peoples).map((userId) => {
      let user = this.props.peoples[userId];

      return { value: userId, label: `${user.fname} ${user.sname}` };
    });

    return list;
  };

  onTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  content() {
    if (!this.state.updateUserForm) return null;

    return <Form {...this.state.updateUserForm} />;
  }
}

export default connect(null, {
  onFioUpdate: fioUpdate,
})(UpdateUserAccountModal);
