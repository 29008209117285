import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Scrollbar from 'Components/BaseScrollbar';
import ScrollBarConfig from 'Config/scrollBar';
import { NAV_ITEMS } from 'Config/navigation';
import { CHAT_RECONNECT_TIME } from 'Config/chat';
import Header from 'Components/Header';
import Navigation from 'Components/navigation/index';
import Router from 'Components/Router';
import SocketService from 'Services/Socket';
import { SipConnect } from 'Services/Sip';
import { appLoad, agreementsLoad } from 'Actions/app';
import { notifyLoad, toggleNotification } from 'Actions/notify';
import { addNotice } from 'Actions/push';
import { walletLoad } from 'Actions/wallet';
import AppleIcon from 'Assets/appApple.png';
import RuStoreIcon from 'Assets/appRuStore.png';
import {
  categoriesTypesLoad,
  devicesTypesLoad,
  sensorsTypesLoad,
} from 'Actions/devices';
import {
  socketConnect,
  socketMessage,
  socketError,
  socketClose,
} from 'Actions/socket';
import {
  onAnswer,
  onDecline,
  onVideo,
  onHold,
  onConnected,
  onDisconnected,
  onNewRTCSession,
  onRegistered,
  onUnregistered,
  onRegistrationFailed,
} from 'Actions/sip';
import { controllersLoad } from 'Actions/controllers';
import UserLogin from 'Containers/user/login/index';
import VideoPlayerModal from 'Components/videoCameras/PlayerModal';
import NotifyService from 'Services/Notify';
import UserService from 'Services/User';
import ControlService from 'Services/Control';
// import WalletService from 'Services/Wallet';
import Modals from 'Components/modals/index';
import Panels from 'Components/panels/index';
import Push from 'Components/Push';
import SipClient from 'Components/SipClient';
import { openModal } from 'Actions/modal';
import {
  NOTIFY_CATEGORY_MESSAGE,
  NOTIFY_CATEGORY_UNKNOW,
  NOTIFY_STATUS_ACTIVE,
} from 'Config/notify';
import { isDev, isMobile } from 'Config';
import { mainNavigationToggle, switchGrid } from 'Actions/navigation';
import Notifications from 'Components/notifications/index';
import Top from 'Components/Top';
import ControllerService from 'Services/Controller';
import { onPlayerClose } from 'Actions/video';
import { setFilters } from 'Actions/filters/filters';
import { withTranslation } from 'react-i18next';
import { setLanguage } from 'Actions/language';
import { loadUserData } from 'Actions/userData';
import DialogWindow from 'Components/dialogWindows';
import Preloader from 'Components/Preloader';
import Rating from 'Components/Rating';
import UpdateApp from 'Components/UpdateApp';
import { roomsLoad } from 'Actions/rooms/rooms';
import ErrorBoundary from 'Containers/ErrorBoundary';
import { getAvailableDevices } from 'Actions/availableDevices';
import { setActiveApartment, setApartments } from 'Actions/apartments';
import PromoImage from '../assets/images/logo.png';
import Promo from 'Components/Promo';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const versionWebview = require('../../package.json').version;

class App extends Component {
  state = {
    showFeedback: false,
    isOldVersion: false,
    isLoad: false,
    showPromo: true,
  };

  constructor(props) {
    super(props);

    this.ScrollBarCfg = new ScrollBarConfig();

    // this.bgClass = 'app-content__bg bg1' + (Math.floor(Math.random() * 7) + 1); // 1
    this.bgClass = 'app-content__bg bg1';
  }

  socketBindings() {
    let socket = SocketService.connect();
    socket.onopen = this.props.onOpen;
    socket.onmessage = this.props.onMessage;
    socket.onclose = (e) => {
      this.props.onClose(e);
      setTimeout(() => {
        socket = SocketService.reconnect();
        this.socketBindings();
      }, CHAT_RECONNECT_TIME);
    };
    socket.onerror = this.props.onError;
  }

  componentDidUpdate(prevProps) {
    if (!this.sipConnect && this.props.sipConfig !== null) {
      this.sipConnect = SipConnect({
        config: this.props.sipConfig,
        actions: {
          onConnected: this.props.onConnected,
          onDisconnected: this.props.onDisconnected,
          onNewRTCSession: this.props.onNewRTCSession,
          onRegistered: this.props.onRegistered,
          onUnregistered: this.props.onUnregistered,
          onRegistrationFailed: this.props.onRegistrationFailed,
        },
        newSessionCallback: (conn) => {
          // TODO: think about it
          setTimeout(() => {
            const sipClient = this.props.sipClient;

            if (sipClient.answer === false && sipClient.ringing === true) {
              this.props.onDisconnected();
            }
          }, 30000);

          conn
            .on('failed', (e) => {
              if (e.originator === 'remote') this.props.onDisconnected();
            })
            .on('ended', (e) => {
              if (e.originator === 'remote') this.props.onDisconnected();
            });
        },
      });
    }
    if (prevProps.router.location.key !== this.props.router.location.key) {
      this.ScrollBarCfg.toTop();
    }

    if (this.props.language !== prevProps.language) {
      const { i18n } = this.props;

      const changeLang = (lang) => {
        i18n.changeLanguage(lang);
      };
      changeLang(this.props.language.lang);
    }

    if (
      this.props.activeApartment !== prevProps.activeApartment &&
      this.props.activeApartment
    ) {
      const { apartmentId } = this.props.activeApartment;

      window.sendMessageToWebview({
        action: 'setApartmentId',
        payload: apartmentId,
      });

      UserService.getAvailableDevices(
        {
          apartment_id: apartmentId,
        },
        (res) =>
          this.props.onGetAvailableDevices({
            cameras: res.cameras,
            counters: res.counters,
            devices: res.devices,
            lights: res.lights,
            rooms: res.rooms,
            scenarios: res.scenarios,
            sensors: res.sensors,
            sockets: res.sockets,
          }),
      );

      ControllerService.getControllers({ apartmentId }, (res) => {
        this.props.onControllersLoad(res.smarthomeList);
      });
    }
  }

  componentDidMount() {
    if (!isDev()) {
      const checkVersion = () => {
        const versionBack = 154;
        const versionApp = Number(window.versionApp.split('.').join(''));

        if (versionBack > versionApp) this.setState({ isOldVersion: true });
      };

      const interval = setInterval(() => {
        if (window.versionApp) {
          checkVersion();
          clearInterval(interval);
        }
      }, 50);
    }

    UserService.getUser(async (user) => {
      if (!user.ok) {
        return false;
      }
      await this.props.onLoad(user);

      const { t } = this.props;

      this.socketBindings();

      NotifyService.getNotifications(
        {
          status: NOTIFY_STATUS_ACTIVE,
        },
        (res) => {
          if (res.notifyList) {
            // ВРЕМЕННАЯ ЗАГЛУШКА, ЧТОБЫ НЕ СВЕТИЛИСЬ УВЕДОМЛЕНИЯ ОТ ЧАТА
            const notices =
              res.notifyList.filter(
                (item) =>
                  !(
                    item.category === NOTIFY_CATEGORY_MESSAGE ||
                    item.category === NOTIFY_CATEGORY_UNKNOW
                  ),
              ) || [];

            this.props.onNotificationsLoad(notices);
            let text = '';

            text =
              notices.length > 0
                ? notices.length === 1
                  ? `1 ${t('notices.new_event')}`
                  : `${notices.length} ${t('notices.new_events')}`
                : null;

            if (text) {
              this.props.onAddNotice({
                group: 'g2',
                type: 'success',
                text,
                delay: 3000,
              });
            }
          }
        },
      );

      await UserService.getApartments((apartments) => {
        this.props.onSetApartments(apartments.apartmentList);
        const defaultApartmentId = apartments.apartmentList[0].apartmentId;

        UserService.getSettings((res) => {
          const updateData = {};

          if (res.jsonData) {
            this.props.onLoadUserData(res.jsonData);
          }
          if (res.jsonData && res.jsonData.filters) {
            this.props.onSetFilters(res.jsonData.filters);
          }

          if (res.jsonData && res.jsonData.language) {
            this.props.onSetLanguage(res.jsonData.language);
          } else {
            this.props.onSetLanguage(window.navigator.language.slice(0, 2));
            this.props.onLoadUserData({
              language: window.navigator.language.slice(0, 2),
            });
          }

          if (res.jsonData && res.jsonData.gridStatus) {
            this.props.toggleGrid(res.jsonData.gridStatus);
          } else {
            this.props.toggleGrid(1);
            this.props.onLoadUserData({
              statusGrid: 1,
            });
          }

          if (res.jsonData && res.jsonData.versionApp) {
            if (window.versionApp !== res.jsonData.versionApp) {
              this.props.onLoadUserData({
                versionApp: window.versionApp,
              });

              updateData.versionApp = window.versionApp;
            } else {
              this.props.onLoadUserData({
                versionApp: res.jsonData.versionApp,
              });
            }
          } else {
            this.props.onLoadUserData({
              versionApp: window.versionApp,
            });
            updateData.versionApp = window.versionApp;
          }

          if (res.jsonData && res.jsonData.versionWebview) {
            if (versionWebview !== res.jsonData.versionWebview) {
              this.props.onLoadUserData({
                versionWebview,
              });

              updateData.versionWebview = versionWebview;
            } else {
              this.props.onLoadUserData({
                versionWebview: res.jsonData.versionWebview,
              });
            }
          } else {
            this.props.onLoadUserData({
              versionWebview,
            });
            updateData.versionWebview = versionWebview;
          }

          if (res.jsonData && res.jsonData.apartmentId) {
            this.props.onLoadUserData({
              apartmentId: apartments.apartmentList.filter(
                (item) => item.apartmentId === res.jsonData.apartmentId,
              )[0].apartmentId,
            });

            this.props.onSetActiveApartment(
              apartments.apartmentList.filter(
                (item) => item.apartmentId === res.jsonData.apartmentId,
              )[0],
            );
          } else {
            this.props.onLoadUserData({
              apartmentId: defaultApartmentId,
            });
            this.props.onSetActiveApartment(
              apartments.apartmentList.filter(
                (item) => item.apartmentId === defaultApartmentId,
              )[0],
            );

            updateData.apartmentId = defaultApartmentId;
          }

          if (Object.keys(updateData).length > 0) {
            UserService.updateSettings({
              json_data: JSON.stringify({
                ...this.props.userData,
                ...updateData,
              }),
            });
          }
        });
      });

      await UserService.getAgreements((res) => {
        this.props.onAgreementsLoad(res);
      });

      await ControlService.getCategoriesTypes((res) => {
        this.props.onCategoriesTypesLoad(res);
      });

      await ControlService.getSensorsTypes((res) => {
        this.props.onSensorsTypesLoad(res);
      });

      await ControlService.getDevicesTypes((res) => {
        this.props.onDevicesTypesLoad(res);
      });

      await UserService.getRooms((res) => {
        this.props.onRoomsLoad(res.roomList);
      });

      this.setState({ isLoad: true });

      UserService.getFeedback((res) => {
        if (res.ok && res.showFeedback)
          setTimeout(
            () => this.setState({ showFeedback: res.showFeedback }),
            3000,
          );
      });

      // UserService.getPromo((res) => {
      //   if (res.ok) {
      //     setTimeout(() => {
      //       this.setState({ showPromo: res.promo });
      //     }, 3000);
      //   }
      // });
    });
    window.sendMessageToWebview({ action: 'pageLoaded' });
  }

  toggleMenu = () => {
    const menuState = !this.props.rootState.navigation.isMinimized;

    this.props.onMenuToggle(menuState);
    this.props.onNotificationsToggle(false);
  };

  closeMenu = () => {
    if (window.innerWidth < 841 && !this.props.navigation.isMinimized) {
      this.props.onMenuToggle(true);
    }
  };

  render() {
    const location = this.props.router.location;

    if (this.state.isOldVersion) return <UpdateApp />;

    if (!UserService.isAuth()) {
      window.sendMessageToWebview({ action: 'needToken' });
    }

    if (!UserService.isAuth() || !UserService.getUserToken()) {
      if (location.pathname !== '/login') {
        this.props.history.push('/login');
      }

      return (
        <ConnectedRouter history={this.props.history}>
          <>
            <UserLogin
              user={this.props.app.user}
              onAddNotice={this.props.onAddNotice}
            />
            <Push ignoreGroup='g2' />
          </>
        </ConnectedRouter>
      );
    }

    if (!this.state.isLoad) return <Preloader />;

    if (
      UserService.isAuth() &&
      this.props.app.user.active === true &&
      location.pathname === '/login'
    ) {
      this.props.history.push('/');
    }

    if (location.pathname === '/intercom/call') {
      return (
        <>
          <Router />
        </>
      );
    }

    if (this.state.showPromo)
      return (
        <Promo
          image={PromoImage}
          onClose={() => {
            UserService.updatePromo(true, () => {
              this.setState({ showPromo: false });
            });
          }}
          closable={false}
        >
          <p
            style={{
              fontSize: '18px',
            }}
          >
            Данная версия приложения больше недоступна. Чтобы не потерять доступ, пожалуйста, обновите
            приложение в магазине приложений. <br />С уважением команда
            <span style={{ color: '#3759ca', fontSize: '18px' }}> T.one</span>
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <a
              href='https://www.rustore.ru/catalog/app/com.tone.client'
              target='_blank'
            >
              <img
                src={RuStoreIcon}
                alt='appRuStore'
                style={{
                  width: '140px',
                }}
              />
            </a>
            <a
              href='https://apps.apple.com/ru/app/t-one-app/id1479967254'
              target='_blank'
            >
              <img
                src={AppleIcon}
                alt='appApple'
                style={{
                  width: '140px',
                }}
              />
            </a>
          </div>
        </Promo>
      );

    return (
      <ConnectedRouter history={this.props.history}>
        <div className={`app-container${isMobile ? ' isMobile' : ''}`}>
          <SipClient
            intercom={this.props.intercom}
            userData={this.props.app.user}
            client={this.props.sipClient}
            actions={{
              onAnswer: this.props.onAnswer,
              onDecline: this.props.onDecline,
              onVideo: this.props.onVideo,
              onHold: this.props.onHold,
            }}
          />
          <VideoPlayerModal
            isOpen={this.props.videoPlayer.open}
            onClose={this.props.onPlayerClose}
            camera={this.props.videoPlayer.cam}
          />
          <div
            className={`app-container__wrapper${
              this.props.modal.isModalOpen || this.props.videoPlayer.open
                ? ' modal-open'
                : ''
            }`}
          >
            <div
              className={`app-content${
                location.pathname.split('/')[1] === 'messenger'
                  ? ' content_messenger'
                  : ''
              }`}
              onClick={this.closeMenu}
            >
              <div className={this.bgClass} />
              {!this.state.showFeedback && <Push />}
              <ErrorBoundary>
                <Scrollbar cfg={this.ScrollBarCfg}>
                  <Header
                    wallet={this.props.wallet}
                    active={this.props.app.user.active}
                    notices={this.props.notices}
                    onNotificationsOpen={this.props.onNotificationsToggle}
                    t={this.props.t}
                  />
                  <div
                    className={`app-content__inner${
                      location.pathname.split('/')[1] === 'messenger'
                        ? ' messenger'
                        : ''
                    }`}
                  >
                    <Router />
                  </div>
                </Scrollbar>
              </ErrorBoundary>
            </div>
            <Navigation items={NAV_ITEMS(this.props.t)} t={this.props.t} />
          </div>
          <ErrorBoundary>
            {!this.props.notificationsOpen ? null : (
              <Notifications t={this.props.t} />
            )}
          </ErrorBoundary>
          <Modals />
          {this.props.dialogWindow.isOpen && (
            <DialogWindow {...this.props.dialogWindow} />
          )}
          <Panels />
          {location.pathname.split('/')[1] === 'news' &&
            location.pathname.split('/')[2] && (
              <Top scrollbar={this.ScrollBarCfg} />
            )}
          {this.state.showFeedback && (
            <Rating
              onHiddenModal={() => this.setState({ showFeedback: false })}
            />
          )}
        </div>
      </ConnectedRouter>
    );
  }
}

export default connect(
  (state) => ({
    rootState: state,
    navigation: state.navigation,
    modal: state.modal,
    router: state.router,
    wallet: state.wallet,
    app: state.app,
    sipConfig: state.sip.config,
    sipClient: state.sip.client,
    intercom: state.intercom.list || [],
    notices: state.notify.notices,
    videoPlayer: state.video.player,
    notificationsOpen: state.notify.notificationsOpen,
    dialogWindow: state.dialogWindow,
    language: state.language,
    userData: state.userData,
    activeApartment: state.apartments.active,
  }),
  {
    // socket actions
    onOpen: socketConnect,
    onClose: socketClose,
    onMessage: socketMessage,
    onError: socketError,

    // sip actions
    onConnected,
    onDisconnected,
    onNewRTCSession,
    onRegistered,
    onUnregistered,
    onRegistrationFailed,
    onAnswer,
    onDecline,
    onVideo,
    onHold,

    // common actions
    onModalOpen: openModal,
    onLoad: appLoad,
    onAgreementsLoad: agreementsLoad,
    onWalletLoad: walletLoad,
    onCategoriesTypesLoad: categoriesTypesLoad,
    onDevicesTypesLoad: devicesTypesLoad,
    onSensorsTypesLoad: sensorsTypesLoad,
    onMenuToggle: mainNavigationToggle,
    onNotificationsToggle: toggleNotification,
    onNotificationsLoad: notifyLoad,
    // onNotificationsTypesLoad: notifyTypesLoad,
    onControllersLoad: controllersLoad,
    onPlayerClose: onPlayerClose,
    onAddNotice: addNotice,
    onLoadUserData: loadUserData,
    toggleGrid: switchGrid,
    onSetFilters: setFilters,
    onSetLanguage: setLanguage,
    onRoomsLoad: roomsLoad,
    onGetAvailableDevices: getAvailableDevices,
    onSetApartments: setApartments,
    onSetActiveApartment: setActiveApartment,
  },
)(withTranslation()(App));
