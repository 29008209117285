import React from 'react';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Config from 'Config';

class ChatSettingsPanel extends BaseSettingPanel {
  title = this.props.t('panels.chat_settings.title');
  className = 'chat-setting-panel';

  userList = (
    <div className='chat-users'>
      <ul className='chat-users-list'>
        {Object.keys(this.props.data).map((u) => {
          const user = this.props.data[u];

          user.avatar = `${Config.urls.uploads}/user_${user.user_id}.jpeg`;

          return (
            <li className='chat-user-card-min' key={u}>
              <span className='chat-user-card-min__img'>
                <span className='chat-user-card-min__img-default' />
                <span
                  className='chat-user-card-min__img-avatar'
                  style={{
                    backgroundImage: `url('${user.avatar}')`,
                  }}
                />
              </span>
              <span className='chat-user-card-min__text'>
                {user.fname + ' ' + user.mname}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );

  content() {
    return this.userList;
  }
}

export default ChatSettingsPanel;
