import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import InputMask from 'react-input-mask';
import moment from 'moment';
import MeterService from 'Services/Meter';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'Reducers/index';
import { addNotice } from 'Actions/push';
import { useTranslation } from 'react-i18next';
import Chart from 'Components/Chart';

type IProps = {
  type: 'heating' | 'water' | 'electricity';
  unit: string;
};

const Calculation = ({ type, unit }: IProps): JSX.Element => {
  const { active } = useSelector((state: IRootState) => state.apartments);

  const [price, setPrice] = useState<string | null>(null);
  const [consumption, setConsumption] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [dateFrom, setDateFrom] = useState<string>(
    moment().subtract(1, 'month').format('DD.MM.YYYY'),
  );
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format('DD.MM.YYYY'),
  );

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onSubmit = () => {
    setIsLoading(true);

    MeterService.getCounterReceipt(
      type,
      {
        type,
        apartment_id: active && active.apartmentId,
        date_from: dateFrom,
        date_to: dateTo,
      },
      (res): void => {
        if (res.ok && res.counters && res.counters.length > 0) {
          const _chartData = {};
          let cost = 0;
          let _consumption = 0;
          res.counters.forEach((item) => {
            if (item.price && item.price >= 0) cost += item.price;
          });

          res.counters.forEach((item) => {
            if (item.valueList === undefined) {
              return;
            }
            if (item.valueList.length > 0) {
              const firstVal = Number(item.valueList[0].price);
              const lastVal = Number(
                item.valueList[item.valueList.length - 1].price,
              );
              const result = lastVal - firstVal;
              _consumption += result;

              item.valueList.forEach((val) => {
                const key = val.createdTs.split('T')[0];
                if (_chartData[key]) {
                  const y = _chartData[key].y + Math.round(Number(val.price));
                  _chartData[key] = {
                    x: key,
                    y,
                  };
                } else {
                  _chartData[key] = {
                    x: key,
                    y: Math.round(Number(val.price)),
                  };
                }
              });
            }
          });

          setChartData(Object.values(_chartData));

          if (cost === 0 && _consumption > 0) {
            setPrice(t('meters.calculation_component.without_tariff'));
          }

          if (
            (cost > 0 && _consumption > 0) ||
            (cost === 0 && _consumption === 0)
          ) {
            setPrice(`${Number(cost.toFixed(2)).toLocaleString('ru')} ₽`);
          }

          if (_consumption < 1) {
            setConsumption('< 1');
          } else {
            setConsumption(
              Number(_consumption.toFixed(2)).toLocaleString('ru'),
            );
          }
        } else if (!res.ok) {
          dispatch(
            addNotice({
              group: 'g1',
              type: 'error',
              text: t('notices.unknown_error'),
            }),
          );
        } else if (!res.counters || !res.counters.length) {
          dispatch(
            addNotice({
              group: 'g1',
              type: 'error',
              text: t('notices.meters_calculation_error'),
            }),
          );
        }
      },
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const onDateFrom = (e) => {
    const inputVal = e.target.value;
    setDateFrom(inputVal);
  };

  const onDateTo = (e) => {
    const inputVal = e.target.value;
    setDateTo(inputVal);
  };

  useEffect(() => {
    const _dateFrom = moment(dateFrom, 'DD.MM.YYYY', true).isValid();
    const _dateTo = moment(dateTo, 'DD.MM.YYYY', true).isValid();
    const currentDate = moment().format('DD.MM.YYYY');

    if (!isLoading && _dateFrom && _dateTo && currentDate !== dateFrom) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [dateTo, dateFrom, isLoading]);

  return (
    <>
      <h3 className={styles.Title}>
        {t('meters.calculation_component.title')}
      </h3>

      <div className={styles.Fact}>
        <span className={styles.Description}>
          {t('meters.calculation_component.date_from')}
        </span>

        <InputMask
          className={`form-control ${styles.InputCalendar}`}
          type='ext'
          value={dateFrom}
          onChange={onDateFrom}
          placeholder={t('commons.inputs.birthday_placeholder')}
          mask='ed.nm.ziyy'
          alwaysShowMask={false}
          formatChars={{
            i: '[0,9]',
            n: '[0-1]',
            m: '[0-9]',
            e: '[0-3]',
            d: '[0-9]',
            z: '[1-2]',
            y: '[0-9]',
          }}
        />

        {moment().format('DD.MM.YYYY') === dateFrom && (
          <span className={styles.Warning}>
            {t('meters.calculation_component.warning')}&nbsp;
            {moment().subtract(1, 'day').format('DD.MM.YYYY')}
          </span>
        )}
      </div>

      <div>
        <span className={styles.Description}>
          {t('meters.calculation_component.date_to')}
        </span>
        <InputMask
          className={`form-control ${styles.InputCalendar}`}
          type='ext'
          value={dateTo}
          onChange={onDateTo}
          placeholder={t('commons.inputs.birthday_placeholder')}
          mask='ed.nm.ziyy'
          alwaysShowMask={false}
          formatChars={{
            i: '[0,9]',
            n: '[0-1]',
            m: '[0-9]',
            e: '[0-3]',
            d: '[0-9]',
            z: '[1-2]',
            y: '[0-9]',
          }}
        />
      </div>

      {isLoading && (
        <>
          <span className={styles.Price}>
            {t('meters.calculation_component.price')}&nbsp;
            <span className={styles.SkeletonLoading} />
          </span>
          <span className={styles.Price}>
            {t('meters.calculation_component.consumption')}&nbsp;
            <span className={styles.SkeletonLoading} />
          </span>
        </>
      )}

      {!isLoading && price && (
        <span className={styles.Price}>
          {t('meters.calculation_component.price')}&nbsp;<b>{price}</b>
        </span>
      )}

      {!isLoading && consumption && (
        <span className={styles.Price}>
          {t('meters.calculation_component.consumption')}&nbsp;
          <b>
            {consumption}&nbsp;{unit}
          </b>
        </span>
      )}

      {!isLoading && chartData && chartData.length > 0 && (
        <div className={styles.GraphContainer}>
          <Chart chartData={chartData} unit={unit} />
        </div>
      )}

      <div className={styles.SubmitContainer}>
        <button
          className={'btn btn_primary btn_full-width'}
          onClick={onSubmit}
          disabled={isDisabled}
        >
          {t('commons.buttons.calculation')}
        </button>
      </div>
    </>
  );
};

export default Calculation;
