import { NOTIFY_PAGE_COUNT } from 'Config/notify';

const component = 'Notify';

export const EVENT_LOAD = `${component}/LOAD`;
export const EVENT_TYPES_LOAD = `${component}/TYPES_LOAD`;
export const EVENT_ADD = `${component}/ADD`;
export const EVENT_DELETE = `${component}/DELETE`;
export const EVENT_DELETE_ALL = `${component}/DELETE_ALL`;
export const EVENT_TOGGLE_NOTIFICATIONS = `${component}/TOGGLE_NOTIFICATIONS`;
export const EVENT_ADD_APARTAMENT_NOTICE = `${component}/EVENT_ADD_APARTAMENT_NOTICE`;
export const EVENT_ADD_RFID_NOTICE = `${component}/EVENT_ADD_RFID_NOTICE`;
export const EVENT_ADD_MESSAGES_NOTICE = `${component}/EVENT_ADD_MESSAGES_NOTICE`;
export const SWITCH_ACTIVE_CATEGORY = `${component}/SWITCH_ACTIVE_CATEGORY`;
export const EVENT_CLEAR_STATE_NOTICES = `${component}/EVENT_CLEAR_STATE_NOTICES`;

export const EVENT_NOTIFY_DEVICE = `${component}/Device`;
export const EVENT_NOTIFY_SENSOR = `${component}/Sensor`;

export function notifyLoad(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_LOAD,
      notices: obj,
    });
  };
}

// export function notifyTypesLoad(obj) {
//   return (dispatch) => {
//     dispatch({
//       type: EVENT_TYPES_LOAD,
//       types: obj
//     });
//   };
// }

export function addNotice(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ADD,
      notice: obj,
    });
  };
}

export function deleteNotice(
  notices,
  name,
  notifyPageCount = NOTIFY_PAGE_COUNT,
) {
  return (dispatch) => {
    dispatch({
      type: EVENT_DELETE,
      notices,
      name,
      notifyPageCount,
    });
  };
}

export function deleteAllNotices() {
  return (dispatch) => {
    dispatch({
      type: EVENT_DELETE_ALL,
    });
  };
}

export function toggleNotification(bool) {
  return (dispatch) => {
    dispatch({
      type: EVENT_TOGGLE_NOTIFICATIONS,
      notificationsOpen: bool,
    });
  };
}

export function addApartamentNotices(notice) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ADD_APARTAMENT_NOTICE,
      notice,
    });
  };
}

export function addRfidNotices(notice) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ADD_RFID_NOTICE,
      notice,
    });
  };
}

export function addMessagesNotices(notice) {
  return (dispatch) => {
    dispatch({
      type: EVENT_ADD_MESSAGES_NOTICE,
      notice,
    });
  };
}

export function switchActiveCategory(category) {
  return (dispatch) => {
    dispatch({
      type: SWITCH_ACTIVE_CATEGORY,
      category,
    });
  };
}

export const clearStateNotices = () => ({
  type: EVENT_CLEAR_STATE_NOTICES,
});
