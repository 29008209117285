import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BaseSettingPanel from 'Components/BaseSettingPanel';
import Form from 'Components/Form';
import { panelClose } from 'Actions/panel';

export const EditActiveApartmentType = 'edit-active-apartment';

class EditActiveApartment extends BaseSettingPanel {
  title = this.props.panelData.props.title;

  renderForm() {
    const formParams = {
      action: null,
      className: `form_${EditActiveApartmentType}`,
      fields: {
        items: {
          id: `form_${EditActiveApartmentType}`,
          type: EditActiveApartmentType,
          apartments: this.props.apartments,
        },
      },
      formatBeforeSend: () => null,
      callback: null,
    };

    return <Form {...formParams} />;
  }

  content() {
    return this.renderForm.call(this);
  }
}

const mapStateToProps = (state) => {
  return {
    isOpened: state.panel.current === 'EditActiveApartment',
    panelData: state.panel,
    apartments: state.apartments,
  };
};

export default connect(mapStateToProps, {
  onClose: panelClose,
})(withRouter(EditActiveApartment));
