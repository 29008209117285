import React from 'react';
import BaseComponent from 'Components/BaseComponent';
import VideoCamera from 'Components/videoCameras/VideoCamera';
import { IconClose } from 'Components/Icons';

class VideoPlayerModal extends BaseComponent {
  onClosePlayer() {
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    if (!this.props.isOpen) return null;

    return (
      <div className='video-player-modal-wrapper'>
        <div className='video-player-modal'>
          <VideoCamera camera={this.props.camera} autoPlay={true} />
        </div>
        <button
          type='button'
          className='modal-close'
          onClick={this.onClosePlayer.bind(this)}
        >
          {IconClose}
        </button>
      </div>
    );
  }
}

export default VideoPlayerModal;
