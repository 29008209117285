import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as Icons from 'Components/Icons';
import Tooltip from 'Components/Tooltip';
import { connect } from 'react-redux';
import { mainNavigationToggle } from 'Actions/navigation';
import { panelOpen } from 'Actions/panel';
import { getTitles } from 'Config/navigation';

class MenuItem extends Component {
  state = {
    openItem: false,
  };

  openModal = (title) => {
    const { t } = this.props;

    if (title === getTitles('applicationToUk', t))
      return this.props.panelOpen('UkAddIssue');

    if (title === 'Zwave') return this.props.panelOpen('AddZwave');
  };

  getSubmenu = (index) => {
    const item = this.props.item;

    if (!item.menu || item.menu.length === 0) return false;

    return (
      <ul>
        {item.menu.map((subitem, _index) => {
          return (
            <li key={`${index}_${_index}`}>
              <NavLink
                className={'app-navigation__menu-sublink '}
                activeClassName={item.link !== '#' ? 'link-selected' : ''}
                to={subitem.link}
                data-tip={subitem.title}
                onClick={(e) => {
                  this.openModal(subitem.title);
                  this.props.onMenuToggle(true);
                }}
              >
                <span className='app-nav-icon'>
                  {Icons[`Icon${subitem.icon}`]}
                </span>
                <span className='app-nav-text'>{subitem.title}</span>
              </NavLink>
              {this.props.rootState.navigation.isMinimized && (
                <Tooltip place='right' type='dark' effect='solid' />
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    const item = this.props.item;

    return (
      <li key={this.props.key}>
        <NavLink
          className={'app-navigation__menu-link '}
          activeClassName={item.link !== '#' ? 'link-selected' : ''}
          to={item.link}
          exact // Добавляем атрибут exact
          data-tip={item.title}
          onClick={() => {
            this.openModal(item.title);
            if (item.menu) {
              this.handleClickItem();
            } else {
              this.props.onMenuToggle(true);
            }
          }}
        >
          <span className='app-nav-icon'>{Icons[`Icon${item.icon}`]}</span>
          <span className='app-nav-text'>{item.title}</span>
        </NavLink>
        {this.state.openItem || !this.props.isMinimized
          ? this.getSubmenu(this.props.key)
          : null}
      </li>
    );
  }

  handleClickItem = () => {
    this.setState((state) => {
      return { openItem: !state.openItem };
    });
  };
}

export default connect(
  (state) => ({
    rootState: state,
    isMinimized: state.navigation.isMinimized,
  }),
  {
    onMenuToggle: mainNavigationToggle,
    panelOpen: panelOpen,
  },
)(MenuItem);
