import {
  EVENT_WALLET_LOAD
} from 'Actions/wallet';

const initialState = {
  value: 0
}

export function wallet(state = initialState, action) {
  let data = {};

  switch (action.type) {
    case EVENT_WALLET_LOAD:
      data = {
        ...state,
        ...action.event
      }

      return data;
    default:
      return state;
  }
}